import {
  Component,
  isDevMode,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { getNaicsCodes } from 'app/shared/insured-account/insured-account-helpers';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import {
  phoneValidator,
  zipCodeValidator,
  urlValidator,
} from '../../../../shared/helpers/form-helpers';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { NaicsService } from '../../../../shared/services/naics.service';
import {
  checkGWTempEmailAddress,
  checkGWTempPhoneNumber,
  validateEmailAddress,
  validateNoSpecialCharactersInAddress,
} from '../../../attune-bop/models/form-validators';

import {
  LESSORS_OF_NON_RESIDENTIAL_BUILDINGS,
  GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
  GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
} from 'app/features/attune-bop/models/constants';

@Component({
  selector: 'app-insured-account-edit-form',
  templateUrl: './account-edit-form.component.html',
})
export class AccountEditFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() open = false;
  @Input() accountId: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  form: UntypedFormGroup;
  isBogusQuote = false;
  loading = false;
  submitted = false;
  isDevMode = isDevMode();
  model: InsuredAccount = new InsuredAccount();
  organizationTypes: { [key: string]: string } = {};
  isFormReadyToMount = false;

  private sub: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private naicsService: NaicsService,
    private insuredAccountService: InsuredAccountService,
    private organizationTypeService: OrganizationTypeService,
    private amplitudeService: AmplitudeService
  ) {
    this.organizationTypeService.getOrganizationTypes().forEach((orgType) => {
      this.organizationTypes[orgType.value] = orgType.name;
    });
  }

  getCurrentState() {
    const stateField = this.form.get('state');
    return (stateField && stateField.value) || '';
  }

  ngOnInit() {
    this.initializeDependencies();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.accountId) {
      // Reset the form and reload everything, since a new account has been selected
      this.initializeDependencies();
    }
    if (changes.open) {
      // When the form is closed or opened, the form state should reset
      this.loading = false;
      this.submitted = false;
      this.isBogusQuote = false;
    }
  }

  initializeDependencies() {
    this.buildForm();
    this.loading = true;

    // This modal component is first initialized before an account is selected.
    // In that case, we can't load account details.
    if (!this.accountId) {
      return;
    }
    this.sub.add(
      this.insuredAccountService.get(this.accountId).subscribe((account: InsuredAccount) => {
        this.loading = false;
        this.model = account;
        if (parseInt(this.accountId, 10) === parseInt(this.model.id, 10)) {
          this.form.patchValue({
            companyName: account.companyName,
            phoneNumber: checkGWTempPhoneNumber(account),
            emailAddress: checkGWTempEmailAddress(account),
            addressLine1: account.addressLine1,
            addressLine2: account.addressLine2,
            city: account.city,
            state: account.state,
            zip: account.zip,
            website: account.website,
            naicsCode: account.naicsCode,
            description: account.description,
            doingBusinessAs: account.doingBusinessAs,
          });
          this.isFormReadyToMount = true;
        }
      })
    );
    this.subscribeToStateChanges();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      companyName: ['', Validators.required],
      phoneNumber: [GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER, [phoneValidator]],
      emailAddress: [GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS, [validateEmailAddress]],
      addressLine1: [
        '',
        [Validators.required, Validators.maxLength(60), validateNoSpecialCharactersInAddress],
      ],
      addressLine2: ['', [Validators.maxLength(60), validateNoSpecialCharactersInAddress]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, zipCodeValidator]],
      website: [null, urlValidator],
      naicsCode: [null],
      description: ['', Validators.maxLength(170)],
      doingBusinessAs: [''],
    });
  }

  editAccount() {
    this.submitted = true;
    if (this.form.valid) {
      const formData = this.form.value;

      if (formData.emailAddress === '') {
        formData.emailAddress = GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS;
      }
      if (formData.phoneNumber === '') {
        formData.phoneNumber = GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER;
      }

      const payload = {
        id: this.accountId,
        organizationType: this.model.organizationType,
        ...formData,
      };
      this.loading = true;
      this.sub.add(
        this.insuredAccountService.edit(payload).subscribe(() => {
          this.insuredAccountService.cachebust();
          this.naicsService.updateProductEligibility.next({
            accountId: this.accountId,
            naicsCode: formData.naicsCode as NaicsCode,
            stateCode: formData.state,
          });
          this.amplitudeService.track({
            eventName: 'account_edit',
            detail: this.accountId,
            useLegacyEventName: true,
          });
          this.closeModal.emit(true);
          this.submitted = false;
        })
      );
    }
  }

  subscribeToStateChanges() {
    this.sub.add(
      (<UntypedFormControl>this.form.get('state')).valueChanges.subscribe((state: string) => {
        if (state) {
          const naicsFormControl = this.form.get('naicsCode') as UntypedFormControl;

          // if LRO was selected before CA was selected, reset to the original naics code
          if (
            state === 'CA' &&
            naicsFormControl.value?.hash === LESSORS_OF_NON_RESIDENTIAL_BUILDINGS.hash
          ) {
            this.form.patchValue({
              naicsCode: this.model.naicsCode,
            });
          }
        }
      })
    );
  }

  addLROClass() {
    this.form.patchValue({
      naicsCode: LESSORS_OF_NON_RESIDENTIAL_BUILDINGS,
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  checkForBogusQuote() {
    if (this.form.value.companyName) {
      const name = this.form.value.companyName.toLowerCase();
      this.isBogusQuote =
        name.includes('aig') ||
        name.includes('test') ||
        name.includes('american international group');
    }
  }

  naicsFormatter(code: NaicsCode) {
    return code.description;
  }

  getNaicsCodes = (searchTerm: string) => {
    const naicsCodes = getNaicsCodes(this.naicsService, searchTerm);
    if (this.getCurrentState() === 'CA') {
      return naicsCodes.pipe(
        map((codes: NaicsCode[]) =>
          codes.filter((code: NaicsCode) => code.hash !== LESSORS_OF_NON_RESIDENTIAL_BUILDINGS.hash)
        )
      );
    }
    return naicsCodes;
  };
}
