<div class="book-mix-progress-container" *ngIf="productBookMix">
  <div class="book-mix-progress-section">
    <div class="product-badge-group product-badge-group__margin-bottom">
      <app-product-badge product="{{ productType }}" pasSource="attune"></app-product-badge>
      <div>
        <h3 class="account-policies-header-h3">{{ getSectionHeading() }}</h3>
        <p class="p p__no-margin">
          Attune <span class="gray-text">({{ getAttunePaper() }})</span>&nbsp;&nbsp;<a
            href="{{ getGuidelinesLink() }}"
            target="_blank"
            >Learn more</a
          >
        </p>
      </div>
    </div>
  </div>
  <div class="book-mix-progress-section">
    <app-activity-book-mix-progress-bar
      [targetPercentage]="productBookMix.producerCodeBookConfig.targetPercentage"
      [preferredCount]="preferredCount"
      [nonPreferredCount]="nonPreferredCount"
      [targetCount]="totalTargetPreferredPoliciesCount"
      [calculationStartDate]="productBookMix.producerCodeBookConfig.calculationStartDate"
      [productType]="productType"
    >
    </app-activity-book-mix-progress-bar>
  </div>
  <div class="book-mix-progress-section">
    <app-activity-policies-drop-drown-list [productType]="productTypeName">
    </app-activity-policies-drop-drown-list>
  </div>
</div>
