import { Component, Input, OnInit } from '@angular/core';
import {
  SERVICING_INFO_BY_CARRIER,
  ServicingInfo,
  ServicingInfoCarrier,
} from '../carrier-partner-servicing-info/helpers';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-post-bind-servicing-information',
  templateUrl: './post-bind-servicing-information.component.html',
})
export class PostBindServicingInformationComponent implements OnInit {
  @Input() carrier: ServicingInfoCarrier;
  @Input() isPayByPayPolicy: boolean = false;

  servicingInfo: ServicingInfo;

  constructor() {}

  ngOnInit(): void {
    const servicingInfo = cloneDeep(SERVICING_INFO_BY_CARRIER[this.carrier]);
    this.servicingInfo = servicingInfo;
    if (this.carrier === 'attune-gw-wc' && this.isPayByPayPolicy) {
      // Remove billing from policy servicing items
      this.servicingInfo.servicingItems = this.servicingInfo.servicingItems.filter(
        (item) => item.name !== 'Billing'
      );
    }
  }
}
