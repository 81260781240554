<app-dialog-box
  *ngIf="showOfacWarning()"
  header="OFAC Alert"
  type="warning"
  class="js-account-policies-ofac"
>
  A potential OFAC alert was identified for this account. Please contact Attune Customer Care for
  more information.
  <div class="dialog-text-links">
    <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new" class="dialog-text-link"
      >Get in touch with us</a
    >
  </div>
</app-dialog-box>
<app-insured-account-new-quote
  *ngIf="!loading && !quotes.length && !policies.length"
  [showBackButton]="false"
></app-insured-account-new-quote>
<div
  *ngIf="!showOfacWarning() && !loading && (quotes.length || policies.length)"
  class="account-policies-container"
>
  <!-- Start Billing section -->

  <app-insured-account-billing-card
    *ngIf="shouldShowBillingSection()"
    [invoices]="invoices"
    [accountId]="accountId"
  ></app-insured-account-billing-card>

  <!-- End Billing section -->

  <div *ngIf="policies.length > 0" class="product-card">
    <div class="card-header">
      <span class="card-header-title">
        <h2 class="h2">Policies</h2>
      </span>
    </div>

    <div class="quote-list js-policies-list">
      <table>
        <thead>
          <th>Line</th>
          <th>Effective date</th>
          <th>Status</th>
          <th>Policy Type</th>
          <th>Premium</th>
        </thead>
        <tbody>
          <tr *ngFor="let policy of policies" class="quote-list-row">
            <td>
              <div class="quote-badge">
                <div class="product-badge product-badge__{{ policy.product }} product-badge__small">
                  <div
                    class="
                    product-badge-carrier-icon
                    product-badge-carrier-icon__{{ policy.pasSource }}
                    product-badge-carrier-icon__small
                  "
                  ></div>
                </div>
              </div>
            </td>
            <td class="td__tabular">
              {{ policy.effectiveDate ? (policy.effectiveDate | date: 'mediumDate':'UTC') : 'N/A' }}
            </td>
            <td class="quote-table-status-td">
              <app-policy-status [policyStatus]="policy.status"></app-policy-status>
            </td>
            <td class="quote-type-info">
              <app-preference-tag
                [preferenceCategory]="policy.preferenceCategory"
              ></app-preference-tag>
              <span *ngIf="!policy.preferenceCategory">{{ policy.quoteType }}</span>
            </td>
            <td class="td__bold quote-table-narrow-td">
              <strong class="mono-number">{{ formatPremium(policy) }}</strong>
            </td>
            <td class="td__right">
              <a (click)="navigateToDetails(policy)" (keypress)="navigateToDetails(policy)"
                >Details</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="product-card">
    <div class="card-header">
      <div class="card-header-info">
        <span class="card-header-title">
          <h2 class="h2">Quotes</h2>
        </span>
      </div>
      <a [routerLink]="getRouterLinkForNewQuote()">
        <button class="button button__secondary button__small button__no-margin">New quote</button>
      </a>
    </div>
    <div *ngIf="!quotes.length" class="quote-list">There are no open quotes for this account.</div>

    <div *ngIf="quotes.length" class="quote-list js-quotes-list">
      <table>
        <thead>
          <th>Line</th>
          <th>Quote name</th>
          <th>Status</th>
          <th>Quote Type</th>
          <th>Premium</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let quote of quotes">
            <ng-container *ngIf="quote.product === 'bundle'">
              <tr class="quote-list-row">
                <td>
                  <div class="product-badge-group">
                    <div class="product-badge product-badge__bop-plus product-badge__small">
                      <div
                        class="product-badge-carrier-icon product-badge-carrier-icon__attune_gw"
                      ></div>
                    </div>
                    <div class="product-badge product-badge__cyber_admitted product-badge__small">
                      <div
                        class="product-badge-carrier-icon product-badge-carrier-icon__coalition"
                      ></div>
                    </div>
                  </div>
                </td>
                <td
                  class="td__tabular"
                  (click)="quote.expanded = !quote.expanded"
                  (keypress)="quote.expanded = !!quote.expanded"
                >
                  Bundled
                  <span
                    class="button-icon"
                    [ngClass]="{
                      'icon-arrow-down': !quote.expanded,
                      'icon-arrow-up': quote.expanded
                    }"
                  ></span>
                </td>
                <td>
                  <span class="pill-tag pill-tag__{{ mapQuoteStatusToStyle(quote.status) }}">
                    {{ mapQuoteStatus(quote.status) }}
                  </span>
                </td>
                <td class="quote-table-narrow-td">
                  {{ quote.product === 'cyber_surplus' ? 'Surplus' : 'Admitted' }}
                </td>
                <td class="td__bold quote-table-narrow-td">
                  <strong class="mono-number">{{ formatPremium(quote) }}</strong>
                </td>
                <td class="td__right">
                  <a (click)="navigateToDetails(quote)" (keypress)="navigateToDetails(quote)">
                    Details
                  </a>
                </td>
              </tr>
              <ng-container *ngIf="quote.expanded">
                <tr *ngFor="let subQuote of quote.bundleItems" class="quote-list-row">
                  <td class="td__bold td__thin">
                    {{ subQuote.product === 'bop' ? 'BOP' : 'Cyber' }}
                  </td>
                  <td class="td__tabular td__thin">
                    {{ subQuote.quoteName || 'N/A' }}
                  </td>
                  <td></td>
                  <td></td>
                  <td class="quote-table-narrow-td td__thin mono-number">
                    {{ formatPremium(subQuote) }}
                  </td>
                  <td></td>
                </tr>
              </ng-container>
            </ng-container>
            <tr *ngIf="quote.product !== 'bundle'" class="quote-list-row">
              <td>
                <div class="quote-badge">
                  <div
                    class="product-badge product-badge__{{ quote.product }} product-badge__small"
                  >
                    <div
                      class="
                    product-badge-carrier-icon
                    product-badge-carrier-icon__{{ quote.pasSource }}
                    product-badge-carrier-icon__small
                  "
                    ></div>
                  </div>
                </div>
              </td>
              <td class="td__tabular">
                {{ quote.quoteName || 'N/A' }}
              </td>
              <td>
                <span class="pill-tag pill-tag__{{ mapQuoteStatusToStyle(quote.status, quote) }}">
                  {{ mapQuoteStatus(quote.status) }}
                  <span
                    *ngIf="quote.isBindBlocked"
                    class="tool-tip tool-tip__large tool-tip__no-transform"
                    data-tooltip="Quote not bindable, see details"
                  >
                    <span class="quote-alert"></span>
                  </span>
                </span>
              </td>
              <td class="quote-type-info">
                <app-preference-tag
                  [preferenceCategory]="quote.preferenceCategory"
                ></app-preference-tag>
                <span *ngIf="!quote.preferenceCategory">{{ quote.quoteType }}</span>
              </td>
              <td class="td__bold quote-table-narrow-td">
                <strong class="mono-number">{{ formatPremium(quote) }}</strong>
              </td>
              <td
                class="td__right"
                [ngClass]="{ button__disabled: quote.flags?.includes('QUOTE_REFERRING') }"
              >
                <a (click)="navigateToDetails(quote)" (keypress)="navigateToDetails(quote)"
                  >Details</a
                >
              </td>
            </tr>

            <ng-container *ngIf="quote.product === 'wc'">
              <app-modal
                [open]="quote.flags?.includes('SHOW_PENDING_REFER')"
                (handleModalClose)="quote.flags = []"
                modalClass="app-modal-content__wc-pending-refer"
              >
                <header>
                  <div class="modal-image" role="presentation">
                    <img src="../../../../../assets/img/airplane_icon.png" alt="" />
                  </div>
                  <h2>Referred</h2>
                </header>
                <section>
                  <p class="wc-pending-refer-text">Estimate</p>
                  <h2 class="wc-pending-refer-price">
                    {{ quote.premium | currency: 'USD':'symbol':'1.0-0' }}
                  </h2>
                  <p>
                    This is an estimated premium, which is subject to change after review by our
                    carrier's underwriting team. Would you like to continue with the referral?
                  </p>
                  <div class="nav-button-group nav-button-group__centered-horizontal">
                    <button
                      (click)="quote.flags = []"
                      (keypress)="quote.flags = []"
                      class="button button__secondary js-close-pending-refer-modal"
                    >
                      No thanks
                    </button>
                    <button
                      (click)="referWcQuote(quote)"
                      (keypress)="referWcQuote(quote)"
                      class="button button__primary"
                    >
                      Yes, continue
                    </button>
                  </div>
                </section>
              </app-modal>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="product-card">
    <div class="card-header">
      <div class="card-header-info">
        <span class="card-header-title">
          <h2 class="h2">Cross sell opportunities</h2>
          <div>This account is also eligible for the following lines of business:</div>
        </span>
      </div>
    </div>
    <app-insured-account-new-quote
      [showBackButton]="false"
      [compact]="true"
      [excludeNonCrossSell]="true"
      [excludeNeptune]="false"
    ></app-insured-account-new-quote>
  </div>

  <ng-container *ngIf="showAlternativeCarriersCard()">
    <div class="product-card js-alternative-carriers">
      <div class="card-header">
        <div class="card-header-info">
          <span class="card-header-title">
            <h2 class="h2">Alternative carriers</h2>
            <div>
              With some additional information, we may be able to get quotes from alternative
              carriers:
            </div>
          </span>
        </div>
      </div>
      <app-insured-account-new-quote
        [showBackButton]="false"
        [compact]="true"
        [showAlternativeCarriersOnly]="true"
        [excludeNeptune]="true"
      ></app-insured-account-new-quote>
    </div>
  </ng-container>
</div>
