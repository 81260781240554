<header class="policy-pane-header">
  <div class="policy-pane-header-left">
    <app-product-badge *ngIf="productBadge" [product]="productBadge"></app-product-badge>
    <div class="policy-pane-quote-info">
      <h2 class="policy-pane-header-h2">Policy details</h2>
      <small class="gray-text">No. {{ policyTermTransaction.PolicyNumber }}</small>
      <div *ngIf="preferenceCategory" class="quote-type-info">
        <app-preference-tag [preferenceCategory]="preferenceCategory"></app-preference-tag>
        <span
          *ngIf="preferenceTip"
          class="more-info-tip tool-tip tool-tip__large"
          attr.data-tooltip="{{ preferenceTip }}"
        >
          <span class="icon icon-question"></span>
        </span>
      </div>
    </div>
  </div>
  <div class="policy-pane-header-right">
    <h2 class="policy-pane-header-h2 js-total-cost">
      {{ getTotalCost() | currency: '$':'symbol':'1.2-2' }}
    </h2>
    <small class="gray-text">Amount</small>
  </div>
</header>

<app-document-download
  [displayName]="displayProductName()"
  [documentDownload$]="policyDocumentDownload$"
  [documentReady$]="policyDocumentDownloadReady$"
></app-document-download>
<app-document-download
  class="js-application-input-download"
  *ngIf="isBop() || isAttuneWc()"
  displayName="Application Input"
  [documentDownload$]="quoteSubmissionDownload$"
></app-document-download>

<app-document-download
  *ngIf="isLossRunsDownloadEnabled && hasDownloadableLossRuns"
  displayName="Loss Runs Documents"
  [documentDownload$]="lossRunsDocumentDownload$"
  [documentReady$]="lossRunsDocumentDownloadReady$"
></app-document-download>

<div class="policy-pane-details">
  <div class="policy-detail-subsection">
    <dl *ngIf="quoteDetails?.governingClassDescription" class="js-governing-class">
      <dt>Governing Class</dt>
      <dd class="{{ governingClassCssClass }}">
        {{ quoteDetails.governingClassDescription }} ({{ quoteDetails.governingClassCode }})
      </dd>
    </dl>
    <dl *ngIf="quoteDetails?.hazardGroup" class="js-hazard-group">
      <dt>Hazard Group</dt>
      <dd>{{ quoteDetails.hazardGroup }}</dd>
    </dl>
    <dl>
      <dt>Effective date</dt>
      <dd>{{ policyTermTransaction.PeriodStart | date: 'mediumDate':'UTC' }}</dd>
    </dl>
    <dl>
      <dt>Expiration date</dt>
      <dd>{{ policyTermTransaction.PeriodEnd | date: 'mediumDate':'UTC' }}</dd>
    </dl>
  </div>
</div>

<app-dialog-box
  *ngIf="secondaryPolicyId"
  fullWidth="true"
  type="primary"
  header="Your bind includes Cyber coverage"
>
  <p>View details and download policy documents by clicking below.</p>
  <a class="dialog-text-link" href="/accounts/{{ accountId }}/quotes/{{ secondaryPolicyId }}">
    View Cyber policy
  </a>
</app-dialog-box>

<ng-container *ngIf="changeHistory?.length">
  <h3 class="policy-pane-subheader js-policy-change-history">Change History</h3>
  <ul *ngFor="let policyChange of changeHistory" class="link-list">
    <li>
      <app-document-download
        [displayName]="policyChange.displayName"
        [documentDownload$]="policyChange.download$"
        [documentReady$]="changeHistoryDownloadsReady$"
      ></app-document-download>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="!isAttuneWc() || attuneWcPaymentPlanLoaded">
  <app-additional-documents
    [isAttuneWc]="isAttuneWc()"
    paneType="policy"
    [isPayByPayPolicy]="isPayByPayPolicy"
  ></app-additional-documents>

  <app-post-bind-servicing-information
    carrier="{{ isAttuneWc() ? 'attune-gw-wc' : 'attune-gw-bop' }}"
    [isPayByPayPolicy]="isPayByPayPolicy"
  >
  </app-post-bind-servicing-information>
</ng-container>

<app-modal
  [open]="showSafetyReviewModal"
  (handleModalClose)="showSafetyReviewModal = false"
  disableClose="true"
  modalNameForAmplitudeEvent="safety-review-modal"
  id="js-safety-review-modal"
>
  <header class="safety-review-modal-header app-modal-header">
    <div class="modal-image" role="presentation">
      <img src="../../../../../assets/img/wc_shield_icon.png" alt="" />
    </div>
    <h2>Everpeak Workplace Safety Review Required</h2>
  </header>
  <section>
    <p class="safety-review-modal-text">
      A safety review is required within 30 days to maintain workers' comp coverage for
      {{ insuredAccountName }}.
      <br />
      <br />
      On behalf of EverPeak, Builders Insurance or one of their safety expert partners (TransStar
      Technical Services, Lowery & Associates, or Midwest Technical Inspections) will contact the
      policyholder within two weeks to schedule their review.
      <br />
      <br />
      <strong
        >As their agent, you are essential in notifying the policyholder about this requirement.
        Please use this
        <a [href]="safetyReviewEmailTemplateUrl" target="_blank">email template</a>
        to inform the policyholder and share this
        <a [href]="safetyReviewOverviewPolicyholdersUrl" target="_blank">safety review overview</a>,
        which explains what to expect.</strong
      >
    </p>
    <div class="nav-button-group nav-button-group__right">
      <a
        [href]="safetyReviewOverviewAgentsUrl"
        target="_blank"
        class="button button__secondary"
        data-amplitude-input-name="safety_review_modal_learn_more"
      >
        Learn more
      </a>
      <button
        (click)="closeSafetyReviewModal()"
        data-amplitude-input-name="safety_review_modal_got_it"
        class="button button__primary"
      >
        Got it
      </button>
    </div>
  </section>
</app-modal>
