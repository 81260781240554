import { FormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { get } from 'lodash';

// Constants
import { US_DATE_MASK } from 'app/constants';
import {
  DEFAULT_PAYMENT_PLAN,
  ATTUNE_WC_PAYMENT_PLAN_MAPPING,
  ATTUNE_WC_INSURED_CONTACT_OPTIONS,
} from 'app/workers-comp/attune/constants';

// Models
import {
  ContactListGroup,
  getNewBrokerContactFormSection,
  selectivelyPatchBrokerContactForm,
  transformContacts,
  TransformedContacts,
} from 'app/shared/helpers/broker-contact-helpers';
import { createInsuredContactFormArray } from 'app/shared/helpers/insured-contact-helpers';
import { GwAccountContacts, GwCommContact, GwProducerCodeDetails } from 'app/bop/guidewire/typings';
import { AttuneWcGWPolicyPayment } from 'app/workers-comp/attune/models/attune-wc-gw-policy-payment.model';
import { BindFormGroup } from '../models/bind-form.model';
import { QSAttuneWcPaymentPlan } from '../models/payment-plans.model';

// Validators
import {
  minDateExceededValidator,
  maxDateExceededValidator,
  feinValidator,
} from 'app/shared/helpers/form-helpers';

// Services
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

@Injectable()
export class AttuneWcBindFormService extends FormDslSteppedFormBaseService {
  public form: BindFormGroup;
  public policyPaymentDetails: AttuneWcGWPolicyPayment;

  originalEffectiveDate: string | null = null;

  // Contacts variables
  currentContact: GwCommContact;
  subscribedContacts: TransformedContacts;
  availableContacts: TransformedContacts;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.initializeForm();
    this.syncAllSteps();
  }

  public initializeForm() {
    // The default value is today's date and is replaced when policy is fetched
    const today = moment();
    const threeMonthsFromToday = today.clone().add(3, 'months');
    this.form = this.formBuilder.group({
      policyDetails: this.formBuilder.group({
        effectiveDate: [
          today.format(US_DATE_MASK),
          [
            Validators.required,
            minDateExceededValidator(today, 'day'),
            maxDateExceededValidator(threeMonthsFromToday),
          ],
        ],
        employerIdentificationNumber: ['', [Validators.required, feinValidator]],
        insuredContacts: createInsuredContactFormArray(ATTUNE_WC_INSURED_CONTACT_OPTIONS),
        brokerContacts: getNewBrokerContactFormSection(),
      }),
      paymentDetails: this.formBuilder.group({
        paymentPlan: this.formBuilder.control<QSAttuneWcPaymentPlan | null>(DEFAULT_PAYMENT_PLAN, {
          validators: [Validators.required],
        }),
      }),
    });
  }

  setDetailsFromPolicy(quoteDetails: QuoteDetails) {
    this.setDatesFromPolicy(quoteDetails);
    this.setPaymentDetailsFromPolicy(quoteDetails);
    this.setFeinFromPolicy(quoteDetails);
  }

  private setFeinFromPolicy(quoteDetails: QuoteDetails) {
    const fein = quoteDetails?.contact?.FEINOfficialID;

    if (fein) {
      this.form.get('policyDetails.employerIdentificationNumber')?.patchValue(fein);
    }
  }

  private setDatesFromPolicy(quoteDetails: QuoteDetails) {
    const policyStart = quoteDetails.policyStart;
    const today = moment.utc().startOf('day');
    // We use moment.utc() here because policyStart is returned as a moment w/ a utc offset.
    const isBeforeToday: boolean = moment.utc(policyStart).isBefore(today);
    // The form is already defaulted to today.
    // If the original eff date is in the future we set it here.
    if (!isBeforeToday) {
      const effectiveDate = moment.utc(policyStart).format(US_DATE_MASK);
      this.form.patchValue({ policyDetails: { effectiveDate: effectiveDate } });
    }
  }

  private setPaymentDetailsFromPolicy(quoteDetails: QuoteDetails) {
    // Set the initial payment plan details.
    const paymentPlanDetails = ATTUNE_WC_PAYMENT_PLAN_MAPPING[DEFAULT_PAYMENT_PLAN];
    this.policyPaymentDetails = new AttuneWcGWPolicyPayment({
      downPaymentPercentage: paymentPlanDetails.downPaymentPercentage,
      numberOfPayments: paymentPlanDetails.numberOfPayments,
      totalPremium: quoteDetails.totalPremium,
      totalTaxes: quoteDetails.totalTaxes,
    });
  }

  // Broker contact form methods

  public setCurrentBrokerContact(currentContact: GwCommContact) {
    this.currentContact = currentContact;
  }

  public setSubscribedBrokerContacts(contacts: GwAccountContacts) {
    this.subscribedContacts = transformContacts(
      contacts.contacts || [],
      'CommunicationContact_ATTN.Contact',
      'CommunicationContact_ATTN.CommunicationContactRoles.Entry',
      'CommunicationType'
    );
  }

  public setAvailableBrokerContacts(producerContacts: GwProducerCodeDetails) {
    this.availableContacts = transformContacts(
      get(producerContacts, 'ProdCodeCommContacts_ATTN.Entry', []),
      'Contact',
      'ProdCodComContactRol_ATTN.Entry',
      'CommunicationType_ATTN'
    );
  }

  public patchBrokerContactsForm() {
    selectivelyPatchBrokerContactForm(
      this.subscribedContacts,
      this.currentContact,
      this.form.get('policyDetails')?.get('brokerContacts') as ContactListGroup
    );
  }

  // End of broker contact form methods

  public fillInHappyPath(): void {}

  public generateSteps(): RouteFormStep[] {
    return [
      {
        args: {},
        displayName: 'Policy details',
        slug: 'policy-details',
        parent: 'policy-details',
        formPath: 'policyDetails',
      },
      {
        args: {},
        displayName: 'Payment details',
        slug: 'payment-details',
        parent: 'payment-details',
        formPath: 'paymentDetails',
      },
    ].filter(this.isStep);
  }
}
