import { InsuredAccount } from '../../insured-account/models/insured-account.model';
import {
  UntypedFormControl,
  AbstractControl,
  UntypedFormGroup,
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';
import { get, some } from 'lodash';

import { BuildingClassificationCategory } from 'app/features/attune-bop/models/building-classifications';
import { validBuildingClassification } from 'app/features/attune-bop/services/attune-bop-building-classification.service';
import { formatMoneyNoCents, parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { classificationHasByobAlcohol } from 'app/features/attune-bop/models/classification-helpers';
import {
  AVAILABLE_BUSINESS_TYPES,
  GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
  GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
  INELIGIBLE_STATES,
  MINIMUM_BPP_BY_BUSINESS_TYPE,
} from './constants';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { shouldGetBopV2, shouldGetBopV3 } from 'app/shared/helpers/account-helpers';
import { UNACCEPTABLE_TENANT_NAMES } from 'app/constants';
import { AttuneBopFortegraEnabledStates } from '../../../shared/services/typings';

export type SimpleValidator = (formControl: AbstractControl) => null | { [key: string]: string };

export const trimmedEqual = (
  newValue: string | null | undefined,
  oldValue: string | null | undefined
) => {
  newValue = newValue === null || newValue === undefined ? '' : newValue;
  oldValue = oldValue === null || oldValue === undefined ? '' : oldValue;
  return newValue.toLowerCase().trim() === oldValue.toLowerCase().trim();
};

export const addressesEqual = (address: Address, newAddress: Address) => {
  const { addressLine1, addressLine2, city, state, zip } = address;
  const {
    addressLine1: newAddressLine1,
    addressLine2: newAddressLine2,
    city: newCity,
    state: newState,
    zip: newZip,
  } = newAddress;
  return (
    addressLine1 &&
    newAddressLine1 &&
    trimmedEqual(newAddressLine1, addressLine1) &&
    trimmedEqual(newAddressLine2, addressLine2) &&
    trimmedEqual(newCity, city) &&
    trimmedEqual(newState, state) &&
    trimmedEqual(newZip, zip)
  );
};

export const isDuplicate = (
  newAddress: Address,
  addresses: Address[],
  currentFormIndex: number
): boolean => {
  let error = false;

  addresses.forEach((address, index) => {
    if (currentFormIndex !== index && addressesEqual(address, newAddress)) {
      error = true;
    }

    return;
  });
  return error;
};

export const uniqueAddressValidator = (group: UntypedFormGroup) => {
  const locationAddressesControl = group.get('locationAddresses') as UntypedFormArray;
  const locationAddresses = locationAddressesControl ? locationAddressesControl.value : [];
  if (locationAddresses.length < 2) {
    return null;
  }
  let error = null;
  locationAddresses.forEach((address: Address, index: number) => {
    if (index !== 0) {
      if (isDuplicate(address, locationAddresses, index)) {
        error = { repeatAddress: { value: locationAddressesControl, index } };
        return;
      }
    }
  });
  return error;
};

export const validateEmailAddress = (control: UntypedFormControl) => {
  if (control.value) {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(control.value)) {
      return null;
    }
    return { invalidEmailAddress: { value: control.value } };
  }
  return null;
};

export const noSpecialCharsOrNumbers = (control: UntypedFormControl) => {
  if (control.value) {
    const numberAndCharRegex = /[0-9,\(\)\'\"\:\;\@&\[\]\\\^]/g;
    const asteriskRegex = /\*/g;
    if (numberAndCharRegex.test(control.value) || asteriskRegex.test(control.value)) {
      return { invalidCharacters: { value: control.value } };
    }
  }
  return null;
};

export const notBlank = (control: UntypedFormControl) => {
  if (control.value) {
    const whiteSpaceRegex = /\s/g;
    const trimmed = control.value.replace(whiteSpaceRegex, '');
    if (trimmed.length) {
      return null;
    }
    return { isOnlyWhitespace: { value: control.value } };
  }
  return null;
};

export const validatePhoneNumber = (control: UntypedFormControl) => {
  if (control.value) {
    // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number/16702965#16702965
    const phoneRegex: RegExp =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (phoneRegex.test(control.value)) {
      return null;
    }
    return { invalidPhoneNumber: { value: control.value } };
  }
  return null;
};

export const validateVersionNumber = (control: UntypedFormControl) => {
  if (control.value) {
    const versionNumberRegex: RegExp = /^[\d\.]+$/;
    if (versionNumberRegex.test(control.value)) {
      return null;
    }
    return { invalidVersionNumber: { value: control.value } };
  }
  return null;
};

export const validateNoSpecialCharactersInAddress = (control: UntypedFormControl) => {
  // check for most special characters not allowed by USPS: ,:;'"@&*()[]
  // + character causing issues for WC binding, but not quoting, so need to ensure this character is not used
  const specialCharacterPresent = new RegExp(`[\+:;'"@&*()\\[\\]]+`);
  if (specialCharacterPresent.test(control.value)) {
    return {
      specialCharacterError: { value: control.value },
    };
  }
  return null;
};

export const locationStateValidator = (control: AbstractControl): ValidationErrors | null => {
  if (INELIGIBLE_STATES.includes(control.value)) {
    return {
      individualLocationState: {
        value: control.value,
        validationMessage: `We currently do not offer BOP for these states: ${INELIGIBLE_STATES.join(
          ', '
        )}.`,
      },
    };
  }
  return null;
};

export const locationStateBopVersionAsyncValidator = (
  bopExposure: Observable<BopExposureInfo>,
  bopV3EnabledStates: Observable<AttuneBopFortegraEnabledStates | null>
) => {
  return (formControl: UntypedFormControl) => {
    return combineLatest([bopExposure, bopV3EnabledStates]).pipe(
      map(([exposure, v3EnabledStates]) => {
        const { bopVersion } = exposure;
        const locationState = formControl.value;
        const isStateOnBopV2 = !!shouldGetBopV2(locationState);
        const isStateOnBopV3 = v3EnabledStates && shouldGetBopV3(v3EnabledStates, locationState);
        if (!isStateOnBopV2 && bopVersion === 2) {
          return {
            locationIsIneligible: {
              value: formControl.value,
              validationMessage: `We do not currently offer Accredited BOP in ${locationState}.`,
            },
          };
        } else if (!isStateOnBopV3 && bopVersion === 3) {
          return {
            locationIsIneligible: {
              value: formControl.value,
              validationMessage: `We do not currently offer Fortegra BOP in ${locationState}.`,
            },
          };
        }
        return null;
      }),
      first()
    );
  };
};

export const knownBuildingClassificationDescription =
  (exposure: UntypedFormGroup) =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const lessorsRiskControl = exposure.get('lessorsRisk');
    const lessorsRisk = lessorsRiskControl ? lessorsRiskControl.value : null;
    const isValid = control.value && validBuildingClassification(control.value, lessorsRisk);
    return isValid ? null : { unknownCode: { value: control.value } };
  };

export const validateAddressIsNotPO = (control: UntypedFormControl) => {
  const isPOBox = new RegExp('^P\\.?(O|0)\\.?(\\s|\\d|Box)', 'i');
  if (isPOBox.test(control.value)) {
    return { POBoxError: { value: control.value } };
  }
  return null;
};

export const locationsHaveLiquorSalesValidator = (locations: UntypedFormArray): SimpleValidator => {
  return function (formControl: AbstractControl) {
    if (!locations || !locations.value) {
      return null;
    }
    const liquorSales = locations.value.map((loc: BopLocation) =>
      parseMaskedInt(get(loc, 'buildings.0.exposure.classification.alcoholSales'))
    );

    const classificationsAreByob = locations.value.map((loc: BopLocation) =>
      classificationHasByobAlcohol(get(loc, 'buildings.0.exposure.classification.code'))
    );

    if (formControl.value && !some(liquorSales) && !some(classificationsAreByob)) {
      return { alcoholSalesRequired: 'At least one location must have liquor sales' };
    }

    return null;
  };
};

export const perOccurenceLimitForLiquorLiability = (
  control: UntypedFormControl,
  limit: number
): SimpleValidator => {
  return function (formControl: AbstractControl) {
    if (formControl.value === true && control.value > limit) {
      return {
        abovePerOccurenceLimit: `Per occurence liability limits greater than \$${limit} cannot be selected when including liquor liability.`,
      };
    }

    return null;
  };
};

export const checkGWTempEmailAddress = (account: InsuredAccount): string => {
  let { emailAddress } = account;
  if (!emailAddress || emailAddress.trim() === GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS) {
    emailAddress = '';
  }
  return emailAddress;
};

export const checkGWTempPhoneNumber = (account: InsuredAccount): string => {
  let { phoneNumber } = account;
  if (!phoneNumber || phoneNumber.trim() === GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER) {
    phoneNumber = '';
  }
  return phoneNumber;
};

export const checkCommercialAutoSumOfVehicles: ValidatorFn = (group: UntypedFormGroup) => {
  const vehicleSum: number = Object.keys(group.controls)
    .map((controlKey): number => Number(group.value[controlKey]))
    .reduce((accumulator, currentValue) => accumulator + currentValue);
  const vehicleSumInvalid: boolean = isNaN(vehicleSum) || vehicleSum < 1;
  return vehicleSumInvalid ? { commercialAutoSumOfVehicles: { value: vehicleSum } } : null;
};

export const checkForMinimumBppLimit = (
  bppLimit: string,
  businessType: BuildingClassificationCategory,
  isLessorsRisk: boolean
): ValidationErrors | null => {
  const input = parseMaskedInt(String(bppLimit));
  if (isNaN(input)) {
    return {
      bppValidator: {
        value: bppLimit,
        message: 'Business personal property limit must be a number.',
      },
    };
  }

  if (isLessorsRisk) {
    // Note: Lessors risk doesn't require BPP
    return null;
  }

  if (input < 1) {
    // Businesses must have at least $1 of BPP
    return {
      bppValidator: {
        value: bppLimit,
        message: 'Business personal property limit is required.',
      },
    };
  }

  if (!MINIMUM_BPP_BY_BUSINESS_TYPE[businessType]) {
    // Note: If no minimum for the business type we can just return here
    return null;
  }

  if (input >= (MINIMUM_BPP_BY_BUSINESS_TYPE[businessType] as number)) {
    return null;
  }

  return {
    bppValidator: {
      value: bppLimit,
      message: `Our ${
        AVAILABLE_BUSINESS_TYPES[businessType]
      } program requires a minimum BPP of ${formatMoneyNoCents(
        '' + MINIMUM_BPP_BY_BUSINESS_TYPE[businessType]
      )}.`,
    },
  };
};

// Invalidates control if there are uploads and if at least one has failed or is loading.
export const checkForFailedUpload = (fileControls: UntypedFormArray) => {
  const uploadFailed = (fileControls.value as FileUpload[]).some((fileUpload) => {
    return fileUpload.status !== 'done';
  });

  return uploadFailed
    ? {
        uploadFailed: {
          validationMessage:
            'Please remove any failed uploads, wait for any pending uploads to complete, and try again.',
        },
      }
    : null;
};

// Checks if string is valid MM/DD/YYYY date
export const validateDate = (dateControl: UntypedFormControl) => {
  if (!dateControl || !dateControl.value) {
    return null;
  }

  const dateInvalid = !moment(dateControl.value, 'MM/DD/YYYY', true).isValid();

  return dateInvalid
    ? {
        dateInvalid: {
          validationMessage: 'Please enter date in MM/DD/YYYY format.',
        },
      }
    : null;
};

export const validateAgentLicenseNumber: ValidatorFn = (control: UntypedFormControl) => {
  if (control.value) {
    // Agent license number are a letter + 6 digits, ex: A123456
    const agentLicenseNumberRegex: RegExp = /^[a-zA-Z]{1}\d{6}/;
    if (agentLicenseNumberRegex.test(control.value)) {
      return null;
    }
    return {
      invalidLicenseNumber: {
        value: control.value,
        validationMessage: 'Please enter agent license number in proper format, e.g. A123456',
      },
    };
  }
  return null;
};

export const validateTenantName: ValidatorFn = (control: UntypedFormControl) => {
  if (
    control.value &&
    UNACCEPTABLE_TENANT_NAMES.some(
      (tenantName) => tenantName.toLowerCase() === control.value.toLowerCase()
    )
  ) {
    return {
      invalidTenantName: {
        value: control.value,
        validationMessage: 'Please enter a valid tenant name',
      },
    };
  }

  return null;
};

export const validateLiquorLicenseNumber: ValidatorFn = (control: UntypedFormControl) => {
  if (control.value) {
    const disallowedTestText = ['test', 'tbd', 'n/a', 'example'];
    // RegExp to look for string with only same characters (.) matches any character except line breaks, {1,} matches 1 or more of the preceeding token
    const allSameCharacterRegex: RegExp = /^(.)\1{1,}$/;
    if (
      allSameCharacterRegex.test(control.value.toLowerCase()) ||
      disallowedTestText.includes(control.value.toLowerCase())
    ) {
      return {
        invalidLicenseNumber: {
          value: control.value,
          validationMessage: 'Please enter a valid liquor license number.',
        },
      };
    }
  }
  return null;
};

// Matches Experience Modification factors, values close to 1 with 2 decimal places e.g. 1.34, 0.86, .92
export const validateEMod = (control: UntypedFormControl) => {
  if (control.value) {
    const versionNumberRegex: RegExp = /^[0-9]{0,1}\.[0-9]{2}$/;
    if (versionNumberRegex.test(control.value)) {
      return null;
    }
    return { invalidVersionNumber: { value: control.value } };
  }
  return null;
};
