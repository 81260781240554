import { ZENDESK_NEW_REQUEST_LINK } from 'app/features/invoices/models/invoices-constants';
import { HISCOX_GL_PL_GUIDE } from 'app/features/hiscox/models/constants';
import { AdditionalInsuredType } from 'app/features/attune-bop/models/bop-additional-insured-business';

export const INSURED_BILLING_GUIDE =
  'https://cdn.brandfolder.io/8ATZIINR/at/k46xhzf8hgv3fnxjmxfxvhwr/Insured_Billing_Guide_01_2.pdf';

export const BOP_GUIDELINE_LINK_ACCREDITED =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+Plus+UW+Guidelines.pdf';

export const BOP_GUIDELINE_LINK_BLACKBOARD =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+UW+Guidelines.pdf';

// TODO: Update with Fortegra Guidelines
export const BOP_GUIDELINE_LINK_FORTEGRA =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+UW+Guidelines.pdf';

export const CYBER_GUIDELINES_LINK_COALITION =
  'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Coalition%20Cyber%20Insurance%20Guidelines%2003.18.2022.pdf';

export const ATTUNE_NEW_BUSINESS_APP =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+New+Business+App+2020.pdf';

export const BROKER_OF_RECORD_CHANGE =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Agent+or+Broker+of+Record+Change+(Acord+36).pdf';

export const NEPTUNE_FLOOD_GUIDE =
  'https://cdn.brandfolder.io/8ATZIINR/as/t5sznw5nnrgt2hsc5x79ckmx/Flood_Guidelines.pdf';

export const LOST_POLICY_RELEASE =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Lost+Policy+Release+2020.pdf';

export const COALITION_TECHNOLOGY_AND_SERVICES =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Coalition+Technology+And+Services.pdf';

export const EMPLOYERS_WC_GUIDE =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Employers+WC+UW+Guidelines.pdf';

export const ATTUNE_WC_GUIDE =
  'https://resources.attuneinsurance.com/wc-state-appetite-guide/national';

export const HELLO_SIGN_UPDATE_REFUND_BANK_INFO = 'https://app.hellosign.com/s/EzwAwv1K';

export const GUIDELINES: { fileName: string; guidelineLink: string }[] = [
  {
    fileName: 'Attune BOP Underwriting Guidelines',
    guidelineLink: BOP_GUIDELINE_LINK_ACCREDITED,
  },
  {
    fileName: 'Coalition Cyber Underwriting Guidelines',
    guidelineLink: CYBER_GUIDELINES_LINK_COALITION,
  },
  {
    fileName: 'Neptune Flood Underwriting Guidelines',
    guidelineLink: NEPTUNE_FLOOD_GUIDE,
  },
  {
    fileName: 'Hiscox GL/PL Underwriting Guidelines',
    guidelineLink: HISCOX_GL_PL_GUIDE,
  },
  {
    fileName: 'Employers WC Underwriting Guidelines',
    guidelineLink: EMPLOYERS_WC_GUIDE,
  },
];

export const ATTUNE_WC_GUIDELINES = {
  fileName: 'Attune WC Underwriting Guidelines',
  guidelineLink: ATTUNE_WC_GUIDE,
};

export const BROKER_ENDORSEMENTS_GUIDE_LINK =
  'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+Broker+Endorsements+Guide+2020-10-26.pdf';

export const BOOK_BALANCE_GUIDE_LINK =
  'https://cdn.brandfolder.io/8ATZIINR/as/nz3ng92j454ffznx7rs6qs8/Book_Balance_at_Attune.pdf';

export const ADDITIONAL_GUIDES: {
  fileName: string;
  guideLink: string;
  amplitudeInputName: string;
}[] = [
  {
    fileName: 'Insured Billing Guide',
    guideLink: INSURED_BILLING_GUIDE,
    amplitudeInputName: 'billing-guide',
  },
  {
    fileName: 'Broker Endorsements Guide',
    guideLink: BROKER_ENDORSEMENTS_GUIDE_LINK,
    amplitudeInputName: 'endorsements-guide',
  },
];

export const ATTUNE_WC_ADDITIONAL_GUIDES = {
  fileName: 'Attune WC Book Balance Guide',
  guideLink: BOOK_BALANCE_GUIDE_LINK,
  amplitudeInputName: 'attune-wc-book-balance-guide',
};

export const SUPPORT_FAQS: { title: string; body: string }[] = [
  {
    title: 'How can I use Attune?',
    body: 'Attune provides bindable quotes for over 300 <strong>BOP</strong>, <strong>Workers\' Comp</strong>, and <strong>XS</strong> classes, to most states in just a few minutes with no required documents.\
      <p class="support-faq-paragraph">Our policies, available from carriers with over a 100 years of experience on an admitted basis, include a number of optional coverages, and make available class-specific endorsements that provide tailored coverage.</p>',
  },
  {
    title: 'What is in your appetite?',
    body: "Our appetite includes:\
        <li>Most restaurant classes from cafes to fine-dining including liquor exposure</li>\
        <li>Professional service offices including accountants, lawyers, insurance agents, medical offices, and veterinarian offices</li>\
        <li>Service providers such as barber/beauty parlors, printers, copy shops, and nail salons with professional liability available for select service classes</li>\
        <li>Violation free contractor classes like HVAC contractors, electricians, plumbers, painters, masons, and tile workers</li>\
        <li>Traditional main street retail including auto parts, candy shops, florists, clothing stores, drugstores, and gift shops</li>\
        <li>Workers' Compensation is available as a monoline product and includes over 200 NAICS codes of common businesses like gas stations, car washes, medical labs, manufacturing, tire dealers, and supermarkets</li>\
        <li>Lessors risk policies available for buildings occupied by eligible tenants</li>\
        <li>Many more classes are available. Please see our Underwriting Guidelines above to learn more more about available classes and specific eligibility.</li>",
  },
  {
    title: 'What is needed to bind?',
    body: 'Nothing, it’s easy! The portal is our application. When you click “Bind” in our portal, the policy is issued within a few seconds. No need to send us paper applications, ACORD forms, or any other documentation.',
  },
  {
    title: 'How can I ask underwriting-related questions?',
    body: 'Most questions can be answered by reviewing our <a href="https://attunehelp.zendesk.com/hc/en-us" target="_blank">FAQ</a>.\
      <p class="support-faq-paragraph">For other inquiries, you can contact our friendly Customer Care Team using any chat button you see in the portal.</p>',
  },
  {
    title: 'How are policies issued?',
    body: 'Policies are issued immediately upon binding. They are available and downloadable from the Attune Portal. No paper copies are mailed.',
  },
  {
    title: 'How are policies billed?',
    body: 'BOP invoices are created the day policy is bound and are sent daily every 4 hours. Invoices are emailed to the account email provided, as well as the binding broker. Invoices are due 5 days from the effective date.\
      <p class="support-faq-paragraph">WC invoices are sent directly to the insured 7-10 days after bind. The insured will receive information on how to pay their bill.</p>',
  },
  {
    title: 'How can I make adjustments to my quote or policy?',
    body: `If you need to make modifications to a quote or an account, you can do so by the “Edit” feature in the Attune Portal. If the policy is bound and you need to make an endorsement, a select number of self service endorsements can be made by navigating to the policy details and selecting "Endorse Policy." If your change request is not listed, please submit the request on our <a href="${ZENDESK_NEW_REQUEST_LINK}">helpdesk</a> and our Customer Care Team will assist you.`,
  },
];

export const SUPPORT_ZENDESK_URL = 'https://attunehelp.zendesk.com/hc/en-us';
export const SUPPORT_ZENDESK: { displayName: string; link: string }[] = [
  {
    displayName: 'Help Center',
    link: SUPPORT_ZENDESK_URL,
  },
];

export const ADDITIONAL_INSURED_TYPES = {
  building_owner__not_available_in_fl___bp_12_31_:
    'Building Owner (Not available in FL) (BP 12 31)',
  'co-owner_of_insured_premises__bp_04_11_': 'Co-owner of Insured Premises (BP 04 11)',
  controlling_interest__bp_04_06_: 'Controlling Interest (BP 04 06)',
  designated_person_or_organization___bp_04_48_: 'Designated Person or Organization (BP 04 48)',
  engineers__architects_or_surveyors__bp_04_49_: 'Engineers, Architects or Surveyors (BP 04 49)',
  engineers__architects_or_surveyors_not_engaged_by_the_named_insured__bp_04_49_:
    'Engineers, Architects or Surveyors Not Engaged by the Named Insured (BP 04 49)',
  less_or_leased_equipment__flat_rate___bp_04_16_:
    'Less or Leased Equipment (Flat Rate) (BP 04 16)',
  'loss_of_rental_value_-_landlord_as_designated_payee__flat_rate___bp_05_93_':
    'Loss of Rental Value - Landlord as Designated Payee (Flat Rate) (BP 05 93)',
  loss_payable__bp_12_03_: 'Loss Payable (BP 12 03)',
  managers_or_lessors_of_premises__flat_rate___bp_04_02_:
    'Managers or Lessors of Premises (Flat Rate) (BP 04 02)',
  mortgage_holder__declarations_: 'Mortgage Holder (Declarations)',
  mortgagee__assignee_or_receiver__bp_04_09_: 'Mortgagee, Assignee or Receiver (BP 04 09)',
  owners_or_other_interests_from_whom_land_has_been_leased__bp_04_10_:
    'Owners or Other Interests from Whom Land Has Been Leased (BP 04 10)',
  'owners__lessees_or_contractors_-_completed_operations__not_available_in_fl___bp_14_02_':
    'Owners, Lessees or Contractors - Completed Operations (Not available in FL) (BP 14 02)',
  'owners__lessees_or_contractors_-_scheduled_person_or_organization__bp_04_50_':
    'Owners, Lessees or Contractors - Scheduled Person or Organization (BP 04 50)',
  'owners__lessees_or_contractors-_with_additional_insured_requirement_in_construction_contract__bp_04_51_':
    'Owners, Lessees or Contractors- With Additional Insured Requirement in Construction Contract (BP 04 51)',
  'state_or_governmental_agency_or_subdivision_or_political_subdivision-_permits_or_authorizations___bp_04_52_':
    'State or Governmental Agency or Subdivision or Political Subdivision- Permits or Authorizations ( BP 04 52)',
  vendors__bp_04_47_: 'Vendors (BP 04 47)',
} as const;

export const ADDITIONAL_INSURED_TYPES_MAPPING: Record<
  keyof typeof ADDITIONAL_INSURED_TYPES,
  AdditionalInsuredType
> = {
  building_owner__not_available_in_fl___bp_12_31_: AdditionalInsuredType.BUILDING_OWNER,
  'co-owner_of_insured_premises__bp_04_11_': AdditionalInsuredType.CO_OWNER,
  controlling_interest__bp_04_06_: AdditionalInsuredType.CONTROLLING_INTEREST,
  designated_person_or_organization___bp_04_48_: AdditionalInsuredType.DESIGNATED_PERSON,
  engineers__architects_or_surveyors__bp_04_49_: AdditionalInsuredType.ENGINEERS,
  engineers__architects_or_surveyors_not_engaged_by_the_named_insured__bp_04_49_:
    AdditionalInsuredType.ENGINEERS_NOT_ENGAGED,
  less_or_leased_equipment__flat_rate___bp_04_16_: AdditionalInsuredType.LESSOR_OF_EQUIPMENT,
  'loss_of_rental_value_-_landlord_as_designated_payee__flat_rate___bp_05_93_':
    AdditionalInsuredType.LOSS_OF_RENTAL_VALUE,
  loss_payable__bp_12_03_: AdditionalInsuredType.LOSS_PAYABLE,
  managers_or_lessors_of_premises__flat_rate___bp_04_02_: AdditionalInsuredType.MANAGER_OF_PREMISES,
  mortgage_holder__declarations_: AdditionalInsuredType.MORTGAGE_HOLDER,
  mortgagee__assignee_or_receiver__bp_04_09_: AdditionalInsuredType.MORTGAGEE,
  owners_or_other_interests_from_whom_land_has_been_leased__bp_04_10_:
    AdditionalInsuredType.OWNERS_OF_LAND,
  'owners__lessees_or_contractors_-_completed_operations__not_available_in_fl___bp_14_02_':
    AdditionalInsuredType.OWNERS_COMPLETED_OPS,
  'owners__lessees_or_contractors_-_scheduled_person_or_organization__bp_04_50_':
    AdditionalInsuredType.OWNERS_SCHEDULED_PERSON,
  'owners__lessees_or_contractors-_with_additional_insured_requirement_in_construction_contract__bp_04_51_':
    AdditionalInsuredType.OWNERS,
  'state_or_governmental_agency_or_subdivision_or_political_subdivision-_permits_or_authorizations___bp_04_52_':
    AdditionalInsuredType.STATE_SUBDIVISIONS_AUTHORIZATIONS,
  vendors__bp_04_47_: AdditionalInsuredType.VENDORS,
};
