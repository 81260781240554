import { API_URI_BASE, API_V3_BASE } from 'app/constants';
import { BuildingClassificationCategory } from 'app/features/attune-bop/models/building-classifications';
import { AdditionalInsuredType } from 'app/features/attune-bop/models/bop-additional-insured-business';
import { BOP_GUIDELINE_LINK_BLACKBOARD } from 'app/features/support/models/support-constants';
import { environment } from 'environments/environment';
import { mapValues, zipObject } from 'lodash';
import { AttuneBopProductBadge } from '../../digital-carrier/models/types';
import { PreferenceLevel } from '../../../shared/services/preference.service';

export const CREATE_QUOTE_API_URI = `${API_URI_BASE}/submission/quote`;
export const API_V3_BOP_QUOTES_URI = `${API_V3_BASE}/quotes/bop`;
export const UPDATE_LESSORS_RISK_DOCUMENTS = `${API_V3_BASE}/quotes/bop/lessors-risk-documents`;
export const GET_LESSORS_RISK_DOCUMENTS = `${API_V3_BASE}/quotes/bop/lessors-risk-documents`;
export const QUOTE_NAME_URI = `${API_V3_BASE}/quotes/names`;
export const UPCOMING_POLICIES_URI = `${API_V3_BASE}/quotes/bop/upcoming-policies`;
export const UPDATE_AUTOBIND_STATUS_URI = `${API_V3_BASE}/quotes/bop/update-autobind-status`;
export const STATE_ROLLOVER_INFO_URI = `${API_V3_BASE}/quotes/bop/state-rollovers`;
export const BUSINESS_ROLLOVER_INFO_URI = `${API_V3_BASE}/quotes/bop/business-rollovers`;
export const ESTIMATE_PRICE_REDUCTION_URI = `${API_V3_BASE}/quotes/bop`;
export const UTILITY_SERVICE_LIMITS_API_URI = `${API_V3_BASE}/bop/utility-service-limits`;
export const WIND_OPTIONS_API_URI = `${API_V3_BASE}/wind-limits`;
export const API_V3_RATED_QUOTE_URI = `${API_V3_BASE}/quotes`;
export const GW_QUOTE_BIND_URI = `${API_URI_BASE}/v1/pc/submission/issue`;
export const BILLER_GROUP_ID = environment.billerGroupId;
export const INELIGIBLE_STATES = ['AK', 'HI', 'WA'];
export const RESTRICTED_BOP_CAT_STATES = ['FL', 'LA'];

export const BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD = 'BOP_GENERAL_ELIGIBILITY_OVERRIDE';
export const BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD = 'BOP_CLASS_ELIGIBILITY_OVERRIDE';

export const BOP_V1_UW_COMPANY: BopV1Company = 'HI';
export const BOP_V2_UW_COMPANY: BopV2Company = '26379';
export const BOP_V3_UW_COMPANIES: Readonly<BopV3Company[]> = ['10051', '40754', '10970', '11162'];

export const isBopV1 = (uwCompanyCode: string): uwCompanyCode is BopV1Company =>
  uwCompanyCode === BOP_V1_UW_COMPANY;
export const isBopV2 = (uwCompanyCode: string): uwCompanyCode is BopV2Company =>
  uwCompanyCode === BOP_V2_UW_COMPANY;
export const isBopV3 = (uwCompanyCode: string): uwCompanyCode is BopV3Company =>
  BOP_V3_UW_COMPANIES.includes(uwCompanyCode as any);

export const isBopV2orV3 = (uwCompanyCode: string): uwCompanyCode is BopV2Company | BopV3Company =>
  isBopV2(uwCompanyCode) || isBopV3(uwCompanyCode);

export const ATTUNE_BOP_UW_COMPANY_TO_BOP_VERSION: Record<BopUwCompany, BopVersion> = {
  [BOP_V1_UW_COMPANY]: 1,
  [BOP_V2_UW_COMPANY]: 2,
  ...(zipObject(
    BOP_V3_UW_COMPANIES,
    BOP_V3_UW_COMPANIES.map((_) => 3)
  ) as Record<BopV3Company, 3>),
} as const;

export const ATTUNE_BOP_VERSION_TO_PRODUCT_BADGE: Record<BopVersion, AttuneBopProductBadge> = {
  1: 'bop',
  2: 'bop-plus',
  3: 'bop-fortegra',
} as const;

export const ATTUNE_BOP_UW_COMPANY_TO_PRODUCT_BADGE: Record<BopUwCompany, AttuneBopProductBadge> =
  mapValues(ATTUNE_BOP_UW_COMPANY_TO_BOP_VERSION, (bopVersion) => {
    return ATTUNE_BOP_VERSION_TO_PRODUCT_BADGE[bopVersion];
  });

export const STRONGLY_PREFERRED_PREFERENCE_LEVELS: Readonly<PreferenceLevel[]> = ['1'];
export const PREFERRED_PREFERENCE_LEVELS: Readonly<PreferenceLevel[]> = ['2', '3'];
export const NON_PREFERRED_PREFERENCE_LEVELS: Readonly<PreferenceLevel[]> = ['4'];

export const AVAILABLE_BUSINESS_TYPES: { [key in BuildingClassificationCategory]: string } = {
  Artisans: 'Artisans',
  ApartmentCondominiumAssociation: 'Commercial Condominium Association',
  Contractor: 'Contractor',
  Mercantile: 'Mercantile',
  Office: 'Office',
  OfficeCondominium: 'Office Condominium',
  ProcessingandService: 'Processing and Service',
  RestaurantCasualDining: 'Restaurant Casual Dining',
  RestaurantFastFood: 'Restaurant Fast Food',
  RestaurantFineDining: 'Restaurant Fine Dining',
  RestaurantLimitedCooking: 'Restaurant Limited Cooking',
  Wholesaler: 'Wholesaler',
};

export const HNOA_NOT_AVAILABLE_BUSINESS_TYPES = [
  'Artisans',
  'Contractor',
  'RestaurantCasualDining',
  'RestaurantFastFood',
  'RestaurantFineDining',
  'RestaurantLimitedCooking',
];

export const HNOA_AVAILABLE_STATES = ['PA', 'OH', 'OK', 'KS', 'NV', 'AZ', 'CO'];
export const HNOA_AVAILABLE_CLASS_CODES = [
  '09211', // RestaurantLimitedCooking: Pizza shops
  '09201', // RestaurantFastFood: Pizza shops
  '09651', // RestaurantCasualDining: Family-style restaurants - with no sales of alcoholic beverages
];

export const BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000 = [
  'RestaurantCasualDining',
  'RestaurantFineDining',
  'RestaurantLimitedCooking',
  'RestaurantFastFood',
];

export const BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000 = [
  'Contractor',
  'OfficeCondominium',
  'Office',
  'ProcessingandService',
  'Wholesaler',
  'Mercantile',
  'ApartmentCondominiumAssociation',
];

export const BUILDING_LIMIT_NOT_AVAILABLE_BUSINESS_TYPES = ['Artisans'];

interface AvailableOptionsType {
  [index: string]: string[];
}

interface AvailableOptionalCoveragesType {
  [index: string]: OperationsCoverage[];
}

export enum OperationsCoverage {
  OPTICAL = 'opticalAndHearingAidEstablishmentsProfessionalLiability',
  PRINTER = 'printersErrorsAndOmissionsProfessionalLiability',
  FUNERAL_DIRECTOR = 'funeralDirectorsProfessionalLiability',
  BARBER_SHOP_AND_HAIR_SALON = 'barberShopsAndHairSalonsProfessionalLiability',
  BEAUTY_SALON = 'beautySalonsProfessionalLiability',
  VETERINARIAN = 'veterinariansProfessionalLiability',
}

export const LESSORS_OF_NON_RESIDENTIAL_BUILDINGS = {
  code: '531120',
  description: 'Lessors of nonresidential buildings (except miniwarehouses)',
  hash: '487de5d0db526b5d95d58506adfb2f80',
};

export const AVAILABLE_LIABILITIES_BY_BUILDING_CLASSIFICATION: AvailableOptionalCoveragesType = {
  '50571': [OperationsCoverage.OPTICAL],
  '50721': [OperationsCoverage.OPTICAL],
  '59954': [OperationsCoverage.OPTICAL],
  '59974': [OperationsCoverage.OPTICAL],
  '64181': [OperationsCoverage.VETERINARIAN],
  '64191': [OperationsCoverage.VETERINARIAN],
  '71332': [OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON],
  '71865': [OperationsCoverage.FUNERAL_DIRECTOR],
  '71912': [OperationsCoverage.PRINTER],
  '71952': [OperationsCoverage.BEAUTY_SALON],
};

export const ALL_PROF_LIABILITIES = [
  OperationsCoverage.OPTICAL,
  OperationsCoverage.PRINTER,
  OperationsCoverage.FUNERAL_DIRECTOR,
  OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON,
  OperationsCoverage.BEAUTY_SALON,
  OperationsCoverage.VETERINARIAN,
];

export const PROF_LIABILITY_FIELD_NAMES = {
  [OperationsCoverage.OPTICAL]: ['opticalAndHearingAidSales'],
  [OperationsCoverage.VETERINARIAN]: ['numberOfVeterinarians', 'veterinariansOnlyTreatHousePets'],
  [OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON]: ['barberShopsNumberOfOperators'],
  [OperationsCoverage.FUNERAL_DIRECTOR]: [],
  [OperationsCoverage.PRINTER]: ['printersErrorsAndOmissionsSalesOrPayroll'],
  [OperationsCoverage.BEAUTY_SALON]: [
    'beautySalonsDescriptionOfAdditionalServices',
    'beautySalonsNumberOfOperators',
  ],
};

export const UNAVAILABLE_COVERAGES_BY_STATE: AvailableOptionsType = {
  FL: ['beautySalonsProfessionalLiability'],
  LA: ['beautySalonsProfessionalLiability'],
  NY: ['cyberLiabilityCoverage', 'acceptSnowPlowCompletedOpsCoverage'],
};

export const AVAILABLE_CONSTRUCTION_TYPES = {
  [CONSTRUCTION_TYPE.FRAME_CONSTRUCTION]: 'Frame Construction',
  [CONSTRUCTION_TYPE.JOISTED_MASONRY]: 'Joisted Masonry',
  [CONSTRUCTION_TYPE.NON_COMBUSTIBLE]: 'Non-Combustible',
  [CONSTRUCTION_TYPE.MASONRY_NON_COMBUSTIBLE]: 'Masonry Non-Combustible',
  [CONSTRUCTION_TYPE.MODIFIED_FIRE_RESISTIVE]: 'Modified Fire-Resistive',
  [CONSTRUCTION_TYPE.FIRE_RESISTIVE]: 'Fire-Resistive',
};

export const CONSTRUCTION_TYPE_CODES: { [key: string]: CONSTRUCTION_TYPE } = {
  F: CONSTRUCTION_TYPE.FRAME_CONSTRUCTION,
  FR: CONSTRUCTION_TYPE.FIRE_RESISTIVE,
  HTJM: CONSTRUCTION_TYPE.JOISTED_MASONRY,
  JM: CONSTRUCTION_TYPE.JOISTED_MASONRY,
  MNC: CONSTRUCTION_TYPE.MASONRY_NON_COMBUSTIBLE,
  NC: CONSTRUCTION_TYPE.NON_COMBUSTIBLE,
  SFR: CONSTRUCTION_TYPE.MODIFIED_FIRE_RESISTIVE,
  SMNC: CONSTRUCTION_TYPE.MASONRY_NON_COMBUSTIBLE,
  SNC: CONSTRUCTION_TYPE.NON_COMBUSTIBLE,
};

export const AVAILABLE_PROPERTY_DEDUCTIBLES = [500, 1000, 2500, 5000, 7500, 10000];
export const AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY = [500, 1000, 2500];
export const AVAILABLE_PROPERTY_DEDUCTIBLES_HIGH = [2500, 5000, 7500, 10000];
export const AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH = [2500];
export const AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES = [1000, 2500, 5000, 7500, 10000];
export const AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY = [1000, 2500];
export const AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_HIGH = [2500, 5000, 7500, 10000];
export const AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY_HIGH = [2500];
export const AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_CA = [5000, 7500, 10000];

export const AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES: {
  [key: string]: number;
} = {
  '$300,000': 300000,
  '$500,000': 500000,
  '$1,000,000': 1000000,
  '$2,000,000': 2000000,
};

export const AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES_UP_TO_1000000: {
  [key: string]: number;
} = {
  '$300,000': 300000,
  '$500,000': 500000,
  '$1,000,000': 1000000,
};

export const AVAILABLE_LIMITS_PER_PERSON_OF_MEDICAL_EXPENSES = {
  '$5,000': 5000,
  '$10,000': 10000,
};

export const AVAILABLE_INSTALLATION_LIMITS = {
  '$5,000': 5000,
  '$10,000': 10000,
  '$15,000': 15000,
  '$20,000': 20000,
  '$25,000': 25000,
};

export const AVAILABLE_TOOLS_BLANKET_LIMITS = {
  $3000: 3000,
  '$10,000': 10000,
  '$25,000': 25000,
  '$50,000': 50000,
  '$100,000': 100000,
};

export const AVAILABLE_TOOLS_SUBLIMITS = {
  $500: 500,
  '$1,000': 1000,
  '$2,000': 2000,
};

export const AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY = [0, 5000, 10000, 25000, 50000, 100000];
export const AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY_FROM_10000 = [10000, 25000, 50000, 100000];
export const AVAILABLE_LIMITS_FOR_EMPLOYEE_DISHONESTY_FROM_25000 = [25000, 50000, 100000];

export interface LimitOption {
  limit: number;
  deductible: number;
}

/**
 * Limits should be entered in ascending order
 */
export const BOP_V2_CYBER_OPTIONS: {
  default: {
    aggregateLimitOptions: LimitOption[];
    firstPartyLimitOptions: LimitOption[];
  };
  [stateOverrides: string]: {
    aggregateLimitOptions?: LimitOption[];
    firstPartyLimitOptions?: LimitOption[];
  };
} = {
  default: {
    aggregateLimitOptions: [
      {
        limit: 50_000,
        deductible: 1_000,
      },
      {
        limit: 100_000,
        deductible: 1000,
      },
      {
        limit: 250_000,
        deductible: 2500,
      },
    ],
    firstPartyLimitOptions: [
      {
        limit: 25_000,
        deductible: 1000,
      },
      {
        limit: 50_000,
        deductible: 1000,
      },
      {
        limit: 125_000,
        deductible: 2500,
      },
    ],
  },
  NY: {
    firstPartyLimitOptions: [
      {
        limit: 500_000,
        deductible: 10_000,
      },
      {
        limit: 1_000_000,
        deductible: 10_000,
      },
    ],
  },
};

export const BOP_V2_CYBER_SPLIT_STATES = [
  'AR',
  'LA',
  'MN',
  'MT',
  'NH',
  'NM',
  'NY',
  'OK',
  'SD',
  'RI',
  'VT',
];

export type CyberProductType = 'coalition-cyber' | 'endorsement' | 'none';

export const BOP_V2_EPLI_SPLIT_STATES = ['LA', 'MT', 'NM', 'AR', 'VT'];
export const BOP_V2_EPLI_SPLIT_LIMITS_HIGH = [50000, 125000];
export const BOP_V2_EPLI_SPLIT_LIMITS_NORMAL = [12500, 25000, 37500].concat(
  BOP_V2_EPLI_SPLIT_LIMITS_HIGH
);
export const BOP_V2_EPLI_AGG_LIMITS_HIGH = [100000, 250000];
export const BOP_V2_EPLI_AGG_LIMITS_NORMAL = [25000, 50000, 75000].concat(
  BOP_V2_EPLI_AGG_LIMITS_HIGH
);

export const BOP_V2_EPLI_AGG_LIMIT_OPTIONS = {
  '$25,000': 25000,
  '$50,000': 50000,
  '$75,000': 75000,
  '$100,000': 100000,
  '$250,000': 250000,
};
export const BOP_V2_EPLI_AGG_LIMIT_OPTIONS_HIGH = {
  '$100,000': 100000,
  '$250,000': 250000,
};

export const BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS = {
  '$12,500': 12500,
  '$25,000': 25000,
  '$37,500': 37500,
  '$50,000': 50000,
  '$125,000': 125000,
};
export const BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS_HIGH = {
  '$50,000': 50000,
  '$125,000': 125000,
};

export const BOP_V2_DEDUCTIBLE_OPTIONS: { [key: string]: number } = {
  '$2,500': 2500,
  '$5,000': 5000,
  '$10,000': 10000,
  '$25,000': 25000,
};

export const BOP_V2_STATES_WITHOUT_2500_EPLI_DEDUCTIBLE = ['CA', 'NJ'];

export interface EpliOptions {
  aggregateLimitV2: { [key: string]: number };
  defenseLimitV2: { [key: string]: number };
  deductibleV2: { [key: string]: number };
}

export const BOP_V2_EPLI_DEFAULT_OPTIONS: EpliOptions = {
  aggregateLimitV2: BOP_V2_EPLI_AGG_LIMIT_OPTIONS,
  defenseLimitV2: BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS,
  deductibleV2: BOP_V2_DEDUCTIBLE_OPTIONS,
};

export const AVAILABLE_AGGREGATE_LIMITS_FOR_CYBER_LIABILITY = {
  '$50,000': 50000,
  '$100,000': 100000,
};

export const ADDITIONAL_INSURED_TYPES: { [key: string]: AdditionalInsuredType } = {
  'Building Owner - Additional Insured': AdditionalInsuredType.BUILDING_OWNER,
  'Co-Owner of Insured Premises': AdditionalInsuredType.CO_OWNER,
  'Contract, Agreement Or Permit': AdditionalInsuredType.CONTRACT_AGREEMENT_PERMIT,
  'Controlling Interest': AdditionalInsuredType.CONTROLLING_INTEREST,
  'Designated Person or Organization': AdditionalInsuredType.DESIGNATED_PERSON,
  'Lessor of Leased Equipment': AdditionalInsuredType.LESSOR_OF_EQUIPMENT,
  'Loss Payable': AdditionalInsuredType.LOSS_PAYABLE,
  'Loss of rental value - Landlord as Designated Payee': AdditionalInsuredType.LOSS_OF_RENTAL_VALUE,
  'Managers or Lessors of Premises': AdditionalInsuredType.MANAGER_OF_PREMISES,
  'Mortgage Holder': AdditionalInsuredType.MORTGAGE_HOLDER,
  'Mortgagee, Assignee or Receiver': AdditionalInsuredType.MORTGAGEE,
  'Owners or Other Interests From Whom Land Has Been leased': AdditionalInsuredType.OWNERS_OF_LAND,
  'Owners, lessees or contractors - Completed Operations':
    AdditionalInsuredType.OWNERS_COMPLETED_OPS,
  'Owners, lessees or contractors - Scheduled Person or Organization':
    AdditionalInsuredType.OWNERS_SCHEDULED_PERSON,
  Vendors: AdditionalInsuredType.VENDORS,
};

export const ADDITIONAL_INSURED_TYPES_IN_FL: { [key: string]: AdditionalInsuredType } = {
  'Controlling Interest': AdditionalInsuredType.CONTROLLING_INTEREST,
  'Designated Person or Organization': AdditionalInsuredType.DESIGNATED_PERSON,
  Vendors: AdditionalInsuredType.VENDORS,
  'Co-Owner of Insured Premises': AdditionalInsuredType.CO_OWNER,
  'Lessor of Leased Equipment': AdditionalInsuredType.LESSOR_OF_EQUIPMENT,
  'Managers or Lessors of Premises': AdditionalInsuredType.MANAGER_OF_PREMISES,
  'Owners or Other Interests From Whom Land Has Been leased': AdditionalInsuredType.OWNERS_OF_LAND,
  'Owners, lessees or contractors - Scheduled Person or Organization':
    AdditionalInsuredType.OWNERS_SCHEDULED_PERSON,
  'Loss of rental value - Landlord as Designated Payee': AdditionalInsuredType.LOSS_OF_RENTAL_VALUE,
  'Mortgagee, Assignee or Receiver': AdditionalInsuredType.MORTGAGEE,
  'Loss Payable': AdditionalInsuredType.LOSS_PAYABLE,
  'Mortgage Holder': AdditionalInsuredType.MORTGAGE_HOLDER,
};

export const ADDITIONAL_INSURED_TYPES_DISABLED_IN_PORTAL: {
  [key: string]: AdditionalInsuredType;
} = {
  'Engineers, Architects or Surveyors': AdditionalInsuredType.ENGINEERS,
  'Engineers, Architects or Surveyors Not Engaged by the Named Insured':
    AdditionalInsuredType.ENGINEERS_NOT_ENGAGED,
  'Grantor of Franchise': AdditionalInsuredType.GRANTOR_OF_FRANCHISE,
  'Owners, Lessees or Contractors - With Additional Insured Requirement in Construction Contract':
    AdditionalInsuredType.OWNERS,
  'State or Political Subdivision - Permits or Authorizations':
    AdditionalInsuredType.STATE_SUBDIVISIONS_AUTHORIZATIONS,
  'State or Political Subdivisions - Permits Relating to Premises':
    AdditionalInsuredType.STATE_SUBDIVISIONS,
};

export const ADDITIONAL_INSURED_TYPES_WITH_NO_ENTITY: {
  [key: string]: AdditionalInsuredType;
} = {
  'Contract, Agreement Or Permit': AdditionalInsuredType.CONTRACT_AGREEMENT_PERMIT,
};

export const ADDITIONAL_INSURED_ADDRESS_TYPES = {
  billing: 'Billing',
  business: 'Business',
  home: 'Home',
  other: 'Other',
};

export const getBaseEmployeeLiabilityLimit = (baseState?: string) => {
  const baseLimit = 1000000;
  switch (baseState) {
    default:
      return baseLimit;
  }
};

export const getAvailableEachEmployeeLimitsForEmployeeLiability = (baseState?: string) => {
  const defaultLimits = [100000, 300000, 500000, 1000000];
  switch (baseState) {
    case 'CT':
      return [500000, 1000000];
    default:
      return defaultLimits;
  }
};

export const getAvailableAggregateLimitsForEmployeeLiability = (baseState?: string) => {
  const defaultLimits = {
    '$1,000,000': 1000000,
    '$2,000,000': 2000000,
  };

  switch (baseState) {
    default:
      return defaultLimits;
  }
};

export const getAvailableDeductiblesForEmployeeLiability = (baseState?: string) => {
  const defaultDeductibles = {
    $0: 0,
    '$1,000': 1000,
    '$2,500': 2500,
    '$5,000': 5000,
  };
  switch (baseState) {
    case 'CT':
      return {
        '$1,000': 1000,
        '$2,500': 2500,
        '$5,000': 5000,
      };
    default:
      return defaultDeductibles;
  }
};

export const DEBRIS_REMOVAL_LIMIT_DEFAULT = 25000;

export const AVAILABLE_DEBRIS_REMOVAL_LIMITS = {
  '$25,000': DEBRIS_REMOVAL_LIMIT_DEFAULT,
  '$50,000': 50000,
  '$75,000': 75000,
  '$100,000': 100000,
};

export const AVAILABLE_SPOILAGE_LIMITS: number[] = [0, 10000, 20000, 30000, 40000, 50000];

export const AVAILABLE_SPOILAGE_LIMITS_RESTAURANTS: number[] = [0, 30000, 40000, 50000];

export const BOP_V1_EPLI_EACH_ACT_LIMITS = [10000, 25000, 50000, 75000, 100000, 250000];

export const BOP_V1_EPLI_AVAILABLE_DEDUCTIBLES = {
  '$5,000': 5000,
  '$10,000': 10000,
  '$25,000': 25000,
};

export const BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS: { [key: string]: number } =
  {
    '6': 6,
    '9': 9,
    '12': 12,
    '18': 18,
    '24': 24,
  };

export const NAMED_INSURED_CHANGE_TYPES = {
  update_primary: 'Update primary named insured',
  add_additional: 'Add a named insured',
};
export type NAMED_INSURED_CHANGE_TYPE = keyof typeof NAMED_INSURED_CHANGE_TYPES;

export const EPLI_EXCEPTIONS = ['63921', '63931', '63761', '63771'];
export const AVAILABLE_EACH_COVERED_JOB_SITE_LIMITS = [3000, 5000, 10000, 15000, 20000, 25000];

export type ClassRestrictedCoverage = 'Spoilage';

export type SpoilageClass =
  | 'BakeryGoods'
  | 'DairyProductsexcludingIceCream'
  | 'Delicatessens'
  | 'Florists'
  | 'FruitsandVegetables'
  | 'MeatandPoultryMarkets'
  | 'Other'
  | 'Pharmaceuticals'
  | 'Restaurants'
  | 'Seafood';

export interface SpoilageDescription {
  spoilageClass: SpoilageClass;
  perishableDesc: string;
}

export const PIZZA_SHOP_CLASSIFICATION_CODE = '09211';

export const NAIL_SALON_CLASS_CODE = '71952';
export const BARBER_SHOP_CLASS_CODE = '71332';

export const REQUIRED_BOPV1_WIND_COVERAGE_STATES = ['FL', 'LA', 'NY', 'TX'];
export const REQUIRED_BOPV2_WIND_COVERAGE_STATES = ['MA', 'NY', 'FL'];

export const NO_WINDSTORM_OR_HAIL_EXCLUSION_STATES = ['AR', 'DC', 'LA', 'MA', 'NY', 'RI'];

export const LIQUOR_LIABILITY_COVERAGE_EXCLUSION_STATES = ['DC', 'VT'];

export const AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_EACH_COMMON_CAUSE = [
  50000, 100000, 300000, 500000, 1000000,
];

export const AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_AGGREGATE = [
  100000, 200000, 600000, 1000000, 2000000,
];

export const BOPV1_AVAILABLE_LIMITS_FOR_LIQUOR_LIABILITY_AGGREGATE_NY = [
  100000, 200000, 600000, 1000000,
];

export const MAX_LIQUOR_LIABILITY_AGGREGATE = 2000000;
export const BOPV1_MAX_LIQUOR_LIABILITY_AGGREGATE_NY = 1000000;

export const IL_LL_SOCIETAL_LIMIT_CODE = 55000;
export const IL_LL_BODILY_INJURY_LIMIT_CODE = 45000;
export const IL_LL_PROPERTY_LIMIT_CODE = 45000;

export const EXCESS_LIABILITY_LIMITS = [1e6, 2e6, 3e6, 4e6, 5e6];

export const EXCESS_LIABILITY_PER_OCCURRENCE_THRESHOLD = 1e6;
export const STOP_GAP_PER_OCCURENCE_THRESHOLD = 1e6;

export const EXCESS_COMMERCIAL_AUTO_LIMIT_OPTIONS = [
  1e6, 2e6, 3e6, 4e6, 5e6, 6e6, 7e6, 8e6, 9e6, 1e7,
];

export const EXCLUDED_V2_EXCESS_CODES = [
  '50996',
  '50997',
  '50998',
  '50999',
  '09996',
  '09997',
  '09992',
  '09993',
  '89991',
  '89993',
  '89994',
  '89998',
  '89999',
  '69974',
  '69975',
  '69976',
  '69977',
  '69984',
  '69985',
  '69986',
  '69987',
  '69988',
  '69989',
  '69990',
  '69991',
  '69992',
  '69993',
  '69996',
  '69997',
  '69998',
  '69999',
  '51961',
  '51965',
  '51971',
  '51973',
  '51977',
  '51978',
  '51980',
  '51981',
  '51982',
  '51988',
  '51992',
  '51997',
  '79986',
  '79987',
  '79988',
  '79989',
  '79990',
  '79991',
  '79992',
  '79993',
  '79994',
  '79995',
  '79998',
  '79999',
  '69982',
  '69983',
  '99978',
  '99979',
  '99980',
  '99981',
  '99982',
  '99983',
  '99984',
  '99985',
  '99986',
  '99987',
  '99988',
  '99989',
  '99990',
  '99991',
  '99992',
  '99993',
  '99995',
  '99997',
  '99998',
];

/* Liquor Liability
 * - available to ALL 3 sets of codes below
 * - defaults to true for DEFAULT... codes
 * - BYOB is separated from AVAILABLE due to payload + sales collection differences
 */
export const AVAILABLE_LIQUOR_CLASS_CODES = [
  '09001',
  '09011',
  '09021',
  '09031',
  '09041',
  '09051',
  '09061',
  '09071',
  '09081',
  '09091',
  '09101',
  '09111',
  '09121',
  '09141',
  '09151',
  '09161',
  '09171',
  '09201',
  '09211',
  '09221',
  '09231',
  '09241',
  '09251',
  '09261',
  '09341',
  '09990',
  '09991',
  '09992',
  '09993',
  '09994',
  '09995',
  '09996',
];

export const DEFAULT_LIQUOR_CLASS_CODES = [
  '09621',
  '09641',
  '09661',
  '09431',
  '09441',
  '09997',
  '09998',
  '09999',
  '59215',
];

export const BYOB_LIQUOR_CLASS_CODES = ['09451', '09671', '09681', '09691'];

export const DOCTORS_AND_DENTISTS_CLASS_CODES = ['63991', '63981'];

export const AVAILABLE_NUMBER_OF_STORIES: StoryCounts = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '10': '10',
  '11': '11',
  '12': '12',
  '13': '13',
  '14': '14',
  '15': '15',
  '16': '16',
  '17': '17',
  '18': '18',
  '19': '19',
  '20': '20',
  Over20: '21+',
};

export const BOP_POLICY_PAYMENT_PLAN_OPTIONS = {
  'One-time payment': 1,
  'Four payments': 4,
  'Ten payments': 10,
};

export const POLICY_PAYMENT_PLAN_IDS: { [key: number]: QSBindPaymentPlan } = {
  1: 'ONE_PAY',
  4: 'FOUR_PAY',
  10: 'TEN_PAY',
};

export const PREMIUM_CUTOFF_1PAY_ONLY = 530;
export const INSTALLMENT_FEE_FL = 3;
export const INSTALLMENT_FEE_4PAY = 5;
export const INSTALLMENT_FEE_10PAY = 4;
export const INSTALLMENT_FEE_BOPV2 = 5;

export const BOP_V2_STATES_WITH_NO_INSTALLMENT_FEES = ['CA', 'WV'];
export const BOP_V2_STATES_WITH_NO_INSTALLMENT_FEES_IN_FIRST_PAYMENT = ['LA'];

// https://docs.google.com/spreadsheets/d/1sNnGzo0PwFqkIUZfw-lwWSdLbowDwT-qO87t5gMDBHM/edit?usp=sharing
export const CC_PROCESSING_FEE_EXCLUSIONS = ['FL', 'ME', 'NC', 'RI'];

export const TECHNOLOGY_FEE_TEXT_PER_STATE: {
  // [effectiveDateString, feePercentage, feeMaxAmount]
  [key: string]: [string, number, number][];
} = {
  AL: [
    ['2021-04-01', 4, 250],
    ['2023-10-01', 6, 350],
  ],
  AZ: [
    ['2021-04-01', 4, 250],
    ['2024-01-01', 6, 350],
  ],
  AR: [
    ['2021-04-01', 4, 250],
    ['2024-01-01', 6, 350],
  ],
  CO: [
    ['2021-04-01', 4, 250],
    ['2024-01-01', 6, 350],
  ],
  FL: [
    ['2021-04-01', 4, 250],
    ['2023-04-01', 6, 350],
  ],
  GA: [
    ['2021-04-01', 4, 250],
    ['2023-08-01', 6, 350],
  ],
  IL: [
    ['2021-04-01', 4, 250],
    ['2023-05-01', 6, 350],
  ],
  IN: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  KS: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  KY: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  LA: [
    ['2021-04-01', 4, 250],
    ['2023-04-01', 6, 350],
  ],
  MA: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  MI: [['2024-07-01', 6, 350]],
  MO: [['2024-07-01', 6, 350]],
  MS: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  NJ: [
    ['2021-04-01', 4, 250],
    ['2023-08-01', 6, 350],
  ],
  NM: [
    ['2021-04-01', 4, 250],
    ['2024-01-01', 6, 350],
  ],
  NC: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  OK: [
    ['2021-04-01', 4, 250],
    ['2024-01-01', 6, 350],
  ],
  PA: [
    ['2021-04-01', 4, 250],
    ['2023-08-01', 6, 350],
  ],
  RI: [
    ['2021-04-01', 4, 250],
    ['2024-04-01', 6, 350],
    // Note: after 2024-11-01, tech fees are not applied in RI
  ],
  SC: [
    ['2021-04-01', 4, 250],
    ['2022-12-25', 6, 350],
  ],
  TN: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  TX: [
    ['2021-04-01', 4, 250],
    ['2022-12-01', 6, 350],
  ],
  VA: [
    ['2021-04-01', 4, 250],
    ['2024-03-01', 6, 350],
  ],
  default: [['2021-04-01', 4, 250]],
};

export const REQUIRED_WIND_LOSS_MITIGATION_STATES = ['SC'];

export const DEFAULT_DISTANCE_TO_OCEAN_CODE = '16';
export const DEFAULT_AIR_DISTANCE_TO_OCEAN_CODE = '18';

export const WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE = 'NotApplicable';

export const WIND_LOSS_MITIGATION_QUESTIONS = {
  level: {
    A: 'Level A',
    B: 'Level B',
    C: 'Level C',
    D: 'Level D',
    RCR: 'Reinforced Concrete Roof Deck',
  },

  roofToWallConnection: {
    Clips: 'Clips',
    SingleWraps: 'Single/Double Wraps',
    ToenAils: 'Toe Nails', // ToenAils is right
  },

  openingProtection: {
    AllOther: 'All Other',
    None: 'None',
    THL: 'Tempered/ Heat Strengthened/ Laminated Glass/ Insulating Glass Units',
  },

  doorStrength: {
    AllOther: 'All Other',
    RSWD: 'Reinforced Single Width Doors',
  },

  roofShape: {
    Flat: 'Flat',
    GEWB: 'Gable End With Bracing',
    Hip: 'Hip',
  },

  roofAndDeckAttachment: {
    Metal: 'Metal',
    RCR: 'Reinforced Concrete',
    Wood: 'Wood',
  },
};

export const DEFAULT_EXCESS_LIABILITY_COVERAGE_OPTIONS = {
  '$500,000': '$500,000',
  '$1,000,000': '$1,000,000',
  '$2,000,000': '$2,000,000',
  '$3,000,000': '$3,000,000',
  '$4,000,000': '$4,000,000',
  '$5,000,000': '$5,000,000',
  '$6,000,000': '$6,000,000',
  '$7,000,000': '$7,000,000',
  '$8,000,000': '$8,000,000',
  '$9,000,000': '$9,000,000',
  '$10,000,000': '$10,000,000',
};

export interface UWQuestion {
  questionID: string;
  questionText: string;
  options?: { [K: string]: string | boolean | number };
  answerToDecline?: string | boolean | number;
  exclusionsToAddIfYes?: string[];
}

export type QuestionCode =
  | 'CUP_SURGERY_CUE'
  | 'CUP_PatientsAdmitOverNight_CUE'
  | 'CUP_LandPowerLines_CUE'
  | 'CUP_HelmetCarSeatChildSafety_CUE'
  | 'CUP_RaceHorses_CUE'
  | 'CUP_RentLeaseScaffoldingCrane_CUP'
  | 'CUP_RepackageRelabel_CUE'
  | 'CUP_ForeignFireArms_CUE'
  | 'CUP_PoliceSupplies_CUE'
  | 'CUP_Smoke_CUE'
  | 'CUP_ReadyMixTrucks_CUE';

export const UW_QUESTIONS: { [K: string]: UWQuestion } = {
  CUP_ForeignFireArms_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_ForeignFireArms_CUE',
    questionText: 'Does the insured sell any foreign manufactured firearms?',
  },
  CUP_HelmetCarSeatChildSafety_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_HelmetCarSeatChildSafety_CUE',
    questionText: 'Does the insured import any helmets, car seats or other child safety equipment?',
  },
  CUP_LandPowerLines_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_LandPowerLines_CUE',
    questionText: 'Does the insured clear land used for power lines?',
  },
  CUP_PatientsAdmitOverNight_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_PatientsAdmitOverNight_CUE',
    questionText: 'Does the insured admit patients for overnight stays at their facility?',
  },
  CUP_PoliceSupplies_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_PoliceSupplies_CUE',
    questionText: 'Does the insured sell police supplies?',
  },
  CUP_RaceHorses_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_RaceHorses_CUE',
    questionText: 'Does the insured perform any services for race horses?',
  },
  CUP_ReadyMixTrucks_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_ReadyMixTrucks_CUE',
    questionText: 'Does the scheduled underlying auto policy include any ready mix trucks?',
  },
  CUP_RentLeaseScaffoldingCrane_CUP: {
    exclusionsToAddIfYes: ['CUP_CRANE_SCAFFOLD_CUE'],
    questionID: 'CUP_RentLeaseScaffoldingCrane_CUP',
    questionText: 'Does the insured rent or lease scaffolding and/or cranes to others?',
  },
  CUP_RepackageRelabel_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_RepackageRelabel_CUE',
    questionText:
      'Does the insured repackage or relabel any of the products they sell or distribute?',
  },
  CUP_SURGERY_CUE: {
    answerToDecline: 'Yes',
    questionID: 'CUP_SURGERY_CUE',
    questionText:
      "Do the insured's physicians perform any surgeries that require sedation or are any such surgeries performed at the insured's premises?",
  },
  CUP_Smoke_CUE: {
    answerToDecline: 'No',
    questionID: 'CUP_Smoke_CUE',
    questionText:
      'Are there smoke detectors in each unit and minimum of two (2) marked exists? (If battery operated - monthly inspection program must be documented and available to review if requested)',
  },
};

export const REQUIRED_UW_QUESTIONS_BY_BUILDING_CODE: { [K: string]: QuestionCode[] } = {
  '59526': [
    'CUP_HelmetCarSeatChildSafety_CUE',
    'CUP_RepackageRelabel_CUE',
    'CUP_ForeignFireArms_CUE',
  ],
  '59999': ['CUP_RepackageRelabel_CUE', 'CUP_PoliceSupplies_CUE'],
  '60989': ['CUP_Smoke_CUE'],
  '60999': ['CUP_Smoke_CUE'],
  '63981': ['CUP_SURGERY_CUE', 'CUP_PatientsAdmitOverNight_CUE'],
  '63991': ['CUP_SURGERY_CUE', 'CUP_PatientsAdmitOverNight_CUE'],
  '64061': ['CUP_Smoke_CUE'],
  '64071': ['CUP_Smoke_CUE'],
  '64181': ['CUP_RaceHorses_CUE'],
  '64191': ['CUP_RaceHorses_CUE'],
  '65171': ['CUP_Smoke_CUE'],
  '65231': ['CUP_Smoke_CUE'],
  '74341': ['CUP_RentLeaseScaffoldingCrane_CUP', 'CUP_ReadyMixTrucks_CUE'],
  '74351': ['CUP_RentLeaseScaffoldingCrane_CUP', 'CUP_ReadyMixTrucks_CUE'],
  '74471': ['CUP_RentLeaseScaffoldingCrane_CUP', 'CUP_ReadyMixTrucks_CUE'],
  '74481': ['CUP_RentLeaseScaffoldingCrane_CUP', 'CUP_ReadyMixTrucks_CUE'],
  '74501': ['CUP_RentLeaseScaffoldingCrane_CUP'],
  '74511': ['CUP_RentLeaseScaffoldingCrane_CUP'],
  '74891': ['CUP_LandPowerLines_CUE'],
  '74901': ['CUP_LandPowerLines_CUE'],
  '75751': ['CUP_RentLeaseScaffoldingCrane_CUP'],
  '75761': ['CUP_RentLeaseScaffoldingCrane_CUP'],
};

export interface BindFormSteps {
  activeSteps: string[];
  currentStepIndex: number;
  allSteps: string[];
  paths: { [k: string]: string };
}

export const ExcessBindLimitOptions = [
  1e5, 2e5, 3e5, 4e5, 5e5, 6e5, 7e5, 8e5, 9e5, 1e6, 2e6, 3e6, 4e6, 5e6, 6e6, 7e6, 8e6, 9e6, 1e7,
];

export const BOP_V2_EPLI_INELIGIBLE_STATES = ['AR'];

export const MINIMUM_LRO_BUILDING_LIMIT = 50000;

export const MINIMUM_BPP_BY_BUSINESS_TYPE: {
  [key in BuildingClassificationCategory]?: number;
} = {
  Contractor: 5000,
  Mercantile: 5000,
  Office: 5000,
  OfficeCondominium: 5000,
  ProcessingandService: 5000,
  RestaurantLimitedCooking: 35000,
  RestaurantFastFood: 35000,
  RestaurantCasualDining: 50000,
  RestaurantFineDining: 50000,
  Wholesaler: 5000,
};

export const BPP_LIMIT_CHANGE_THRESHOLDS = {
  INCREASE: 100000,
  DECREASE: 100000,
};

export const BPP_LIMIT_REL_CHANGE_THRESHOLDS = {
  INCREASE: 0.2,
  DECREASE: 0.1,
};

export const BUILDING_LIMIT_REL_CHANGE_THRESHOLDS = {
  INCREASE: 0.2,
  DECREASE: 0.1,
};

export const BUILDING_LIMIT_ESTIMATE_MINIMUM = 25000;

export const SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM = 3500;

export const GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS = 'tbd@email.com';

export const GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER = '8888888888';

export const MAX_BACKDATE_AGE_IN_DAYS = 10;

export const NKLL_TEMPLATE_URL =
  'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Attune+BOP+NKLL+Template.pdf';

// Endorsement FAQ constants
export const WHAT_IS_NKLL_FAQ: Faq = {
  question: 'What is a No Known Loss Letter (NKLL)?',
  answer:
    'A NKLL is a document that certifies that there have been no known losses, accidents or circumstances that might give rise to a claim under the relevant insurance policy. A NKLL is required for backdating certain types of endorsements.',
};

export const WHICH_ENDORSEMENTS_NEED_NKLL_FAQ: Faq = {
  question: 'Which endorsements, if backdated, need a NKLL?',
  answer:
    'The following endorsements require a NKLL if they are backdated:\
    <ul class="endorse-bop-faqs-answer">\
      <li>Additional insureds (excluding BP 04 09, BP 04 10, BP 12 03, BP 12 31, Mortgage Holder)</li>\
      <li>Coverage changes</li>\
      <li>Named insured changes</li>\
      <li>Location changes</li>\
    </ul>',
};

export const NKLL_TEMPLATE_PROVIDED_FAQ: Faq = {
  question: 'Do you provide a NKLL template?',
  answer: `Yes, you can complete the following form and submit it as a NKLL. <a href=${NKLL_TEMPLATE_URL} target="_blank">Download the form here</a>.`,
};

export const AVAILABLE_ADDITIONAL_INSUREDS_ENDORSEMENTS_FAQ: Faq = {
  question: 'Which types of endorsements for additional insureds are available?',
  answer: `The full list of available endorsements for additional insureds is accessible on page 31 of our <a href=${BOP_GUIDELINE_LINK_BLACKBOARD}#page=31 target="_blank">BOP Underwriting Guidelines</a>.`,
};

export const BOP_ENDORSEMENT_GENERAL_FAQS: Faq[] = [
  WHAT_IS_NKLL_FAQ,
  WHICH_ENDORSEMENTS_NEED_NKLL_FAQ,
  NKLL_TEMPLATE_PROVIDED_FAQ,
];

export const BOP_ENDORSEMENT_AI_FAQS: Faq[] = [
  WHAT_IS_NKLL_FAQ,
  WHICH_ENDORSEMENTS_NEED_NKLL_FAQ,
  NKLL_TEMPLATE_PROVIDED_FAQ,
  AVAILABLE_ADDITIONAL_INSUREDS_ENDORSEMENTS_FAQ,
];
// End Endorsement FAQ constants

// LRO Guideline FAQ constant
export const LRO_HOW_TO_CLASSIFY_FAQ: Faq = {
  question: 'How to Classify',
  answer:
    "<h5>Single Occupancy LRO</h3>\
    <ul class='lro-bop-faqs-answer'>\
      <li>Select the applicable class code (from the library of available Attune BOP class codes) of the building occupant/tenant</li>\
    </ul>\
    <h5>Multiple Occupancy LRO</h3>\
    <ul class='lro-bop-faqs-answer'>\
      <li>If an Office building contains eligible restaurant occupancies, rate as a restaurant regardless of % of total floor area</li>\
      <li>If an Office building contains eligible occupancies other than restaurants, select the classification of the largest occupancy type (by square footage)</li>\
      <li>If a building (other than an Office building, i.e. a strip center) contains multiple eligible occupancies, select the classification of the occupancy type (by square footage) with the largest total floor area.</li>\
    </ul>",
};

// Prefill constants
export const PREFILL_REQUEST_GROUP_SIZE = 6;
export const PREFILL_TIMEOUT_MS = 90 * 1000;
// End Prefill constants

export const EACH_COVERED_JOB_SITE_LIMIT_DEFAULT = AVAILABLE_EACH_COVERED_JOB_SITE_LIMITS[0];
export const EXCLUDED_V2_CYBER_CODES = ['63691', '63711', '63721', '63731', '64161', '64171'];
// Note - This only excludes the cyber standard endorsement, not active cyber coverage.
export const BOP_V1_EXCLUDED_CYBER_STANDARD_ENDORSEMENT_STATES = ['NY'];
export const EXCLUDED_V2_EXCESS_STATES = ['CT', 'MA'];
export const INCLUDED_STOP_GAP_STATES = ['OH', 'ND', 'WY', 'WA'];
// These are states where BOP+ contractor forms have not been approved, so we're going to use the v1 contractor fields
export const KEEP_V1_CONTRACTOR_CONTROL_STATES = ['GA', 'NY', 'HI', 'KY'];

export const ELECTRONICS_STORE_CLASS_CODE = '57326';

// Note: A css property `pre-wrap` is applied to this text to ensure that whitespace and `\n`s are preserved. If in the future, more styling is necessary, we might have to switch to putting that language in the html template and using `*ngIf`s
export const FRAUD_LANGUAGE_BY_STATE: { [key: string]: string } = {
  AR: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  CA: 'For your protection, California law requires that you be made aware of the following: Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.',
  CO: 'It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory agencies.',
  DC: 'WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.',
  FL: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  KY: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  LA: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  ME: 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines, or a denial of insurance benefits.',
  MD: 'Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  NJ: 'Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.',
  NM: 'ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO CIVIL FINES AND CRIMINAL PENALTIES.',
  NY: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  OH: 'Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.',
  OK: 'WARNING – Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.',
  OR: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents materially false information in an application for insurance may be guilty of a crime and may be subject to fines and confinement in prison.\nIn order for us to deny a claim on the basis of misstatements, misrepresentations, omissions or concealments on your part, we must show that: \n        A.        The misinformation is material to the content of the policy;\n        B.        We relied upon the misinformation; and \n        C.        The information was either:\n              1.        Material to the risk assumed by us; or \n              2.        Provided fraudulently. \nFor remedies other than the denial of a claim, misstatements, misrepresentations, omissions or concealments on your part must either be fraudulent or material to our interests. \nWith regard to fire insurance, in order to trigger the right to remedy, material misrepresentations must be willful or intentional.\nMisstatements, misrepresentations, omissions or concealments on your part are not fraudulent unless they are made with the intent to knowingly defraud.',
  PA: 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.',
  RI: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  TN: 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
  VA: 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
  WA: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  WV: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
};

export const DEFAULT_FRAUD_LAUNGUAGE =
  'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.';

export const UW_INTERVENTION_BUILDING_LIMIT_TEMPLATE =
  "This building's estimated replacement cost is %ESTIMATE%. You entered a building limit of %ENTERED% which is %PERCENT_OF% below the estimate.";
export const UW_INTERVENTION_BPP_TEMPLATE =
  'Businesses like this one typically have Business Personal Property of approximately %ESTIMATE_LOWER% to %ESTIMATE_UPPER%.  You entered a BPP value of %ENTERED%, which is %PERCENT_OF_LOWER% - %PERCENT_OF_UPPER% less than similar businesses.';
export const UW_INTERVENTION_SALES_TEMPLATE =
  'Businesses like this one typically have sales of approximately %ESTIMATE_LOWER% to %ESTIMATE_UPPER%.  You entered a sales value of %ENTERED%, which is %PERCENT_OF_LOWER% - %PERCENT_OF_UPPER% less than similar businesses.';

export const AIG_EXCESS_BIND_CUTOFF_DATE = 'March 20, 2022';

export const EXCESS_LIABILITY_QUESTION_TEXT: { [k: string]: string } = {
  excessEmployersUnderlyingPremium: 'Employers liability estimated underlying premium (Optional)',
  excessCommercialAutoUnderlyingPremium: 'Commercial auto estimated underlying premium (Optional)',
  excessAnnualRevenue: 'Annual Revenue',
  excessLiabilityLimit: 'Excess Liability Limit',
  excessLiabilityCoverageIsScheduled: "Would you like to schedule Employers' Liability Coverage?",
  excessLiabilityPerAccidentCoverage: 'Per accident',
  excessLiabilityPerDiseaseCoverage: 'Per disease',
  excessLiabilityPerPolicyCoverage: 'Per policy',
  excessCommercialAutoCoverageIsScheduled: 'Would you like to schedule Commercial Auto Coverage?',
  excessCommercialAutoCombinedLimit: 'Commercial Auto Coverage Combined Single Limit',
  excessCommercialAutoCoverageVehicleCounts: 'Commercial Auto Coverage Vehicle Counts',
  PrivatePassengerVehicleCount: 'Private Passenger Vehicles - Including hired and non-owned',
  LightTrucksCount: 'Light Trucks (0 to 10,000 lbs.) - Including 1-8 Passenger Vans',
  MediumTrucksCount: 'Medium Trucks (10,001 to 20,000 lbs.) - Including 9-20 Passenger Vans',
  HeavyTrucksCount: 'Heavy Trucks (20,001 to 45,000 lbs.) - Units not for hire',
  ExHvyTruckAndTractorSHCount:
    'Short Haul Extra Heavy Trucks and Tractors (over 45,000 lbs.) - Units not for hire',
  ExHvyTruckAndTractorLHCount:
    'Long Haul Extra Heavy Trucks and Tractors (over 45,000 lbs.) - Units not for hire',
  CementMixersCount: 'Cement Mixers',
  BusesOver20PassengersCount: 'Buses Over 20 Passengers Only',
  excessCommercialAutoCoverageVehicleExposure:
    "Applicable exposures for the Insured's automobile fleet",
  SchoolBusOrVan: 'School Buses or Vans',
  PoliceVehFireTrkAmbulance: 'Police Vehicles, Fire Trucks, or Ambulances',
  MoreThan10HtlMtlParkVan: 'Hotel/Motel/Parking Lot Courtesy Vans',
  MoreThan15PassengerCourtseyVan: 'Courtesy Vans More than 15 Passengers',
  RapidDevliveryOperations: 'Rapid Delivery Operations e.g. Pizza, Magazine, Newspaper',
  GasHaulHzdWasteRedLblMat: 'Gasoline Hauling or Hazardous Waste/Red Label Materials',
  CommodityIIIORIVHauling: 'Commodity III or IV Hauling',
  excessCommercialAutoCoverageStates:
    'At any point during the policy period, will you have vehicles registered in, garaged in, or traveling within any of the following states? (check all that apply)',
  hasCommercialAutoInFL: 'Florida',
  hasCommercialAutoInLA: 'Louisiana',
  hasCommercialAutoInNH: 'New Hampshire',
  hasCommercialAutoInVT: 'Vermont',
  hasCommercialAutoInWV: 'West Virginia',
  uwQuestions: 'Underwriting Questions',
};

export const HURRICANE_IAN_MORATORIUM_STATES = ['FL', 'SC', 'GA', 'NC'];

export const UTILITY_SERVICES_INELIGIBLE_STATES = [
  'TX',
  'MS',
  'AL',
  'GA',
  'SC',
  'NC',
  'VA',
  'MD',
  'DE',
  'NJ',
  'NY',
  'CT',
  'OK',
  'AR',
  'MO',
  'IA',
  'IL',
  'FL',
  'LA',
];

export const FUNCTIONAL_REPLACEMENT_COST_STATE_EXCEPTIONS = ['CA', 'CT', 'LA', 'MN', 'WV'];
// Buildings older than 50 years can only get Functional Replacement Cost coverage
export const FUNCTIONAL_REPLACEMENT_COST_CUTOFF_YEAR = 50;
export const FUNCTIONAL_REPLACEMENT_COST_MESSAGE =
  'Damages to this building suffered from a covered cause of loss will be adjusted on a functional replacement cost basis.';
