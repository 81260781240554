import * as _ from 'lodash';
import { CobGroups } from './pl-types';
import { PL_BUSINESS_CLASS_CODES_V4 } from './pl-constants';

/*
  COB_GROUPS is used to set the groups of COB codes to which certain question
  need to be applied to.
*/
export const COB_GROUPS: CobGroups = {
  ARCHITECT_AND_ENGINEERING: [
    PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
    PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
    PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
  ],
  ARCHITECT_AND_ENGINEERING2: [
    PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
    PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
    PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
  ],
  INS_AGENT: [PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE],
  AID_CPR: [
    PL_BUSINESS_CLASS_CODES_V4.FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION,
  ],
  HOME_PERSONAL_AID: [
    PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  ],
  ACCOUNTING: [
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC,
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES,
  ],
  PLACEMENT_RECRUIT: [
    PL_BUSINESS_CLASS_CODES_V4.EXECUTIVE_PLACEMENT_EXECUTIVE_PLACEMENT_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.RECRUITING_EMPLOYMENT_PLACEMENTS_EMPLOYMENT_AGENCIES,
  ],
  PROCESS_SERVER: [PL_BUSINESS_CLASS_CODES_V4.PROCESS_SERVER_PROCESS_SERVER_SERVICES],
  ANS_MARKETING: [
    PL_BUSINESS_CLASS_CODES_V4.ANSWERINGPAGING_SERVICES_ANSWERING_SERVICES_TELEPHONE,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ADVERTISING_MATERIAL_PREPARATION_SERVICES_FOR_MAILING_OR_OTHER_DIRECT_DISTRIBUTION,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_CANVASSERS_HEADQUARTERS_FOR_RETAIL_HOME_DELIVERY_DIRECT_SELLING,
  ],
  MARKET_PR: [
    // Advertising
    PL_BUSINESS_CLASS_CODES_V4.ADVERTISING_ADVERTISING_AGENCIES,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ADVERTISING_MATERIAL_PREPARATION_SERVICES_FOR_MAILING_OR_OTHER_DIRECT_DISTRIBUTION,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ADVERTISING_AGENCY_CONSULTING_SERVICES,
    // Brand consultant
    PL_BUSINESS_CLASS_CODES_V4.BRAND_CONSULTANT_MARKETING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.BRAND_CONSULTANT_MARKETING_ANALYSIS_SERVICES,
    // Digital marketing
    PL_BUSINESS_CLASS_CODES_V4.DIGITAL_MARKETING_MARKETING_MANAGEMENT_CONSULTING_SERVICES,
    // Direct marketing
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ADVERTISING_MATERIAL_PREPARATION_SERVICES_FOR_MAILING_OR_OTHER_DIRECT_DISTRIBUTION,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_CANVASSERS_HEADQUARTERS_FOR_RETAIL_HOME_DELIVERY_DIRECT_SELLING,
    // Market research
    PL_BUSINESS_CLASS_CODES_V4.MARKET_RESEARCH_MARKETING_ANALYSIS_SERVICES,
    // Marketing/media consulting
    PL_BUSINESS_CLASS_CODES_V4.MARKETINGMEDIA_CONSULTING_MARKETING_CONSULTING_SERVICES,
    // Other marketing/PR services
    PL_BUSINESS_CLASS_CODES_V4.OTHER_MARKETINGPR_SERVICES_MARKETING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_MARKETINGPR_SERVICES_PUBLIC_RELATIONS_CONSULTING_SERVICES,
    // Public relations
    PL_BUSINESS_CLASS_CODES_V4.PUBLIC_RELATIONS_PUBLIC_RELATIONS_AGENCIES,
    // Search engine services (SEO/SEM)
    PL_BUSINESS_CLASS_CODES_V4.SEARCH_ENGINE_SERVICES_SEOSEM_MARKETING_ANALYSIS_SERVICES,
    // Social media consultant
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_PUBLIC_RELATIONS_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_BUSINESS_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_MARKETING_MANAGEMENT_CONSULTING_SERVICES,
  ],
  PUBLIC_RELATIONS: [PL_BUSINESS_CLASS_CODES_V4.PUBLIC_RELATIONS_PUBLIC_RELATIONS_AGENCIES],
  TECH_ALL: [
    PL_BUSINESS_CLASS_CODES_V4.APPLICATION_DEVELOPMENT_APPLICATIONS_SOFTWARE_PROGRAMMING_SERVICES_CUSTOM_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.APPLICATION_SERVICE_PROVIDER_APPLICATION_HOSTING,
    PL_BUSINESS_CLASS_CODES_V4.COMPUTER_CONSULTING_COMPUTER_HARDWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.COMPUTER_PROGRAMMING_SERVICES_PROGRAMMING_SOFTWARE_ANALYSIS_SERVICES_CUSTOM_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.COMPUTER_SYSTEMNETWORK_DEVELOPER_LOCAL_AREA_NETWORK_LAN_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.DATA_PROCESSING_AUTOMATED_DATA_PROCESSING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.DATABASE_DESIGNER_INFORMATION_MANAGEMENT_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.IT_CONSULTING_COMPUTER_SOFTWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.IT_PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS,
    PL_BUSINESS_CLASS_CODES_V4.IT_SOFTWAREHARDWARE_TRAINING_SERVICES_COMPUTER_OPERATOR_TRAINING,
    PL_BUSINESS_CLASS_CODES_V4.SOFTWARE_DEVELOPMENT_PROGRAM_SOFTWARE_CUSTOM_ANALYSIS_DESIGN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_SOFTWARE_PACKAGED_MERCHANT_WHOLESALERS,
    PL_BUSINESS_CLASS_CODES_V4.WEBSITE_DESIGN_WEB_IE_INTERNET_PAGE_DESIGN_SERVICES_CUSTOM,
  ],
  IT_CONSULT: [
    PL_BUSINESS_CLASS_CODES_V4.APPLICATION_DEVELOPMENT_APPLICATIONS_SOFTWARE_PROGRAMMING_SERVICES_CUSTOM_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.COMPUTER_CONSULTING_COMPUTER_HARDWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.COMPUTER_SYSTEMNETWORK_DEVELOPER_LOCAL_AREA_NETWORK_LAN_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.IT_CONSULTING_COMPUTER_SOFTWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
  ],
  AUCTIONEERING: [PL_BUSINESS_CLASS_CODES_V4.AUCTIONEERING_AUCTIONEERS_INDEPENDENT],
  AUDIOLOGY: [PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS],
  CLAIMS_SERVICES: [
    PL_BUSINESS_CLASS_CODES_V4.CLAIMS_ADJUSTING_CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL,
  ],
  DIET: [
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
  ],
  DOC_RESUME_SERVICES: [
    PL_BUSINESS_CLASS_CODES_V4.DOCUMENT_PREPARATION_DESKTOP_PUBLISHING_SERVICES_IE_DOCUMENT_PREPARATION_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.RESUME_CONSULTING_RESUME_WRITING_SERVICES,
  ],
  EVENT_PLANNING: [PL_BUSINESS_CLASS_CODES_V4.EVENT_PLANNINGPROMOTION_PARTY_PLANNING_SERVICES],
  GRAPHIC_DESIGN: [PL_BUSINESS_CLASS_CODES_V4.GRAPHIC_DESIGN_ART_SERVICES_GRAPHIC],
  HYPNOSIS: [PL_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS],
  PROP_MGT: [
    PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
    PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
  ],
  PHOTO: [PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_PASSPORT_PHOTOGRAPHY_SERVICES],
  PHOTO_SERVICE: [
    PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_COMMERCIAL_PHOTOGRAPHY_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_CAMERAMEN_INDEPENDENT_FREELANCE,
  ],
  REALESTATE: [
    PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
    PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
  ],
  RESEARCH: [
    PL_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_RESEARCH_AND_DEVELOPMENT_LABORATORIES_OR_SERVICES_EXCEPT_BIOTECHNOLOGY_NANOTECHNOLOGY,
    PL_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_BIOTECHNOLOGY_RESEARCH_AND_DEVELOPMENT_LABORATORIES_OR_SERVICES_EXCEPT_NANOBIOTECHNOLOGY_RESEARCH_AND_DEVELOPMENT,
    PL_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_ARCHEOLOGICAL_RESEARCH_AND_DEVELOPMENT_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_NANOBIOTECHNOLOGIES_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_LABORATORIES,
  ],
  SOCIAL_WORK: [
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_ELDERLY_DISABLED_HOME_CARE_NON_MEDICAL_HOMEMAKER_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_SOCIAL_FAMILY_HOTLINE_MEDIATION_REHAB_SELF_HELP_SUPPORT_SERVICES,
  ],
  EDUCATION_CONSULTING: [
    PL_BUSINESS_CLASS_CODES_V4.EDUCATION_CONSULTING_EDUCATIONAL_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.TRAINING_BUSINESS_VOCATIONAL_OR_LIFE_SKILLS_HABILITATION_JOB_COUNSELING_AND_TRAINING_VOCATIONAL,
    PL_BUSINESS_CLASS_CODES_V4.TUTORING_ACADEMIC_TUTORING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LEARNING_CENTER_ACADEMIC_TUTORING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LEARNING_CENTER_TEST_PREP_EXAM_PREPARATION_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROFESSIONAL_TRAINING_PROFESSIONAL_DEVELOPMENT_TRAINING,
    PL_BUSINESS_CLASS_CODES_V4.TRAINING_AND_DEVELOPMENT_CONSULTING_PROFESSIONAL_DEVELOPMENT_CONSULTING,
  ],
  TRAVEL: [PL_BUSINESS_CLASS_CODES_V4.TRAVEL_AGENCY_TRAVEL_AGENCIES],
  NOTARY: [PL_BUSINESS_CLASS_CODES_V4.NOTARY_SERVICES_NOTARY_PUBLIC_SERVICES],
  OCC_SPEECH: [
    PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  ],
  ACC_PUN_PRES: [
    PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
  ],
  PHYSICAL_TREATMENT: [
    PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
    PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
  ],
  MASSAGE: [
    PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  ],
  PERSONAL_ASSISTANT: [PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CONCIERGEASSISTANT_CONCIERGE_SERVICES],
  CONSULTING: [
    PL_BUSINESS_CLASS_CODES_V4.BUSINESS_CONSULTING_BUSINESS_STARTUP_REORGANIZATIONAL_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.EDUCATION_CONSULTING_EDUCATIONAL_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.HUMAN_RESOURCES_HR_CONSULTING_HUMAN_RESOURCE_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_COMPENSATION_PLANNING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_GENERAL_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_FREIGHT_RATE_AUDITOR_TRAFFIC_CONSULTING_INFORMATION_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_BENEFIT_COMPENSATION_EMPLOYEE_ASSESSMENT_LABOR_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ADVERTISING_AGENCY_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_BUILDING_ENVELOPE_ECONOMIC_ENTOMOLOGY_HORTICULTURAL_MOTION_PICTURE_RADIO_ROOF_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_CUSTOMS_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_SANITATION_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EDUCATIONAL_CONSULTANTS,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_MEDICAL_RECORDS_SITE_LOCATION_SELECTION_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_INDUSTRIAL_DESIGN_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_INVESTMENT_ADVICE_CONSULTING_SERVICES_CUSTOMIZED_FEES_PAID_BY_CLIENT,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_JURY_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_LANDSCAPE_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_OUTPLACEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_POLITICAL_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS,
    PL_BUSINESS_CLASS_CODES_V4.STRATEGY_CONSULTANT_STRATEGIC_PLANNING_CONSULTING_SERVICES,
  ],
  COACH: [
    PL_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_CAREER_AND_VOCATIONAL_COUNSELING_SERVICES_EXCEPT_REHABILITATIVE,
    PL_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_LIFE_COACH_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_MANAGEMENT_DEVELOPMENT_TRAINING,
  ],
  HAIR_NAIL_1: [PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES],
  HAIR_BEAUTY_2: [
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
    PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
  ],
  HAIR_BEAUTY_3: [
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
    PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
  ],
  HAIR_BEAUTY_4: [PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES],
  HAIR_BEAUTY_5: [
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
  ],
  HAIR_BEAUTY_6: [
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
    PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES, //
    PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
  ],
  DIET_EXEC: [
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
  ],
  EXEC: [
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
  ],
  LICENSED_DOCTOR_NURSE: [
    PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
    PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_ELDERLY_DISABLED_HOME_CARE_NON_MEDICAL_HOMEMAKER_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_SOCIAL_FAMILY_HOTLINE_MEDIATION_REHAB_SELF_HELP_SUPPORT_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  ],
  PROF_LIC: [
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC,
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
    PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
    PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.CLAIMS_ADJUSTING_CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL,
    PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION,
    PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
    PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
    PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
    PL_BUSINESS_CLASS_CODES_V4.NOTARY_SERVICES_NOTARY_PUBLIC_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_ELDERLY_DISABLED_HOME_CARE_NON_MEDICAL_HOMEMAKER_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_SOCIAL_FAMILY_HOTLINE_MEDIATION_REHAB_SELF_HELP_SUPPORT_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
  ],
  FINANCIAL_AUDITS: [
    PL_BUSINESS_CLASS_CODES_V4.BOOKKEEPING_BOOKKEEPERS_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.TAX_PREPARATION_INCOME_TAX_COMPILATION_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.BUSINESS_MANAGER_SERVICES_ADMINISTRATIVE_MANAGEMENT_CONSULTING_SERVICES,
  ],
  BUSINESS_MGR_SERVICES: [
    PL_BUSINESS_CLASS_CODES_V4.BUSINESS_MANAGER_SERVICES_ADMINISTRATIVE_MANAGEMENT_CONSULTING_SERVICES,
  ],
  ACUPRESSURE2: [
    PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  ],
  ACUPUNCTURE: [
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
  ],
  ALLIED_HEALTH: [
    PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
    PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
    PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION,
    PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
    PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
    PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_ELDERLY_DISABLED_HOME_CARE_NON_MEDICAL_HOMEMAKER_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_SOCIAL_FAMILY_HOTLINE_MEDIATION_REHAB_SELF_HELP_SUPPORT_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS,
    PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
  ],
  CREATIVE: [
    PL_BUSINESS_CLASS_CODES_V4.GRAPHIC_DESIGN_ART_SERVICES_GRAPHIC,
    PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_PASSPORT_PHOTOGRAPHY_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_COMMERCIAL_PHOTOGRAPHY_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_CAMERAMEN_INDEPENDENT_FREELANCE,
  ],
  FINANCIAL_SERVICES: [
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC,
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.BOOKKEEPING_BOOKKEEPERS_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.MEDICAL_BILLING_MEDICAL_CODING_SERVICES_COMBINED_WITH_ACCOUNTING_SERVICES_EXCEPT_CPA_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TAX_PREPARATION_INCOME_TAX_COMPILATION_SERVICES,
  ],
  LEGAL_SERVICES: [
    PL_BUSINESS_CLASS_CODES_V4.CLAIMS_ADJUSTING_CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL,
    PL_BUSINESS_CLASS_CODES_V4.COURT_REPORTING_COURT_REPORTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.DOCUMENT_PREPARATION_DESKTOP_PUBLISHING_SERVICES_IE_DOCUMENT_PREPARATION_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.EXPERT_WITNESS_SERVICES_JURY_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.NOTARY_SERVICES_NOTARY_PUBLIC_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROCESS_SERVER_PROCESS_SERVER_SERVICES,
  ],
  MISCELLANEOUS: [
    PL_BUSINESS_CLASS_CODES_V4.ANSWERINGPAGING_SERVICES_ANSWERING_SERVICES_TELEPHONE,
    PL_BUSINESS_CLASS_CODES_V4.AUCTIONEERING_AUCTIONEERS_INDEPENDENT,
    PL_BUSINESS_CLASS_CODES_V4.BUSINESS_MANAGER_SERVICES_ADMINISTRATIVE_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.EVENT_PLANNINGPROMOTION_PARTY_PLANNING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.EXECUTIVE_PLACEMENT_EXECUTIVE_PLACEMENT_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_CAREER_AND_VOCATIONAL_COUNSELING_SERVICES_EXCEPT_REHABILITATIVE,
    PL_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_LIFE_COACH_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_MANAGEMENT_DEVELOPMENT_TRAINING,
    PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CONCIERGEASSISTANT_CONCIERGE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.RECRUITING_EMPLOYMENT_PLACEMENTS_EMPLOYMENT_AGENCIES,
    PL_BUSINESS_CLASS_CODES_V4.TALENT_AGENCY_AGENTS_TALENT,
    PL_BUSINESS_CLASS_CODES_V4.TRAINING_BUSINESS_VOCATIONAL_OR_LIFE_SKILLS_HABILITATION_JOB_COUNSELING_AND_TRAINING_VOCATIONAL,
    PL_BUSINESS_CLASS_CODES_V4.TRANSLATINGINTERPRETING_INTERPRETATION_SERVICES_LANGUAGE,
    PL_BUSINESS_CLASS_CODES_V4.TRAVEL_AGENCY_TRAVEL_AGENCIES,
    PL_BUSINESS_CLASS_CODES_V4.TUTORING_ACADEMIC_TUTORING_SERVICES,
  ],
  FORBIDDEN_PROJECTS3: [
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
  ],
  FORBIDDEN_PROJECTS5: [
    PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
  ],
  PRESCRIBE_HERBS3: [
    PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
  ],
  PRESCRIBE_HERBS4: [
    PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
    PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
  ],
  SUBSTANCE_ABUSE_COUNSELING: [
    PL_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS,
  ],
  BOOKKEEPING: [PL_BUSINESS_CLASS_CODES_V4.BOOKKEEPING_BOOKKEEPERS_OFFICES],
  MANUFACTURER_SALES_REPRESENTATIVE: [
    PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
  ],
  NAIL_TECH: [PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS],
  SAFETY_CONSULT: [PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES],
  TAX_PREP: [PL_BUSINESS_CLASS_CODES_V4.TAX_PREPARATION_INCOME_TAX_COMPILATION_SERVICES],
  TRANSLATION: [
    PL_BUSINESS_CLASS_CODES_V4.TRANSLATINGINTERPRETING_INTERPRETATION_SERVICES_LANGUAGE,
  ],
  LARGEST_PROJECT: [
    PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.APPLICATION_DEVELOPMENT_APPLICATIONS_SOFTWARE_PROGRAMMING_SERVICES_CUSTOM_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
    PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
    PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES,
  ],
  COST_VALUATION: [
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
  ],
  REALESTATE_AGENT: [PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE],
  SUBCONTRACT_PROF_SERVICES: [
    PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
    PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
    PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
    PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
    PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
    PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
    PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
  ],
  CLNTBUSNSPROMOTION: [
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ADVERTISING_MATERIAL_PREPARATION_SERVICES_FOR_MAILING_OR_OTHER_DIRECT_DISTRIBUTION,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING,
    PL_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_CANVASSERS_HEADQUARTERS_FOR_RETAIL_HOME_DELIVERY_DIRECT_SELLING,
    PL_BUSINESS_CLASS_CODES_V4.EVENT_PLANNINGPROMOTION_PARTY_PLANNING_SERVICES,
  ],
};

/**
 * This datastructure is used when there are cobOverrideSets in a node.
 * It helps to determine which groups the question needs to apply to
 */
export const PL_COB_OVERRIDE_GROUPS = {
  DIET: [
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
    PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
  ], // Dietician/nutrition
  INS_AGENT: [PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE], // Insurance agent
  PROP_MGT: [
    PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
    PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
  ], // Property management
  REALESTATE: [PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE], // Real estate agent/broker
  CLAIMS_ADJ: [
    PL_BUSINESS_CLASS_CODES_V4.CLAIMS_ADJUSTING_CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL,
  ], // Claims adjusting
  // PL V3 - Per occurrence limit group
  IT_CONSULTING_AND_SERVICES: _.uniq(_.concat(COB_GROUPS['TECH_ALL'], COB_GROUPS['IT_CONSULT'])),
  // PL V4 - Per occurrence limit group
  // Used in AR, ND, CA, and not in NY
  // NOTE: For all 5 of these states, we really only need to adjust the LOI if the broker checks
  // one or more boxes for the PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ITTECHSERVICES
  // question; however, doing so would require significant refactor that is not worth the time investment
  // right now. Instead, we'll adjust the limits whenever that checkbox group is enabled, i.e. when
  // the COB is part of the TECH_ALL group.
  IT_CONSULTING_AND_OTHER_SERVICES: _.uniq(
    _.concat(
      COB_GROUPS['TECH_ALL'],
      COB_GROUPS['PROP_MGT'],
      COB_GROUPS['INS_AGENT'],
      PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES
    )
  ),
  // NY PL V4 - Per occurrence limit group with real estate
  IT_CONSULTING_AND_OTHER_SERVICES_NY: _.uniq(
    _.concat(
      COB_GROUPS['TECH_ALL'],
      COB_GROUPS['PROP_MGT'],
      COB_GROUPS['INS_AGENT'],
      PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
      PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE
    )
  ),
  // PL V3 - Per occurrence limit group, includes IT Consulting/Services
  // Property Management, and Real estate agents/brokers
  // For NY state
  IT_CONS_AND_SERVICES_AND_PROP: _.uniq(
    _.concat(
      COB_GROUPS['TECH_ALL'],
      COB_GROUPS['IT_CONSULT'],
      COB_GROUPS['PROP_MGT'],
      COB_GROUPS['REALESTATE']
    )
  ),
};

// ALL_PL_COBS is a unique array with all the COB codes previously added in COB_GROUPS
export const ALL_PL_COBS = _.uniq(_.flatten(_.concat(_.values(COB_GROUPS))));

COB_GROUPS['PROF_LIABILITY_INSURANCE'] = _.concat(COB_GROUPS['ARCHITECT_AND_ENGINEERING'], [
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
  PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
  PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES,
]);

// LARGEST_CUSTOMER = ALL COBS, except those defined in the second array
COB_GROUPS['LARGEST_CUSTOMER'] = _.difference(ALL_PL_COBS, [
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
  PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
  PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
  PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
  PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION,
  PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE,
  PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
  PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
  PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
  PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
  PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_ELDERLY_DISABLED_HOME_CARE_NON_MEDICAL_HOMEMAKER_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
  PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS,
  PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_SOCIAL_FAMILY_HOTLINE_MEDIATION_REHAB_SELF_HELP_SUPPORT_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS,
  PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
]);

COB_GROUPS['ANE_FORBIDDENPROJECTS1'] = _.difference(COB_GROUPS['ARCHITECT_AND_ENGINEERING'], [
  PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
  PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
]);

COB_GROUPS['RETRODATE'] = [
  PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
  PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
  PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
  PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
  PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
  PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES,
];

COB_GROUPS['CONTINGENT_BODILY_INJURY_AND_PROPERTY_DAMAGE'] = _.difference(ALL_PL_COBS, [
  // A&E
  PL_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS,
  PL_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER,
  PL_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR,
  PL_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_CONSTRUCTION_LAND_TOPOGRAPHIC_SURVEYING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
  PL_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
  // Allied Health
  PL_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS,
  PL_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS,
  PL_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION,
  PL_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS,
  PL_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_PSYCHOANALYSTS_PSYCHOTHERAPISTS_EXCEPT_MDS_OR_DOS_SOCIAL_WORKERS_MENTAL_HEALTH_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS,
  PL_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_OCCUPATIONAL_THERAPISTS_OFFICES_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_HOME_CARE_OF_ELDERLY_MEDICAL,
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_ELDERLY_DISABLED_HOME_CARE_NON_MEDICAL_HOMEMAKER_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS,
  PL_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_SOCIAL_FAMILY_HOTLINE_MEDIATION_REHAB_SELF_HELP_SUPPORT_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
  PL_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS,
  PL_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS,
  // Real Estate
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING,
  PL_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_MANAGERS_OFFICES,
  PL_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE,
  // Insuranec inspector
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE,
  PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES,
  // Manufacturer sales represenative
  PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
  // Personal concierge/assistant
  PL_BUSINESS_CLASS_CODES_V4.PERSONAL_CONCIERGEASSISTANT_CONCIERGE_SERVICES,
  // Photography
  PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_CAMERAMEN_INDEPENDENT_FREELANCE,
  PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_COMMERCIAL_PHOTOGRAPHY_SERVICES,
  PL_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_PASSPORT_PHOTOGRAPHY_SERVICES,
  // Safety consultant
  PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES,
]);

COB_GROUPS['ESTMTD_ANNUAL_REVENUE'] = _.difference(ALL_PL_COBS, [
  PL_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE,
]);

COB_GROUPS['BUS_PROCESS'] = _.concat(COB_GROUPS['TECH_ALL'], COB_GROUPS['CNG_MGT']);

COB_GROUPS['CHANGE_MGT'] = _.concat(
  COB_GROUPS['ARCHITECT_AND_ENGINEERING'], // - All A&E COBs;
  COB_GROUPS['TECH_ALL'], // - All Technology COBs;
  COB_GROUPS['MARKET_PR'], // - All Marketing/PR COBs;
  COB_GROUPS['CREATIVE'], // - All Creative COBs;
  COB_GROUPS['CONSULTING'],
  COB_GROUPS['EDUCATION_CONSULTING'],
  COB_GROUPS['RESEARCH'],
  [
    PL_BUSINESS_CLASS_CODES_V4.ANSWERINGPAGING_SERVICES_ANSWERING_SERVICES_TELEPHONE, // - Answering Paging Services;
    PL_BUSINESS_CLASS_CODES_V4.COURT_REPORTING_COURT_REPORTING_SERVICES, // - Court Reporting;
    PL_BUSINESS_CLASS_CODES_V4.EXECUTIVE_PLACEMENT_EXECUTIVE_PLACEMENT_SERVICES, // - Executive Placement;
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE, // - Insurance Inspector;
    PL_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES, // - Insurance Inspector;
    PL_BUSINESS_CLASS_CODES_V4.BUSINESS_MANAGER_SERVICES_ADMINISTRATIVE_MANAGEMENT_CONSULTING_SERVICES, // - Management consulting;
    PL_BUSINESS_CLASS_CODES_V4.IT_PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS, // - Project management;
    PL_BUSINESS_CLASS_CODES_V4.RECRUITING_EMPLOYMENT_PLACEMENTS_EMPLOYMENT_AGENCIES, // - Recruiting;
    PL_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES, // - Safety consultant;
  ]
);
