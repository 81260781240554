import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, filter, tap, first, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { BopQuoteFormValue } from 'app/features/attune-bop/models/bop-policy';
import { WcFormValue as EmployersWcFormValue } from 'app/workers-comp/employers/models/wc-policy';
import { GetDraftResponse as AttuneWCDraftQuoteFormValue } from 'app/workers-comp/attune/models/quote.model';
import { API_V3_BASE } from 'app/constants';
import { ProductCombinationForAvailability } from 'app/features/digital-carrier/models/types';

import { SentryService } from 'app/core/services/sentry.service';

export const enum DraftOrigin {
  PrefillOutage = 'prefill_outage',
  PrefillDecline = 'prefill_decline',
  QuoteFlow = 'quote_flow',
  QuoteSubmit = 'quote_submit',
  EligibilityDecline = 'eligibility_decline',
}

export interface DraftQuote {
  formData:
    | DeepPartial<BopQuoteFormValue>
    | DeepPartial<EmployersWcFormValue>
    | DeepPartial<AttuneWCDraftQuoteFormValue>;
  id: string;
  updatedAt: moment.Moment;
  product: ProductCombinationForAvailability['product'];
  pasSource: ProductCombinationForAvailability['pasSource'];
}

interface DraftCache {
  accountId: string;
  quotes: DraftQuote[];
}

const ACCOUNT_DRAFTS_URI = (accountId: string) => `${API_V3_BASE}/drafts/${accountId}`;
const DRAFT_QUOTE_URI = (accountId: string, uuid: string) =>
  `${API_V3_BASE}/drafts/${accountId}/${uuid}`;

const byUuid = (id: string) => (draftQuote: DraftQuote) => draftQuote.id === id;
const quoteToMoment = (quote: DraftQuote) => ({
  ...quote,
  updatedAt: moment(quote.updatedAt),
});

@Injectable({
  providedIn: 'root',
})
export class DraftQuoteService {
  private quotesSubject$ = new BehaviorSubject<DraftCache>({ accountId: '', quotes: [] });
  private fetchingAccountId?: string;

  constructor(private http: HttpClient, private sentryService: SentryService) {}

  get(accountId: string): Observable<DraftQuote[]> {
    const cached = this.quotesSubject$.getValue();

    const sortQuotes = (quotes: DraftQuote[]) =>
      quotes.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));

    if (cached.accountId === accountId) {
      return of(sortQuotes(cached.quotes));
    }
    return this.fetch(accountId).pipe(map((x) => sortQuotes(x.quotes)));
  }

  getQuote(accountId: string, id: string): Observable<DraftQuote | null> {
    const cached = this.quotesSubject$.getValue();
    if (cached.accountId === accountId) {
      return of(cached.quotes.find(byUuid(id)) || null);
    }

    return this.fetch(accountId).pipe(map((x) => x.quotes.find(byUuid(id)) || null));
  }

  fetch(accountId: string) {
    if (this.fetchingAccountId === accountId) {
      return this.quotesSubject$.asObservable().pipe(
        filter((x: DraftCache) => x.accountId === accountId),
        first()
      );
    }
    this.fetchingAccountId = accountId;
    this.http
      .get<DraftQuote[]>(ACCOUNT_DRAFTS_URI(accountId))
      .pipe(map((quotes) => quotes.map(quoteToMoment)))
      .subscribe(
        (quotes) => {
          this.quotesSubject$.next({
            accountId,
            quotes,
          });
        },
        (error) => {
          this.sentryService.notify('Error fetching drafts.', {
            severity: 'error',
            metaData: {
              accountId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          console.warn('*** Error fetching drafts ***', error);
          this.quotesSubject$.next({
            accountId,
            quotes: [],
          });
        }
      );

    return this.quotesSubject$.asObservable().pipe(
      filter((x: DraftCache) => x.accountId === accountId),
      first()
    );
  }

  update(
    accountId: string,
    uuid: string,
    formValue:
      | DeepPartial<BopQuoteFormValue>
      | DeepPartial<EmployersWcFormValue>
      | DeepPartial<AttuneWCDraftQuoteFormValue>
  ) {
    this.bust();
    return this.http.put(DRAFT_QUOTE_URI(accountId, uuid), formValue).pipe(
      tap(() => this.bust()),
      catchError((error) => {
        this.sentryService.notify('Error updating draft quote.', {
          severity: 'error',
          metaData: {
            accountId,
            uuid,
            formValue,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  delete(accountId: string, uuid: string) {
    this.bust();
    return this.http.delete(DRAFT_QUOTE_URI(accountId, uuid)).pipe(
      tap(() => this.bust()),
      catchError((error) => {
        this.sentryService.notify('Error deleting draft quote.', {
          severity: 'error',
          metaData: {
            accountId,
            uuid,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  create({
    accountId,
    formValue,
    product,
    pasSource,
    origin,
    declineReasons = [],
    portalViewable = true,
  }: {
    accountId: string;
    formValue:
      | DeepPartial<BopQuoteFormValue>
      | DeepPartial<EmployersWcFormValue>
      | DeepPartial<AttuneWCDraftQuoteFormValue>;
    product: ProductCombinationForAvailability['product'];
    pasSource: ProductCombinationForAvailability['pasSource'];
    origin: DraftOrigin;
    declineReasons?: string[];
    portalViewable?: boolean;
  }) {
    this.bust();
    return this.http
      .post<{ id: string }>(ACCOUNT_DRAFTS_URI(accountId), {
        formValue: formValue,
        product: product,
        pasSource: pasSource,
        origin: origin,
        declineReasons: declineReasons,
        portalViewable: portalViewable,
      })
      .pipe(
        tap(() => this.bust()),
        catchError((error) => {
          this.sentryService.notify(`Error creating draft ${product} quote.`, {
            severity: 'error',
            metaData: {
              accountId,
              formValue,
              pasSource,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  bust() {
    delete this.fetchingAccountId;
    this.quotesSubject$.next({ accountId: '', quotes: [] });
  }
}
