import { Component, Input } from '@angular/core';
import { AttuneProduct } from 'app/features/digital-carrier/models/types';

@Component({
  selector: 'app-activity-book-mix-progress-bar',
  templateUrl: './activity-book-mix-progress-bar.component.html',
})
export class ActivityBookMixProgressBarComponent {
  @Input() targetPercentage: number;
  @Input() preferredCount: number;
  @Input() nonPreferredCount: number;
  @Input() targetCount: number;
  @Input() calculationStartDate: number;
  @Input() productType: AttuneProduct['product'];

  getPreferredPercentage() {
    const totalPolicies = this.preferredCount + this.nonPreferredCount;
    return Math.round((this.preferredCount / totalPolicies) * 100);
  }

  getNonPreferredPercentage() {
    return 100 - this.getPreferredPercentage();
  }

  getPolicyCountCopy(preferenceLevel: 'foundational' | 'frontier') {
    const policyCount =
      preferenceLevel === 'foundational' ? this.preferredCount : this.nonPreferredCount;
    const singularOrPlural = policyCount === 1 ? 'policy' : 'policies';

    return `${policyCount} ${preferenceLevel} ${singularOrPlural}`;
  }

  getUnsustainableMessage() {
    const policiesLeftToGo = this.targetCount - this.preferredCount;
    return `Bind ${policiesLeftToGo} more Foundational or Foundational Plus ${
      policiesLeftToGo === 1 ? 'policy' : 'policies'
    } to hit the target.`;
  }
}
