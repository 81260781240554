import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, startWith } from 'rxjs';

// Constants
import {
  ATTUNE_WC_PAYMENT_PLAN_MAPPING,
  ATTUNE_WC_PAYMENT_PLAN_OPTIONS,
  AttuneWcPaymentPlanOptions,
} from 'app/workers-comp/attune/constants';

// Services
import { AttuneWcBindFormService } from 'app/workers-comp/attune/services/attune-wc-bind-form.service';
import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from 'app/core/services/feature-flag.service';

// Components
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

// Models
import { QSAttuneWcPaymentPlan } from 'app/workers-comp/attune/models/payment-plans.model';
import { PaymentDetailsFormGroup } from 'app/workers-comp/attune/models/bind-form.model';

@Component({
  selector: 'app-wc-bind-payment-page',
  templateUrl: './attune-wc-bind-payment-details.component.html',
})
export class AttuneWcBindPaymentDetailsComponent
  extends SteppedFormPageComponent<AttuneWcBindFormService, PaymentDetailsFormGroup>
  implements OnInit, OnDestroy
{
  form: PaymentDetailsFormGroup;
  formPath = 'paymentDetails';

  sub: Subscription = new Subscription();
  paymentPlanOptions: AttuneWcPaymentPlanOptions;
  paymentPlanMapping = ATTUNE_WC_PAYMENT_PLAN_MAPPING;
  disabledPaymentPlanOptions: string[];
  isADPPayByPayEnabled: boolean = false;
  constructor(
    protected formService: AttuneWcBindFormService,
    private featureFlagService: FeatureFlagService
  ) {
    super(formService);
    this.paymentPlanOptions = { ...ATTUNE_WC_PAYMENT_PLAN_OPTIONS };
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupPaymentPlanSubscription();
    this.setDisabledPaymentPlans();
    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.ADP_PAY_BY_PAY_ENABLED)
        .subscribe((isEnabled) => {
          this.isADPPayByPayEnabled = isEnabled || false;
          this.updatePaymentPlanOptions();
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updatePaymentPlanOptions() {
    // Note: the ADP payment plan option is only available if the ADP pay by pay feature flag is enabled. It is not shown as "disabled", it is entirely hidden for users without access.
    if (this.isADPPayByPayEnabled) {
      this.paymentPlanOptions = {
        ...ATTUNE_WC_PAYMENT_PLAN_OPTIONS,
        'Pay By Pay Billing': 'ADP_PAY_BY_PAY',
      };
    } else {
      this.paymentPlanOptions = { ...ATTUNE_WC_PAYMENT_PLAN_OPTIONS };
    }

    this.setDisabledPaymentPlans();
  }

  setDisabledPaymentPlans() {
    // Some payment plans require the total premium to be above a certain threshold.
    const totalPremium = this.paymentDetails.totalPremium;
    this.disabledPaymentPlanOptions = Object.entries(this.paymentPlanOptions)
      .filter(([_paymentPlanSelection, PaymentPlanId]) => {
        const paymentPlanDetails = this.paymentPlanMapping[PaymentPlanId];
        return totalPremium < paymentPlanDetails.premiumThreshold;
      })
      .map(([paymentPlanSelection, _paymentPlanId]) => {
        return paymentPlanSelection;
      });
  }

  setupPaymentPlanSubscription() {
    const paymentPlanControl = this.form.get('paymentPlan');
    if (!paymentPlanControl) {
      return;
    }

    this.sub.add(
      paymentPlanControl.valueChanges
        .pipe(startWith(paymentPlanControl.value))
        .subscribe((paymentPlanVal) => {
          this.setPaymentDetails(paymentPlanVal as QSAttuneWcPaymentPlan);
        })
    );
  }

  setPaymentDetails(paymentPlanSelected: QSAttuneWcPaymentPlan) {
    const paymentPlanDetails = this.paymentPlanMapping[paymentPlanSelected];
    this.paymentDetails.updatePaymentPlan(
      paymentPlanDetails.numberOfPayments,
      paymentPlanDetails.downPaymentPercentage
    );
  }

  get paymentDetails() {
    return this.formService.policyPaymentDetails;
  }

  get paymentPlanQuestionNote() {
    if (this.disabledPaymentPlanOptions.length > 0) {
      return 'Due to the small premium size, some payment options are disabled for this quote.';
    }
    return '';
  }
}
