import * as moment from 'moment';
import { cloneDeep, get, uniq } from 'lodash';
import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Observable,
  of as observableOf,
  first,
  Subject,
  timer,
  switchMap,
  catchError,
  NEVER,
  Subscription,
  tap,
  forkJoin,
} from 'rxjs';

// Constants
import { US_DATE_MASK } from 'app/constants';
import { UNKNOWN_ERROR_WITHOUT_RETRY } from 'app/shared/quote-error-modal/errors';
import {
  ATTUNE_WC_BIND_ERROR,
  ATTUNE_WC_BIND_FEIN_BLOCK,
  ATTUNE_WC_ELIGIBILITY_ERROR,
  ATTUNE_WC_INVALID_FEIN_ERROR,
  ATTUNE_WC_MULTIPLE_RISK_IDS_FOUND_ERROR,
  PAY_BY_PAY_PLAN_IDS,
} from 'app/workers-comp/attune/constants';

// Components
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';

// Models
import { GwAccountContacts, GwCommContact, GwProducerCodeDetails } from 'app/bop/guidewire/typings';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { GuidewireWCQuoteResponse } from 'app/workers-comp/attune/models/quote.model';

// Services
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AttuneWCQuoteService } from 'app/workers-comp/attune/services/attune-wc-quote.service';
import { GWBindService, MappedBindResponse } from 'app/shared/services/gw-bind.service';
import { AttuneWcBindFormService } from 'app/workers-comp/attune/services/attune-wc-bind-form.service';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { UserService } from 'app/core/services/user.service';
import { InformService } from 'app/core/services/inform.service';

// helpers
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import { normalizePhoneNumber, parseMoney } from 'app/shared/helpers/number-format-helpers';
import { map, take } from 'rxjs/operators';
import {
  ContactListGroup,
  getBrokerContactChanges,
} from 'app/shared/helpers/broker-contact-helpers';
import { SegmentService } from '../../../../../core/services/segment.service';
import {
  hasNewOrUpdatedInsuredContacts,
  translateInsuredContact,
} from 'app/shared/helpers/insured-contact-helpers';
import {
  hasNcciMultipleRiskIdsError,
  hasNcciValidationError,
} from 'app/workers-comp/attune/helpers/errorParsing';

@Component({
  templateUrl: './attune-wc-bind-quote.component.html',
  providers: [AttuneWcBindFormService],
})
export class AttuneWcBindQuoteComponent
  extends FormDslSteppedFormBaseComponent<AttuneWcBindFormService>
  implements OnInit, OnDestroy
{
  constructor(
    public formService: AttuneWcBindFormService,
    public bindService: GWBindService,
    protected quoteService: AttuneWCQuoteService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected userService: UserService,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    private informService: InformService,
    private segmentService: SegmentService
  ) {
    super(formService, route, router);
  }

  // Flags
  public isDevMode: boolean = isDevMode();
  public quoteDetailsLoading = false;
  errorModalOpen = false;
  showProgressBar = false;
  displayPriceDiffModal = false;

  // IDs
  public accountId: string;
  public quoteId: string;
  public policyId: string;
  public policyTermNumber: string;

  insuredAccount: InsuredAccount;
  quoteDetails: QuoteDetails | null = null;
  originalEffectiveDate: string;
  originalTotalCost: number;
  newTotalCost: number;
  requoteUwIssues: string[] = [];
  requoteErrors: string[] = [];
  hasBindFeinBlocker = false;

  errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
  bindSuccess$ = new Subject();
  subscriptions = new Subscription();

  ngOnInit() {
    super.ngOnInit();
    this.accountId = this.route.snapshot.params.accountId;
    this.quoteId = this.route.snapshot.params.quoteId;
    this.initializeDetails();

    zendeskLeftSnap();
  }

  public initializeDetails() {
    this.quoteDetailsLoading = true;

    const quoteDetails$ = this.getDetailsFromPolicy(this.quoteId).pipe(
      tap((quoteDetails) => this.setDetailsFromPolicy(quoteDetails))
    );

    const contactDetails$ = this.fetchContacts().pipe(
      tap((contacts) => this.setContacts(contacts))
    );

    const insuredAccount$ = this.insuredAccountService.get(this.accountId).pipe(
      first(),
      tap((insuredAccount) => (this.insuredAccount = insuredAccount))
    );

    const combinedFetches$ = forkJoin([quoteDetails$, contactDetails$, insuredAccount$]);

    this.subscriptions.add(
      combinedFetches$.subscribe({
        next: () => {
          this.quoteDetailsLoading = false;
        },
        error: () => {
          this.handleFailedFetch();
        },
      })
    );
  }

  public fetchContacts() {
    const getAccountContacts$ = this.insuredAccountService.getAccountContacts(this.accountId);
    const getCurrentContact$ = this.insuredAccountService.getCurrentContact();
    const getProducerContacts$ = this.userService.getUser().pipe(
      first(),
      switchMap((user) => this.insuredAccountService.getProducerCodeDetails(user.producer))
    );

    return forkJoin([getAccountContacts$, getCurrentContact$, getProducerContacts$]);
  }

  public setContacts([accountContacts, currentContact, producerContacts]: [
    GwAccountContacts,
    GwCommContact,
    GwProducerCodeDetails
  ]) {
    this.formService.setSubscribedBrokerContacts(accountContacts);
    this.formService.setCurrentBrokerContact(currentContact);
    this.formService.setAvailableBrokerContacts(producerContacts);

    // After all contacts are set, patch the form.
    this.formService.patchBrokerContactsForm();
  }

  public getDetailsFromPolicy(quoteId: string) {
    return this.bindService.getQuoteDetails(quoteId);
  }

  public setDetailsFromPolicy(quoteDetails: QuoteDetails) {
    this.quoteDetails = quoteDetails;
    // store originalTotalCost in case we requote, to show price comparison.
    this.originalTotalCost = parseMoney(quoteDetails.totalCost);
    // store original effective date in case we need to requote if eff date changes.
    // We use moment.utc() here because policyStart is returned as a moment w/ a utc offset.
    this.originalEffectiveDate = moment.utc(quoteDetails.policyStart).format(US_DATE_MASK);
    this.formService.setDetailsFromPolicy(quoteDetails);
  }

  public handleFailedQuote(): void {
    this.amplitudeService.track({
      detail: 'attune_wc',
      eventName: 'bind_error',
    });
    this.errorModalOpen = true;
    this.showProgressBar = false;
  }

  public getErrorList() {
    if (this.requoteUwIssues.length > 0) {
      return this.requoteUwIssues;
    }
    return [];
  }

  public getErrorType() {
    if (this.requoteUwIssues.length > 0) {
      const ineligibleRequoteError = cloneDeep(ATTUNE_WC_ELIGIBILITY_ERROR);
      ineligibleRequoteError.body =
        'This was requoted due to changes in effective date and is no longer eligible for this program.';
      return ineligibleRequoteError;
    }

    if (hasNcciValidationError(this.requoteErrors)) {
      return ATTUNE_WC_INVALID_FEIN_ERROR;
    }

    if (hasNcciMultipleRiskIdsError(this.requoteErrors)) {
      return ATTUNE_WC_MULTIPLE_RISK_IDS_FOUND_ERROR;
    }

    if (this.hasBindFeinBlocker) {
      return ATTUNE_WC_BIND_FEIN_BLOCK;
    }

    return ATTUNE_WC_BIND_ERROR;
  }

  private handleFailedFetch() {
    this.amplitudeService.track({
      detail: 'attune_wc',
      eventName: 'load_details_error',
    });
    this.informService.minorErrorToast(
      'We encountered an error while loading information about this account. Please try refreshing the page.',
      null,
      'Failed to retrieve account information.',
      'Retry',
      () => {
        this.initializeDetails();
      },
      0
    );
  }

  public loadInitialData(): void {}

  public onIncrementedStep(nextStep: RouteFormStep): void {
    this.navigateToCurrentStep();
  }

  public sendForm(): Observable<any> {
    this.amplitudeService.track({
      detail: 'attune_wc',
      eventName: 'bind_attempt',
    });
    this.showProgressBar = true;
    if (this.quoteDetails === null) {
      this.sentryService.notify('Trying to submit bind form before policy details were retrieved', {
        severity: 'error',
      });
      return observableOf(null);
    }

    if (this.displayPriceDiffModal) {
      this.displayPriceDiffModal = false;
      // If we've already re-quoted and are showing the price change modal
      // proceed to bind if the user clicks bind on the modal.
      return this.bindQuote();
    }

    /**
     * We attempt to do four things here:
     * 1. check if broker contact details have changed and update if necessary, otherwise no-op
     * 2. check if account details changed and update if necessary, otherwise no-op.
     * 3. check if effective date or FEIN changed and re-quote if necessary, otherwise no-op.
     *  3a. If price has changed, we show a price change modal to the broker and halt the rest of the observable sequence.
     * 4. bind the original or updated quote.
     *
     * If any of these steps error we will drop into the catchError.
     */

    return this.checkAndEditBrokerContactDetails().pipe(
      switchMap(() => this.checkAndEditAccountDetails()),
      switchMap(() => this.checkAndRequoteIfNeeded()),
      switchMap(() => this.bindQuote()),
      catchError((error) => {
        // TODO - handle error when we can better determine if its a retryable error.
        // for now this will show the bind error modal.
        // Returning null here will be picked up as a failed attempt by checkQuotingSuccess.
        return observableOf(null);
      })
    );
  }

  public checkQuotingSuccess(response: MappedBindResponse): Observable<boolean> {
    if (response === null || response.success === false) {
      return observableOf(false);
    }
    const responseData = response.bindResponse[0].data;
    if ('errors' in responseData) {
      return observableOf(false);
    }

    this.policyId = responseData.policyId;
    this.policyTermNumber = responseData.policyTermNumber || '1';
    return observableOf(true);
  }

  public handleSuccessfulQuote(): void {
    this.amplitudeService.track({
      detail: 'attune_wc',
      eventName: 'bind_success',
    });
    this.bindSuccess$.next(true);
    this.subscriptions.add(
      timer(3000).subscribe(() => {
        this.showProgressBar = false;
        this.navigateToPolicyPage();
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();

    removeZendeskLeftSnap();
  }

  navigateToPolicyPage() {
    this.insuredAccountService.cachebust();
    // Note: If policy Id is not returned, then just navigate to the account page.
    if (!this.policyId) {
      this.goBackToAccount();
      return;
    }

    const queryParams: Params = {};

    const paymentPlanId = this.formService.form.getRawValue().paymentDetails
      .paymentPlan as QSBindPaymentPlan;
    if (!PAY_BY_PAY_PLAN_IDS.includes(paymentPlanId)) {
      queryParams.waitForInvoice = 'true';
    }

    if (this.quoteDetails?.safetyReview) {
      queryParams.safetyReviewModal = 'true';
    }

    this.router.navigate(
      [
        '/accounts',
        this.route.snapshot.params.accountId,
        'terms',
        this.policyId,
        this.policyTermNumber,
      ],
      {
        queryParams,
      }
    );
  }

  public closeQuoteErrorModal(result: { close: boolean; retry: boolean }): void {
    if (!result.close && !result.retry) {
      this.goBackToAccount();
    }

    if (result.close) {
      this.errorModalOpen = false;
      if (hasNcciValidationError(this.requoteErrors)) {
        // If they click on "update FEIN", we should send them back to the appropriate page.
        this.handleNavigateToSlug('policy-details');
      }
      this.resetRequoteErrorList();
      // This ensures that if a re-quote happens (due to change of FEIN after the validation error modal is closed), it will have a different request id from the original attempt since this is technically a different quote from the GW perspective.
      this.generateRequestId();
    }

    if (result.retry) {
      this.formService.stepForward();
    }
  }

  goBackToAccount() {
    this.insuredAccountService.cachebust();
    this.router.navigate(['/accounts', this.accountId]);
  }

  private checkAndRequoteIfNeeded() {
    const rawFormValue = this.formService.form.getRawValue();
    const currentEffectiveDate = rawFormValue.policyDetails.effectiveDate;
    const currentFein = rawFormValue.policyDetails.employerIdentificationNumber;
    const effectiveDateChanged = this.effectiveDateChanged(currentEffectiveDate);
    const feinChanged = this.feinChanged(currentFein);

    if (effectiveDateChanged || feinChanged) {
      this.amplitudeService.track({
        detail: 'attune_wc',
        eventName: 'bind_requote_attempt',
      });

      return this.insuredAccountService.get(this.accountId).pipe(
        switchMap((insuredAccount) =>
          this.quoteService.requote({
            tsRequestId: this.tsRequestId,
            insuredAccount,
            quoteNumber: this.quoteId,
            dataToUpdate: {
              effectiveDate:
                effectiveDateChanged && currentEffectiveDate ? currentEffectiveDate : undefined,
              fein: feinChanged && currentFein ? currentFein : undefined,
            },
          })
        ),
        switchMap((updatedQuoteResp) => {
          return this.handleUpdatedQuote(updatedQuoteResp);
        }),
        catchError((_error) => {
          this.amplitudeService.track({
            detail: 'attune_wc',
            eventName: 'bind_requote_error',
          });
          this.handleFailedQuote();
          // If we error, stop this observable sequence from emitting so that we don't continue to bind.
          return NEVER;
        })
      );
    }
    // No changes
    return observableOf(null);
  }

  private handleUpdatedQuote(updatedQuote: GuidewireWCQuoteResponse) {
    if (updatedQuote.underwritingIssues && updatedQuote.underwritingIssues.length > 0) {
      this.amplitudeService.track({
        detail: 'attune_wc',
        eventName: 'bind_requote_decline',
      });

      // In some cases, UW issues are duplicated for quote/bind blocks, here we ensure each entry is unique.
      this.requoteUwIssues = uniq(
        updatedQuote.underwritingIssues.map((uwIssue) => uwIssue.message)
      );
      // If the quote has uw issues, show a decline and stop this observable sequence from emitting.
      this.handleFailedQuote();
      return NEVER;
    }

    if (updatedQuote.errors && updatedQuote.errors.length > 0) {
      this.requoteErrors = updatedQuote.errors
        .map((error) => error?.message)
        .filter((message) => !!message);
      // If the quote has errors, show a error modal and stop this observable sequence from emitting.
      this.handleFailedQuote();
      return NEVER;
    }

    const newTotalCost = updatedQuote.totalCost;
    const validQuote = updatedQuote.validQuote;
    if (!newTotalCost || !validQuote) {
      this.sentryService.notify(
        'Error while binding WC Quote. Could not get a valid quote when requoting with updated eff date.',
        {
          severity: 'error',
          metaData: {
            accountId: this.accountId,
            quoteId: this.quoteId,
            updatedQuote,
          },
        }
      );
      this.handleFailedQuote();
      // If we error, stop this observable sequence from emitting so that we don't continue to bind.
      return NEVER;
    }

    this.amplitudeService.track({
      detail: 'attune_wc',
      eventName: 'bind_requote_success',
    });

    this.quoteId = updatedQuote.quoteNumber;
    // If dates are different and prices are diff, display modal
    if (Math.abs(newTotalCost - this.originalTotalCost) >= 1) {
      this.newTotalCost = newTotalCost;
      this.showProgressBar = false;
      this.displayPriceDiffModal = true;
      this.amplitudeService.track({
        detail: 'attune_wc',
        eventName: 'bind_price_diff_modal_open',
      });
      // If the price diff modal is shown, stop this observable sequence from emitting.
      return NEVER;
    }
    return observableOf(null);
  }

  private effectiveDateChanged(currentEffDate: string | null) {
    return this.originalEffectiveDate !== currentEffDate;
  }

  private feinChanged(currentFein: string | null) {
    return this.insuredAccount?.fein !== currentFein?.trim();
  }

  private checkAndEditAccountDetails() {
    const formValue = this.formService.form.value;

    // We use the email and phone from the first billing contact provided to update the email/phone for the account holder contact on the account.
    const billingContact = formValue?.policyDetails?.insuredContacts?.find(
      (contact) => contact?.contactPurpose?.BillingContact === true
    );

    // There is form validation to prevent this.
    if (!billingContact) {
      this.sentryService.notify(
        'Error while binding WC Quote. No insured contact provided for billing.',
        {
          severity: 'error',
          metaData: {
            accountId: this.accountId,
            quoteId: this.quoteId,
            insuredContacts: formValue?.policyDetails?.insuredContacts,
          },
        }
      );
      this.handleFailedQuote();
      // If we error, stop this observable sequence from emitting so that we don't continue to bind.
      return NEVER;
    }

    const emailAddress = billingContact.emailAddress;
    const phoneNumber = billingContact.phoneNumber;
    const insuredContacts = formValue?.policyDetails?.insuredContacts;

    const translatedContacts = insuredContacts
      ?.map(translateInsuredContact)
      .filter((contact): contact is InsuredContact => !!contact);

    // These are the account-level details that might have changed.
    const dataToCheckForChanges = {
      emailAddress,
      phoneNumber,
      insuredContacts: translatedContacts,
    };

    // We first check if account details have changed, and if so we edit the account accordingly.
    if (this.haveAccountDetailsChanged(dataToCheckForChanges)) {
      return this.insuredAccountService.get(this.accountId).pipe(
        first(),
        switchMap((account) =>
          this.editAccount({
            account: account,
            dataToUpdate: dataToCheckForChanges,
          })
        )
      );
    }

    return observableOf(null);
  }

  private checkAndEditBrokerContactDetails(): Observable<null | any[]> {
    const contactChanges$ = this.getBrokerContactChangesObservables();
    // If there are any contact changes, update them. Otherwise no-op.
    if (contactChanges$.length > 0) {
      return forkJoin(contactChanges$);
    }
    return observableOf(null);
  }

  private getBrokerContactChangesObservables(): Observable<any>[] {
    const contactChanges = getBrokerContactChanges(
      this.formService.form.get('policyDetails')?.get('brokerContacts') as ContactListGroup,
      this.formService.subscribedContacts
    );

    return contactChanges.map((contactChange) => {
      switch (contactChange.action) {
        case 'follow':
          return this.insuredAccountService.followAccount(
            this.accountId,
            contactChange.contactId as string,
            contactChange.roles
          );
        case 'unfollow':
          return this.insuredAccountService.unfollowAccount(
            this.accountId,
            contactChange.contactId as string,
            contactChange.roles
          );
        case 'create':
          return this.insuredAccountService.followNewAccount(
            this.accountId,
            get(contactChange, 'contactInfo.email', ''),
            get(contactChange, 'contactInfo.firstName', ''),
            get(contactChange, 'contactInfo.lastName', ''),
            contactChange.roles
          );
        default:
          return observableOf(null);
      }
    });
  }

  private editAccount(data: {
    account: InsuredAccount;
    dataToUpdate: {
      emailAddress: string | null | undefined;
      phoneNumber: string | null | undefined;
      insuredContacts: InsuredContact[] | undefined;
    };
  }): Observable<any> {
    const { account, dataToUpdate } = data;
    const { phoneNumber, emailAddress, insuredContacts } = dataToUpdate;

    if (phoneNumber) {
      account.phoneNumber = normalizePhoneNumber(phoneNumber);
    }
    if (emailAddress) {
      account.emailAddress = emailAddress;
    }

    if (insuredContacts) {
      account.insuredContacts = insuredContacts;
    }

    return this.insuredAccountService.edit(account);
  }

  private haveAccountDetailsChanged(dataToCheckForChanges: {
    emailAddress: string | null | undefined;
    phoneNumber: string | null | undefined;
    insuredContacts: InsuredContact[] | undefined;
  }): boolean {
    const { emailAddress, phoneNumber, insuredContacts } = dataToCheckForChanges;

    if (
      insuredContacts &&
      hasNewOrUpdatedInsuredContacts(insuredContacts, this.insuredAccount.insuredContacts)
    ) {
      return true;
    }

    if (emailAddress?.trim() !== this.insuredAccount.emailAddress) {
      return true;
    }

    if (
      phoneNumber &&
      normalizePhoneNumber(this.insuredAccount.phoneNumber) !== normalizePhoneNumber(phoneNumber)
    ) {
      return true;
    }

    return false;
  }

  private bindQuote() {
    const paymentPlanId = this.formService.form.getRawValue().paymentDetails
      .paymentPlan as QSBindPaymentPlan;

    return this.insuredAccountService.get(this.accountId).pipe(
      take(1),
      switchMap((insuredAccount: InsuredAccount) => {
        return forkJoin([
          this.bindService.bind(
            paymentPlanId,
            this.quoteId,
            'WC',
            undefined,
            undefined,
            undefined,
            insuredAccount
          ),
          this.sendBindAttemptSegmentEvent(insuredAccount),
        ]);
      }),
      map(([bindResponse, _sendBindAttemptToSegment]) => {
        return bindResponse;
      }),
      tap((bindResponse) => {
        if (bindResponse.success === false && bindResponse.isFeinBlocked) {
          this.hasBindFeinBlocker = true;
        }
      })
    );
  }

  private sendBindAttemptSegmentEvent(insuredAccount: InsuredAccount) {
    const eventName = 'Bind Attempted';
    return this.quoteService.getQuote(this.quoteId).pipe(
      tap((getQuoteResponse) => {
        const firstLocationGroup = getQuoteResponse?.locations?.[0];
        const insuredAddress = firstLocationGroup?.address;
        const firstLocationClassCode = getQuoteResponse?.locations?.[0]?.classCodes?.[0];
        const classCode = {
          business_type: firstLocationClassCode?.description,
          classification: firstLocationClassCode?.classCode,
        };
        this.segmentService.track({
          event: eventName,
          properties: {
            product: 'wc',
            carrier: 'attune',
            class_code: classCode,
            naics_code: insuredAccount?.naicsCode,
            primary_state: insuredAddress?.state,
            insured_address: insuredAddress,
            insured_email: this.formService.currentContact?.EmailAddress1,
            business_name: insuredAccount.companyName,
          },
        });
      }),
      catchError((err) => {
        this.sentryService.notify('Unable to get WC quote to send segment event', {
          severity: 'error',
          metaData: {
            eventName,
            underlyingError: err,
            underlyingErrorMessage: err?.message,
          },
        });

        return observableOf(null);
      })
    );
  }

  private resetRequoteErrorList() {
    this.requoteErrors = [];
  }
}
