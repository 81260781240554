import { Validators, ValidatorFn } from '@angular/forms';

import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';
import {
  maxDateExceededValidator,
  minDateExceededValidator,
  phoneValidator,
  rangeValidator,
  validateCurrencyGreaterThanZero,
  validateCurrencyIsNotEmpty,
  zipCodeValidator,
} from 'app/shared/helpers/form-helpers';

import {
  GL_BUSINESS_CLASSES_V4,
  HiscoxGlFormDataFieldV4,
  HiscoxExpectedAnswers,
  GL_V4_FIELDS_WITH_NONE_OF_THE_ABOVE_OPTION,
} from 'app/features/hiscox/models/gl-constants';
import { REMOVE_FROM_PAYLOAD } from 'app/features/hiscox/models/hiscox-constants';
import {
  glAgreeDisagreeV4,
  validateCheckboxGroupNode,
} from 'app/features/hiscox/models/hiscox-customization';

import {
  FormDslNode,
  MultiInputNode,
  checkboxGroupNode,
  SearchQueryMethod,
  SearchFormatter,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { calculateNodeIndex } from 'app/shared/form-dsl/utils/form-dsl-helpers';
import { UsStateService } from 'app/shared/services/us-state.service';
import { getToday, getTodayUs } from 'app/shared/helpers/date-time-helpers';
import {
  getOneHundredYearsAgo,
  getLatestEffectiveDateMoment,
} from 'app/features/hiscox/models/date-time';
import {
  classOfBusinessExceptionsByStateValidator,
  validateAggregateLimitGreateThanPerOccurrence,
  validateExclusiveCheckboxOption,
  validateHnoaLimitNotGreaterThanGlLoi,
  validateMaxSelectionsInCheckboxGroup,
  validateNumberOfEmployees,
  validateNumberOfOwners,
  validatePrimaryCobLoiLessThanEqualSecondaryCobLoiLimit,
  validateSubcontractProfSrvcsAtLeastOneSubQuestionSelectedYes,
  zipMatchesStateValidator,
} from 'app/features/hiscox/models/form-validators';
import {
  yesNoOptions,
  HISCOX_API_VERSION,
  agreeDisagreeOptionsV4,
  HISCOX_PRODUCTS,
  CyberUpsellFormLogicControlName,
  HiscoxFormStepPath,
  GlV4Overrides,
} from './hiscox-types';
import { sortDictionaryByValues } from 'app/shared/helpers/helper-functions';
import { HiscoxSharedDataField } from './hiscox-constants';
import { CoalitionCyberQuestion } from '../../coalition/models/cyber-typings.model';
import {
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
} from '../../coalition/models/cyber-constants.model';

const usStateService = new UsStateService();
const usStates = usStateService.getUsStateAbbreviations();

// eventually all of these will be typed as form-dsl-nodes once those types are merged
export const alwaysAdd: any[] = [
  {
    inputId: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HireNonOwnVehclUse',
    nameOfFormControl: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HireNonOwnVehclUse',
    labelText:
      'Does the business use rented or non-owned vehicles (e.g. an employee’s car) for business purposes?',
    primitive: 'RADIO',
    options: yesNoOptions,
  },
  {
    primitive: 'DIV',
    inputId: 'HNOA_CoverID_Div',
    children: [
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HireNonOwnVehclUse',
        enableValue: 'Yes',
        conditionalChildren: [
          {
            primitive: 'RADIO',
            labelText: 'Include Non Owned Auto Coverage',
            inputId: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HNOACoverQuoteRq_CoverId',
            nameOfFormControl: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HNOACoverQuoteRq_CoverId',
            options: {
              yes: 'CGLHNOA',
              no: null,
            },
          },
        ],
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HNOACoverQuoteRq_CoverId',
        enableValue: 'CGLHNOA',
        conditionalChildren: [
          {
            primitive: 'SELECT',
            labelText: 'Non-owned auto occurrence limit',
            cssClass: 'sub-question',
            inputId: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HNOACoverQuoteRq_RatingInfo_LOI',
            nameOfFormControl:
              'ProductQuoteRqs_GeneralLiabilityQuoteRq_HNOACoverQuoteRq_RatingInfo_LOI',
            options: {
              300000: 300000,
              500000: 500000,
              1000000: 1000000,
              2000000: 2000000,
            },
          },
        ],
      },
    ],
  },
  {
    inputId: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_GeneralLiabilityCoverQuoteRq_RatingInfo_LOI',
    nameOfFormControl:
      'ProductQuoteRqs_GeneralLiabilityQuoteRq_GeneralLiabilityCoverQuoteRq_RatingInfo_LOI',
    labelText: 'Per occurrence limit',
    primitive: 'SELECT',
    value: 1000000,
    options: {
      300000: 300000,
      500000: 500000,
      1000000: 1000000,
      2000000: 2000000,
    },
  },
  {
    primitive: 'RADIO',
    inputId: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
    nameOfFormControl: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
    labelText: 'Include Coalition Cyber Standalone Policy?',
    options: {
      Yes: 'Yes',
      No: 'No',
    },
  },
  {
    primitive: 'VALUE-CONDITIONAL',
    inputId: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
    dependsOn: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
    enableValue: 'Yes',
    cssClass: 'form-subsection',
    conditionalChildren: [
      // NAICS_CODE_IS_INELIGIBLE is only used for managing display logic of the industry control,
      // and is not rendered in the flow.
      {
        primitive: 'TEXT',
        inputId: CyberUpsellFormLogicControlName.NAICS_CODE_IS_INELIGIBLE,
        nameOfFormControl: CyberUpsellFormLogicControlName.NAICS_CODE_IS_INELIGIBLE,
        labelText: '',
        versions: [HISCOX_API_VERSION.v4],
        cssClass: 'hx-hide',
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: CyberUpsellFormLogicControlName.NAICS_CODE_IS_INELIGIBLE,
        enableValue: true,
        versions: [HISCOX_API_VERSION.v4],
        conditionalChildren: [
          {
            primitive: 'DROPDOWN_SEARCH',
            inputId: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
            nameOfFormControl: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
            labelText: 'Description of business',
            queryMethodName: SearchQueryMethod.COALITION_CYBER_INDUSTRY,
            formatterName: SearchFormatter.COALITION_CYBER_INDUSTRY,
            required: true,
          },
        ],
      },
      {
        primitive: 'MONEY_WITHOUT_DECIMAL',
        inputId: CoalitionCyberQuestion.COMPANY_REVENUE,
        nameOfFormControl: CoalitionCyberQuestion.COMPANY_REVENUE,
        labelText: 'Annual revenue',
        required: true,
      },
      // INDUSTRY_REQUIRES_TECH_EO and INDUSTRY_REQUIRES_NET_PROFIT are only used for
      // managing display logic of other controls, and are not rendered in the flow.
      {
        primitive: 'TEXT',
        inputId: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_TECH_EO,
        nameOfFormControl: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_TECH_EO,
        labelText: '',
        versions: [HISCOX_API_VERSION.v4],
        cssClass: 'hx-hide',
      },
      {
        primitive: 'TEXT',
        inputId: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_NET_PROFIT,
        nameOfFormControl: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_NET_PROFIT,
        labelText: '',
        versions: [HISCOX_API_VERSION.v4],
        cssClass: 'hx-hide',
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_TECH_EO,
        enableValue: true,
        versions: [HISCOX_API_VERSION.v4],
        conditionalChildren: [
          {
            primitive: 'RADIO',
            labelText:
              'Will the company have an active technology errors and omissions policy concurrent with this insurance policy?',
            versions: [HISCOX_API_VERSION.v4],
            inputId: CoalitionCyberQuestion.HAS_TECH_EO,
            nameOfFormControl: CoalitionCyberQuestion.HAS_TECH_EO,
            options: { Yes: 'Yes', No: 'No' },
            required: true,
          },
        ],
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_NET_PROFIT,
        enableValue: true,
        versions: [HISCOX_API_VERSION.v4],
        conditionalChildren: [
          {
            primitive: 'MONEY_WITHOUT_DECIMAL',
            versions: [HISCOX_API_VERSION.v4],
            inputId: CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
            nameOfFormControl: CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
            labelText: 'Projected gross profit/net revenue over the next 12 months',
            required: true,
          },
        ],
      },
      {
        primitive: 'TRADITIONAL_RADIO',
        versions: [HISCOX_API_VERSION.v4],
        labelText: 'Limit and retention',
        inputId: CoalitionCyberQuestion.AGGREGATE_LIMIT,
        nameOfFormControl: CoalitionCyberQuestion.AGGREGATE_LIMIT,
        options: {
          '250k limit, 5k retention': MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
          '100k limit, 1k retention': ESSENTIAL_AGGREGATE_LIMIT_INDEX,
        },
        required: true,
      },
    ],
  },
];

export const NODES_TO_ALWAYS_INCLUDE: FormDslNode[] = [
  {
    inputId:
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID,
    nameOfFormControl:
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID,
    labelText: 'Terrorism Coverage',
    primitive: 'RADIO',
    options: {
      Yes: HiscoxExpectedAnswers.TRIA_COVERAGE,
      No: REMOVE_FROM_PAYLOAD, // when not included, it will not be sent in request to SQ
    },
  },
];

// fields that are removed from the second form step
// eventually all of these will be typed as form-dsl-nodes once those types are merged
export const removeFields = [
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HireNonOwnVehclUse',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_AnEConstructionSrvcsExplain',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SubcontractProfSrvcsExplain',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MergerAcquisitionsNonAcceptReason',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsExplain',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_InsuranceDeclineNonAcceptReason',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ClaimDetails',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BusinessOwnershipNonAcceptReason',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ApplicantAuthorizedNonAcceptReason',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ExcludedActivitiesNonAcceptReason',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TangibleGoodWorkExplain',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TangibleGoodWorkIT',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_AnEConstructionSrvcsExplain',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SimilarInsuranceCarrier',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_HNOACoverQuoteRq_RatingInfo_LOI',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_GeneralLiabilityPropertyCoverQuoteRq_RatingInfo_LOI',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_EmailConsent',
  'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_InformationConfirmAgreement',
];

// Fields that can be removed since they are:
// - populated in the backend,
// - showin in step one
// - shown in modal
// - overriden as part of a conditional node
export const FIELDS_TO_REMOVE = [
  HiscoxSharedDataField.AGENTINFO_AGENTID,
  HiscoxSharedDataField.AGENTINFO_AGENCYNAME,
  HiscoxSharedDataField.AGENTINFO_PERSON_NAME_FIRSTNAME,
  HiscoxSharedDataField.AGENTINFO_PERSON_NAME_LASTNAME,
  HiscoxSharedDataField.AGENTINFO_AGENTREPRESENTATIVE,
  HiscoxSharedDataField.AGENTINFO_AGENTREGIONALOFFICE,
  HiscoxSharedDataField.AGENTINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER,
  HiscoxSharedDataField.AGENTINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONEEXTENSION,
  HiscoxSharedDataField.AGENTINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR,
  HiscoxSharedDataField.PRODUCERINFO_EMAILINFO_EMAILADDR,
  HiscoxSharedDataField.PRODUCERINFO_PRODUCERCLIENT,
  HiscoxSharedDataField.PRODUCERINFO_PARTNERUNIQUEID,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_FIRSTNAME,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_LASTNAME,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONEEXTENSION,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_HAVEDBAORFKA,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_DBAORFKANAME,
  // TangibleGoodWork override - start
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKTRADESMEN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT3,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE4,
  // TangibleGoodWork override - end
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_QUOTERQDT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_STATEORPROVCD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID, // added always, as RADIO
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CLASSOFBUSINESSCD, // already in first step
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_ADDR1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_ADDR2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_CITY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_COUNTY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_POSTALCODE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_STATEORPROVCD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_LOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_BUSINESSPROPANDEQUIPPREMISELIMIT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_CGLSTATEMENT1, // shown in modal
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ,
  // SupplyManufactDistbtGoodsOrProducts override - start
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSASSOCWEBSITE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTURED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTUREDDESCRIBE,
  // SupplyManufactDistbtGoodsOrProducts override - end
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_APPLICATIONAGREEMENTSTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGCOMP,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGRELBUS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGRELINS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSCOV,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSCOVPURPOSE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSREQ,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNPERCENTCONTROL,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUACT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUACTDATE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUAMOUNT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUAVOID,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUCLAIMDATE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUNAME,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_DEDUCTIBLESTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILDELIVERYSTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_FRAUDWARNING,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_HISCOXSTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INFORMATIONCONFIRMAGREEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINEACTION,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECANCEL,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECANCELDATE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECARRIER,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITION,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_STATESPCFCFRAUDWARNING,
];

export const HNOA_FIELDS_TO_REMOVE = [
  // GLHireNonOwnVehicleCoverage is being sent in the API for all COBs/states, need to remove for certain COBs/states
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI,
];

// Fields that can default to ceratin values in v4
const glFieldDefaultsV4: Partial<Record<HiscoxGlFormDataFieldV4, any>> = {
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE]:
    '500',
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ]:
    REMOVE_FROM_PAYLOAD,
};

export const getGlFieldDefaults = () => {
  // today's date
  const todayUs = getTodayUs();

  return {
    ...glFieldDefaultsV4,
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE]: todayUs,
  };
};

export const getGlFieldValidators = (): Record<string, ValidatorFn[]> => {
  // today's date
  const today = getToday();
  // latest allowable value for coverage start date
  const maxEffectiveDateMoment = getLatestEffectiveDateMoment();
  // earliest allowable date for professional experience and retroactive date
  const oneHundredYearsAgo = getOneHundredYearsAgo();

  const validatorDictionary: Partial<
    Record<HiscoxFormStepPath | HiscoxGlFormDataFieldV4, ValidatorFn[]>
  > = {
    // Form step validators
    [HiscoxFormStepPath.QUOTE_BASIC]: [
      classOfBusinessExceptionsByStateValidator(HISCOX_PRODUCTS.gl),
      zipMatchesStateValidator(),
    ],
    [HiscoxFormStepPath.QUOTE_RATING]: [
      validateAggregateLimitGreateThanPerOccurrence({
        perOccurrenceLimitControlName:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      }),
      validateSubcontractProfSrvcsAtLeastOneSubQuestionSelectedYes(),
      validateHnoaLimitNotGreaterThanGlLoi,
      validatePrimaryCobLoiLessThanEqualSecondaryCobLoiLimit,
    ],
    // Individual control validators
    [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE]: [
      zipCodeValidator,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER]: [
      phoneValidator,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR]: [
      validateEmailAddress,
      Validators.email,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFEMPLOYEESINCOMP]:
      [validateNumberOfEmployees],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE]: [
      maxDateExceededValidator(maxEffectiveDateMoment),
      minDateExceededValidator(today, 'day'),
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE]: [
      maxDateExceededValidator(today),
      minDateExceededValidator(oneHundredYearsAgo),
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_AGREEDISAGREESTATEMENTS]: [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SECONDARYCOBSMALLCONTRACTORS_CLASSOFBUSINESSCD]:
      [
        validateMaxSelectionsInCheckboxGroup(
          4,
          'Please select a maximum of 4 secondary classes of business.'
        ),
        validateExclusiveCheckboxOption('NoneOfTheAbove'),
        validateCheckboxGroupNode,
        Validators.required,
      ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_EMPLOYEEWAGEROLL]: [
      validateCurrencyIsNotEmpty,
      Validators.max(999_999_999),
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS]:
      [validateCurrencyGreaterThanZero, rangeValidator(1, 999999999), Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE]:
      [validateCurrencyIsNotEmpty, rangeValidator(1, 999999999), Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE]:
      [validateCurrencyGreaterThanZero, rangeValidator(1, 999999999), Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFOWNERS]: [
      validateNumberOfOwners,
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_LOI]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK]: [
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKDESCRIBE]: [
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKTRADESMEN]: [
      Validators.required,
    ],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT3]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE4]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE]:
      [Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_BUSINESSPROPANDEQUIPPREMISELIMIT]:
      [Validators.max(999_999_999), Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_ESTMTDPAYROLLSC]: [
      rangeValidator(0, 999999999),
      Validators.required,
    ],
    // the following validations must be done on the original field and not the overridden field:
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU]:
      [Validators.max(999_999_999), Validators.required],
    [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANOTHERS]:
      [Validators.required],
  };

  GL_V4_FIELDS_WITH_NONE_OF_THE_ABOVE_OPTION.forEach((field: HiscoxGlFormDataFieldV4) => {
    const validators = validatorDictionary[field];
    if (validators) {
      validatorDictionary[field] = [
        validateExclusiveCheckboxOption('NoneOfTheAbove'),
        ...validators,
      ];
    } else {
      validatorDictionary[field] = [validateExclusiveCheckboxOption('NoneOfTheAbove')];
    }
  });
  return validatorDictionary;
};

/**
 * SimpleOverridesV4 is being used to define simple overrides for the Hiscox Dynamic questions.
 * This Dictionary datastrure is references by key, which matches the
 */
const simpleOverridesV4: { [key in HiscoxGlFormDataFieldV4]?: GlV4Overrides } = {
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID]:
    {
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID,
      nameOfFormControl:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID,
      labelText: 'Primary and Non-Contributory',
      primitive: 'RADIO',
      options: {
        Yes: HiscoxExpectedAnswers.PRIMARY_NON_CONTRIB_COVERAGE,
        No: REMOVE_FROM_PAYLOAD,
      },
    },
  [HiscoxGlFormDataFieldV4.BUSINESSINFO_HAVEDBAORFKA]: {
    labelText: 'Does the business have a DBA or FKA business name?',
    required: false,
  },
  [HiscoxGlFormDataFieldV4.BUSINESSINFO_DBAORFKANAME]: {
    labelText: 'DBA/FKA name',
    required: false,
  },
  [HiscoxGlFormDataFieldV4.BUSINESSINFO_NUMOFEMPLOYEES]: {
    labelText:
      'Including the owner, how many full-time, part-time, and temporary employees does the business have? (Do not include subcontractors.)',
    errorText: 'Number of employees must be greater than 0',
  },
  // Note: Overriding due to INVALID xPath
  AcknowledgementsEmailConsent: {
    inputId: HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT,
    nameOfFormControl: HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT,
    primitive: 'RADIO',
    labelText: 'The business owner agrees to receive policy documents by email.',
    options: agreeDisagreeOptionsV4,
  },
  // Note: Overriding due to INVALID xPath
  AcknowledgementsEmailConsent2: {
    inputId: HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT2,
    nameOfFormControl: HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT2,
    primitive: 'RADIO',
    labelText: 'The business owner agrees to receive important notices by email.',
    options: agreeDisagreeOptionsV4,
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_OPERATEDFROMHOME]: {
    labelText: "Is the business operated out of the owner's home?",
    options: yesNoOptions,
  },
  // Note: the nameOfFormControl will not accept an value with number, e.g. including COB code, so this is changes in SQ instead
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SECONDARYCOBSMALLCONTRACTORS_CLASSOFBUSINESSCD]:
    checkboxGroupNode({
      nameOfFormControl:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SECONDARYCOBSMALLCONTRACTORS_CLASSOFBUSINESSCD,
      labelText: 'Select all of the following services the business provides',
      checkboxConfigs: [
        {
          // Hiscox code: 23822000_49902100_01000000
          nameOfFormControl: 'AirConditioningSystemsInstallationAndRepair',
          labelText: 'Air conditioning systems installation and repair',
        },
        {
          // Hiscox code: 81141200_49909900_00000000
          nameOfFormControl: 'ApplianceAndAccessoriesInstallationAndRepair',
          labelText: 'Appliance and accessories installation and repair',
        },
        {
          // Hiscox code: 23821000_49905200_01000000
          nameOfFormControl: 'CablingInstallation',
          labelText: 'Cabling installation',
        },
        {
          // Hiscox code: 23835000_47203100_01000100
          nameOfFormControl: 'CarpentryInteriorOnly',
          labelText: 'Carpentry (interior only)',
        },
        {
          // Hiscox code: 56174000_51601100_00010000
          nameOfFormControl: 'CarpetRugFurnitureOrUpholsteryCleaningCustomersPremisesOnly',
          labelText: "Carpet, rug, furniture, or upholstery cleaning (customer's premises only)",
        },
        {
          // Hiscox code: 81149000_49906400_00000000
          nameOfFormControl: 'ClockMakingAndRepair',
          labelText: 'Clock making and repair',
        },
        {
          // Hiscox code: 23821000_49905200_02000000
          nameOfFormControl: 'CommunicationEquipmentInstallationCableSatellite',
          labelText: 'Communication equipment installation (cable/satellite)',
        },
        {
          // Hiscox code: 23835000_47203100_02000300
          nameOfFormControl: 'DoorOrWindowInstallationAndRepair',
          labelText: 'Door or window installation and repair',
        },
        {
          // Hiscox code: 23899000_47409100_00000000
          nameOfFormControl: 'DrivewayOrSidewalkPavingRepaving',
          labelText: 'Driveway or sidewalk paving/repaving',
        },
        {
          // Hiscox code: 23831000_47208100_01000000
          nameOfFormControl: 'DrywallOrWallboardInstallationAndRepair',
          labelText: 'Drywall or wallboard installation and repair',
        },
        {
          // Hiscox code: 56179000_37201900_02000200
          nameOfFormControl: 'ExteriorCleaningServices',
          labelText: 'Exterior cleaning services',
        },
        {
          // Hiscox code: 23821000_47211100_01000100
          nameOfFormControl: 'ElectricalWorkInteriorOnly',
          labelText: 'Electrical work (interior only)',
        },
        {
          // Hiscox code: 23899000_47403100_00000000
          nameOfFormControl: 'FenceInstallationAndRepair',
          labelText: 'Fence installation and repair',
        },
        {
          // Hiscox code: 23833000_47204200_01000000
          nameOfFormControl: 'FloorCoveringInstallationNoCeramicTileOrStone',
          labelText: 'Floor covering installation (no ceramic tile or stone)',
        },
        {
          // Hiscox code: 23815000_47212100_01000000
          nameOfFormControl: 'GlassInstallationAndRepairNoAutoWork',
          labelText: 'Glass installation and repair (no auto work)',
        },
        {
          // Hiscox code: 23839000_49907100_01000000
          nameOfFormControl: 'HandypersonNoRoofWork',
          labelText: 'Handyperson (no roof work)',
        },
        {
          // Hiscox code: 23822000_49902100_02000000
          nameOfFormControl:
            'HeatingAndAirConditioningInstallationAndRepairNoLiquefiedPetroleumGasLpg',
          labelText:
            'Heating and air conditioning installation and repair (no liquefied petroleum gas (LPG))',
        },
        {
          // Hiscox code: 81121100_49209700_00000000
          nameOfFormControl: 'HomeTheaterInstallation',
          labelText: 'Home theater Installation',
        },
        {
          // Hiscox code: 23835000_47203100_03000100
          nameOfFormControl: 'InteriorFinishingWork',
          labelText: 'Interior finishing work',
        },
        {
          // Hiscox code: 56172000_37201100_01000000
          nameOfFormControl: 'JanitorialCleaningServices',
          labelText: 'Janitorial cleaning services',
        },
        {
          // Hiscox code: 56173000_37301100_01000000
          nameOfFormControl: 'LandscapeGardeningServices',
          labelText: 'Landscape gardening services',
        },
        {
          // Hiscox code: 56173000_37301100_02000000
          nameOfFormControl: 'LawnCareServices',
          labelText: 'Lawn care services',
        },
        {
          // Hiscox code: 56162200_49909400_00000000
          nameOfFormControl: 'Locksmiths',
          labelText: 'Locksmiths',
        },
        {
          // Hiscox code: 23814000_47205100_00000000
          nameOfFormControl: 'MasonryWork',
          labelText: 'Masonry work',
        },
        {
          // Hiscox code: 23832000_47214100_00000100
          nameOfFormControl: 'PaintingInteriorOnly',
          labelText: 'Painting (interior only)',
        },
        {
          // Hiscox code: 23831000_47208100_02000000
          nameOfFormControl: 'PlasteringOrStuccoWork',
          labelText: 'Plastering or stucco work',
        },
        {
          // Hiscox code: 23822000_47215200_00020000
          nameOfFormControl: 'PlumbingCommercialOrIndustrial',
          labelText: 'Plumbing (commercial or industrial)',
        },
        {
          // Hiscox code: 23822000_47215200_00010000
          nameOfFormControl: 'PlumbingResidentialOrDomestic',
          labelText: 'Plumbing (residential or domestic)',
        },
        {
          // Hiscox code: 56162100_49209800_00000000
          nameOfFormControl: 'SecuritySystemInstallation',
          labelText: 'Security system installation',
        },
        {
          // Hiscox code: 54189000_51912300_00000200
          nameOfFormControl: 'SignPaintingAndLetteringExteriorOnly',
          labelText: 'Sign painting and lettering (exterior only)',
        },
        {
          // Hiscox code: 54189000_51912300_00000100
          nameOfFormControl: 'SignPaintingAndLetteringInteriorOnly',
          labelText: 'Sign painting and lettering (interior only)',
        },
        {
          // Hiscox code: 23821000_49209700_00000000
          nameOfFormControl: 'SoundEquipmentInstallation',
          labelText: 'Sound equipment installation',
        },
        {
          // Hiscox code: 23834000_47204400_00000100
          nameOfFormControl: 'TileStoneMarbleMosaicOrTerrazzoWorkInteriorOnly',
          labelText: 'Tile, stone, marble, mosaic, or terrazzo work (interior only)',
        },
        {
          // Hiscox code: 81142000_51609300_00000000
          nameOfFormControl: 'UpholsteryWork',
          labelText: 'Upholstery work',
        },
        {
          // Hiscox code: 56172000_37201100_02000000
          nameOfFormControl: 'WindowCleaningNothingAbove15Feet',
          labelText: 'Window cleaning (nothing above 15 feet)',
        },
        {
          nameOfFormControl: 'NoneOfTheAbove',
          labelText: 'None of the above',
        },
      ],
      required: true,
    }),
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS]:
    {
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS,
      labelText: "What are the business's expected commissions during the next 12 months?",
      primitive: 'MONEY_WITHOUT_DECIMAL',
      errorText: 'Amount must be less than $1,000,000,000.',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU]:
    {
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU,
      labelText: 'Approximately how many square feet does the business occupy at this location?',
      primitive: 'NUMBER',
      errorText: 'Value must be less than 999,999,999 square feet.',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE]:
    {
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE,
      labelText: "For the next 12 months, what is the business's expected revenue?",
      primitive: 'MONEY_WITHOUT_DECIMAL',
      errorText: 'Amount must be less than $1,000,000,000.',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE]:
    {
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE,
      labelText: "For the next 12 months, what is the business's expected payroll? ",
      primitive: 'MONEY_WITHOUT_DECIMAL',
      errorText: 'Amount must be less than $1,000,000,000.',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_ESTMTDPAYROLLSC]: {
    labelText:
      "What's the business's estimated payroll for subcontractors only during the next 12 months?",
    errorText: 'Amount must be less than $1,000,000,000.',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS]:
    checkboxGroupNode({
      nameOfFormControl:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS,
      labelText: 'Does the business provide any of the following products:',
      checkboxConfigs: [
        {
          nameOfFormControl: 'AlcoholicBeveragesOrProducts',
          labelText:
            'Alcoholic beverages or products (includes serving, selling, or sub-contracting)',
        },
        {
          nameOfFormControl: 'AnimalLivestockFeed',
          labelText: 'Animal/Livestock Feed',
        },
        {
          nameOfFormControl: 'ProductsRequiringAPrescription',
          labelText: 'Any products requiring a prescription',
        },
        {
          nameOfFormControl: 'BabyFood',
          labelText: 'Baby food',
        },
        {
          nameOfFormControl: 'BodyPiercings',
          labelText: 'Body piercings',
        },
        {
          nameOfFormControl: 'CannabisCBDOilOrInfusedProducts',
          labelText: 'Cannabis, CBD oil or any cannabis or CBD oil infused products',
        },
        {
          nameOfFormControl: 'DietProductsCleansesOrPillsAsWeightLoss',
          labelText: 'Diet products or cleanses or pills marketed as a “weight loss” product',
        },
        {
          nameOfFormControl: 'EnergyDrinks',
          labelText: 'Energy drinks',
        },
        {
          nameOfFormControl: 'Kava',
          labelText: 'Kava',
        },
        {
          nameOfFormControl: 'MedicalInvasiveProductsWithinBody',
          labelText: 'Medical invasive products – intended to stay within body',
        },
        {
          nameOfFormControl: 'NutraceuticalsVitaminsSupplementsSuppositories',
          labelText:
            'Nutraceuticals, vitamins, supplements, suppositories or products containing pharmaceuticals, drugs or medicines',
        },
        {
          nameOfFormControl: 'PermanentTattoos',
          labelText: 'Permanent tattoos',
        },
        {
          nameOfFormControl: 'TobaccoRelatedProductsECigarettesAndSmokeless',
          labelText: 'Tobacco related products including e-cigarettes and smokeless products',
        },
        {
          nameOfFormControl: 'TopicalProductsSoapsLotionsCosmeticsAndBath',
          labelText: 'Topical products including soaps, lotions, cosmetics and bath products',
        },
        {
          nameOfFormControl: 'UnmannedAircraftSystems',
          labelText: 'Unmanned aircraft systems (e.g. drones, RC aircrafts)',
        },
        {
          nameOfFormControl: 'Weapons',
          labelText: 'Weapons',
        },
        {
          nameOfFormControl: 'NoneOfTheAbove',
          labelText: 'None of the above',
        },
      ],
      required: true,
    }),
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS]: {
    labelText:
      "Does the business or any of the business's subcontractors directly perform any of the following physical services (check all that apply)?",
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1]: {
    labelText:
      "Does the business or any of the business's subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure (check all that apply)?",
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE]: {
    labelText: 'Approximately when did the business begin?',
    subLabelText:
      'The business begin date will be defaulted to the first day of the month for the date you enter.',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SIMILARINSURANCE]: {
    labelText:
      'Does the business currently have an insurance policy in effect for the coverage requested?',
    options: {
      Yes: 'Yes',
      No: 'No',
      PreferNotToAnswer: 'Prefer not to answer',
    },
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS]: {
    labelText: 'Does the business subcontract any professional services?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFEMPLOYEESINCOMP]:
    {
      labelText: 'How many employees are there in the company (include full time/part time)?',
      subLabelText:
        'Note: Exclude owners, clerical employees, independent contractors, or subcontractors.',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFOWNERS]: {
    labelText: 'How many owners are there in the company?',
    subLabelText: 'Note: Owners include active partners and executive officers.',
    errorText: 'Number of owners must be greater than 0 and less than 100',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_EMPLOYEEWAGEROLL]: {
    labelText: 'What is the employee payroll for the next 12 months (include full time/part time)?',
    subLabelText:
      'Note: Exclude owners, clerical employees, independent contractors, or subcontractors.',
    errorText: 'Amount must be $0 or less than $1,000,000,000',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES]:
    {
      labelText: 'The business does not conduct any of the following activities:',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS]: {
    labelText:
      "Does the business or any of the business's clients work in any of the following areas or industries (check all that apply)?",
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY]: {
    labelText: 'Does the business provide any of the following services (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL]:
    {
      labelText:
        'Does the business provide any of the following operations, goods, or services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS]: {
    labelText:
      'Does the business provide any of the following services or activities (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5]: {
    labelText:
      "Does the business or any of the business's subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure (check all that apply)?",
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CHIROPHYSICALTHERAPY]: {
    labelText: 'Does the business provide any chiropractic or physical therapy services?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP]: {
    labelText: 'Is the business a franchisee of a salon or spa franchise?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP2]: {
    labelText:
      'Does the business have an ownership interest in a Physical Therapy Center or Recreation Center that has equipment on site valued over $50,000?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP3]: {
    labelText:
      'Does the business have an ownership interest in a Fitness Center, Gym, Health Club or Yoga/Pilates Studio that provides 24 hour/unsupervised access or has equipment on site valued over $50,000?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTDESIGN]: {
    labelText:
      'Does the business subcontract design related architectural, drafting, or engineering services?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTINSURANCE]: {
    labelText: 'Does the business require subcontractors you hire to purchase their own insurance?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR]: {
    labelText:
      'Does the business sell or distribute any of the following items, products, or supplies (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRBRANCHESOPS]: {
    labelText:
      'Do the manufacturers the business works for have branches or operations located in the United States?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINDPNTCTRCTR]: {
    labelText:
      'Is the business hired as an independent contractor by the original manufacturer(s)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINVNTRY]: {
    labelText: 'Does the business have storage or warehouse facilities for inventory?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS]:
    {
      labelText:
        'Does the business perform or assist in any of the following services or treatments? Choose all that apply.',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY]: {
    labelText: 'Does the business conduct any of the following (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO]: {
    labelText:
      'Does the business supply, manufacture, or distribute any tangible goods or products?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP]:
    {
      labelText: 'Does the business provide any of the following services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS]:
    checkboxGroupNode({
      nameOfFormControl:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS,
      labelText:
        "Does the business or any of the business's subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure (check all that apply)?",
      checkboxConfigs: [
        {
          nameOfFormControl: 'Airports',
          labelText: 'Airports',
        },
        {
          nameOfFormControl: 'AmusementRidesPoolsOrPlaygrounds',
          labelText: 'Amusement rides, pools or playgrounds',
        },
        {
          nameOfFormControl: 'AnyProductsRequiringAPrescription',
          labelText: 'Any products requiring a prescription',
        },
        {
          nameOfFormControl: 'AsbestosLeadOrMoldEvaluationOrAbatement',
          labelText: 'Asbestos, lead, or mold evaluation or abatement',
        },
        {
          nameOfFormControl: 'BridgesDamsHarborsMinesPiersOrTunnels',
          labelText: 'Bridges, dams, harbors, mines, piers or tunnels',
        },
        {
          nameOfFormControl: 'BlastingOrDemolition',
          labelText: 'Blasting or demolition',
        },
        {
          nameOfFormControl: 'CraneOperation',
          labelText: 'Crane operation',
        },
        {
          nameOfFormControl: 'EmergencySystems',
          labelText: 'Emergency systems',
        },
        {
          nameOfFormControl: 'FireSuppression',
          labelText: 'Fire suppression',
        },
        {
          nameOfFormControl: 'FoundationSheetingOrRetainingWalls',
          labelText: 'Foundation, sheeting or retaining walls',
        },
        {
          nameOfFormControl: 'HazardousMaterialOrWasteRemoval',
          labelText: 'Hazardous material or waste removal',
        },
        {
          nameOfFormControl: 'HydraulicFracturingHydrofracturingOrFracking',
          labelText: 'Hydraulic fracturing, hydrofracturing or fracking',
        },
        {
          nameOfFormControl: 'Landfills',
          labelText: 'Landfills',
        },
        {
          nameOfFormControl: 'OilGasOrWells',
          labelText: 'Oil, gas or wells',
        },
        {
          nameOfFormControl: 'ProessPiping',
          labelText: 'Process piping',
        },
        {
          nameOfFormControl: 'Roofing',
          labelText: 'Roofing',
        },
        {
          nameOfFormControl: 'ScaffoldingOperation',
          labelText: 'Scaffolding operation',
        },
        {
          nameOfFormControl: 'UndergroundStorageTanksOrUtilities',
          labelText: 'Underground storage tanks or utilities',
        },
        {
          nameOfFormControl: 'AnyOtherHazardousProjectsOrMaterials',
          labelText: 'Any other similarly hazardous projects or materials',
        },
        {
          nameOfFormControl: 'NoneOfTheAbove',
          labelText: 'None of the above',
        },
      ],
      required: true,
    }),
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2]: {
    labelText: 'Does the business provide any of the following services (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRHOTELHEALTHCARE]: {
    labelText: 'Does the business manage any hotel properties or health care facilities?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRRENOVATION]: {
    labelText:
      'Does the business manage or implement any construction, renovation or reconstruction projects on the properties managed?',
    subLabelText: '(This does not apply to oversight of routine maintenance.)',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP]: {
    labelText: 'Does the business provide any of the following services (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES]:
    {
      labelText:
        'Does the business provide any of the following operations or services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC]: {
    labelText: 'Does the business own or host any of the following (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GL]: {
    labelText:
      'Does the business provide any of the following services or use any of the following equipment?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP]:
    {
      labelText: 'Does the business provide any of the following services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP]:
    {
      labelText: 'Does the business provide any of the following services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP]:
    {
      labelText: 'Does the business provide any of the following services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2]: {
    labelText: 'Is the business any of the following? Check all that apply.',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3]: {
    labelText:
      'Does the business conduct any of the following training, instruction or services (check all that apply)?',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4]: {
    labelText: 'Does the business conduct any of the following? Check all that apply',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSLICENSESHEALTHSRVCS]: {
    labelText:
      "As required by state law, is the business owner and the business's employees properly trained or certified to administer, dispense, or assist in providing medications to patients?",
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3]: {
    labelText:
      'Does the business or any of the subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure? Choose all that apply.',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL]:
    {
      labelText:
        'Does the business provide any of the following operations, goods, or services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP]:
    {
      labelText: 'Does the business provide any of the following services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS]:
    {
      labelText:
        'Does the business provide any of the following operations, goods, or services (check all that apply)?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1]:
    {
      labelText: 'Does the business provide any of the following operations, goods, or services?',
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CLASSOFBUSINESSCD]: {
    labelText: 'Select all of the following services the business provides (check all that apply):',
  },
};

const coverageOverridesV4: { [characterName: string]: GlV4Overrides } = {
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID]: {
    labelText: 'TRIA coverage',
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID]:
    {
      labelText: 'Waiver of Subrogation',
      primitive: 'RADIO',
      options: {
        yes: HiscoxExpectedAnswers.WAIVER_OF_SUBROGATION_COVERAGE, // value from 'Quote V4 -AllProducts 1.3.15.xsd' schema file
        no: REMOVE_FROM_PAYLOAD,
      },
    },
};

const conditionalOverridesV4: { [key in HiscoxGlFormDataFieldV4]?: GlV4Overrides } = {
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS]: {
    primitive: 'DIV',
    children: [
      {
        primitive: 'RADIO',
        inputId: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS,
        nameOfFormControl:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS,
        labelText: 'Does your business subcontract any professional services?',
        options: yesNoOptions,
        required: true,
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS,
        enableValue: 'Yes',
        conditionalChildren: [
          {
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTDESIGN,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTDESIGN,
            cssClass: 'sub-question',
            primitive: 'RADIO',
            options: {
              Yes: 'Yes',
              No: 'No',
            },
            labelText:
              'Does the business subcontract design related architectural, drafting, or engineering services?',
            required: true,
          },
          {
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTREPAIR,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTREPAIR,
            cssClass: 'sub-question',
            primitive: 'RADIO',
            options: {
              Yes: 'Yes',
              No: 'No',
            },
            labelText: 'Does the business subcontract physical construction or repair services?',
            required: true,
          },
          {
            primitive: 'RADIO',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTOTHERSRVCS,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTOTHERSRVCS,
            cssClass: 'sub-question',
            labelText: 'Does the business subcontract any other services?',
            options: yesNoOptions,
            required: true,
          },
          {
            primitive: 'VALUE-CONDITIONAL',
            dependsOn:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTOTHERSRVCS,
            enableValue: 'Yes',
            conditionalChildren: [
              {
                primitive: 'TEXT',
                cssClass: 'sub-question',
                labelText: 'Please describe',
                required: true,
                inputId:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTSRVCSDESCRIBE,
                nameOfFormControl:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTSRVCSDESCRIBE,
              },
            ],
          },
          {
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTINSURANCE,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTINSURANCE,
            primitive: 'RADIO',
            options: {
              Yes: 'Yes',
              No: 'No',
            },
            labelText:
              'Does the business require subcontractors you hire to purchase their own insurance?',
            required: true,
          },
        ],
      },
    ],
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES]: {
    primitive: 'DIV',
    children: [
      {
        primitive: 'RADIO',
        inputId: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES,
        nameOfFormControl:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES,
        labelText: 'Does the business provide any real estate agent services?',
        options: yesNoOptions,
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES,
        enableValue: 'Yes',
        conditionalChildren: [
          checkboxGroupNode({
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1,
            labelText:
              'Does the business provide any of the following services (check all that apply)?',
            checkboxConfigs: [
              {
                nameOfFormControl: 'ConstructionOrRemodeling',
                labelText: 'Construction or remodeling',
              },
              {
                nameOfFormControl: 'GeneralContracting',
                labelText: 'General contracting',
              },
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'JanitorialOrCleaning',
                labelText: 'Janitorial or cleaning',
              },
              {
                nameOfFormControl: 'LandscapingOrLawnCare',
                labelText: 'Landscaping or lawn care',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder',
              },
              {
                nameOfFormControl: 'Property preservation',
                labelText: 'Property preservation',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          }),
        ],
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES,
        enableValue: 'No',
        conditionalChildren: [
          checkboxGroupNode({
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2,
            labelText:
              'Does the business provide any of the following services (check all that apply)?',
            checkboxConfigs: [
              {
                nameOfFormControl: 'ConstructionOrRemodeling',
                labelText: 'Construction or remodeling',
              },
              {
                nameOfFormControl: 'GeneralContracting',
                labelText: 'General contracting',
              },
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'JanitorialOrCleaning',
                labelText: 'Janitorial or cleaning',
              },
              {
                nameOfFormControl: 'LandscapingOrLawnCare',
                labelText: 'Landscaping or lawn care',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder',
              },
              {
                nameOfFormControl: 'PropertyPreservation',
                labelText: 'Property preservation',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          }),
        ],
      },
    ],
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES]: {
    primitive: 'DIV',
    children: [
      {
        primitive: 'RADIO',
        inputId: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES,
        nameOfFormControl:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES,
        labelText: 'Does the business provide any property management services?',
        options: yesNoOptions,
        required: true,
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES,
        enableValue: 'Yes',
        conditionalChildren: [
          checkboxGroupNode({
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1,
            labelText:
              'Does the business provide any of the following services (check all that apply)?',
            checkboxConfigs: [
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'MortgageBankerOrBroker',
                labelText: 'Mortgage banker/broker',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder',
              },
              {
                nameOfFormControl: 'TitleAgentOrAbstractor',
                labelText: 'Title agent/abstractor',
              },
              {
                nameOfFormControl: 'ConstructionOrRemodeling',
                labelText: 'Construction or remodeling',
              },
              {
                nameOfFormControl: 'GeneralContracting',
                labelText: 'General contracting',
              },
              {
                nameOfFormControl: 'JanitorialOrCleaning',
                labelText: 'Janitorial or cleaning',
              },
              {
                nameOfFormControl: 'LandscapingOrLawnCare',
                labelText: 'Landscaping or lawn care',
              },
              {
                nameOfFormControl: 'PropertyPreservation',
                labelText: 'Property preservation',
              },
              {
                nameOfFormControl: 'RealEstateAppraiser',
                labelText:
                  'Real estate appraiser (for loan valuations, does not apply to brokerage service)',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          }),
        ],
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES,
        enableValue: 'No',
        conditionalChildren: [
          checkboxGroupNode({
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2,
            labelText:
              'Does the business provide any of the following services (check all that apply)?',
            checkboxConfigs: [
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'MortgageBankerOrBroker',
                labelText: 'Mortgage banker/broker',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder',
              },
              {
                nameOfFormControl: 'RealEstateAppraiser',
                labelText:
                  'Real estate appraiser (for loan valuations, does not apply to brokerage service)',
              },
              {
                nameOfFormControl: 'TitleAgentOrAbstractor',
                labelText: 'Title agent/abstractor',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          }),
        ],
      },
    ],
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
          labelText: 'Business Property and Equipment Deductible',
          options: yesNoOptions,
        },
      ],
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ,
          labelText: 'Hired Auto and Non-Owned Auto Coverage',
          subLabelText: `The business owner's quote excludes optional hired auto and non-owned auto liability coverage as described <a target="_blank" href="https://www.hiscox.com/small-business-insurance/general-liability-insurance/non-owned-auto-liability">here</a>.  If the owner wishes to add this coverage to their quote an additional charge will apply.`,
          options: {
            Include: 'Include',
            'Do not include': REMOVE_FROM_PAYLOAD,
          },
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ,
          enableValue: 'Include',
          conditionalChildren: [
            {
              primitive: 'SELECT',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI,
              labelText: 'Occurrence limit',
              options: {
                '300000': '$300,000',
                '500000': '$500,000',
                '1000000': '$1,000,000',
                '2000000': '$2,000,000',
              },
              required: true,
            },
          ],
        },
      ],
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID,
          labelText: 'Business Property and Equipment Coverage',
          options: {
            yes: HiscoxExpectedAnswers.PROPERTY_AND_EQUIPMENT_COVERAGE, // value from 'Quote V4 -AllProducts 1.3.15.xsd' schema file
            no: REMOVE_FROM_PAYLOAD,
          },
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID,
          enableValue: 'BusinessPropandEquipCoverage',
          conditionalChildren: [
            {
              primitive: 'SELECT',
              options: {
                5000: '$5,000',
                10000: '$10,000',
                15000: '$15,000',
                20000: '$20,000',
                25000: '$25,000',
              },
              labelText: 'Business Property and Equipment LOI',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_LOI,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_LOI,
              required: true,
            },
            {
              primitive: 'SELECT',
              options: {
                500: '$500',
              },
              labelText: 'Business Property and Equipment Deductible',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
              required: true,
            },
          ],
        },
      ],
    },
};

const agreeDisagreeV4: { [characterName: string]: GlV4Overrides } = glAgreeDisagreeV4;

const supplyManufacturingOverridesV4: { [characterName: string]: GlV4Overrides } = {
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE,
          labelText:
            'Does the business supply, manufacture, or distribute any tangible goods or products?',
          subLabelText:
            'This does not apply to construction projects the business designs or reviews.',
          options: yesNoOptions,
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE,
          enableValue: 'Yes',
          conditionalChildren: [
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBEAANDE,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBEAANDE,
              labelText: 'What is the tangible good or product?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED2,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED2,
              labelText: 'How are the tangible goods used?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSOWNERSHIP,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSOWNERSHIP,
              labelText:
                'Does the business have ownership in the manufacturing process or does the business hire a third party to complete the manufacturing?',
            },
            {
              primitive: 'NUMBER',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT2,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT2,
              labelText:
                "What percentage of the business's annual revenue comes from these tangible goods or products?",
            },
          ],
        },
      ],
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS,
          labelText:
            'Does the business supply, manufacture, or distribute any tangible goods or products?',
          options: yesNoOptions,
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS,
          enableValue: 'Yes',
          conditionalChildren: [
            {
              primitive: 'RADIO',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITED,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITED,
              labelText:
                'Is this limited to items like computer equipment and peripherals, brochures, and documents that form part of the services provided?',
              options: yesNoOptions,
            },
          ],
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITED,
          enableValue: 'No',
          conditionalChildren: [
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE1,
              labelText: 'What are the tangible goods or products?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT1,
              labelText:
                "What percent of the business's annual revenue is generated from the sale of the tangible good/product?",
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED1,
              labelText: 'How is the good or property used?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY1,
              labelText: 'How long has the owner been involved in this activity?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE1,
              labelText: 'Where is the product manufactured?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU1,
              labelText: 'Who manufactures the good or product?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSASSOCWEBSITE,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSASSOCWEBSITE,
              labelText:
                'Please provide the website address for the company the owner is associated with if applicable',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE1,
              labelText: "Please provide the company's website address.",
            },
            {
              primitive: 'RADIO',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES1,
              labelText: 'Are there any recall procedures in place for the good or product?',
              options: yesNoOptions,
            },
            {
              primitive: 'VALUE-CONDITIONAL',
              dependsOn:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES1,
              enableValue: 'Yes',
              conditionalChildren: [
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE1,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE1,
                  labelText: 'Describe Recall Procedures',
                },
              ],
            },
            {
              primitive: 'RADIO',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS1,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS1,
              labelText: 'Are there any prior recalls?',
              options: yesNoOptions,
            },
            {
              primitive: 'VALUE-CONDITIONAL',
              dependsOn:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS1,
              enableValue: 'Yes',
              conditionalChildren: [
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE1,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE1,
                  labelText: 'Description',
                },
              ],
            },
            {
              primitive: 'RADIO',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTURED,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTURED,
              labelText: 'Is the product currently manufactured?',
              options: yesNoOptions,
            },
            {
              primitive: 'VALUE-CONDITIONAL',
              dependsOn:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTURED,
              enableValue: 'No',
              conditionalChildren: [
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTUREDDESCRIBE,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTUREDDESCRIBE,
                  labelText: 'Explain why',
                },
              ],
            },
          ],
        },
      ],
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
          labelText:
            'Does the owner of the business or the business supply, manufacture, distribute any tangible goods or products?',
          options: yesNoOptions,
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
          enableValue: 'Yes',
          conditionalChildren: [
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE,
              labelText: 'What is the tangible good or product?',
            },
            {
              primitive: 'NUMBER',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT,
              labelText:
                "What percent of the business's annual revenue is generated from the sale of the tangible good/product?",
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED,
              labelText: 'How is the good or property used?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE,
              labelText: 'Where is the product manufactured?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU,
              labelText: 'Who manufactures the good or product?',
            },
            {
              primitive: 'TEXT',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE,
              labelText: "Please provide the company's website address.",
            },
            {
              primitive: 'RADIO',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
              labelText: 'Are there any recall procedures in place for the good or product?',
              options: yesNoOptions,
            },
            {
              primitive: 'VALUE-CONDITIONAL',
              dependsOn:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
              enableValue: 'Yes',
              conditionalChildren: [
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE,
                  labelText: 'Describe',
                },
              ],
            },
            {
              primitive: 'RADIO',
              cssClass: 'sub-question',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
              labelText: 'Are there any prior recalls?',
              options: yesNoOptions,
            },
            {
              primitive: 'VALUE-CONDITIONAL',
              dependsOn:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
              enableValue: 'Yes',
              conditionalChildren: [
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE,
                  labelText: 'Description',
                },
              ],
            },
          ],
        },
      ],
      cobOverrideSets: [
        {
          cobSet: 'MANU_FACT_DIST_GOODS_OR_PRODUCTS2',
          overrides: {
            children: [
              {
                primitive: 'RADIO',
                inputId:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
                nameOfFormControl:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
                labelText:
                  'Does the owner or the business supply, manufacture, distribute any tangible goods or products?',
                options: yesNoOptions,
              },
              {
                primitive: 'VALUE-CONDITIONAL',
                dependsOn:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
                enableValue: 'Yes',
                conditionalChildren: [
                  {
                    primitive: 'RADIO',
                    cssClass: 'sub-question',
                    inputId:
                      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANOTHERS,
                    nameOfFormControl:
                      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANOTHERS,
                    labelText:
                      'Are the products the business provides as part of their services limited to those manufactured by others and do the total sales from these products represent less than 25% of your estimated annual gross sales?',
                    options: yesNoOptions,
                  },
                  {
                    primitive: 'VALUE-CONDITIONAL',
                    dependsOn:
                      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANOTHERS,
                    enableValue: 'No',
                    conditionalChildren: [
                      {
                        primitive: 'TEXT',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE,
                        labelText: 'What is the tangible good or product?',
                      },
                      {
                        primitive: 'NUMBER',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT,
                        labelText:
                          "What percent of the business's annual revenue is generated from the sale of the tangible good/product?",
                      },
                      {
                        primitive: 'TEXT',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED,
                        labelText: 'How is the good or property used?',
                      },
                      {
                        primitive: 'TEXT',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE,
                        labelText: 'Where is the product manufactured?',
                      },
                      {
                        primitive: 'TEXT',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU,
                        labelText: 'Who manufactures the good or product?',
                      },
                      {
                        primitive: 'TEXT',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE,
                        labelText: "Please provide the company's website address.",
                      },
                      {
                        primitive: 'RADIO',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
                        labelText:
                          'Are there any recall procedures in place for the good or product?',
                        options: yesNoOptions,
                      },
                      {
                        primitive: 'VALUE-CONDITIONAL',
                        dependsOn:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
                        enableValue: 'Yes',
                        conditionalChildren: [
                          {
                            primitive: 'TEXT',
                            cssClass: 'sub-question',
                            inputId:
                              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE,
                            nameOfFormControl:
                              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE,
                            labelText: 'Describe',
                          },
                        ],
                      },
                      {
                        primitive: 'RADIO',
                        cssClass: 'sub-question',
                        inputId:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
                        nameOfFormControl:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
                        labelText: 'Are there any prior recalls?',
                        options: yesNoOptions,
                      },
                      {
                        primitive: 'VALUE-CONDITIONAL',
                        dependsOn:
                          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
                        enableValue: 'Yes',
                        conditionalChildren: [
                          {
                            primitive: 'TEXT',
                            cssClass: 'sub-question',
                            inputId:
                              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE,
                            nameOfFormControl:
                              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE,
                            labelText: 'Description',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      ],
    },
};

const tangibleGoodWorkOverridesV4: { [characterName: string]: GlV4Overrides } = {
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK]: {
    primitive: 'DIV',
    children: [
      {
        primitive: 'RADIO',
        inputId: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK,
        nameOfFormControl:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK,
        labelText:
          'Does the business perform any design, construction, installation, removal, or physical repair of any property or tangible good?',
        options: yesNoOptions,
      },
    ],
    cobOverrideSets: [
      {
        cobSet: 'TANGIABLE_GOODS_WORK',
        overrides: {
          children: [
            {
              primitive: 'RADIO',
              inputId:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK,
              nameOfFormControl:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK,
              labelText:
                'Does the business perform any design, construction, installation, removal, or physical repair of any property or tangible good?',
              options: yesNoOptions,
            },
            {
              primitive: 'VALUE-CONDITIONAL',
              dependsOn:
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK,
              enableValue: 'Yes',
              conditionalChildren: [
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKDESCRIBE,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKDESCRIBE,
                  labelText:
                    'Please describe all services provided and the type of property/goods upon which work is performed',
                },
                {
                  primitive: 'RADIO',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKTRADESMEN,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKTRADESMEN,
                  labelText:
                    'Does the owner of the business perform any services as a general contractor or a tradesmen?',
                  options: yesNoOptions,
                },
                {
                  primitive: 'NUMBER',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT3,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT3,
                  labelText:
                    'What percentage of services are related to the design, construction, installation, removal, or physical repair of  property or a tangible good?',
                },
                {
                  primitive: 'TEXT',
                  cssClass: 'sub-question',
                  inputId:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE4,
                  nameOfFormControl:
                    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE4,
                  labelText: 'If applicable, what is the business website address?',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1]: {
    primitive: 'DIV',
    children: [
      {
        primitive: 'RADIO',
        inputId:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1,
        nameOfFormControl:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1,
        labelText:
          'Does the business perform any design, construction, installation, removal, or physical repair of any property or tangible good?',
        options: yesNoOptions,
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1,
        enableValue: 'Yes',
        conditionalChildren: [
          {
            primitive: 'RADIO',
            cssClass: 'sub-question',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKIT,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKIT,
            labelText:
              'Is this limited to the installation, removal, or repair of software, hardware, computer equipment and other technology peripherals?',
            options: yesNoOptions,
          },
        ],
      },
      {
        primitive: 'VALUE-CONDITIONAL',
        dependsOn:
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKIT,
        enableValue: 'No',
        conditionalChildren: [
          {
            primitive: 'TEXT',
            cssClass: 'sub-question',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKDESCRIBE1,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKDESCRIBE1,
            labelText:
              'Please describe all services provided and the type of property/good upon which work is performed:',
          },
          {
            primitive: 'TEXT',
            cssClass: 'sub-question',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKTRADESMEN1,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKTRADESMEN1,
            labelText:
              'Does the owner perform any services as a general contractor or a tradesmen?',
          },
          {
            primitive: 'TEXT',
            cssClass: 'sub-question',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY2,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY2,
            labelText: 'How long has the owner of the business been involved in this activity?',
          },
          {
            primitive: 'NUMBER',
            cssClass: 'sub-question',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT4,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT4,
            labelText:
              'What percentage of customers services are related to the design, construction, installation, removal, or physical repair of property or a tangible good?',
          },
          {
            primitive: 'TEXT',
            cssClass: 'sub-question',
            inputId:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE5,
            nameOfFormControl:
              HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE5,
            labelText: "If applicable, what is the business's website address?",
          },
        ],
      },
    ],
  },
};

const cobOverridesV4: { [characterName: string]: GlV4Overrides } = {
  // Note: Overriding due to INVALID xPath
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIESEXCLUDEDACTIVITIES]:
    {
      primitive: 'RADIO',
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES,
      nameOfFormControl:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES,
      labelText: 'The business does not conduct any of the following activities:',
      subLabelText:
        "This does not include the activities of the business's clients in any of these industries.",
      ul: {
        li: [
          'Automotive repair or sales',
          'Food service/restaurant operations',
          'Medical services',
          'Retail operations',
        ],
      },
      options: agreeDisagreeOptionsV4,
      required: true,
      cobOverrideSets: [
        {
          cobSet: 'MOBILE_FOOD',
          overrides: {
            ul: {
              li: ['Automotive repair or sales', 'Medical services'],
            },
          },
        },
        {
          cobSet: 'RETAIL',
          overrides: {
            ul: {
              li: [
                'Automotive repair or sales',
                'Food service/restaurant operations',
                'Medical services',
              ],
            },
          },
        },
      ],
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI]:
    {
      primitive: 'SELECT',
      inputId:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI,
      nameOfFormControl:
        HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI,
      labelText: 'Aggregate limit',
      options: {
        // Note: only up to 2 million is supported online, even though higher limits exist
        '600000': '$600,000',
        '1000000': '$1,000,000',
        '1500000': '$1,500,000',
        '2000000': '$2,000,000',
      },
      required: true,
      cobOverrideSets: [
        {
          cobSet: 'AGGREGATE_LIMIT_GROUP',
          overrides: {
            options: {
              '300000': '$300,000',
              '600000': '$600,000',
              '1000000': '$1,000,000',
              '1500000': '$1,500,000',
              '2000000': '$2,000,000',
            },
          },
        },
        {
          cobSet: 'LAND_SURVEYOR',
          overrides: {
            options: {
              '300000': '$300,000',
              '600000': '$600,000',
              '1000000': '$1,000,000',
            },
          },
        },
      ],
    },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI]: {
    primitive: 'SELECT',
    inputId:
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
    nameOfFormControl:
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
    labelText: 'Occurrence limit',
    required: true,
    options: {
      // Note: only up to 2 million is supported online, even though higher limits exist
      '300000': '$300,000',
      '500000': '$500,000',
      '1000000': '$1,000,000',
      '1500000': '$1,500,000',
      '2000000': '$2,000,000',
    },
    cobOverrideSets: [
      {
        cobSet: 'OCCURRENCE_LIMIT_GROUP_ALL_STATES',
        overrides: {
          options: {
            '300000': '$300,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        cobSet: 'OCCURRENCE_LIMIT_GROUP_CO_NJ_NY_PA',
        overrides: {
          options: {
            '300000': '$300,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
        states: ['CO', 'NJ', 'NY', 'PA'],
      },
    ],
  },
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE]:
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',
          inputId:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
          nameOfFormControl:
            HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
          labelText: 'Deductible',
          subLabelText: 'Selection required',
          options: {
            $0: '0',
          },
          required: true,
        },
      ],
      cobOverrideSets: [
        {
          cobSet: 'RATINGINFO_DEDUCTIBLE_EXCEPTIONS',
          overrides: {
            children: [
              {
                primitive: 'RADIO',
                inputId:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
                nameOfFormControl:
                  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
                labelText: 'Deductible',
                subLabelText: 'Selection required',
                options: {
                  '$1,000': '1000',
                },
                required: true,
              },
            ],
          },
        },
      ],
    },
};

// Counties in relevant states
const countiesByState: { [key: string]: string[] } = {
  FL: [
    'Broward county',
    'Duval county',
    'Hillsborough county',
    'Miami-Dade county',
    'Palm Beach county',
    'Pinellas county',
    'Remainder of state',
  ],
  TX: [
    'Bexar county',
    'Cameron county',
    'Dallas county',
    'El Paso county',
    'Galveston county',
    'Hays county',
    'Harris county (Houston)',
    'Harris county (other than Houston)',
    'Hidalgo county',
    'Jefferson county',
    'Nueces county',
    'Tarrant county',
    'Travis county',
    'Willacy county',
    'Remainder of state',
  ],
  MO: [
    'Jackson county (Kansas City)',
    'Jackson county (other than Kansas City)',
    'Clay county (Kansas City)',
    'Clay county (other than Kansas City)',
    'Cass county (Kansas City)',
    'Cass county (other than Kansas City)',
    'Platte county (Kansas City)',
    'Platte county (other than Kansas City)',
    'Saint Louis county',
    'Remainder of state',
  ],
};

// Retrieve the county node
export const getCountyNode = (state: string, version: HISCOX_API_VERSION): FormDslNode[] => {
  if (!(state in countiesByState)) {
    return [];
  }
  const countyOptions: string[] = countiesByState[state];
  const statesQuestionNode: FormDslNode = {
    primitive: 'SELECT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
    labelText: 'County',
    options: countyOptions,
    required: true,
  };
  return [statesQuestionNode];
};

// gl quote first step tree
export const glBasicTreeV4: FormDslNode[] = [
  {
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_COMMERCIALNAME,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_COMMERCIALNAME,
    primitive: 'TEXT',
    labelText: 'Business name',
    cssClass: 'dropdown-search hidden',
    value: 'Replaced in HiscoxGlQuotePageComponent',
    required: true,
  },
  {
    primitive: 'DATE',
    inputId: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE,
    nameOfFormControl:
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE,
    labelText: 'Policy effective date',
    errorText: 'Effective date must be between today and 60 days in the future',
    required: true,
  },
  {
    primitive: 'SELECT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_CLASSOFBUSINESSCD,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_CLASSOFBUSINESSCD,
    labelText: 'Class of business',
    options: sortDictionaryByValues(GL_BUSINESS_CLASSES_V4),
    required: true,
  },
  {
    primitive: 'SELECT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_BUSINESSOWNERSHIPSTRUCTURE,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_BUSINESSOWNERSHIPSTRUCTURE,
    labelText: 'Business ownership structure',
    options: {
      Corporation: 'Corporation',
      Estate: 'Estate',
      'Joint Venture': 'Joint Venture',
      'Limited Liability Company': 'Limited Liability Company',
      'Not for profit Corporation': 'Not for profit Corporation',
      Partnership: 'Partnership',
      'Professional Association': 'Professional Association',
      'Sole Proprietor': 'Sole Proprietor',
      Trust: 'Trust',
      Other: 'Other',
    },
    required: true,
  },
  {
    primitive: 'ADDRESS_AUTO_COMPLETE',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1,
    labelText: 'Street address',
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2,
    labelText: 'Street address 2',
  },
  {
    primitive: 'TEXT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY,
    labelText: 'City',
    required: true,
  },
  {
    primitive: 'SELECT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
    labelText: 'County',
    options: [], // Replaced in HiscoxGlQuotePageComponent
    required: true,
  },
  {
    primitive: 'SELECT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD,
    labelText: 'State',
    options: usStates,
    readonly: true,
    required: true,
  },
  {
    primitive: 'NUMBER',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE,
    tooltipText:
      'Hiscox validates addresses via USPS. This sometimes results in an error as Google may provide an incorrect zip code. To prevent this, try validating the address through the quick tools option on the USPS website.',
    labelText: 'Zip code',
    maxLength: '5',
    minLength: '5',
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_FIRSTNAME,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_FIRSTNAME,
    labelText: "Business owner's first name",
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_LASTNAME,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_LASTNAME,
    labelText: "Business owner's last name",
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR,
    nameOfFormControl:
      HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR,
    labelText: "Insured's email address",
    required: true,
  },
  {
    primitive: 'PHONE',
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER,
    nameOfFormControl:
      HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER,
    labelText: "Insured's phone number",
    maxLength: '12',
    minLength: '12',
    required: true,
  },
  {
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_HAVEDBAORFKA,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_HAVEDBAORFKA,
    labelText: 'Does the business have a DBA or FKA business name?',
    primitive: 'RADIO',
    inputType: 'text',
    options: {
      'DBA (Doing Business As)': 'DBA',
      'FKA (Formerly Known As)': 'FKA',
    },
    required: false,
  },
  {
    inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_DBAORFKANAME,
    nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_DBAORFKANAME,
    primitive: 'TEXT',
    inputType: 'text',
    labelText: 'DBA/FKA name:',
    required: false,
  },
];

// Find county node by state and decide whether to update or remove
export const fixCountyNode = (tree: FormDslNode[], state: string) => {
  const nodeIndex = calculateNodeIndex(
    tree,
    HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY
  );

  if (!(state in countiesByState)) {
    tree[nodeIndex] = {
      primitive: 'TEXT',
      inputId: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
      nameOfFormControl: HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
      labelText: 'County',
      required: true,
    };
    return;
  }
  const countyNode = tree[nodeIndex] as MultiInputNode;
  const countyNodeOptions = countiesByState[state];
  countyNode['options'] = countyNodeOptions;
};

export const fieldOverridesV4: Record<string, GlV4Overrides> = {
  ...simpleOverridesV4,
  ...coverageOverridesV4,
  ...conditionalOverridesV4,
  ...supplyManufacturingOverridesV4,
  ...tangibleGoodWorkOverridesV4,
  ...cobOverridesV4,
  ...agreeDisagreeV4,
};
