import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlphanumericDirective } from 'app/shared/directives/alphanumeric-mask.directive';
import { AmplitudeButtonDirective } from 'app/shared/directives/amplitude-button.directive';
import { ClickOutsideDirective } from 'app/shared/directives/click-outside.directive';
import { DelimitedNumberMaskDirective } from 'app/shared/directives/delimited-number-mask.directive';
import { FloatMaskDirective } from 'app/shared/directives/float-mask.directive';
import { FormatMaskDirective } from 'app/shared/directives/format-mask.directive';
import { MoneyMaskDirective } from 'app/shared/directives/money-mask.directive';
import { NumberMaskDirective } from 'app/shared/directives/number-mask.directive';
import { PhoneMaskDirective } from 'app/shared/directives/phone-mask.directive';
import { RemoveWhitespaceMaskDirective } from 'app/shared/directives/remove-whitespace-mask.directive';

import { FormFieldAddressAutocompleteComponent } from 'app/shared/form-dsl/components/form-field-address-autocomplete/form-field-address-autocomplete.component';
import { FormFieldAddressComponent } from 'app/shared/form-dsl/components/form-field-address/form-field-address.component';
import { AmplitudeTestComponent } from 'app/shared/spec_helpers/amplitude-test/amplitude-test.component';
import { FormFieldBrokerContactsComponent } from 'app/shared/form-dsl/components/form-field-broker-contacts/form-field-broker-contacts.component';
import { FormFieldInsuredContactsComponent } from 'app/shared/form-dsl/components/form-field-insured-contacts/form-field-insured-contacts.component';
import { ActivityRolloverBarChartComponent } from 'app/features/activity/components/activity-rollover-bar-chart/activity-rollover-bar-chart.component';
import { FormFieldCheckboxComponent } from 'app/shared/form-dsl/components/form-field-checkbox/form-field-checkbox.component';
import { FormFieldCheckboxGroupComponent } from 'app/shared/form-dsl/components/form-field-checkbox-group/form-field-checkbox-group.component';
import { FormFieldCheckboxGroupItemComponent } from 'app/shared/form-dsl/components/form-field-checkbox-group-item/form-field-checkbox-group-item.component';
import { CheckboxPillComponent } from 'app/shared/form/checkbox-pill.component';
import { DateFieldComponent } from 'app/shared/form/date-field.component';
import { FormFieldDropdownSearchComponent } from 'app/shared/form-dsl/components/form-field-dropdown-search/form-field-dropdown-search.component';
import { EducationPaneComponent } from 'app/shared/ui-components/education-pane.component';
import { KeysPipe } from 'app/shared/pipes/keys.pipe';
import { LossRunsUploadComponent } from 'app/shared/form/loss-runs-upload.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { UWAlertModalComponent } from 'app/shared/uw-alert-modal/uw-alert-modal.component';
import { NumberOfLocationsComponent } from 'app/shared/form/number-of-locations.component';
import { InvoicesPaymentFormComponent } from 'app/features/invoices/components/invoices-payment-form/invoices-payment-form.component';
import { PhonePipe } from 'app/shared/pipes/phone.pipe';
import { PolicySelectComponent } from 'app/shared/endorsements/policy-select.component';
import { CarrierDisplayNamePipe } from './pipes/carrier-display-name.pipe';
import { LobDisplayNamePipe } from './pipes/lob-display-name.pipe';
import { ProductCrossSellComponent } from './product-cross-sell/product-cross-sell.component';
import { QuoteErrorModalComponent } from 'app/shared/quote-error-modal/quote-error-modal.component';
import { RadioQuestionAltComponent } from 'app/shared/consumer/components/radio-question-alt.component';
import { FormFieldRadioComponent } from 'app/shared/form-dsl/components/form-field-radio/form-field-radio.component';
import { FormFieldRadioDslComponent } from 'app/shared/form-dsl/components/form-field-radio-dsl/form-field-radio-dsl.component';
import { FormFieldSelectComponent } from 'app/shared/form-dsl/components/form-field-select/form-field-select.component';
import { FormFieldSliderComponent } from 'app/shared/form-dsl/components/form-field-slider/form-field-slider.component';
import { SteppedFormProgressCircleComponent } from 'app/shared/stepped-form/stepped-form-progress-circle.component';
import { InvoicesPaymentInputComponent } from 'app/features/invoices/components/invoices-payment-input/invoices-payment-input.component';
import { ActivityRolloverStatesChartComponent } from 'app/features/activity/components/activity-rollover-states-chart/activity-rollover-states-chart.component';
import { FormFieldTextComponent } from 'app/shared/form-dsl/components/form-field-text/form-field-text.component';
import { FormFieldTextareaComponent } from 'app/shared/form-dsl/components/form-field-textarea/form-field-textarea.component';
import { FormFieldToggleSwitchComponent } from 'app/shared/form-dsl/components/form-field-toggle-switch/form-field-toggle-switch.component';
import { FormFieldRadioTraditionalComponent } from 'app/shared/form-dsl/components/form-field-radio-traditional/form-field-radio-traditional.component';
import { FormFieldFileComponent } from 'app/shared/form-dsl/components/form-field-file/form-field-file.component';

import { BindingAnimationComponent } from 'app/shared/games/binding-animation.component';
import { CheckboxQuotingProgressConsumerComponent } from 'app/shared/games/checkbox-quoting-progress-consumer.component';
import { CoinDropAnimationComponent } from './games/coin-drop-animation.component';
import { DateMaskDirective } from './directives/date-mask.directive';
import { DialogBoxComponent } from 'app/shared/ui-components/dialog-box.component';
import { FaqsListComponent } from 'app/shared/faqs-list/faqs-list.component';
import { FooterComponent } from 'app/core/components/footer/footer.component';
import { FormDslFormComponent } from 'app/shared/form-dsl/components/form-dsl-form/form-dsl-form.component';
import { HeaderComponent } from 'app/shared/header/header.component';
import { HelpCenterMarketingModalComponent } from 'app/shared/help-center-marketing-modal/help-center-marketing-modal.component';
import { FormFieldRadioImageComponent } from 'app/shared/form-dsl/components/form-field-radio-image/form-field-radio-image.component';
import { InformToastComponent } from 'app/shared/inform/inform-toast.component';
import { InlineLoadingStatusComponent } from 'app/shared/ui-components/inline-loading-status.component';
import { FormFieldLinkModalComponent } from 'app/shared/form-dsl/components/form-field-link-modal/form-field-link-modal.component';
import { LoadingBarComponent } from 'app/shared/ui-components/loading-bar.component';
import { LoadingSpinnerComponent } from 'app/shared/ui-components/loading-spinner.component';
import { LogoComponent } from './logo/logo.component';
import { LogoWithTextComponent } from './logo/logo-with-text.component';
import { PolicyStatusComponent } from 'app/shared/ui-components/policy-status.component';
import { StatusIndicatorComponent } from 'app/shared/ui-components/status-indicator.component';
import { PriceDifferenceModalComponent } from './price-difference-modal/price-difference-modal.component';
import { ProgressBarComponent } from 'app/shared/games/progress-bar.component';
import { QuotingGameComponent } from 'app/shared/games/quoting-game.component';
import { CatchingGameComponent } from 'app/shared/games/catching-game.component';
import { QuotingProgressComponent } from 'app/shared/games/quoting-progress.component';
import { QuotingProgressConsumerComponent } from 'app/shared/games/quoting-progress-consumer.component';
import { RewardsToastComponent } from 'app/shared/rewards/rewards-toast.component';
import { ShimmerAnimationComponent } from './games/shimmer-animation.component';
import { ToastsComponent } from 'app/shared/toasts/toasts.component';
import { BillingToastComponent } from 'app/shared/billing-toast/billing-toast.component';
import { SampleQuoteModalComponent } from 'app/shared/sample-quote-modal/sample-quote-modal.component';
import { FeinMaskDirective } from 'app/shared/directives/fein-mask.directive';
import { CarrierPartnerServicingInfoComponent } from './carrier-partner-servicing-info/carrier-partner-servicing-info.component';
import { ProductBadgeComponent } from './product-badge/product-badge.component';
import { QuoteFeedbackFormComponent } from 'app/shared/quote-feedback/quote-feedback-form.component';
import { CommissionPlanInformationModalComponent } from 'app/shared/commission-plan-information-modal/commission-plan-information-modal.component';
import { MultiPageModalComponent } from './multi-page-modal/multi-page-modal.component';
import { MultiPageModalPageDirective } from 'app/shared/directives/multi-page-modal-page.directive';
import { AdditionalDocumentsComponent } from './additional-documents/additional-documents.component';
import { PreferenceTagComponent } from 'app/shared/preference-tag/preference-tag.component';

// Here we declare and export all the components and pipes in app/shared.
// Others (services, guards, etc...) can be listed in more than one module.

// Components that requires routing should not be listed in the SharedModule.
// Instead make a separate module like LoginModule or import directly
@NgModule({
  declarations: [
    FormFieldAddressAutocompleteComponent,
    FormFieldAddressComponent,
    AlphanumericDirective,
    AmplitudeButtonDirective,
    AmplitudeTestComponent,
    ActivityRolloverBarChartComponent,
    BillingToastComponent,
    BindingAnimationComponent,
    FormFieldBrokerContactsComponent,
    FormFieldInsuredContactsComponent,
    FormFieldInsuredContactsComponent,
    CarrierPartnerServicingInfoComponent,
    CatchingGameComponent,
    FormFieldCheckboxComponent,
    FormFieldCheckboxGroupComponent,
    FormFieldCheckboxGroupItemComponent,
    CheckboxPillComponent,
    CheckboxQuotingProgressConsumerComponent,
    ClickOutsideDirective,
    CoinDropAnimationComponent,
    DateFieldComponent,
    DateMaskDirective,
    DelimitedNumberMaskDirective,
    DialogBoxComponent,
    FormFieldDropdownSearchComponent,
    EducationPaneComponent,
    FaqsListComponent,
    FeinMaskDirective,
    FormFieldFileComponent,
    FloatMaskDirective,
    FooterComponent,
    FormatMaskDirective,
    FormDslFormComponent,
    HeaderComponent,
    HelpCenterMarketingModalComponent,
    FormFieldRadioImageComponent,
    InformToastComponent,
    InlineLoadingStatusComponent,
    KeysPipe,
    CarrierDisplayNamePipe,
    LobDisplayNamePipe,
    FormFieldLinkModalComponent,
    LoadingBarComponent,
    LoadingSpinnerComponent,
    LogoComponent,
    LogoWithTextComponent,
    LossRunsUploadComponent,
    ModalComponent,
    MoneyMaskDirective,
    NumberMaskDirective,
    NumberOfLocationsComponent,
    InvoicesPaymentFormComponent,
    PhoneMaskDirective,
    PhonePipe,
    PolicySelectComponent,
    PolicyStatusComponent,
    PriceDifferenceModalComponent,
    ProductCrossSellComponent,
    ProgressBarComponent,
    QuoteErrorModalComponent,
    QuoteFeedbackFormComponent,
    QuotingGameComponent,
    QuotingProgressComponent,
    QuotingProgressConsumerComponent,
    RadioQuestionAltComponent,
    FormFieldRadioComponent,
    FormFieldRadioDslComponent,
    RemoveWhitespaceMaskDirective,
    RewardsToastComponent,
    SampleQuoteModalComponent,
    FormFieldSelectComponent,
    ShimmerAnimationComponent,
    FormFieldSliderComponent,
    ActivityRolloverStatesChartComponent,
    StatusIndicatorComponent,
    SteppedFormProgressCircleComponent,
    InvoicesPaymentInputComponent,
    FormFieldTextareaComponent,
    FormFieldTextComponent,
    ToastsComponent,
    FormFieldToggleSwitchComponent,
    FormFieldRadioTraditionalComponent,
    UWAlertModalComponent,
    ProductBadgeComponent,
    CommissionPlanInformationModalComponent,
    MultiPageModalComponent,
    MultiPageModalPageDirective,
    AdditionalDocumentsComponent,
    PreferenceTagComponent,
  ],
  exports: [
    FormFieldAddressAutocompleteComponent,
    FormFieldAddressComponent,
    AlphanumericDirective,
    AmplitudeButtonDirective,
    ActivityRolloverBarChartComponent,
    BillingToastComponent,
    BindingAnimationComponent,
    FormFieldBrokerContactsComponent,
    FormFieldInsuredContactsComponent,
    CarrierPartnerServicingInfoComponent,
    CatchingGameComponent,
    FormFieldCheckboxComponent,
    FormFieldCheckboxGroupComponent,
    FormFieldCheckboxGroupItemComponent,
    CheckboxPillComponent,
    CheckboxQuotingProgressConsumerComponent,
    ClickOutsideDirective,
    CoinDropAnimationComponent,
    DateFieldComponent,
    DateMaskDirective,
    DelimitedNumberMaskDirective,
    DialogBoxComponent,
    FormFieldDropdownSearchComponent,
    EducationPaneComponent,
    FaqsListComponent,
    FeinMaskDirective,
    FormFieldFileComponent,
    FloatMaskDirective,
    FooterComponent,
    FormatMaskDirective,
    FormDslFormComponent,
    HeaderComponent,
    HelpCenterMarketingModalComponent,
    FormFieldRadioImageComponent,
    InformToastComponent,
    InlineLoadingStatusComponent,
    KeysPipe,
    FormFieldLinkModalComponent,
    LoadingBarComponent,
    LoadingSpinnerComponent,
    LogoComponent,
    LogoWithTextComponent,
    LossRunsUploadComponent,
    LossRunsUploadComponent,
    ModalComponent,
    MoneyMaskDirective,
    NumberMaskDirective,
    NumberOfLocationsComponent,
    InvoicesPaymentFormComponent,
    PhoneMaskDirective,
    PhonePipe,
    PolicySelectComponent,
    PolicyStatusComponent,
    PriceDifferenceModalComponent,
    ProductBadgeComponent,
    ProductCrossSellComponent,
    ProgressBarComponent,
    QuoteErrorModalComponent,
    QuoteFeedbackFormComponent,
    QuotingGameComponent,
    QuotingProgressComponent,
    QuotingProgressConsumerComponent,
    RadioQuestionAltComponent,
    FormFieldRadioComponent,
    FormFieldRadioDslComponent,
    RemoveWhitespaceMaskDirective,
    RewardsToastComponent,
    SampleQuoteModalComponent,
    FormFieldSelectComponent,
    ShimmerAnimationComponent,
    FormFieldSliderComponent,
    ActivityRolloverStatesChartComponent,
    StatusIndicatorComponent,
    SteppedFormProgressCircleComponent,
    InvoicesPaymentInputComponent,
    FormFieldTextareaComponent,
    FormFieldTextComponent,
    ToastsComponent,
    FormFieldToggleSwitchComponent,
    FormFieldRadioTraditionalComponent,
    UWAlertModalComponent,
    CommissionPlanInformationModalComponent,
    MultiPageModalComponent,
    MultiPageModalPageDirective,
    AdditionalDocumentsComponent,
    LobDisplayNamePipe,
    CarrierDisplayNamePipe,
    PreferenceTagComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
})
export class SharedModule {}
