import * as _ from 'lodash';

export enum QUOTE_API_ERROR_STATUS_CODES {
  VALIDATION = 422,
}

/**
 * This data structure was generated via a script in the `dcp-helper-scripts` and
 * uses the content of `Eligible Classes_NAICs_Ind-Codes v1.2` at
 * https://docs.google.com/spreadsheets/d/165HRtyANa9a6OQaoSNCH_6lggBPsSec2/edit#gid=908633085
 * to determine which COBs are available for each Hiscox product (GL/PL)
 */
// TODO: Overlaps with PL, should consider merging
export enum HISCOX_BUSINESS_CLASS_CODES_V4 {
  ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62139900_29129100_01000000.621399',
  ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS = '62139900_29129100_02000000.621111',
  ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS = '62139900_29129100_02000000.621399',
  ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62134000_29112500_01000000.621340',
  AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS = '62134000_29118100_00000000.621340',
  BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS = '81211100_39501100_00000000.812111',
  BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS = '81211100_39501100_00000000.812112',
  BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES = '81211200_39501200_00000000.812112',
  DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62134000_29112500_02000000.621340',
  DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS = '81219100_29103100_00000000.621399',
  DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL = '81219100_29103100_00000000.812191',
  DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62134000_29112500_03000000.621340',
  ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES = '81211200_39509400_00000000.812112',
  FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION = '61169900_21109100_00000000.611699',
  HOME_HEALTH_AIDE_ELDERLY_MEDICAL_INFUSION_THERAPY_HOSPICE_CARE_AGENCIES_SERVICES = '62161000_31112100_01000000.621610',
  HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62133000_19303900_01000000.621399',
  MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS = '62419000_21101300_00000000.624190',
  MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62139900_31901100_00000000.621399',
  MENTAL_HEALTH_COUNSELING_CLINICAL_PSYCHOANALYSTS_PSYCHOTHERAPISTS_SOCIAL_WORKER = '62133000_21101400_00000000.621330',
  MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62134000_29112500_04000000.621340',
  NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS = '81211300_39509200_00000000.812113',
  OCCUPATIONAL_THERAPY_EG_CENTERS_CLINICS = '62134000_29112200_00000000.621340',
  PERSONAL_CARE_AIDE_ELDERLY_MEDICAL_INFUSION_THERAPY_AGENCIES_HOSPICE_CARE_SERVICES = '62161000_31112100_02000000.621610',
  PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES = '61169900_29909100_01000000.812990',
  PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS = '62133000_19303900_02000000.621330',
  SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS = '62419000_21102900_00000000.624110',
  SOCIAL_WORK_SERVICES_NON_MEDICAL_ELDER_DISABLED_HOME_CARE_HOMEMAKER_SERVICES = '62419000_21102900_00000000.624120',
  SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_FAMILY_WALFARE_HOTLINE_SELF_HELP_SUPPORT_COUNSELING = '62419000_21102900_00000000.624190',
  SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS = '62134000_29112700_00000000.621340',
  SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS = '62419000_21101100_00000000.624190',
  YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS = '61169900_39903100_00000000.611699',
  ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES = '54131000_17101100_00000000.541310',
  BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS = '54135000_47401100_00000000.541350',
  CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES = '54133000_17205100_00000000.541330',
  CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER = '54151200_51916200_00000000.541512',
  DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES = '54134000_17301900_01000000.541340',
  ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES = '54133000_17207100_00000000.541330',
  ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES = '54133000_17219900_01000000.541330',
  ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES = '54133000_17208100_00000000.541330',
  INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES = '54133000_17211200_00000000.541330',
  INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR = '54141000_27102500_02000100.541410',
  LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_TOPOGRAPHIC_SURVEYING_EXCEPT_GEOPHYSICAL_SERVICES = '54137000_17102200_00000000.541370',
  LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE = '54132000_17101200_00000000.541320',
  PROCESS_ENGINEERING_ENGINEERS_SERVICES = '54133000_13111100_00000000.541330',
  PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES = '54133000_11902100_01000000.541330',
  TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES = '54133000_17219900_02000000.541330',
  TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES = '54133000_17219900_02000000.541614',
  BUSINESS_CONSULTING_BUSINESS_STARTUP_REORGANIZATIONAL_CONSULTING_SERVICES = '54161100_13111100_01000000.541611',
  EDUCATION_CONSULTING_EDUCATIONAL_CONSULTANTS = '54161100_13111100_02000000.611710',
  HUMAN_RESOURCES_HR_CONSULTING_HUMAN_RESOURCE_CONSULTING_SERVICES = '54161200_11312100_00000000.541612',
  MANAGEMENT_CONSULTING_GENERAL_MANAGEMENT_CONSULTING_SERVICES = '54161100_13111100_04000000.541611',
  MANAGEMENT_CONSULTING_COMPENSATION_PLANNING_SERVICES = '54161100_13111100_04000000.541612',
  MANAGEMENT_CONSULTING_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES = '54161100_13111100_04000000.541613',
  MANAGEMENT_CONSULTING_FREIGHT_RATE_AUDITOR_TRAFFIC_INFORMATION_CONSULTING_SERVICES = '54161100_13111100_04000000.541614',
  MANAGEMENT_CONSULTING_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES = '54161100_13111100_04000000.541618',
  OTHER_CONSULTING_SERVICES_INVESTMENT_ADVICE_CONSULTING_SERVICES_CUSTOMIZED_FEES_PAID_BY_CLIENT = '54161100_13111100_05000000.523930',
  OTHER_CONSULTING_SERVICES_JURY_CONSULTING_SERVICES = '54161100_13111100_05000000.541199',
  OTHER_CONSULTING_SERVICES_LANDSCAPE_CONSULTING_SERVICES = '54161100_13111100_05000000.541320',
  OTHER_CONSULTING_SERVICES_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES = '54161100_13111100_05000000.541330',
  OTHER_CONSULTING_SERVICES_DECORATING_CONSULTING_SERVICES_INTERIOR = '54161100_13111100_05000000.541410',
  OTHER_CONSULTING_SERVICES_INDUSTRIAL_DESIGN_CONSULTING_SERVICES = '54161100_13111100_05000000.541420',
  OTHER_CONSULTING_SERVICES_MEDICAL_OFFICE_MANAGEMENT_RECORDS_SITE_LOCATION_SELECTION_CONSULTING_SERVICES = '54161100_13111100_05000000.541611',
  OTHER_CONSULTING_SERVICES_ACTUARIAL_CONSULTING_SERVICES_EXCEPT_INSURANCE_ACTUARIAL_SERVICES = '54161100_13111100_05000000.541612',
  OTHER_CONSULTING_SERVICES_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES = '54161100_13111100_05000000.541613',
  OTHER_CONSULTING_SERVICES_CUSTOMS_CONSULTING_SERVICES = '54161100_13111100_05000000.541614',
  OTHER_CONSULTING_SERVICES_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES = '54161100_13111100_05000000.541618',
  OTHER_CONSULTING_SERVICES_SANITATION_CONSULTING_SERVICES = '54161100_13111100_05000000.541620',
  OTHER_CONSULTING_SERVICES_BUILDING_DEVELOPMENT_ECONOMIC_ENTOMOLOGY_HORTICULTURAL_MOTION_PICTURE_RADIO = '54161100_13111100_05000000.541690',
  OTHER_CONSULTING_SERVICES_ADVERTISING_AGENCY_CONSULTING_SERVICES = '54161100_13111100_05000000.541810',
  OTHER_CONSULTING_SERVICES_POLITICAL_CONSULTING_SERVICES = '54161100_13111100_05000000.541820',
  OTHER_CONSULTING_SERVICES_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES = '54161100_13111100_05000000.561312',
  OTHER_CONSULTING_SERVICES_OUTPLACEMENT_CONSULTING_SERVICES = '54161100_13111100_05000000.561320',
  OTHER_CONSULTING_SERVICES_EDUCATIONAL_CONSULTANTS = '54161100_13111100_05000000.611710',
  PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS = '54161800_13108200_00000000.561110',
  RESEARCH_CONSULTING_NANOBIOTECHNOLOGIES_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_LABORATORIES = '54169000_13111100_00000000.541713',
  RESEARCH_CONSULTING_BOTANY_ENTOMOLOGY_ENVIRONMENTAL_PHYSICAL_SCIENCE = '54169000_13111100_00000000.541714',
  RESEARCH_CONSULTING_BOTANY_COMPUTER_ENGINEERING_FOOD_LIFE_INDUSTRIAL_LABORATORIES_OR_SERVICES = '54169000_13111100_00000000.541715',
  RESEARCH_CONSULTING_ARCHEOLOGICAL_RESEARCH_AND_DEVELOPMENT_SERVICES = '54169000_13111100_00000000.541720',
  RESUME_CONSULTING_RESUME_WRITING_SERVICES = '54161100_13111100_06000000.561410',
  SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES = '54169000_11912100_02000000.541690',
  STRATEGY_CONSULTANT_STRATEGIC_PLANNING_CONSULTING_SERVICES = '54161100_13111100_07000000.541611',
  GRAPHIC_DESIGN_ART_SERVICES_GRAPHIC = '54143000_27102400_00000000.541430',
  PHOTOGRAPHY_PASSPORT_PHOTOGRAPHY_SERVICES = '54192100_27402100_01000000.541921',
  PHOTOGRAPHY_COMMERCIAL_PHOTOGRAPHY_SERVICES = '54192100_27402100_01000000.541922',
  PHOTOGRAPHY_CAMERAMEN_INDEPENDENT_FREELANCE = '54192100_27402100_01000000.711510',
  ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC = '54121100_13201100_01000000.541211',
  ACCOUNTING_PAYROLL_PROCESSING_SERVICES = '54121100_13201100_01000000.541214',
  ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES = '54121100_13201100_01000000.541219',
  ACTUARIAL_SERVICES_ACTUARIAL_SERVICES_INSURANCE = '52429800_15201100_00000000.524298',
  BOOKKEEPING_BOOKKEEPERS_OFFICES = '54121900_43303100_00000000.541219',
  CREDIT_COUNSELING_CONSUMER_CREDIT_COUNSELING_SERVICES = '54199000_13207100_00000000.541990',
  FINANCIAL_AUDITING_OR_CONSULTING_AUDITING_ACCOUNTANTS_IE_CPAS_OFFICES = '54169000_13206100_00000000.541211',
  INSURANCE_AGENT_AGENCIES_INSURANCE = '52421000_41302100_00000000.524210',
  INVESTMENT_ADVICE_CERTIFIED_FINANCIAL_PLANNERS_CUSTOMIZED_FEES_PAID_BY_CLIENT = '52393000_13205200_02000000.523930',
  MEDICAL_BILLING_MEDICAL_CODING_ACCOUNTING_SERVICES_EXCEPT_CPA_SERVICES = '54121900_43302100_00000000.541219',
  MORTGAGE_BROKERINGBANKING_REVERSE_MORTGAGE_LENDING = '52231000_13207200_00000000.522292',
  MORTGAGE_BROKERINGBANKING_BROKERAGES_MORTGAGE = '52231000_13207200_00000000.522310',
  STOCK_BROKERING_BOND_SECURITIES_STOCK_BROKERAGES_OR_OFFICES = '52312000_41303100_00000000.523120',
  TAX_PREPARATION_INCOME_TAX_COMPILATION_SERVICES = '54121300_13208200_00000000.541213',
  EXTERIOR_CLEANING_SERVICES_SANDBLASTING_BUILDING_EXTERIOR = '56179000_37201900_02000200.238990',
  EXTERIOR_CLEANING_SERVICES_BUILDING_EXTERIOR_CLEANING_SERVICES_EXCEPT_SANDBLASTING_WINDOW_CLEANING = '56179000_37201900_02000200.561790',
  JANITORIALCLEANING_SERVICES_BUILDING_CLEANING_SERVICES_JANITORIAL = '56172000_37201100_01000000.561720',
  LANDSCAPINGGARDENING_SERVICES_GARDEN_MAINTENANCE_SERVICES = '56173000_37301100_01000000.561730',
  LAWN_CARE_SERVICES_CEMETERY_PLOT_CARE_SERVICES = '56173000_37301100_02000000.561730',
  SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_REMOVAL_HIGHWAY = '56179000_37301100_01000000.488490',
  SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_SERVICES_COMBINED_WITH_LANDSCAPING_SERVICES_IE_SEASONAL_PROPERTY_MAINTENANCE_SERVICES = '56179000_37301100_01000000.561730',
  SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_DRIVEWAYS_AND_PARKING_LOTS_IE_NOT_COMBINED_WITH_ANY_OTHER_SERVICE = '56179000_37301100_01000000.561790',
  CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL_CONSULTANTS = '52429100_13103100_00000000.524291',
  COURT_REPORTING_COURT_REPORTING_SERVICES = '56149200_27309200_00000000.561492',
  DOCUMENT_PREPARATION_DESKTOP_PUBLISHING_SERVICES_IE_DOCUMENT_PREPARATION_SERVICES = '56141000_23101200_00000000.561410',
  EXPERT_WITNESS_SERVICES_JURY_CONSULTING_SERVICES = '54119900_19409200_00000000.541199',
  INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE = '52429800_13103100_02000000.524291',
  INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES = '52429800_13103100_02000000.541990',
  LEGAL_SERVICES_ATTORNEYS_OFFICES = '54111000_23101100_03000000.541110',
  LEGAL_SERVICES_OFFICES_OF_NOTARIES = '54111000_23101100_03000000.541120',
  LEGAL_SERVICES_SETTLEMENT_OFFICES_REAL_ESTATE = '54111000_23101100_03000000.541191',
  LEGAL_SERVICES_JURY_CONSULTING_SERVICES = '54111000_23101100_03000000.541199',
  NOTARY_SERVICES_NOTARY_PUBLIC_SERVICES = '54112000_23209900_00000000.541199',
  PROCESS_SERVER_PROCESS_SERVER_SERVICES = '54119900_23209900_00000000.541199',
  TRUSTEE_SERVICES_ADMINISTRATORS_OF_PRIVATE_ESTATES = '52399100_23209900_00000000.523991',
  ADVERTISING_ADVERTISING_AGENCIES = '54181000_41301100_00000000.541810',
  BRAND_CONSULTANT_MARKETING_CONSULTING_SERVICES = '54161300_13116100_01000000.541613',
  BRAND_CONSULTANT_MARKETING_ANALYSIS_SERVICES = '54161300_13116100_01000000.541910',
  DIGITAL_MARKETING_MARKETING_MANAGEMENT_CONSULTING_SERVICES = '54161300_13116100_02000000.541613',
  DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING = '54186000_13116100_00000000.454310',
  DIRECT_MARKETING_CANVASSERS_HEADQUARTERS_HOME_DELIVERY_DIRECT_SELLING = '54186000_13116100_00000000.454390',
  DIRECT_MARKETING_ADVERTISING_MATERIAL_PREPARATION_SERVICES_FOR_MAILING_OR_OTHER_DIRECT_DISTRIBUTION = '54186000_13116100_00000000.541860',
  MARKET_RESEARCH_MARKETING_ANALYSIS_SERVICES = '54191000_13116100_00000000.541910',
  MARKETINGMEDIA_CONSULTING_MARKETING_CONSULTING_SERVICES = '54161300_13116100_03000000.541613',
  OTHER_MARKETINGPR_SERVICES_MARKETING_CONSULTING_SERVICES = '54161300_13116100_04000000.541613',
  OTHER_MARKETINGPR_SERVICES_PUBLIC_RELATIONS_CONSULTING_SERVICES = '54161300_13116100_04000000.541820',
  PUBLIC_RELATIONS_PUBLIC_RELATIONS_AGENCIES = '54182000_27303100_03000000.541820',
  SEARCH_ENGINE_SERVICES_SEOSEM_MARKETING_ANALYSIS_SERVICES = '54161400_13116100_00000000.541910',
  SOCIAL_MEDIA_CONSULTANT_BUSINESS_MANAGEMENT_CONSULTING_SERVICES = '54161300_13116100_05000000.541611',
  SOCIAL_MEDIA_CONSULTANT_MARKETING_MANAGEMENT_CONSULTING_SERVICES = '54161300_13116100_05000000.541613',
  SOCIAL_MEDIA_CONSULTANT_PUBLIC_RELATIONS_CONSULTING_SERVICES = '54161300_13116100_05000000.541820',
  ANSWERINGPAGING_SERVICES_ANSWERING_SERVICES_TELEPHONE = '56142100_43201100_00000000.561421',
  AUCTIONEERING_AUCTIONEERS_INDEPENDENT = '45439000_41909900_00000000.561990',
  BUSINESS_MANAGER_SERVICES_ADMINISTRATIVE_MANAGEMENT_CONSULTING_SERVICES = '56149900_13101100_00000000.541611',
  EVENT_PLANNINGPROMOTION_PARTY_PLANNING_SERVICES = '81299000_13112100_00000000.812990',
  EXECUTIVE_PLACEMENT_EXECUTIVE_PLACEMENT_SERVICES = '56131200_13107100_00000000.561312',
  LIFECAREER_EXECUTIVE_COACHING_MANAGEMENT_DEVELOPMENT_TRAINING = '54161100_11101100_00000000.611430',
  LIFECAREER_EXECUTIVE_COACHING_CAREER_AND_VOCATIONAL_COUNSELING_SERVICES_EXCEPT_REHABILITATIVE = '54161100_11101100_00000000.611710',
  LIFECAREER_EXECUTIVE_COACHING_LIFE_COACH_SERVICES = '54161100_11101100_00000000.812990',
  MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE = '42512000_41401200_00000000.425120',
  PERSONAL_CONCIERGEASSISTANT_CONCIERGE_SERVICES = '81299000_39601200_00000000.812990',
  RECRUITING_EMPLOYMENT_PLACEMENTS_EMPLOYMENT_AGENCIES = '56131100_13107100_00000000.561311',
  RECRUITING_EMPLOYMENT_PLACEMENTS_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES = '56131100_13107100_00000000.561312',
  TALENT_AGENCY_AGENTS_TALENT = '71141000_13101100_00000000.711410',
  TRAINING_BUSINESS_VOCATIONAL_OR_LIFE_SKILLS_HABILITATION_JOB_COUNSELING_AND_TRAINING_VOCATIONAL = '61169900_13115100_01000000.624310',
  TRANSLATINGINTERPRETING_INTERPRETATION_SERVICES_LANGUAGE = '54193000_27309100_00000000.541930',
  TRAVEL_AGENCY_TRAVEL_AGENCIES = '56151000_41304100_00000000.561510',
  TUTORING_ACADEMIC_TUTORING_SERVICES = '61169100_25309900_00000000.611691',
  BARBECUE_TRUCKTRAILER_OPERATOR_LUNCH_WAGONS = '72233000_11101100_01000000.722330',
  COFFEEDONUT_CART_OPERATOR_COFFEE_CARTS_MOBILE = '72233000_11101100_02000000.722330',
  FOOD_TRUCKTRAILER_OPERATOR_LUNCH_WAGONS = '72233000_11101100_03000000.722330',
  FOODBEVERAGE_CART_OPERATOR_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE = '72233000_11101100_04000000.722330',
  HOT_DOG_CART_OPERATOR_CANTEENS_MOBILE = '72233000_11101100_05000000.722330',
  ICE_CREAM_TRUCK_OPERATOR_ICE_CREAM_TRUCK_VENDORS = '72233000_11101100_06000000.722330',
  ICE_CREAM_SNOW_CONE_SHAVED_ICE_CART_OPERATOR_CONCESSION_SNACK_STANDS_MOBILE = '72233000_11101100_07000000.722330',
  LEMONADE_CART_OPERATOR_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE = '72233000_11101100_08000000.722330',
  MOBILE_FOOD_CONCESSIONS_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE = '72233000_11101100_09000000.722330',
  MOBILE_FOOD_SERVICES_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE = '72233000_11101100_10000000.722330',
  MOBILE_FOOD_VENDING_SERVICES_CANTEENS_MOBILE = '72233000_11101100_11000000.722330',
  MOBILE_KITCHEN_OPERATOR_LUNCH_WAGONS = '72233000_11101100_12000000.722330',
  MOBILE_STREET_FOOD_VENDOR_STREET_VENDORS_FOOD = '72233000_11101100_13000000.722330',
  SNACK_TRUCK_OPERATOR_CONCESSION_SNACK_STANDS_MOBILE = '72233000_11101100_14000000.722330',
  PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_REAL_STATE_MANAGERS_OFFICES = '53131100_11914100_00000000.531311',
  PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING = '53131100_11914100_00000000.531312',
  REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE = '53121000_41902100_00000000.531210',
  APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE = '44314100_11101100_00000001.443141',
  APPLIANCEELECTRONIC_STORES_AUDIO_EQUIPMENT_STORES_EXCEPT_AUTOMOTIVE = '44314100_11101100_00000001.443142',
  APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE_USED = '44314100_11101100_00000001.453310',
  CLOTHINGAPPAREL_STORES_APPAREL_STORES_MENS_AND_BOYS_CLOTHING = '44819000_11101100_00000002.448110',
  CLOTHINGAPPAREL_STORES_APPAREL_STORES_WOMENS_AND_GIRLS_CLOTHING = '44819000_11101100_00000002.448120',
  CLOTHINGAPPAREL_STORES_APPAREL_STORES_CHILDRENS_AND_INFANTS_CLOTHING = '44819000_11101100_00000002.448130',
  CLOTHINGAPPAREL_STORES_CLOTHING_STORES_FAMILY = '44819000_11101100_00000002.448140',
  CLOTHINGAPPAREL_STORES_NECKWEAR_STORES = '44819000_11101100_00000002.448150',
  CLOTHINGAPPAREL_STORES_BRIDAL_GOWN_SHOPS_EXCEPT_CUSTOM = '44819000_11101100_00000002.448190',
  FLORISTS_FLORISTS = '45311000_27102300_00000000.453110',
  HOME_FURNISHING_STORES_CARPET_STORES = '44229900_11101100_00000007.442210',
  HOME_FURNISHING_STORES_CURTAIN_AND_DRAPERY_STORES_PACKAGED = '44229900_11101100_00000007.442291',
  HOME_FURNISHING_STORES_BATH_SHOPS = '44229900_11101100_00000007.442299',
  JEWELRY_STORES_COSTUME_JEWELRY_STORES = '44831000_11101100_00000002.448150',
  JEWELRY_STORES_CLOCK_SHOPS = '44831000_11101100_00000002.448310',
  OTHER_STORES_WITH_FOODDRINKS_DELICATESSENS_PRIMARILY_RETAILING_A_RANGE_OF_GROCERY_ITEMS_AND_MEATS = '45399800_11101100_00000006.445110',
  OTHER_STORES_WITH_FOODDRINKS_CONVENIENCE_FOOD_STORES = '45399800_11101100_00000006.445120',
  OTHER_STORES_WITH_FOODDRINKS_LIQUOR_STORES_PACKAGE = '45399800_11101100_00000006.445310',
  OTHER_STORES_WITH_FOODDRINKS_SUPERSTORES_IE_FOOD_AND_GENERAL_MERCHANDISE = '45399800_11101100_00000006.452311',
  OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_NEW_ONLY_OR_NEW_AND_USED = '45399800_11101100_00000007.441110',
  OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_USED_ONLY = '45399800_11101100_00000007.441120',
  OTHER_STORES_WITHOUT_FOODDRINKS_CAMPER_DEALERS_RECREATIONAL = '45399800_11101100_00000007.441210',
  OTHER_STORES_WITHOUT_FOODDRINKS_BOAT_DEALERS_NEW_AND_USED = '45399800_11101100_00000007.441222',
  OTHER_STORES_WITHOUT_FOODDRINKS_AIRCRAFT_DEALERS = '45399800_11101100_00000007.441228',
  OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOTIVE_TIRE_DEALERS = '45399800_11101100_00000007.441320',
  OTHER_STORES_WITHOUT_FOODDRINKS_FURNITURE_AND_APPLIANCE_STORES_IE_PRIMARILY_RETAILING_FURNITURE = '45399800_11101100_00000007.442110',
  OTHER_STORES_WITHOUT_FOODDRINKS_CELLULAR_TELEPHONE_ACCESSORIES_STORES = '45399800_11101100_00000007.443142',
  OTHER_STORES_WITHOUT_FOODDRINKS_CABINET_STORES_KITCHEN_EXCEPT_CUSTOM_TO_BE_INSTALLED = '45399800_11101100_00000007.444190',
  OTHER_STORES_WITHOUT_FOODDRINKS_CONVENIENCE_FOOD_WITH_GASOLINE_STATIONS = '45399800_11101100_00000007.447110',
  OTHER_STORES_WITHOUT_FOODDRINKS_GASOLINE_STATIONS_WITHOUT_CONVENIENCE_STORES = '45399800_11101100_00000007.447190',
  OTHER_STORES_WITHOUT_FOODDRINKS_HANDBAG_STORES = '45399800_11101100_00000007.448150',
  AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION = '23822000_49902100_01000000.238220',
  APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE = '81141200_49909900_00000000.443141',
  APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES = '81141200_49909900_00000000.811412',
  CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE = '23835000_47203100_01000100.238350',
  CARPET_RUG_FURNITURE_OR_UPHOLSTERY_CLEANING_CUSTOMERS_PREMISES_ONLY_CARPET_CLEANING_ON_CUSTOMERS_PREMISES = '56174000_51601100_00010000.561740',
  CLOCK_MAKING_AND_REPAIR_CLOCKS_ASSEMBLING = '81149000_49906400_00000000.334519',
  CLOCK_MAKING_AND_REPAIR_CLOCK_REPAIR_SHOPS_WITHOUT_RETAILING_NEW_CLOCKS = '81149000_49906400_00000000.811490',
  DOOR_OR_WINDOW_INSTALLATION_AND_REPAIR_ALUMINUM_DOOR_AND_WINDOW_RESIDENTIALTYPE_INSTALLATION = '23835000_47203100_02000300.238350',
  DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY = '23899000_47409100_00000000.238990',
  DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS = '23831000_47208100_01000000.238310',
  ELECTRICAL_WORK_INTERIOR_ONLY_ELECTRIC_CONTRACTING = '23821000_47211100_01000100.238210',
  FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION = '23899000_47403100_00000000.238990',
  FLOOR_COVERING_INSTALLATION_NO_CERAMIC_TILESTONE_FLOOR_LAYING_TILE_HARDWOOD_VINYL = '23833000_47204200_01000000.238330',
  GLASS_INSTALLATION_AND_REPAIR_NO_AUTO_WORK_DECORATIVE_GLASS_MIRROR_GLAZING_WINDOW_PANE_SHEET = '23815000_47212100_01000000.238150',
  HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING = '23839000_49907100_01000000.236118',
  HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING = '23839000_49907100_01000000.236220',
  HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING = '23822000_49902100_02000000.238220',
  HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS = '23822000_49902100_02000000.423730',
  INTERIOR_FINISHING_WORK_CONCRETE_FINISHING = '23835000_47203100_03000100.238110',
  INTERIOR_FINISHING_WORK_ACOUSTICAL_CEILING_TILE_AND_PANEL_INSTALLATION = '23835000_47203100_03000100.238310',
  INTERIOR_FINISHING_WORK_ASPHALT_WOOD_FLOORING = '23835000_47203100_03000100.238330',
  INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION = '23835000_47203100_03000100.238340',
  INTERIOR_FINISHING_WORK_MOLDING_TRIM_WOOD_PLASTIC_PREFABRICATED_WINDOW_INSTALLATION = '23835000_47203100_03000100.238350',
  LOCKSMITHS_LOCK_REKEYING_SERVICES = '56162200_49909400_00000000.561622',
  MASONRY_WORK_BRICK_VENEER_INSTALLATION = '23814000_47205100_00000000.238140',
  PAINTING_INTERIOR_ONLY_PAINTING_EXCEPT_ROOF_CONTRACTORS = '23832000_47214100_00000100.238320',
  PLASTERING_OR_STUCCO_STUCCO_CONTRACTORS = '23831000_47208100_02000000.238140',
  PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS = '23831000_47208100_02000000.238310',
  PLUMBING_COMMERCIAL_OR_INDUSTRIAL_CHILLED_HOT_WATER_SYSTEMS_CONTRACTORS = '23822000_47215200_00020000.238220',
  PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION = '23822000_47215200_00010000.238220',
  SIGN_PAINTING_AND_LETTERING_EXTERIOR_ONLY_SIGN_LETTERING_AND_PAINTING_SERVICES = '54189000_51912300_00000200.541890',
  SIGN_PAINTING_AND_LETTERING_INTERIOR_ONLY_DISPLAY_LETTERING_SERVICES = '54189000_51912300_00000100.541890',
  TILE_STONE_MARBLE_MOSAIC_OR_TERRAZZO_WORK_INTERIOR_ONLY_CERAMIC_TILE_INSTALLATION = '23834000_47204400_00000100.238340',
  UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS = '81142000_51609300_00000000.811121',
  UPHOLSTERY_WORK_UPHOLSTERY_EXCEPT_MOTOR_VEHICLE_REPAIR_SERVICES = '81142000_51609300_00000000.811420',
  WINDOW_CLEANING_NOTHING_ABOVE_15_FEET_WINDOW_CLEANING_SERVICES = '56172000_37201100_02000000.561720',
  APPLICATION_DEVELOPMENT_APPLICATIONS_SOFTWARE_PROGRAMMING_SERVICES_CUSTOM_COMPUTER = '54151900_15125100_00000000.541511',
  APPLICATION_SERVICE_PROVIDER_APPLICATION_HOSTING = '51821000_13119900_01000000.518210',
  COMPUTER_CONSULTING_COMPUTER_HARDWARE_CONSULTING_SERVICES_OR_CONSULTANTS = '54169000_15122100_00000000.541512',
  COMPUTER_PROGRAMMING_SERVICES_CUSTOM_SOFTWARE_SUPPORT = '54151100_15125100_01000000.541511',
  COMPUTER_SYSTEMNETWORK_DEVELOPER_LOCAL_AREA_NETWORK_LAN_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES = '54151200_15124100_00000000.541512',
  DATA_PROCESSING_AUTOMATED_DATA_PROCESSING_SERVICES = '51821000_13119900_02000000.518210',
  DATABASE_DESIGNER_INFORMATION_MANAGEMENT_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES = '54151200_15124300_00000000.541512',
  IT_CONSULTING_COMPUTER_SOFTWARE_CONSULTING_SERVICES_OR_CONSULTANTS = '54169000_15129900_00000000.541512',
  IT_PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS = '54169000_15119900_00000000.561110',
  IT_SOFTWAREHARDWARE_TRAINING_SERVICES_COMPUTER_OPERATOR_TRAINING = '61142000_13115100_00000000.611420',
  SOFTWARE_DEVELOPMENT_COMPUTER_SOFTWARE_PROGRAM_ANALYSIS_DESIGN_SERVICES = '54151900_15125200_01000000.541511',
  VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_SOFTWARE_PACKAGED_MERCHANT_WHOLESALERS = '45399800_17206100_00000000.423430',
  VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_EQUIPMENT_STORES = '45399800_17206100_00000000.443142',
  VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_PERIPHERAL_EQUIPMENT_RENTAL_OR_LEASING = '45399800_17206100_00000000.532420',
  WEBSITE_DESIGN_WEB_IE_INTERNET_PAGE_DESIGN_SERVICES_CUSTOM = '54151200_15125400_00000000.541511',
  COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_CABLE_TELEVISION_HOOKUP_CONTRACTORS = '23821000_49905200_02000000.238210',
  COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_SATELLITE_DISH_HOUSEHOLDTYPE_INSTALLATION = '23821000_49905200_02000000.238290',
  CABLING_INSTALLATION_COMPUTER_AND_NETWORK_CABLE_INSTALLATION = '23821000_49905200_01000000.238210',
  HOME_THEATER_INSTALLATION_HOME_THEATER_INSTALLATION = '81121100_49209700_00000000.238210',
  SECURITY_SYSTEM_INSTALLATION_SECURITY_AND_FIRE_SYSTEM_INSTALLATION_ONLY = '56162100_49209800_00000000.238210',
  SOUND_EQUIPMENT_INSTALLATION_SOUND_EQUIPMENT_INSTALLATION = '23821000_49209700_00000000.238210',
  SOUND_EQUIPMENT_INSTALLATION_AUTOMOTIVE_AUDIO_EQUIPMENT_STORES = '23821000_49209700_00000000.441310',
  SOUND_EQUIPMENT_INSTALLATION_RADIO_EXCEPT_TWOWAY_RADIO_REPAIR_AND_MAINTENANCE_SERVICES_WITHOUT_RETAILING_NEW_RADIOS = '23821000_49209700_00000000.811211',
  SECURITY_SYSTEM_INSTALLATION_BURGLAR_ALARM_SALES_COMBINED_WITH_INSTALLATION_REPAIR_OR_MONITORING_SERVICES = '56162100_49209800_00000000.561621',
  LEARNING_CENTER_ACADEMIC_TUTORING_SERVICES = '61169100_25304100_02000000.611691',
  LEARNING_CENTER_TEST_PREP_EXAM_PREPARATION_SERVICES = '61169100_25304100_01000000.611691',
  PROFESSIONAL_TRAINING_PROFESSIONAL_DEVELOPMENT_TRAINING = '61143000_25119900_00000000.611430',
  TRAINING_AND_DEVELOPMENT_CONSULTING_PROFESSIONAL_DEVELOPMENT_CONSULTING = '61143000_13115100_00000000.611430',
}

export const GL_BUSINESS_CLASS_CODES_V4_VALUES = Object.values(HISCOX_BUSINESS_CLASS_CODES_V4);

// COB-based exceptions sets for question label text
export const BUSINESS_CLASS_OVERRIDES: { [inputId: string]: string[] } = {
  ARCH_ENG: [
    HISCOX_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERS_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES,
  ],
  HEALTH: [
    HISCOX_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_ELDERLY_MEDICAL_INFUSION_THERAPY_HOSPICE_CARE_AGENCIES_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_ELDERLY_MEDICAL_INFUSION_THERAPY_AGENCIES_HOSPICE_CARE_SERVICES,
  ],
  IT: [
    HISCOX_BUSINESS_CLASS_CODES_V4.DATABASE_DESIGNER_INFORMATION_MANAGEMENT_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.IT_CONSULTING_COMPUTER_SOFTWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
    HISCOX_BUSINESS_CLASS_CODES_V4.IT_PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS,
    HISCOX_BUSINESS_CLASS_CODES_V4.IT_SOFTWAREHARDWARE_TRAINING_SERVICES_COMPUTER_OPERATOR_TRAINING,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLICATION_DEVELOPMENT_APPLICATIONS_SOFTWARE_PROGRAMMING_SERVICES_CUSTOM_COMPUTER,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLICATION_SERVICE_PROVIDER_APPLICATION_HOSTING,
    HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_CONSULTING_COMPUTER_HARDWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
    HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_PROGRAMMING_SERVICES_CUSTOM_SOFTWARE_SUPPORT,
    HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_SYSTEMNETWORK_DEVELOPER_LOCAL_AREA_NETWORK_LAN_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.DATA_PROCESSING_AUTOMATED_DATA_PROCESSING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOFTWARE_DEVELOPMENT_COMPUTER_SOFTWARE_PROGRAM_ANALYSIS_DESIGN_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_CONSULTING_COMPUTER_HARDWARE_CONSULTING_SERVICES_OR_CONSULTANTS,
    HISCOX_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_SOFTWARE_PACKAGED_MERCHANT_WHOLESALERS,
    HISCOX_BUSINESS_CLASS_CODES_V4.WEBSITE_DESIGN_WEB_IE_INTERNET_PAGE_DESIGN_SERVICES_CUSTOM,
  ],
  MOBILE_FOOD: [
    HISCOX_BUSINESS_CLASS_CODES_V4.COFFEEDONUT_CART_OPERATOR_COFFEE_CARTS_MOBILE,
    HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_KITCHEN_OPERATOR_LUNCH_WAGONS,
    HISCOX_BUSINESS_CLASS_CODES_V4.FOODBEVERAGE_CART_OPERATOR_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE,
    HISCOX_BUSINESS_CLASS_CODES_V4.ICE_CREAM_TRUCK_OPERATOR_ICE_CREAM_TRUCK_VENDORS,
    HISCOX_BUSINESS_CLASS_CODES_V4.SNACK_TRUCK_OPERATOR_CONCESSION_SNACK_STANDS_MOBILE,
    HISCOX_BUSINESS_CLASS_CODES_V4.FOOD_TRUCKTRAILER_OPERATOR_LUNCH_WAGONS,
    HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_CONCESSIONS_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE,
    HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_SERVICES_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE,
    HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_VENDING_SERVICES_CANTEENS_MOBILE,
    HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_STREET_FOOD_VENDOR_STREET_VENDORS_FOOD,
  ],
  OCC_SPEECH: [
    HISCOX_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_EG_CENTERS_CLINICS,
  ],
  PERSONAL_CARE: [HISCOX_BUSINESS_CLASS_CODES_V4.PERSONAL_CONCIERGEASSISTANT_CONCIERGE_SERVICES],
  LANDSCAPE: [
    HISCOX_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE,
    HISCOX_BUSINESS_CLASS_CODES_V4.LANDSCAPINGGARDENING_SERVICES_GARDEN_MAINTENANCE_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.LAWN_CARE_SERVICES_CEMETERY_PLOT_CARE_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_DRIVEWAYS_AND_PARKING_LOTS_IE_NOT_COMBINED_WITH_ANY_OTHER_SERVICE,
    HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_SERVICES_COMBINED_WITH_LANDSCAPING_SERVICES_IE_SEASONAL_PROPERTY_MAINTENANCE_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_REMOVAL_HIGHWAY,
  ],
  LAND_SURVEYOR: [
    HISCOX_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_TOPOGRAPHIC_SURVEYING_EXCEPT_GEOPHYSICAL_SERVICES,
  ],
  JANITOR: [
    HISCOX_BUSINESS_CLASS_CODES_V4.EXTERIOR_CLEANING_SERVICES_BUILDING_EXTERIOR_CLEANING_SERVICES_EXCEPT_SANDBLASTING_WINDOW_CLEANING,
    HISCOX_BUSINESS_CLASS_CODES_V4.JANITORIALCLEANING_SERVICES_BUILDING_CLEANING_SERVICES_JANITORIAL,
  ],
  RETAIL: [
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE_USED,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_AUDIO_EQUIPMENT_STORES_EXCEPT_AUTOMOTIVE,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_CHILDRENS_AND_INFANTS_CLOTHING,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_MENS_AND_BOYS_CLOTHING,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_WOMENS_AND_GIRLS_CLOTHING,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_BRIDAL_GOWN_SHOPS_EXCEPT_CUSTOM,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_CLOTHING_STORES_FAMILY,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_NECKWEAR_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.FLORISTS_FLORISTS,
    HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_BATH_SHOPS,
    HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_CARPET_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_CURTAIN_AND_DRAPERY_STORES_PACKAGED,
    HISCOX_BUSINESS_CLASS_CODES_V4.JEWELRY_STORES_CLOCK_SHOPS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_DELICATESSENS_PRIMARILY_RETAILING_A_RANGE_OF_GROCERY_ITEMS_AND_MEATS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_CONVENIENCE_FOOD_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_LIQUOR_STORES_PACKAGE,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_SUPERSTORES_IE_FOOD_AND_GENERAL_MERCHANDISE,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_NEW_ONLY_OR_NEW_AND_USED,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_USED_ONLY,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CAMPER_DEALERS_RECREATIONAL,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_BOAT_DEALERS_NEW_AND_USED,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AIRCRAFT_DEALERS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOTIVE_TIRE_DEALERS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_FURNITURE_AND_APPLIANCE_STORES_IE_PRIMARILY_RETAILING_FURNITURE,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CELLULAR_TELEPHONE_ACCESSORIES_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CABINET_STORES_KITCHEN_EXCEPT_CUSTOM_TO_BE_INSTALLED,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CONVENIENCE_FOOD_WITH_GASOLINE_STATIONS,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_GASOLINE_STATIONS_WITHOUT_CONVENIENCE_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_HANDBAG_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.JEWELRY_STORES_COSTUME_JEWELRY_STORES,
  ],
  SC: [
    HISCOX_BUSINESS_CLASS_CODES_V4.AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE,
    HISCOX_BUSINESS_CLASS_CODES_V4.CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE,
    HISCOX_BUSINESS_CLASS_CODES_V4.CARPET_RUG_FURNITURE_OR_UPHOLSTERY_CLEANING_CUSTOMERS_PREMISES_ONLY_CARPET_CLEANING_ON_CUSTOMERS_PREMISES,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCKS_ASSEMBLING,
    HISCOX_BUSINESS_CLASS_CODES_V4.DOOR_OR_WINDOW_INSTALLATION_AND_REPAIR_ALUMINUM_DOOR_AND_WINDOW_RESIDENTIALTYPE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY,
    HISCOX_BUSINESS_CLASS_CODES_V4.DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS,
    HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_WORK_INTERIOR_ONLY_ELECTRIC_CONTRACTING,
    HISCOX_BUSINESS_CLASS_CODES_V4.FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.FLOOR_COVERING_INSTALLATION_NO_CERAMIC_TILESTONE_FLOOR_LAYING_TILE_HARDWOOD_VINYL,
    HISCOX_BUSINESS_CLASS_CODES_V4.GLASS_INSTALLATION_AND_REPAIR_NO_AUTO_WORK_DECORATIVE_GLASS_MIRROR_GLAZING_WINDOW_PANE_SHEET,
    HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING,
    HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING,
    HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS,
    HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING,
    HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ACOUSTICAL_CEILING_TILE_AND_PANEL_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ASPHALT_WOOD_FLOORING,
    HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CONCRETE_FINISHING,
    HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_MOLDING_TRIM_WOOD_PLASTIC_PREFABRICATED_WINDOW_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.LOCKSMITHS_LOCK_REKEYING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.MASONRY_WORK_BRICK_VENEER_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.PAINTING_INTERIOR_ONLY_PAINTING_EXCEPT_ROOF_CONTRACTORS,
    HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS,
    HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_STUCCO_CONTRACTORS,
    HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_COMMERCIAL_OR_INDUSTRIAL_CHILLED_HOT_WATER_SYSTEMS_CONTRACTORS,
    HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_EXTERIOR_ONLY_SIGN_LETTERING_AND_PAINTING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_INTERIOR_ONLY_DISPLAY_LETTERING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.TILE_STONE_MARBLE_MOSAIC_OR_TERRAZZO_WORK_INTERIOR_ONLY_CERAMIC_TILE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_UPHOLSTERY_EXCEPT_MOTOR_VEHICLE_REPAIR_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS,
    HISCOX_BUSINESS_CLASS_CODES_V4.WINDOW_CLEANING_NOTHING_ABOVE_15_FEET_WINDOW_CLEANING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.CABLING_INSTALLATION_COMPUTER_AND_NETWORK_CABLE_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCK_REPAIR_SHOPS_WITHOUT_RETAILING_NEW_CLOCKS,
    HISCOX_BUSINESS_CLASS_CODES_V4.HOME_THEATER_INSTALLATION_HOME_THEATER_INSTALLATION,
    HISCOX_BUSINESS_CLASS_CODES_V4.SECURITY_SYSTEM_INSTALLATION_BURGLAR_ALARM_SALES_COMBINED_WITH_INSTALLATION_REPAIR_OR_MONITORING_SERVICES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SECURITY_SYSTEM_INSTALLATION_SECURITY_AND_FIRE_SYSTEM_INSTALLATION_ONLY,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_AUTOMOTIVE_AUDIO_EQUIPMENT_STORES,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_RADIO_EXCEPT_TWOWAY_RADIO_REPAIR_AND_MAINTENANCE_SERVICES_WITHOUT_RETAILING_NEW_RADIOS,
    HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_SOUND_EQUIPMENT_INSTALLATION,
  ],
};

BUSINESS_CLASS_OVERRIDES['MANU_FACT_DIST_GOODS_OR_PRODUCTS2'] = _.difference(
  GL_BUSINESS_CLASS_CODES_V4_VALUES,
  _.concat(
    BUSINESS_CLASS_OVERRIDES['ARCH_ENG'],
    BUSINESS_CLASS_OVERRIDES['LANDSCAPE'],
    BUSINESS_CLASS_OVERRIDES['JANITOR'],
    BUSINESS_CLASS_OVERRIDES['RETAIL'],
    BUSINESS_CLASS_OVERRIDES['SC']
  )
);

BUSINESS_CLASS_OVERRIDES['TANGIABLE_GOODS_WORK'] = _.difference(
  GL_BUSINESS_CLASS_CODES_V4_VALUES,
  _.concat(
    BUSINESS_CLASS_OVERRIDES['ARCH_ENG'], // except all A&E COBs
    BUSINESS_CLASS_OVERRIDES['LANDSCAPE'], // except all landscaping
    BUSINESS_CLASS_OVERRIDES['JANITOR'], // except janitorial
    BUSINESS_CLASS_OVERRIDES['RETAIL'], // except all retail
    BUSINESS_CLASS_OVERRIDES['SC'], // except all small contractors
    BUSINESS_CLASS_OVERRIDES['IT'], // except technology
    BUSINESS_CLASS_OVERRIDES['HEALTH'], // except health aids
    [HISCOX_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE], // except insurance inspector
    [HISCOX_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES] // except safety consultant
  )
);

// Note: Per "Hiscox NOW Underwriting Manual API v1.4 (Mustang).pdf", Small contractos (SC),
// landscape, sanitorial, mobile food services, and retail COBs have a different Aggregate limit
BUSINESS_CLASS_OVERRIDES['AGGREGATE_LIMIT_GROUP'] = _.concat(
  BUSINESS_CLASS_OVERRIDES['LANDSCAPE'],
  BUSINESS_CLASS_OVERRIDES['JANITOR'],
  BUSINESS_CLASS_OVERRIDES['SC'],
  BUSINESS_CLASS_OVERRIDES['MOBILE_FOOD'],
  BUSINESS_CLASS_OVERRIDES['RETAIL']
);

// Note: Per "Hiscox NOW Underwriting Manual API v1.6 (Mustang).pdf", Property Management and real state agents
// have a deductible of $1,000, while all other COBs have a deductible of $0 for all states
BUSINESS_CLASS_OVERRIDES['RATINGINFO_DEDUCTIBLE_EXCEPTIONS'] = _.concat(
  [
    HISCOX_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_REAL_STATE_MANAGERS_OFFICES,
  ],
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING],
  [HISCOX_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE]
);

// Note: Per "Hiscox NOW Underwriting Manual API v1.6 (Mustang).pdf", some Small contractos (SC),
// land surveyors, insurance agents, and property management COBs have a limit of up to 1 million
// for all states.
const SMALL_CONTRACTORS_WITH_1M_LOI_IN_ALL_STATES = [
  HISCOX_BUSINESS_CLASS_CODES_V4.AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE,
  HISCOX_BUSINESS_CLASS_CODES_V4.CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE,
  HISCOX_BUSINESS_CLASS_CODES_V4.DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY,
  HISCOX_BUSINESS_CLASS_CODES_V4.DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING,
  HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING,
  HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING,
  HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS,
  HISCOX_BUSINESS_CLASS_CODES_V4.MASONRY_WORK_BRICK_VENEER_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_STUCCO_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_COMMERCIAL_OR_INDUSTRIAL_CHILLED_HOT_WATER_SYSTEMS_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION,
];

// TODO: state is not currently being evaluated for GL, so this needs to be added later to
// allow state specific limits
BUSINESS_CLASS_OVERRIDES['OCCURRENCE_LIMIT_GROUP_ALL_STATES'] = _.concat(
  SMALL_CONTRACTORS_WITH_1M_LOI_IN_ALL_STATES,
  HISCOX_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE,
  BUSINESS_CLASS_OVERRIDES['LAND_SURVEYOR'],
  HISCOX_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_REAL_STATE_MANAGERS_OFFICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING
);

// Some small contractors have a limit of $1M only for certain states
BUSINESS_CLASS_OVERRIDES['OCCURRENCE_LIMIT_GROUP_CO_NJ_NY_PA'] = _.difference(
  BUSINESS_CLASS_OVERRIDES['SC'],
  SMALL_CONTRACTORS_WITH_1M_LOI_IN_ALL_STATES
);

// Note: Per `Mustang UI Development Guidelines Quote v4 - v1.15`
// All COBs, except landscaping, janitorial, retail, mobile food, and SC COBS
// should get asked `GLHireNonOwnVehicleCoverage` question
export const GL_HNOA_COVERAGE_EXCLUDED_COBS = _.concat(
  BUSINESS_CLASS_OVERRIDES['LANDSCAPE'],
  BUSINESS_CLASS_OVERRIDES['JANITOR'],
  BUSINESS_CLASS_OVERRIDES['RETAIL'],
  BUSINESS_CLASS_OVERRIDES['MOBILE_FOOD'],
  BUSINESS_CLASS_OVERRIDES['SC']
);

// TODO: add test
/**
 * COBs who should see acknowledgement in bind flow:
 *
 * Small Contractors COBs
 * Landscape/Janitorial COBs
 * Retail COBs
 * Mobile Food Service COBs
 */
export const SC_POLICY_COBS = _.concat(
  BUSINESS_CLASS_OVERRIDES['SC'],
  BUSINESS_CLASS_OVERRIDES['LANDSCAPE'],
  BUSINESS_CLASS_OVERRIDES['JANITOR'],
  BUSINESS_CLASS_OVERRIDES['RETAIL'],
  BUSINESS_CLASS_OVERRIDES['MOBILE_FOOD']
);

export const GL_BUSINESS_CLASSES_V4 = {
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACUPRESSURE_SERVICES_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Acupressure services: Massage therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS]:
    "Acupuncture services: Acupuncturists' (MDs or DOs) offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACUPUNCTURE_SERVICES_ACUPUNCTURISTS_EXCEPT_MDS_OR_DOS_OFFICES_EG_CENTERS_CLINICS]:
    "Acupuncture services: Acupuncturists' (except MDs or DOs) offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.ART_THERAPY_ART_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Art therapy: Art therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.AUDIOLOGY_AUDIOLOGISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Audiology: Audiologists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_BARBER_SHOPS]:
    'Barber/hair stylist services: Barber shops',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BARBERHAIR_STYLIST_SERVICES_HAIR_STYLIST_SALONS_OR_SHOPS_UNISEX_OR_WOMENS]:
    "Barber/hair stylist services: Hair stylist salons or shops, unisex or women's",
  [HISCOX_BUSINESS_CLASS_CODES_V4.BEAUTICIANCOSMETOLOGY_SERVICES_BEAUTICIAN_SERVICES]:
    'Beautician/cosmetology services: Beautician services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DANCE_THERAPY_DANCE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Dance therapy: Dance therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIETITIANS_OFFICES_EG_CENTERS_CLINICS]:
    "Diet/nutrition services: Dietitians' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.DIETNUTRITION_SERVICES_DIET_CENTERS_NONMEDICAL]:
    'Diet/nutrition services: Diet centers, non-medical',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DRAMA_THERAPY_RECREATIONAL_EG_ART_DANCE_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Drama therapy: Recreational (e.g., art, dance, music) therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.ESTHETICIAN_SERVICES_ESTHETICIAN_IE_SKIN_CARE_SERVICES]:
    'Esthetician services: Esthetician (i.e., skin care) services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FIRST_AID_AND_CPR_TRAINING_CPR_CARDIOPULMONARY_RESUSCITATION_TRAINING_AND_CERTIFICATION]:
    'First aid and CPR training: CPR (cardiopulmonary resuscitation) training and certification',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HOME_HEALTH_AIDE_ELDERLY_MEDICAL_INFUSION_THERAPY_HOSPICE_CARE_AGENCIES_SERVICES]:
    'Home health aide: Elderly, medical, infusion therapy, hospice care, agencies, services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HYPNOSIS_HYPNOTHERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Hypnosis: Hypnotherapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.MARRIAGE_AND_FAMILY_THERAPY_COUNSELING_SERVICES_EXCEPT_BY_PSYCHIATRISTS_PSYCHOANALYSTS_OR_PSYCHOTHERAPISTS]:
    'Marriage and family therapy: Counseling services (except by psychiatrists, psychoanalysts, or psychotherapists)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MASSAGE_THERAPY_MASSAGE_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Massage therapy: Massage therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.MENTAL_HEALTH_COUNSELING_CLINICAL_PSYCHOANALYSTS_PSYCHOTHERAPISTS_SOCIAL_WORKER]:
    'Mental health counseling: Psychoanalysts, psychotherapists, social worker (e.g., centers, clinics)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MUSIC_THERAPY_MUSIC_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Music therapy: Music therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.NAIL_TECHNICIAN_SERVICES_MANICURE_AND_PEDICURE_SALONS]:
    'Nail technician services: Manicure and pedicure salons',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OCCUPATIONAL_THERAPY_EG_CENTERS_CLINICS]:
    "Occupational therapy: Exercise physiologists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.PERSONAL_CARE_AIDE_ELDERLY_MEDICAL_INFUSION_THERAPY_AGENCIES_HOSPICE_CARE_SERVICES]:
    'Personal care aide: Elderly, medical, infusion therapy agencies, hospice case services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PERSONAL_TRAINING_HEALTH_AND_FITNESS_PERSONAL_FITNESS_TRAINING_SERVICES]:
    'Personal training (health and fitness): Personal fitness training services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PSYCHOLOGY_CLINICAL_PSYCHOLOGISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Psychology: Clinical psychologists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_CHILD_GUIDANCE_SELF_HELP_OUTREACH_AGENCIES_ORGANIZATIONS]:
    'Social work services: Child guidance, self-help, outreach agencies, organizations',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_NON_MEDICAL_ELDER_DISABLED_HOME_CARE_HOMEMAKER_SERVICES]:
    'Social work services: non medical elder, disabled, home care, homemaker services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_WORK_SERVICES_COMMUNITY_HEALTH_FAMILY_WALFARE_HOTLINE_SELF_HELP_SUPPORT_COUNSELING]:
    'Social work services: Community health, family walfare, hotline, self-help, support, counseling',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SPEECH_THERAPY_SPEECH_THERAPISTS_OFFICES_EG_CENTERS_CLINICS]:
    "Speech therapy: Speech therapists' offices (e.g., centers, clinics)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.SUBSTANCE_ABUSE_COUNSELING_ALCOHOLISM_AND_DRUG_ADDICTION_SELFHELP_ORGANIZATIONS]:
    'Substance abuse counseling: Alcoholism and drug addiction self-help organizations',
  [HISCOX_BUSINESS_CLASS_CODES_V4.YOGAPILATES_INSTRUCTION_YOGA_INSTRUCTION_CAMPS_OR_SCHOOLS]:
    'Yoga/pilates instruction: Yoga instruction, camps, or schools',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ARCHITECTURE_ARCHITECTS_EXCEPT_LANDSCAPE_OFFICES]:
    "Architecture: Architects' (except landscape) offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.BUILDING_INSPECTION_BUILDING_INSPECTION_BUREAUS]:
    'Building inspection: Building inspection bureaus',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CIVIL_ENGINEERING_CIVIL_ENGINEERING_SERVICES]:
    'Civil engineering: Civil engineering services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CONTROL_SYSTEMS_INTEGRATIONAUTOMATION_SYSTEMS_INTEGRATION_DESIGN_CONSULTING_SERVICES_COMPUTER]:
    'Control systems integration/automation: Systems integration design consulting services, computer',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DRAFTSMAN_INCLUDING_CADCAM_BLUEPRINT_DRAFTING_SERVICES]:
    'Draftsman (including CAD/CAM): Blueprint drafting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_ENGINEERING_ELECTRICAL_ENGINEERING_SERVICES]:
    'Electrical engineering: Electrical engineering services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ENGINEERING_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES]:
    'Engineering: Acoustical engineering consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ENVIRONMENTAL_ENGINEERING_ENVIRONMENTAL_ENGINEERING_SERVICES]:
    'Environmental engineering: Environmental engineering services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INDUSTRIAL_ENGINEERING_INDUSTRIAL_ENGINEERING_SERVICES]:
    'Industrial engineering: Industrial engineering services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_DESIGN_DECORATING_CONSULTING_SERVICES_INTERIOR]:
    'Interior design: Decorating consulting services, interior',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LAND_SURVEYOR_CADASTRAL_CARTOGRAPHIC_TOPOGRAPHIC_SURVEYING_EXCEPT_GEOPHYSICAL_SERVICES]:
    'Land surveyor: Aerial surveying (except geophysical) services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LANDSCAPE_ARCHITECT_ARCHITECTS_OFFICES_LANDSCAPE]:
    "Landscape architect: Architects' offices, landscape",
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROCESS_ENGINEERING_ENGINEERS_SERVICES]:
    "Process engineering: Consulting engineers' offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGER_ARCHITECTURE_OR_ENGINEERING_ENGINEERING_SERVICES]:
    'Project manager (architecture or engineering): Engineering services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRAFFIC_ENGINEERING_CONSULTING_SERVICES]:
    'Transportation engineering: Traffic engineering consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRANSPORTATION_ENGINEERING_TRANSPORTATION_MANAGEMENT_CONSULTING_SERVICES]:
    'Transportation engineering: Transportation management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BUSINESS_CONSULTING_BUSINESS_STARTUP_REORGANIZATIONAL_CONSULTING_SERVICES]:
    'Business consulting: Business start-up and reorganizational consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.EDUCATION_CONSULTING_EDUCATIONAL_CONSULTANTS]:
    'Education consulting: Educational consultants',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HUMAN_RESOURCES_HR_CONSULTING_HUMAN_RESOURCE_CONSULTING_SERVICES]:
    'Human Resources (HR) consulting: Human resource consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_GENERAL_MANAGEMENT_CONSULTING_SERVICES]:
    'Management consulting: General management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_COMPENSATION_PLANNING_SERVICES]:
    'Management consulting: Compensation planning services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES]:
    'Management consulting: Customer service management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_FREIGHT_RATE_AUDITOR_TRAFFIC_INFORMATION_CONSULTING_SERVICES]:
    'Management consulting: Freight rate auditor, traffic, information, consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MANAGEMENT_CONSULTING_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES]:
    'Management consulting: Telecommunications management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_INVESTMENT_ADVICE_CONSULTING_SERVICES_CUSTOMIZED_FEES_PAID_BY_CLIENT]:
    'Other consulting services: Investment advice consulting services, customized, fees paid by client',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_JURY_CONSULTING_SERVICES]:
    'Other consulting services: Jury consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_LANDSCAPE_CONSULTING_SERVICES]:
    'Other consulting services: Landscape consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ACOUSTICAL_ENGINEERING_CONSULTING_SERVICES]:
    'Other consulting services: Acoustical engineering consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_DECORATING_CONSULTING_SERVICES_INTERIOR]:
    'Other consulting services: Decorating consulting services, interior',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_INDUSTRIAL_DESIGN_CONSULTING_SERVICES]:
    'Other consulting services: Industrial design consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_MEDICAL_OFFICE_MANAGEMENT_RECORDS_SITE_LOCATION_SELECTION_CONSULTING_SERVICES]:
    'Other consulting services: Medical office, records, mananagement, site location, selection consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ACTUARIAL_CONSULTING_SERVICES_EXCEPT_INSURANCE_ACTUARIAL_SERVICES]:
    'Other consulting services: Actuarial consulting services (except insurance actuarial services)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_CUSTOMER_SERVICE_MANAGEMENT_CONSULTING_SERVICES]:
    'Other consulting services: Customer service management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_CUSTOMS_CONSULTING_SERVICES]:
    'Other consulting services: Customs consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_TELECOMMUNICATIONS_MANAGEMENT_CONSULTING_SERVICES]:
    'Other consulting services: Telecommunications management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_SANITATION_CONSULTING_SERVICES]:
    'Other consulting services: Sanitation consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_BUILDING_DEVELOPMENT_ECONOMIC_ENTOMOLOGY_HORTICULTURAL_MOTION_PICTURE_RADIO]:
    'Other consulting services: Building development, economic, entomology, horticultual, montion picture, radio',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_ADVERTISING_AGENCY_CONSULTING_SERVICES]:
    'Other consulting services: Advertising agency consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_POLITICAL_CONSULTING_SERVICES]:
    'Other consulting services: Political consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES]:
    'Other consulting services: Executive placement consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_OUTPLACEMENT_CONSULTING_SERVICES]:
    'Other consulting services: Outplacement consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_CONSULTING_SERVICES_EDUCATIONAL_CONSULTANTS]:
    'Other consulting services: Educational consultants',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS]:
    "Project management: Management services (except complete operation of client's business)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_NANOBIOTECHNOLOGIES_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_LABORATORIES]:
    'Research consulting: Nanobiotechnologies research and experimental development laboratories',
  [HISCOX_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_BOTANY_ENTOMOLOGY_ENVIRONMENTAL_PHYSICAL_SCIENCE]:
    'Research consulting: Biotechnology research and development in botany, entomology, environmental and physical sciences',
  [HISCOX_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_BOTANY_COMPUTER_ENGINEERING_FOOD_LIFE_INDUSTRIAL_LABORATORIES_OR_SERVICES]:
    'Research consulting: Botany, engineering, food, life, industrial laboratories or services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.RESEARCH_CONSULTING_ARCHEOLOGICAL_RESEARCH_AND_DEVELOPMENT_SERVICES]:
    'Research consulting: Archeological research and development services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.RESUME_CONSULTING_RESUME_WRITING_SERVICES]:
    'Resume consulting: Resume writing services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SAFETY_CONSULTANT_SAFETY_CONSULTING_SERVICES]:
    'Safety consultant: Safety consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.STRATEGY_CONSULTANT_STRATEGIC_PLANNING_CONSULTING_SERVICES]:
    'Strategy consultant: Strategic planning consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.GRAPHIC_DESIGN_ART_SERVICES_GRAPHIC]:
    'Graphic design: Art services, graphic',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_PASSPORT_PHOTOGRAPHY_SERVICES]:
    'Photography: Passport photography services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_COMMERCIAL_PHOTOGRAPHY_SERVICES]:
    'Photography: Commercial photography services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PHOTOGRAPHY_CAMERAMEN_INDEPENDENT_FREELANCE]:
    'Photography: Cameramen, independent (freelance)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_IE_CPAS_OFFICES_CERTIFIED_PUBLIC]:
    "Accounting: Accountants' (i.e., CPAs) offices, certified public",
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACCOUNTING_PAYROLL_PROCESSING_SERVICES]:
    'Accounting: Payroll processing services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACCOUNTING_ACCOUNTANTS_EXCEPT_CPAS_OFFICES]:
    "Accounting: Accountants' (except CPAs) offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.ACTUARIAL_SERVICES_ACTUARIAL_SERVICES_INSURANCE]:
    'Actuarial services: Actuarial services, insurance',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BOOKKEEPING_BOOKKEEPERS_OFFICES]:
    "Bookkeeping: Bookkeepers' offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.CREDIT_COUNSELING_CONSUMER_CREDIT_COUNSELING_SERVICES]:
    'Credit counseling: Consumer credit counseling services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FINANCIAL_AUDITING_OR_CONSULTING_AUDITING_ACCOUNTANTS_IE_CPAS_OFFICES]:
    "Financial auditing or consulting: Auditing accountants' (i.e., CPAs) offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE]:
    'Insurance agent: Agencies, insurance',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INVESTMENT_ADVICE_CERTIFIED_FINANCIAL_PLANNERS_CUSTOMIZED_FEES_PAID_BY_CLIENT]:
    'Investment advice: Certified financial planners, customized, fees paid by client',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MEDICAL_BILLING_MEDICAL_CODING_ACCOUNTING_SERVICES_EXCEPT_CPA_SERVICES]:
    'Medical billing: Medical billing medical coding accounting services (except CPA services)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MORTGAGE_BROKERINGBANKING_REVERSE_MORTGAGE_LENDING]:
    'Mortgage brokering/banking: Reverse mortgage lending',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MORTGAGE_BROKERINGBANKING_BROKERAGES_MORTGAGE]:
    'Mortgage brokering/banking: Brokerages, mortgage',
  [HISCOX_BUSINESS_CLASS_CODES_V4.STOCK_BROKERING_BOND_SECURITIES_STOCK_BROKERAGES_OR_OFFICES]:
    'Stock brokering: Bond, securities, stock, brokerages or offices',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TAX_PREPARATION_INCOME_TAX_COMPILATION_SERVICES]:
    'Tax preparation: Income tax compilation services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.EXTERIOR_CLEANING_SERVICES_SANDBLASTING_BUILDING_EXTERIOR]:
    'Exterior cleaning services: Sandblasting, building exterior',
  [HISCOX_BUSINESS_CLASS_CODES_V4.EXTERIOR_CLEANING_SERVICES_BUILDING_EXTERIOR_CLEANING_SERVICES_EXCEPT_SANDBLASTING_WINDOW_CLEANING]:
    'Exterior cleaning services: Building exterior cleaning services (except sandblasting, window cleaning)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.JANITORIALCLEANING_SERVICES_BUILDING_CLEANING_SERVICES_JANITORIAL]:
    'Janitorial/cleaning services: Building cleaning services, janitorial',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LANDSCAPINGGARDENING_SERVICES_GARDEN_MAINTENANCE_SERVICES]:
    'Landscaping/gardening services: Garden maintenance services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LAWN_CARE_SERVICES_CEMETERY_PLOT_CARE_SERVICES]:
    'Lawn care services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_REMOVAL_HIGHWAY]:
    'Snow blowing and removal (no auto coverage): Snow removal, highway',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_SERVICES_COMBINED_WITH_LANDSCAPING_SERVICES_IE_SEASONAL_PROPERTY_MAINTENANCE_SERVICES]:
    'Snow blowing and removal (no auto coverage): Snow plowing services combined with landscaping services (i.e., seasonal property maintenance services)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_DRIVEWAYS_AND_PARKING_LOTS_IE_NOT_COMBINED_WITH_ANY_OTHER_SERVICE]:
    'Snow blowing and removal (no auto coverage): Snow plowing driveways and parking lots (i.e., not combined with any other service)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLAIMS_ADJUSTING_INSURANCE_CLAIMS_LOSS_CONTROL_CONSULTANTS]:
    'Claims adjusting: Insurance, claims, loss control consultants',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COURT_REPORTING_COURT_REPORTING_SERVICES]:
    'Court reporting: Court reporting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DOCUMENT_PREPARATION_DESKTOP_PUBLISHING_SERVICES_IE_DOCUMENT_PREPARATION_SERVICES]:
    'Document preparation: Desktop publishing services (i.e., document preparation services)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.EXPERT_WITNESS_SERVICES_JURY_CONSULTING_SERVICES]:
    'Expert witness services: Jury consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_CAUSEOFLOSS_INVESTIGATORS_INSURANCE]:
    'Insurance inspector: Cause-of-loss investigators, insurance',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INSURANCE_INSPECTOR_APPRAISAL_EXCEPT_REAL_ESTATE_SERVICES]:
    'Insurance inspector: Appraisal (except real estate) services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEGAL_SERVICES_ATTORNEYS_OFFICES]:
    "Legal services: Attorneys' offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEGAL_SERVICES_OFFICES_OF_NOTARIES]:
    'Legal services: Offices of notaries',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEGAL_SERVICES_SETTLEMENT_OFFICES_REAL_ESTATE]:
    'Legal services: Settlement offices, real estate',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEGAL_SERVICES_JURY_CONSULTING_SERVICES]:
    'Legal services: Jury consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.NOTARY_SERVICES_NOTARY_PUBLIC_SERVICES]:
    'Notary services: Notary public services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROCESS_SERVER_PROCESS_SERVER_SERVICES]:
    'Process server: Process server services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRUSTEE_SERVICES_ADMINISTRATORS_OF_PRIVATE_ESTATES]:
    'Trustee services: Administrators of private estates',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ADVERTISING_ADVERTISING_AGENCIES]:
    'Advertising: Advertising agencies',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BRAND_CONSULTANT_MARKETING_CONSULTING_SERVICES]:
    'Brand consultant: Marketing consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BRAND_CONSULTANT_MARKETING_ANALYSIS_SERVICES]:
    'Brand consultant: Marketing analysis services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DIGITAL_MARKETING_MARKETING_MANAGEMENT_CONSULTING_SERVICES]:
    'Digital marketing: Marketing management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ALTERNATIVE_FUELS_DIRECT_SELLING]:
    'Direct marketing: Alternative fuels, direct selling',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_CANVASSERS_HEADQUARTERS_HOME_DELIVERY_DIRECT_SELLING]:
    'Direct marketing: Canvassers, headquarters, home delivery, direct selling',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DIRECT_MARKETING_ADVERTISING_MATERIAL_PREPARATION_SERVICES_FOR_MAILING_OR_OTHER_DIRECT_DISTRIBUTION]:
    'Direct marketing: Advertising material preparation services for mailing or other direct distribution',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MARKET_RESEARCH_MARKETING_ANALYSIS_SERVICES]:
    'Market research: Marketing analysis services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MARKETINGMEDIA_CONSULTING_MARKETING_CONSULTING_SERVICES]:
    'Marketing/media consulting: Marketing consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_MARKETINGPR_SERVICES_MARKETING_CONSULTING_SERVICES]:
    'Other marketing/PR services: Marketing consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_MARKETINGPR_SERVICES_PUBLIC_RELATIONS_CONSULTING_SERVICES]:
    'Other marketing/PR services: Public relations consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PUBLIC_RELATIONS_PUBLIC_RELATIONS_AGENCIES]:
    'Public relations: Public relations agencies',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SEARCH_ENGINE_SERVICES_SEOSEM_MARKETING_ANALYSIS_SERVICES]:
    'Search engine services (SEO/SEM): Marketing analysis services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_BUSINESS_MANAGEMENT_CONSULTING_SERVICES]:
    'Social media consultant: Business management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_MARKETING_MANAGEMENT_CONSULTING_SERVICES]:
    'Social media consultant: Marketing management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOCIAL_MEDIA_CONSULTANT_PUBLIC_RELATIONS_CONSULTING_SERVICES]:
    'Social media consultant: Public relations consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ANSWERINGPAGING_SERVICES_ANSWERING_SERVICES_TELEPHONE]:
    'Answering/paging services: Answering services, telephone',
  [HISCOX_BUSINESS_CLASS_CODES_V4.AUCTIONEERING_AUCTIONEERS_INDEPENDENT]:
    'Auctioneering: Auctioneers, independent',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BUSINESS_MANAGER_SERVICES_ADMINISTRATIVE_MANAGEMENT_CONSULTING_SERVICES]:
    'Business manager services: Administrative management consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.EVENT_PLANNINGPROMOTION_PARTY_PLANNING_SERVICES]:
    'Event planning/promotion: Party planning services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.EXECUTIVE_PLACEMENT_EXECUTIVE_PLACEMENT_SERVICES]:
    'Executive placement: Executive placement services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_MANAGEMENT_DEVELOPMENT_TRAINING]:
    'Life/career/executive coaching: Management development training',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_CAREER_AND_VOCATIONAL_COUNSELING_SERVICES_EXCEPT_REHABILITATIVE]:
    'Life/career/executive coaching: Career and vocational counseling services (except rehabilitative)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LIFECAREER_EXECUTIVE_COACHING_LIFE_COACH_SERVICES]:
    'Life/career/executive coaching: Life coach services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MANUFACTURER_SALES_REPRESENTATIVE_AGENTS_AND_BROKERS_DURABLE_GOODS_WHOLESALE_TRADE]:
    'Manufacturer sales representative: Agents and brokers, durable goods, wholesale trade',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PERSONAL_CONCIERGEASSISTANT_CONCIERGE_SERVICES]:
    'Personal concierge/assistant: Concierge services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.RECRUITING_EMPLOYMENT_PLACEMENTS_EMPLOYMENT_AGENCIES]:
    'Recruiting (employment placements): Employment agencies',
  [HISCOX_BUSINESS_CLASS_CODES_V4.RECRUITING_EMPLOYMENT_PLACEMENTS_EXECUTIVE_PLACEMENT_CONSULTING_SERVICES]:
    'Recruiting (employment placements): Executive placement consulting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TALENT_AGENCY_AGENTS_TALENT]: 'Talent agency: Agents, talent',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRAINING_BUSINESS_VOCATIONAL_OR_LIFE_SKILLS_HABILITATION_JOB_COUNSELING_AND_TRAINING_VOCATIONAL]:
    'Training (business, vocational or life skills): Habilitation job counseling and training, vocational',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRANSLATINGINTERPRETING_INTERPRETATION_SERVICES_LANGUAGE]:
    'Translating/interpreting: Interpretation services, language',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRAVEL_AGENCY_TRAVEL_AGENCIES]: 'Travel agency: Travel agencies',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TUTORING_ACADEMIC_TUTORING_SERVICES]:
    'Tutoring: Academic tutoring services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.BARBECUE_TRUCKTRAILER_OPERATOR_LUNCH_WAGONS]:
    'Barbecue truck/trailer operator: Lunch wagons',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COFFEEDONUT_CART_OPERATOR_COFFEE_CARTS_MOBILE]:
    'Coffee/donut cart operator: Coffee carts, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FOOD_TRUCKTRAILER_OPERATOR_LUNCH_WAGONS]:
    'Food truck/trailer operator: Lunch wagons',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FOODBEVERAGE_CART_OPERATOR_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE]:
    'Food/beverage cart operator: Beverage stands, nonalcoholic, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HOT_DOG_CART_OPERATOR_CANTEENS_MOBILE]:
    'Hot dog cart operator: Canteens, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ICE_CREAM_TRUCK_OPERATOR_ICE_CREAM_TRUCK_VENDORS]:
    'Ice cream truck operator: Ice cream truck vendors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ICE_CREAM_SNOW_CONE_SHAVED_ICE_CART_OPERATOR_CONCESSION_SNACK_STANDS_MOBILE]:
    'Ice cream/snow cone/shaved ice cart operator: Concession snack stands, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEMONADE_CART_OPERATOR_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE]:
    'Lemonade cart operator: Beverage stands, nonalcoholic, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_CONCESSIONS_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE]:
    'Mobile food concessions: Beverage stands, nonalcoholic, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_SERVICES_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE]:
    'Mobile food services: Beverage stands, nonalcoholic, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_VENDING_SERVICES_CANTEENS_MOBILE]:
    'Mobile food vending services: Canteens, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_KITCHEN_OPERATOR_LUNCH_WAGONS]:
    'Mobile kitchen operator: Lunch wagons',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_STREET_FOOD_VENDOR_STREET_VENDORS_FOOD]:
    'Mobile street food vendor: Street vendors, food',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SNACK_TRUCK_OPERATOR_CONCESSION_SNACK_STANDS_MOBILE]:
    'Snack truck operator: Concession snack stands, mobile',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_CONDOMINIUM_RESIDENTIAL_PROPERTY_REAL_STATE_MANAGERS_OFFICES]:
    "Property management: Condominium, residential, property, real state, managers' offices",
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROPERTY_MANAGEMENT_COMMERCIAL_PROPERTY_MANAGING]:
    'Property management: Commercial property managing',
  [HISCOX_BUSINESS_CLASS_CODES_V4.REAL_ESTATE_AGENTBROKER_AGENCIES_REAL_ESTATE]:
    'Real estate agent/broker: Agencies, real estate',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE]:
    'Appliance/electronic stores: Appliance stores, household-type',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_AUDIO_EQUIPMENT_STORES_EXCEPT_AUTOMOTIVE]:
    'Appliance/electronic stores: Audio equipment stores (except automotive)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE_USED]:
    'Appliance/electronic stores: Appliance stores, household-type, used',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_MENS_AND_BOYS_CLOTHING]:
    "Clothing/apparel stores: Apparel stores, men's and boys' clothing",
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_WOMENS_AND_GIRLS_CLOTHING]:
    "Clothing/apparel stores: Apparel stores, women's and girls' clothing",
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_CHILDRENS_AND_INFANTS_CLOTHING]:
    "Clothing/apparel stores: Apparel stores, children's and infants' clothing",
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_CLOTHING_STORES_FAMILY]:
    'Clothing/apparel stores: Clothing stores, family',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_NECKWEAR_STORES]:
    'Clothing/apparel stores: Neckwear stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_BRIDAL_GOWN_SHOPS_EXCEPT_CUSTOM]:
    'Clothing/apparel stores: Bridal gown shops (except custom)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FLORISTS_FLORISTS]: 'Florists: Florists',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_CARPET_STORES]:
    'Home furnishing stores: Carpet stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_CURTAIN_AND_DRAPERY_STORES_PACKAGED]:
    'Home furnishing stores: Curtain and drapery stores, packaged',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_BATH_SHOPS]:
    'Home furnishing stores: Bath shops',
  [HISCOX_BUSINESS_CLASS_CODES_V4.JEWELRY_STORES_COSTUME_JEWELRY_STORES]:
    'Jewelry stores: Costume jewelry stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.JEWELRY_STORES_CLOCK_SHOPS]: 'Jewelry stores: Clock shops',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_DELICATESSENS_PRIMARILY_RETAILING_A_RANGE_OF_GROCERY_ITEMS_AND_MEATS]:
    'Other stores (with food/drinks): Delicatessens primarily retailing a range of grocery items and meats',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_CONVENIENCE_FOOD_STORES]:
    'Other stores (with food/drinks): Convenience food stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_LIQUOR_STORES_PACKAGE]:
    'Other stores (with food/drinks): Liquor stores, package',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_SUPERSTORES_IE_FOOD_AND_GENERAL_MERCHANDISE]:
    'Other stores (with food/drinks): Superstores (i.e., food and general merchandise)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_NEW_ONLY_OR_NEW_AND_USED]:
    'Other stores (without food/drinks): Automobile dealers, new only or new and used',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_USED_ONLY]:
    'Other stores (without food/drinks): Automobile dealers, used only',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CAMPER_DEALERS_RECREATIONAL]:
    'Other stores (without food/drinks): Camper dealers, recreational',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_BOAT_DEALERS_NEW_AND_USED]:
    'Other stores (without food/drinks): Boat dealers, new and used',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AIRCRAFT_DEALERS]:
    'Other stores (without food/drinks): Aircraft dealers',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOTIVE_TIRE_DEALERS]:
    'Other stores (without food/drinks): Automotive tire dealers',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_FURNITURE_AND_APPLIANCE_STORES_IE_PRIMARILY_RETAILING_FURNITURE]:
    'Other stores (without food/drinks): Furniture and appliance stores (i.e., primarily retailing furniture)',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CELLULAR_TELEPHONE_ACCESSORIES_STORES]:
    'Other stores (without food/drinks): Cellular telephone accessories stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CABINET_STORES_KITCHEN_EXCEPT_CUSTOM_TO_BE_INSTALLED]:
    'Other stores (without food/drinks): Cabinet stores, kitchen (except custom), to be installed',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CONVENIENCE_FOOD_WITH_GASOLINE_STATIONS]:
    'Other stores (without food/drinks): Convenience food with gasoline stations',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_GASOLINE_STATIONS_WITHOUT_CONVENIENCE_STORES]:
    'Other stores (without food/drinks): Gasoline stations without convenience stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_HANDBAG_STORES]:
    'Other stores (without food/drinks): Handbag stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION]:
    'Air conditioning systems installation and repair: Air conditioning systems installation, cooling, piping, equipment, installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE]:
    'Appliance and accessories installation and repair: Appliance stores, household-type',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES]:
    'Appliance and accessories installation and repair: House hold type and maintenance services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE]:
    'Carpentry (interior only): Built-in wood cabinets constructed on site',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CARPET_RUG_FURNITURE_OR_UPHOLSTERY_CLEANING_CUSTOMERS_PREMISES_ONLY_CARPET_CLEANING_ON_CUSTOMERS_PREMISES]:
    "Carpet, rug, furniture, or upholstery cleaning (customer’s premises only): Carpet cleaning on customers' premises",
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCKS_ASSEMBLING]:
    'Clock making and repair: Clocks assembling',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCK_REPAIR_SHOPS_WITHOUT_RETAILING_NEW_CLOCKS]:
    'Clock making and repair: Clock repair shops without retailing new clocks',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DOOR_OR_WINDOW_INSTALLATION_AND_REPAIR_ALUMINUM_DOOR_AND_WINDOW_RESIDENTIALTYPE_INSTALLATION]:
    'Door or window installation and repair: Aluminum door and window, residential-type, installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY]:
    'Driveway or sidewalk paving/repaving: Asphalt coating and sealing, residential and commercial parking lot and driveway',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS]:
    'Drywall or wallboard installation and repair: Drywall contractors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_WORK_INTERIOR_ONLY_ELECTRIC_CONTRACTING]:
    'Electrical work (interior only): Electric contracting',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION]:
    'Fence installation and repair: Chain link fence installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.FLOOR_COVERING_INSTALLATION_NO_CERAMIC_TILESTONE_FLOOR_LAYING_TILE_HARDWOOD_VINYL]:
    'Floor covering installation (no ceramic tile/stone): Floor laying, finising, refinising, tile, hardwood, vinyl',
  [HISCOX_BUSINESS_CLASS_CODES_V4.GLASS_INSTALLATION_AND_REPAIR_NO_AUTO_WORK_DECORATIVE_GLASS_MIRROR_GLAZING_WINDOW_PANE_SHEET]:
    'Glass installation and repair (no auto work): Decorative glass, mirror, glazing, window, pane, sheet',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING]:
    'Handyperson (no roof work): Handyman construction service, residential building',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING]:
    'Handyperson (no roof work): Handyman construction service, commercial and institutional building',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING]:
    'Heating and air conditioning installation and repair (no liquefied petroleum gas (LPG)): Air system balancing and testing',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS]:
    'Heating and air conditioning installation and repair (no liquefied petroleum gas (LPG)): Air-conditioning equipment (except room units) merchant wholesalers',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CONCRETE_FINISHING]:
    'Interior finishing work: Concrete finishing',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ACOUSTICAL_CEILING_TILE_AND_PANEL_INSTALLATION]:
    'Interior finishing work: Acoustical ceiling tile and panel installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ASPHALT_WOOD_FLOORING]:
    'Interior finishing work: Asphalt, wood, flooring',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION]:
    'Interior finishing work: Ceramic tile installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_MOLDING_TRIM_WOOD_PLASTIC_PREFABRICATED_WINDOW_INSTALLATION]:
    'Interior finishing work: Molding, trim, wood, plastic, prefabricated, window installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LOCKSMITHS_LOCK_REKEYING_SERVICES]:
    'Locksmiths: Lock rekeying services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.MASONRY_WORK_BRICK_VENEER_INSTALLATION]:
    'Masonry work: Brick veneer, installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PAINTING_INTERIOR_ONLY_PAINTING_EXCEPT_ROOF_CONTRACTORS]:
    'Painting (interior only): Painting (except roof) contractors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_STUCCO_CONTRACTORS]:
    'Plastering or stucco work: Stucco contractors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS]:
    'Plastering or stucco work: Fresco (i.e., decorative plaster finishing) contractors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_COMMERCIAL_OR_INDUSTRIAL_CHILLED_HOT_WATER_SYSTEMS_CONTRACTORS]:
    'Plumbing (commercial or industrial): Chilled, hot, water systems installation, contractors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION]:
    'Plumbing (residential or domestic): Bathroom plumbing fixture and sanitary ware installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_EXTERIOR_ONLY_SIGN_LETTERING_AND_PAINTING_SERVICES]:
    'Sign painting and lettering (exterior only): Sign lettering and painting services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_INTERIOR_ONLY_DISPLAY_LETTERING_SERVICES]:
    'Sign painting and lettering (interior only): Display lettering services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TILE_STONE_MARBLE_MOSAIC_OR_TERRAZZO_WORK_INTERIOR_ONLY_CERAMIC_TILE_INSTALLATION]:
    'Tile, stone, marble, mosaic, or terrazzo work (interior only): Ceramic tile installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS]:
    'Upholstery work: Automotive upholstery shops',
  [HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_UPHOLSTERY_EXCEPT_MOTOR_VEHICLE_REPAIR_SERVICES]:
    'Upholstery work: Upholstery (except motor vehicle) repair services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.WINDOW_CLEANING_NOTHING_ABOVE_15_FEET_WINDOW_CLEANING_SERVICES]:
    'Window cleaning (nothing above 15 feet): Window cleaning services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLICATION_DEVELOPMENT_APPLICATIONS_SOFTWARE_PROGRAMMING_SERVICES_CUSTOM_COMPUTER]:
    'Application development: Applications software programming services, custom computer',
  [HISCOX_BUSINESS_CLASS_CODES_V4.APPLICATION_SERVICE_PROVIDER_APPLICATION_HOSTING]:
    'Application service provider: Application hosting',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_CONSULTING_COMPUTER_HARDWARE_CONSULTING_SERVICES_OR_CONSULTANTS]:
    'Computer consulting: Computer hardware consulting services or consultants',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_PROGRAMMING_SERVICES_CUSTOM_SOFTWARE_SUPPORT]:
    'Computer programming services: Computer programming, custom, computer, support services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COMPUTER_SYSTEMNETWORK_DEVELOPER_LOCAL_AREA_NETWORK_LAN_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES]:
    'Computer system/network developer: Local area network (LAN) computer systems integration design services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DATA_PROCESSING_AUTOMATED_DATA_PROCESSING_SERVICES]:
    'Data processing: Automated data processing services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.DATABASE_DESIGNER_INFORMATION_MANAGEMENT_COMPUTER_SYSTEMS_INTEGRATION_DESIGN_SERVICES]:
    'Database designer: Information management computer systems integration design services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.IT_CONSULTING_COMPUTER_SOFTWARE_CONSULTING_SERVICES_OR_CONSULTANTS]:
    'IT consulting: Computer software consulting services or consultants',
  [HISCOX_BUSINESS_CLASS_CODES_V4.IT_PROJECT_MANAGEMENT_MANAGEMENT_SERVICES_EXCEPT_COMPLETE_OPERATION_OF_CLIENTS_BUSINESS]:
    "IT project management: Management services (except complete operation of client's business)",
  [HISCOX_BUSINESS_CLASS_CODES_V4.IT_SOFTWAREHARDWARE_TRAINING_SERVICES_COMPUTER_OPERATOR_TRAINING]:
    'IT software/hardware training services: Computer operator training',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOFTWARE_DEVELOPMENT_COMPUTER_SOFTWARE_PROGRAM_ANALYSIS_DESIGN_SERVICES]:
    'Software development: Computer, software, program, analysis, design services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_SOFTWARE_PACKAGED_MERCHANT_WHOLESALERS]:
    'Value added reseller of computer hardware: Computer software, packaged, merchant wholesalers',
  [HISCOX_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_EQUIPMENT_STORES]:
    'Value added reseller of computer hardware: Computer equipment stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.VALUE_ADDED_RESELLER_OF_COMPUTER_HARDWARE_COMPUTER_PERIPHERAL_EQUIPMENT_RENTAL_OR_LEASING]:
    'Value added reseller of computer hardware: Computer peripheral equipment rental or leasing',
  [HISCOX_BUSINESS_CLASS_CODES_V4.WEBSITE_DESIGN_WEB_IE_INTERNET_PAGE_DESIGN_SERVICES_CUSTOM]:
    'Website design: Web (i.e., Internet) page design services, custom',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_CABLE_TELEVISION_HOOKUP_CONTRACTORS]:
    'Communication equipment installation (cable/satellite): Cable television hookup contractors',
  [HISCOX_BUSINESS_CLASS_CODES_V4.CABLING_INSTALLATION_COMPUTER_AND_NETWORK_CABLE_INSTALLATION]:
    'Cabling installation: Computer and network cable installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.HOME_THEATER_INSTALLATION_HOME_THEATER_INSTALLATION]:
    'Home theater installation: Home theater installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SECURITY_SYSTEM_INSTALLATION_SECURITY_AND_FIRE_SYSTEM_INSTALLATION_ONLY]:
    'Security system installation: Security and fire system, installation only',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_SOUND_EQUIPMENT_INSTALLATION]:
    'Sound equipment installation: Sound equipment installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.COMMUNICATION_EQUIPMENT_INSTALLATION_CABLESATELLITE_SATELLITE_DISH_HOUSEHOLDTYPE_INSTALLATION]:
    'Communication equipment installation (cable/satellite): Satellite dish, household-type, installation',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_AUTOMOTIVE_AUDIO_EQUIPMENT_STORES]:
    'Sound equipment installation: Automotive audio equipment stores',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SECURITY_SYSTEM_INSTALLATION_BURGLAR_ALARM_SALES_COMBINED_WITH_INSTALLATION_REPAIR_OR_MONITORING_SERVICES]:
    'Security system installation: Burglar alarm sales combined with installation, repair, or monitoring services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.SOUND_EQUIPMENT_INSTALLATION_RADIO_EXCEPT_TWOWAY_RADIO_REPAIR_AND_MAINTENANCE_SERVICES_WITHOUT_RETAILING_NEW_RADIOS]:
    'Sound equipment installation: Radio (except two-way radio) repair and maintenance services without retailing new radios',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEARNING_CENTER_ACADEMIC_TUTORING_SERVICES]:
    'Learning center: Academic tutoring services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.LEARNING_CENTER_TEST_PREP_EXAM_PREPARATION_SERVICES]:
    'Learning center - test prep: Exam preparation services',
  [HISCOX_BUSINESS_CLASS_CODES_V4.PROFESSIONAL_TRAINING_PROFESSIONAL_DEVELOPMENT_TRAINING]:
    'Professional training: Professional development training',
  [HISCOX_BUSINESS_CLASS_CODES_V4.TRAINING_AND_DEVELOPMENT_CONSULTING_PROFESSIONAL_DEVELOPMENT_CONSULTING]:
    'Training and development consulting: Professional development training',
};

export enum HiscoxGlFormDataFieldV4 {
  BUSINESSINFO_COMMERCIALNAME = 'BusinessInfo_CommercialName',
  BUSINESSINFO_HAVEDBAORFKA = 'BusinessInfo_HaveDBAorFKA',
  BUSINESSINFO_DBAORFKANAME = 'BusinessInfo_DBAorFKAName',
  BUSINESSINFO_BUSINESSOWNERSHIPSTRUCTURE = 'BusinessInfo_BusinessOwnershipStructure',
  BUSINESSINFO_NUMOFEMPLOYEES = 'BusinessInfo_NumOfEmployees',
  BUSINESSINFO_CLASSOFBUSINESSCD = 'BusinessInfo_ClassOfBusinessCd',
  BUSINESSINFO_PERSON_NAME_FIRSTNAME = 'BusinessInfo_Person_Name_FirstName',
  BUSINESSINFO_PERSON_NAME_LASTNAME = 'BusinessInfo_Person_Name_LastName',
  BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER = 'BusinessInfo_Person_CommunicationsInfo_PhoneInfo_PhoneNumber',
  BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONEEXTENSION = 'BusinessInfo_Person_CommunicationsInfo_PhoneInfo_PhoneExtension',
  BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR = 'BusinessInfo_Person_CommunicationsInfo_EmailInfo_EmailAddr',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1 = 'BusinessInfo_MailingAddress_AddrInfo_Addr1',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2 = 'BusinessInfo_MailingAddress_AddrInfo_Addr2',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY = 'BusinessInfo_MailingAddress_AddrInfo_City',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD = 'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE = 'BusinessInfo_MailingAddress_AddrInfo_PostalCode',
  BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY = 'BusinessInfo_MailingAddress_AddrInfo_County',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_CARPENTRY = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Carpentry',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_CONSTRUCTIONDEMOLITIONORREMODELING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_ConstructionDemolitionOrRemodeling',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_DELIVERY = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Delivery',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_ELECTRICAL = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Electrical',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_EQUIPMENTINSTALLATIONORREPAIR = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_EquipmentInstallationOrRepair',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_FLOORING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Flooring',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_GENERALCONTRACTING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_GeneralContracting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_HOMEBUILDING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_HomeBuilding',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_LAWNORGARDENMAINTENANCE = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_LawnOrGardenMaintenance',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_PAINTING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Painting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_PLUMBING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Plumbing',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_ROOFING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Roofing',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_TILING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Tiling',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_TRANSPORTATION = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_Transportation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_ANYOTHERPHYSICALMAINTENANCEREPAIRSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_AnyOtherPhysicalMaintenanceRepairServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1 = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_AEROSPACEENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_AerospaceEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_AMUSEMENTRIDESPOOLSORPLAYGROUNDS = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_AmusementRidesPoolsOrPlaygrounds',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_ASBESTOSLEADMOLDEVALUATIONORABETEMENT = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_AsbestosLeadMoldEvaluationOrAbatement',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_BridgesDamsHarborsMinesPiersOrTunnels',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_BUILDINGENVELOPEINSPECTIONS = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_BuildingEnvelopeInspections',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_CONDOMINIUMS = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_Condominiums',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_DESIGNBUILDFIRMS = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_DesignBuildFirms',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_EMERGENCYRESPONSEORHAZARDOUSWASTERCLEANUP = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_EmergencyResponseOrHazardousWasteCleanup',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_FOUNDATIONSHEETINGORRETAININGWALLDESIGN = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_FoundationSheetingOrRetainingWallDesign',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_GEOTECHNICALSOILSENGINEER = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_GeotechnicalSoilsEngineer',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_HOMEINSPECTIONSFORBUYERSORLENDERSORINREALESTATETRANSACTION = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_HomeInspectionsForBuyersOrLendersOrInRealEstateTransaction',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_HYDRAULICFRACKINGHYDROFRACTURINGORFRACKING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_HydraulicFrackingHydrofracturingOrFracking',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_LABORATORYTESTING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_LaboratoryTesting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_LANDACQUISITION = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_LandAcquisition',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_LANDSURVEYINGBOUNDARYSURVEYINGORCONSTRUCTIONSTAKING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_LandSurveyingBoundarySurveyingOrConstructionStaking',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_MARINEENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_MarineEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_MECHANICALENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_MechanicalEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_MININGENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_MiningEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_NUCLEARENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_NuclearEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_OILGASORWELLENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_OilGasOrWellEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_PETROCHEMICALENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_PetroChemicalEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_STRCTURALENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_StrcturalEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_UNDERGROUNDSTORAGETANKSORUTILITIES = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_UndergroundStorageTanksOrUtilities',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_AnEForbiddenProjects1_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CHIROPHYSICALTHERAPY = 'ProductQuoteRqs_ApplicationRatingInfo_ChiroPhysicalTherapy',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_ACTUARIALADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_ActuarialAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_CREDITCOUNSELING = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_CreditCounseling',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_FINANCINGORFINANCIALAUDITING = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_FinancingOrFinancialAuditing',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_HOMEORBUILDINGINSPECTIONS = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_HomeOrBuildingInspections',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_INVESTMENTORTAXADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_InvestmentOrTaxAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_INSURANCEPLACEMENTORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_InsurancePlacementOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LANDACQUISITIONADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_LandAcquisitionAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LAWENFORCEMENTTRAINING = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_LawEnforcementTraining',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LEGALADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_LegalAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LOBBYINGORPOLITICALADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_LobbyingOrPoliticalAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_MEDICALADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_MedicalAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_MERGERSANDACQUISITIONSORBUSINESSVALUATIONS = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_MergersAndAcquisitionsOrBusinessValuations',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_OILGASORPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_OilGasOrPetroleumCOnsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_SALESREPRESENTATIVES = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_SalesRepresentatives',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_SECURITYGUARDSERVICESORPERSONNEL = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_SecurityGuardServicesOrPersonnel',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_ConsultantEligibilitySafety_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS = 'ProductQuoteRqs_ApplicationRatingInfo_EventPlanningSrvcs',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_EQUIPMENTRENTALS = 'ProductQuoteRqs_ApplicationRatingInfo_EventPlanningSrvcs_EquipmentRentals',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_ONLYHOSTINGORCONDUCTINGEVENTSFORYOUROWNBUSINESS = 'ProductQuoteRqs_ApplicationRatingInfo_EventPlanningSrvcs_OnlyHostingOrConductingEventsForYourOwnBusiness',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_STAFFINGORCATERINGSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_EventPlanningSrvcs_StaffingOrCateringServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_TRANSPORTATIONSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_EventPlanningSrvcs_TransportationServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_EventPlanningSrvcs_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3 = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_AMUSEMENTRIDESPOOLSORPLAYGROUNDS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_AmusementRidesPoolsOrPlaygrounds',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_ASBESTOSLEADORMOLDEVALUATIONORABATEMENT = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_AsbestosLeadOrMoldEvaluationOrAbatement',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_BridgesDamsHarborsMinesPiersOrTunnels',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_ConstructionMaintenanceRepairRenovationorPropertyPreservationServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_DESIGNORBUILD = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_DesignOrBuild',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_EMERGENCYRESPONSEORCLEANUPOFHAZARDOUSWASTE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_EmergencyResponseOrCleanupOfHazardousWaste',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_FINANCINGORFINANCIALAUDITING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_FinancingOrFinancialAuditing',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_FOUNDATIONSHEETINGORRETAININGWALLDESIGN = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_FoundationSheetingOrRetainingWallDesign',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_GENERALCONTRACTING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_GeneralContracting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_HYDRAULICFRACTURINGHYDROFRACTURINGORFRACKING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_HydraulicFracturingHydroFracturingOrFracking',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_REALESTATEINSPECTIONS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_RealEstateInspections',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_INSURANCEPLACEMENTORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_InsurancePlacementOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_INVESTMENTORTAXADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_InvestmentOrTaxAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LABORATORYTESTING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_LaboratoryTesting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LANDACQUISITIONADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_LandAcquisitionAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LANDSURVEYINGBOUNDARYSURVEYINGORCONSTRUCTIONSTAKING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_LandSurveyingBoundarySurveyingOrConstructionStaking',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LEGALADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_LegalAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_OILGASPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_OilGasPetroleumConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_REALESTATEAPPRAISALS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_RealEstateAppraisals',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_UNDERGROUNDSTORAGETANKSORUTILITIESORSUBSURFACECONDITION = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_UndergroundStorageTanksOrUtilitiesOrSubsurfaceCondition',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects3_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5 = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_AMUSEMENTRIDESPOOLSORPLAYGROUNDS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_AmusementRidesPoolsOrPlaygrounds',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_ASBESTOSLEADORMOLDEVALUATIONORABATEMENT = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_AsbestosLeadOrMoldEvaluationOrAbatement',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_BRIDGESDAMSHARBORSMINESPIERSSUBDIVISIONSORTUNNELS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_BridgesDamsHarborsMinesPiersSubdivisionsOrTunnels',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_CONSTRUCTIONCOSTESTIMATES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_ConstructionCostEstimates',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_ConstructionMaintenanceRepairRenovationOrPropertyPreservationServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_DESIGNORBUILD = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_DesignOrBuild',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_EMERGENCYRESPONSEORCLEANUPOFHAZARDOUSWASTE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_EmergencyResponseOrCleanupOfHazardousWaste',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_FINANCINGORFINANCIALAUDITING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_FinancingOrFinancialAuditing',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_FOUNDATIONSHEETINGORRETAININGWALLDESIGN = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_FoundationSheetingOrRetainingWallDesign',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_GENERALCONTRACTING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_GeneralContracting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_GROUNDTESTING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_GroundTesting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_HYDRAULICFRACTURINGHYDROFRACTURINGORFRACKING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_HydraulicFracturingHydroFracturingOrFracking',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_REALESTATEINSPECTIONS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_RealEstateInspections',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_INSURANCEPLACEMENTORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_InsurancePlacementOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_INVESTMENTORTAXADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_InvestmentOrTaxAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_LABORATORYTESTING = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_LaboratoryTesting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_LANDACQUISITIONADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_LandAcquisitionAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_LEGALADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_LegalAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_OILGASPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_OilGasPetroleumConsultingOrAdvice',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_QUANTITYSURVEYSORQUANTITYESTIMATES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_QuantitySurveysOrQuantityEstimates',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_REALESTATEAPPRAISALS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_RealEstateAppraisals',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_PHYSICALWORKONUNDERGROUNDSTORAGETANKSORUTILITIESORSUBSURFACECONDITION = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_PhysicalWorkOnUndergroundStorageTanksOrUtilitiesOrSubsurfaceCondition',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjects5_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_ALCOHOLTOBACCOELECTRONICCIGARETTEORVAPORPRODUCTSORSUPPLIES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_AlcoholTobaccoElectronicCigaretteOrVaporProductsOrSupplies',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_AUTOBOATORUTILITYVEHICLESORPARTS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_AutoBoatOrUtilityVehiclesOrParts',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_CHEMICALSORCHEMICALSUPPLIES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_ChemicalsOrChemicalSupplies',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_FARMINGORGARDENINGSUPPLIES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_FarmingOrGardeningSupplies',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_GUNFIREARMORAMMUNITIONPRODUCTSORSUPPLIES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_GunFirearmOrAmmunitionProductsOrSupplies',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_PHARMACEUTICALORMEDICALDEVICES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_PharmaceuticalOrMedicalDevices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_HAZARDOUSMATERIALS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_HazardousMaterials',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_HEALTHFOODHERBSVITAMINSORSUPPLEMENTS = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_HealthFoodHerbsVitaminsOrSupplements',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_HERBICIDESORPESTICIDES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_HerbicidesOrPesticides',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_PETORPETSUPPLIES = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_PetOrPetSupplies',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRBRANCHESOPS = 'ProductQuoteRqs_ApplicationRatingInfo_MSRBranchesOPS',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINDPNTCTRCTR = 'ProductQuoteRqs_ApplicationRatingInfo_MSRIndpntCtrctr',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINVNTRY = 'ProductQuoteRqs_ApplicationRatingInfo_MSRInvntry',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_PMRealEstateServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE = 'ProductQuoteRqs_ApplicationRatingInfo_ProfessionalExperience',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSLICENSESHEALTHSRVCS = 'ProductQuoteRqs_ApplicationRatingInfo_ProfessLicensesHealthSrvcs',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRHOTELHEALTHCARE = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrHotelHealthcare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRRENOVATION = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrRenovation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1 = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_CONSTRUCTIONORREMODELING = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_ConstructionOrRemodeling',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_GENERALCONTRACTING = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_GeneralContracting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_HOMEINSPECTOR = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_HomeInspector',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_INSURANCEAGENTORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_InsuranceAgentOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_JANITORIALORCLEANING = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_JanitorialOrCleaning',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_LANDSCAPINGORLAWNCARE = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_LandscapingOrLawnCare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_MORTGAGEBANKERORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_MortgageBankerOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_PROPERTYDEVELOPERORBUILDER = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_PropertyDeveloperOrBuilder',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_PROPERTYPRESERVATION = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_PropertyPreservation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_REALESTATEAPPRAISER = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_RealEstateAppraiser',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_TITLEAGENTORABSTRACTOR = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_TitleAgentOrAbstractor',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2 = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_CONSTRUCTIONORREMODELING = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_ConstructionOrRemodeling',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_GENERALCONTRACTING = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_GeneralContracting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_HOMEINSPECTOR = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_HomeInspector',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_INSURANCEAGENTORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_InsuranceAgentOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_JANITORIALORCLEANING = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_JanitorialOrCleaning',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_LANDSCAPINGORLAWNCARE = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_LandscapingOrLawnCare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_PROPERTYDEVELOPERORBUILDER = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_PropertyDeveloperOrBuilder',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_PROPERTYPRESERVATION = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_PropertyPreservation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_BOXINGGYM = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc_BoxingGym',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_GYMNASTICSSTUDIO = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc_GymnasticsStudio',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_MARTIALARTSSTUDIO = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc_MartialArtsStudio',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_MUDRACESORCHALLENGECOURSES = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc_MudRacesOrChallengeCourses',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_SPORTORRECREATIONALLEAGUES = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc_SportOrRecreationalLeagues',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1 = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_CONSTRUCTIONORREMODELING = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_ConstructionOrRemodeling',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_GENERALCONTRACTING = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_GeneralContracting',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_HOMEINSPECTOR = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_HomeInspector',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_INSURANCEAGENTORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_InsuranceAgentOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_JANITORIALORCLEANING = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_JanitorialOrCleaning',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_LANDSCAPINGORLAWNCARE = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_LandscapingOrLawnCare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_MORTGAGEBANKERORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_MortgageBankerOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_PROPERTYDEVELOPERORBUILDER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_PropertyDeveloperOrBuilder',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_PROPERTYPRESERVATION = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_PropertyPreservation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_REALESTATEAPPRAISER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_RealEstateAppraiser',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_TITLEAGENTORABSTRACTOR = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_TitleAgentOrAbstractor',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2 = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_HOMEINSPECTOR = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_HomeInspector',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_INSURANCEAGENTORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_InsuranceAgentOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_MORTGAGEBANKERORBROKER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_MortgageBankerOrBroker',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_PROPERTYDEVELOPERORBUILDER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_PropertyDeveloperOrBuilder',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_REALESTATEAPPRAISER = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_RealEstateAppraiser',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_TITLEAGENTORABSTRACTOR = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_TitleAgentOrAbstractor',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_REPropManServices',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_AEROSPACEORAVIATION = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_AerospaceOrAviation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_AMUSEMENTRIDESPOOLORPLAYGROUNDS = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_AmusementRidesPoolOrPlaygrounds',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_BridgesDamsHarborsMinesPiersOrTunnels',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_CONSTRUCTIONSITES = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_ConstructionSites',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_ENVIRONMENTAL = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_Environmental',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_FOODORDRUGPROCESSING = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_FoodOrDrugProcessing',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_FOUNDATIONSHEETINGORRETAININGWALLS = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_FoundationSheetingOrRetainingWalls',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_HAZARDOUSWASTE = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_HazardousWaste',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_MINING = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_Mining',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_OILANDGAS = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_OilAndGas',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_RAILROADS = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_Railroads',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_UNDERGROUNDSTORAGETANKSANDUTILITIES = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_UndergroundStorageTanksAndUtilities',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_SafetyForbiddenProjects_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SIMILARINSURANCE = 'ProductQuoteRqs_ApplicationRatingInfo_SimilarInsurance',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SPAOWNERSHIP = 'ProductQuoteRqs_ApplicationRatingInfo_SpaOwnership',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTDESIGN = 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractDesign',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTINSURANCE = 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractInsurance',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTOTHERSRVCS = 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractOtherSrvcs',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS = 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractProfSrvcs',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTREPAIR = 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractRepair',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTSRVCSDESCRIBE = 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractSrvcsDescribe',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT3 = 'ProductQuoteRqs_ApplicationRatingInfo_SupplyManufactDistbtGoodsOrProductsPercent3',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE2 = 'ProductQuoteRqs_ApplicationRatingInfo_SupplyManufactDistbtGoodsOrProductsWebsite2',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE4 = 'ProductQuoteRqs_ApplicationRatingInfo_SupplyManufactDistbtGoodsOrProductsWebsite4',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK = 'ProductQuoteRqs_ApplicationRatingInfo_TangibleGoodWork',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKDESCRIBE = 'ProductQuoteRqs_ApplicationRatingInfo_TangibleGoodWorkDescribe',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKTRADESMEN = 'ProductQuoteRqs_ApplicationRatingInfo_TangibleGoodWorkTradesmen',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2 = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_COLLEGEORUNIVERSITY = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2_CollegeOrUniversity',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_DAYCAREORELDERLYFACILITY = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2_DaycareOrElderlyFacility',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_PRESCHOOL = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2_Preschool',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_PUBLICPRIVATECHARTERSCHOOL = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2_PublicPrivateCharterSchool',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_TECHNICALORVOCATIONSCHOOL = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility2_TechnicalOrVocationSchool',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3 = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_ADULTORCHILDCARE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_AdultOrChildCare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_AFTERSCHOOLCARE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_AfterSchoolCare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_ANIMAL = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_Animal',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_ARCHITECTUREORENGINEERING = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_ArchitectureOrEngineering',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_AVIATION = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_Aviation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_BEHINDTHEWHEELDRIVING = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_BehindTheWheelDriving',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_CONSTRUCTIONMANAGEMENT = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_ConstructionManagement',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_COOKINGORFOODPREPARATION = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_CookingOrFoodPreparation',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_DAYCAREORCHILDCARE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_DaycareOrChildcare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_DROPINCARE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_DropInCare',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_FITNESS = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_Fitness',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_LAWENFORCEMENT = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_LawEnforcement',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_MEDICAL = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_Medical',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_MEDICALTRAININGORSERVICES = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_Animal',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_SAFETY = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_Safety',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_SECURITYGUARD = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility3_SecurityGuard',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4 = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility4',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_ATLEASTANNUALTHIRDPARTYBACKGROUNDCHECKS = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility4_AtLeastAnnualThirdPartyBackgroundChecks',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_NONEOFTHEABOVE = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility4_NoneOfTheAbove',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_NOSEXUALMISCONDUCTBYWORKERS = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility4_NoSexualMisconductByWorkers',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_THIRDPARTYBACKGROUNDCHECKS = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility4_ThirdPartyBackgroundChecks',
  PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_WRITTENPOLICYZEROTOLERANCESEXUALMISCONDUCT = 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility4_WrittenPolicyZeroToleranceSexualMisconduct',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_QUOTEID = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_QuoteID',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_QUOTERQDT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_QuoteRqDt',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_STATEORPROVCD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_StateOrProvCd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_CoverageStartDate',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_ADDR1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_Addr1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_ADDR2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_Addr2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_CITY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_City',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_STATEORPROVCD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_StateOrProvCd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_POSTALCODE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_PostalCode',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_COUNTY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_County',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_AGEOFBLDNG = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AgeOfBldng',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ANOTHERPARTYINTERESTBLDNG = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AnotherPartyInterestBldng',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYLIMIT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_BsnsPrsnlPropertyLimit',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYDED = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_BsnsPrsnlPropertyDed',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDNLINTRSTEXPLAIN = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AddnlIntrstExplain',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTNAME = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AdditionalInterestName',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTMAILINGADD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AdditionalInterestMailingAdd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTAPPLIESTOBUILDING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AdditionalInterestAppliesToBuilding',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTTYPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AdditionalInterestType',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTDESCRIPTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_AdditionalInterestDescription',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BASEMENT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_Basement',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BPPINVEHICLE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_BPPInVehicle',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BUILDINGCONSTRUCTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_BuildingConstruction',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BUILDINGOWNERSHIP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_BuildingOwnership',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_EstimatedAnnualRevenue',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_EstmtdAnnualCommissions',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_EstmtdPayrollExpense',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESCALATORS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_Escalators',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESCALATORSCOUNT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_EscalatorsCount',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ENTITYTYPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_EntityType',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIRECLASS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_FireClass',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIREDISTRICT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_FireDistrict',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIREHYDRANTDISTANCE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_FireHydrantDistance',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIRESTATIONDISTANCE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_FireStationDistance',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneAL',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALCERT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneALCert',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALCOUNTY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneALCounty',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALROOFAGE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneALRoofAge',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALROOFTYPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneALRoofType',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALZONE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneALZone',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANERESISTANT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_HurricaneResistant',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_INDINSPECTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_IndInspection',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_MULTIPLEOCCUPANTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_MultipleOccupants',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_NUMOFEMPLOYEES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_NumOfEmployees',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_NUMOFSTORIESINBLDNG = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_NumOfStoriesInBldng',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_OPERATEDFROMHOME = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_OperatedFromHome',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_PROFMGMTTEAM = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_ProfMgmtTeam',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_REPLACEMENTCOST = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_ReplacementCost',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ROOF = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_Roof',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_SquareFeetOccupiedByYou',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLAN = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_StrctrlAlterationsPlan',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLANDESCRIBE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_StrctrlAlterationsPlanDescribe',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SUBSCRIBEFIREPROTECTIONSRVC = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_SubscribeFireProtectionSrvc',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormAL',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMROOFCOVER = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormRoofCover',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSC = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSC',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCDOOR = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCDoor',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCOPENING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCOpening',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCPROOF = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCProof',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFDECK = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCRoofDeck',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFSHAPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCRoofShape',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCWALL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCWall',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCWATERRESISTANCE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary_AddrInfo_RatingInfo_WindstormSCwaterResistance',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_ADDR1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_Addr1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_ADDR2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_Addr2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_CITY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_City',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_STATEORPROVCD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_StateOrProvCd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_POSTALCODE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_PostalCode',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_COUNTY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_County',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_AGEOFBLDNG = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AgeOfBldng',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ANOTHERPARTYINTERESTBLDNG = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AnotherPartyInterestBldng',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYLIMIT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_BsnsPrsnlPropertyLimit',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYDED = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_BsnsPrsnlPropertyDed',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDNLINTRSTEXPLAIN = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AddnlIntrstExplain',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTNAME = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AdditionalInterestName',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTMAILINGADD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AdditionalInterestMailingAdd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTAPPLIESTOBUILDING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AdditionalInterestAppliesToBuilding',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTTYPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AdditionalInterestType',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTDESCRIPTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_AdditionalInterestDescription',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BASEMENT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_Basement',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BPPINVEHICLE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_BPPInVehicle',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BUILDINGCONSTRUCTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_BuildingConstruction',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BUILDINGOWNERSHIP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_BuildingOwnership',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_EstimatedAnnualRevenue',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_EstmtdAnnualCommissions',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_EstmtdPayrollExpense',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESCALATORS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_Escalators',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESCALATORSCOUNT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_EscalatorsCount',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ENTITYTYPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_EntityType',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIRECLASS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_FireClass',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIREDISTRICT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_FireDistrict',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIREHYDRANTDISTANCE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_FireHydrantDistance',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIRESTATIONDISTANCE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_FireStationDistance',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneAL',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALCERT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneALCert',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALCOUNTY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneALCounty',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALROOFAGE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneALRoofAge',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALROOFTYPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneALRoofType',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALZONE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneALZone',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANERESISTANT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_HurricaneResistant',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_INDINSPECTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_IndInspection',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_MULTIPLEOCCUPANTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_MultipleOccupants',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_NUMOFEMPLOYEES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_NumOfEmployees',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_NUMOFSTORIESINBLDNG = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_NumOfStoriesInBldng',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_OPERATEDFROMHOME = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_OperatedFromHome',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_PROFMGMTTEAM = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_ProfMgmtTeam',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_REPLACEMENTCOST = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_ReplacementCost',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ROOF = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_Roof',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLAN = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_StrctrlAlterationsPlan',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLANDESCRIBE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_StrctrlAlterationsPlanDescribe',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_SUBSCRIBEFIREPROTECTIONSRVC = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_SubscribeFireProtectionSrvc',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormAL',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMROOFCOVER = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormRoofCover',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSC = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSC',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCDOOR = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCDoor',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCOPENING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCOpening',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCPROOF = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCProof',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFDECK = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCRoofDeck',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFSHAPE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCRoofShape',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCWALL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCWall',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCWATERRESISTANCE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Secondary_AddrInfo_RatingInfo_WindstormSCwaterResistance',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BeautyServices2GL',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GLOPERATESAUNASORSTEAMROOMS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BeautyServices2GLOperateSaunasOrSteamRooms',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GLOPERATETANNINGBEDSORBOOTHS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BeautyServices2GLOperateTanningBedsOrBooths',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GLNONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BeautyServices2GLNoneOfTheAbove',
  // Note: adding ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ClassOfBusinessCd to be type it and remove it, since it is on first step
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CLASSOFBUSINESSCD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ClassOfBusinessCd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_GENERALCONTRACTING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_GeneralContracting',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_LANDACQUISITION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_LandAcquisition',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_LAWENFORCEMENTTRAINING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_LawEnforcementTraining',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_MEDICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_MedicalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_OILGASORPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_OilGasOrPetroleumConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_SAFETYCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_SafetyConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_SALESREPRESENTATIVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_SalesRepresentative',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityConGLBOP_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_GENERALCONTRACTING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_GeneralContracting',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_LANDACQUISITION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_LandAcquisition',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_LAWENFORCEMENTTRAINING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_LawEnforcementTraining',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_MEDICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_MedicalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_OILGASORPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_OilGasOrPetroleumConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_SAFETYCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_SafetyConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_SALESREPRESENTATIVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_SalesRepresentative',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityEDUGLBOP_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_GENERALCONTRACTING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_GeneralContracting',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_LANDACQUISITION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_LandAcquisition',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_LAWENFORCEMENTTRAINING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_LawEnforcementTraining',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_MEDICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_MedicalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_OILGASORPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_OilGasOrPetroleumConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_SAFETYCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_SafetyConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_SALESREPRESENTATIVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_SalesRepresentative',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ConsultantEligibilityHRGLBOP_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_ESTMTDPAYROLLSC = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_EstmtdPayrollSC',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_AUTOBOATVEHICLECLEANINGORDETAILING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_AutoBoatVehicleCleaningOrDetailing',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_COMMERCIALWINDOWCLEANING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_CommercialWindowCleaning',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_ConstructionMaintenanceRepairRenovationOrPropertyPreservationServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_EXTERIORCLEANINGFORCOMMERCIALSURFACESBUILDINGSORSTRUCTURES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_ExteriorCleaningForCommercialSurfacesBuildingsOrStructures',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_HAZARDOUSWASTECLEANINGORREMOVAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_HazardousWasteCleaningOrRemoval',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_LAUNDRYORDRYCLEANINGSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_LaundryOrDryCleaningServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_PETORANIMALGROOMING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_PetOrAnimalGrooming',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_SANDBLASTINGSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_SandblastingServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_AUTOBOATVEHICLECLEANINGORDETAILING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_AutoBoatVehicleCleaningOrDetailing',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_COMMERCIALWINDOWCLEANING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_CommercialWindowCleaning',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_ConstructionMaintenanceRepairRenovationOrPropertyPreservationServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_EXTERIORCLEANINGFORCOMMERCIALSURFACESBUILDINGSORSTRUCTURES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_ExteriorCleaningForCommercialSurfacesBuildingsOrStructures',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_HAZARDOUSWASTECLEANINGORREMOVAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_HazardousWasteCleaningOrRemoval',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_LAUNDRYORDRYCLEANINGSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_LaundryOrDryCleaningServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_PETORANIMALGROOMING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_PetOrAnimalGrooming',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_SANDBLASTINGSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_SandblastingServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsJanitorial1_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsLandscapers',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_EXCAVATIONBELOWTWOFEET = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsLandscapers_ExcavationBelowTwoFeet',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_PROPERTYPRESERVATIONSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsLandscapers_PropertyPreservationServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_TREEREMOVAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsLandscapers_TreeRemoval',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsLandscapers_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_ALLREVENUEFROMECOMMERCEORINTERNETSALES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_AllRevenueFromEcommerceOrInternetSales',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_ALCOHOLTOBACCOELECTRONICCIGARETTEORVAPORSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_AlcoholTobaccoElectronicCigaretteOrVaporStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_AUTOBOATORUTILITYVEHICLEPARTSORDEALERS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_AutoBoatOrUtilityVehiclePartsOrDealers',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_AUTOREPAIRORGASSTATIONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_AutoRepairOrGasStations',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_CONVENIENCESTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_ConvenienceStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_DEPARTMENTSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_DepartmentStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_FARMINGORGARDENINGSUPPLYSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_FarmingOrGardeningSupplyStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_GROCERYSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_GroceryStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_GUNFIREARMORAMMUNITIONSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_GunFirearmOrAmmunitionStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_HEALTHFOODVITAMINORDRUGSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_HealthFoodVitaminOrDrugStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_PETORPETSUPPLYSTORES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_PetOrPetSupplyStores',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_RESTAURANTSBARSORNIGHTCLUBS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_RestaurantsBarsOrNightclubs',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ForbiddenProjectsRetail_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_CATHETERIZATION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_Catheterization',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_DIAGNOSINGCONDITIONSDISORDERSORDISEASES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_DiagnosingConditionsDisordersOrDiseases',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_HEALTHCARESTAFFINGORHOMEHEALTHSTAFFINGAGENCYSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_HealthcareStaffingOrHomeHealthStaffingAgencyServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_HOLISTICMEDICINE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_HolisticMedicine',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_INFUSIONTHERAPY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_InfusionTherapy',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_INJECTIONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_Injections',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_LASERTREATMENTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_LaserTreatments',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_SERVICESRELATEDCANNABISNOTLIMITEDTOMEDICALMARIJUANADISPENSING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_ServicesRelatedCannabisNotLimitedToMedicalMarijuanaDispensing',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_ORGANSPERMBLOODSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_OrganSpermBloodServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PATIENTCLIENTCAREFACILITYANYTYPEYOUOWNORRENT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_PatientClientCareFacilityAnyTypeYouOwnOrRent',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PATIENTTRANSPORTATION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_PatientTransportation',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PEDIATRICORDEVELOPMENTLYDISABLEDCLIENTCARE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_PediatricOrDevelopmentlyDisabledClientCare',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PHYSICALTHERAPY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_PhysicalTherapy',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_RESEARCHOREXPERIMENTALSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_ResearchOrExperimentalServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_SELFDIRECTEDCAREFAMILYCAREGIVERNURSINGWAIVERPROGRAM = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_SelfDirectedCareFamilyCaregiverNursingWaiverProgram',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_STRESSTESTSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_StressTestServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_WOUNDCARE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_WoundCare',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MarketingEligibilityGLBOP',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_LOBBYINGORPOLITICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MarketingEligibilityGLBOP_LobbyingOrPoliticalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_MEDICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MarketingEligibilityGLBOP_MedicalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_SALESREPRESENTATIVEORRETAIL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MarketingEligibilityGLBOP_SalesRepresentativeOrRetail',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MarketingEligibilityGLBOP_NoneOfTheAbove',
  // Note: Added to override question
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_ALCOHOLICBEVERAGESORPRODUCTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_AlcoholicBeveragesOrProducts',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_ANIMALLIVESTOCKFEED = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_AnimalLivestockFeed',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_PRODUCTSREQUIRINGPRESCRIPTION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_ProductsRequiringPrescription',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_BABYFOOD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_BabyFood',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_BODYPIERCINGS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_BodyPiercings',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_CANNABISCBDOILORINFUSEDPRODUCTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_CannabisCBDOilOrInfusedProducts',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_DIETPRODUCTSCLEANSESORPILLSASWEIGHTLOSS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_DietProductsCleansesOrPillsAsWeightLoss',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_ENERGYDRINKS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_EnergyDrinks',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_KAVA = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_Kava',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_MEDICALINVASIVEPRODUCTSWITHINBODY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_MedicalInvasiveProductsWithinBody',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_NUTRACEUTICALSVITAMINSSUPPLEMENTSSUPPOSITORIES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_NutraceuticalsVitaminsSupplementsSuppositories',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_PERMANENTTATTOOS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_PermanentTattoos',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_TOBACCORELATEDPRODUCTSECIGARETTESANDSMOKELESS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_TobaccoRelatedProductsECigarettesAndSmokeless',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_TOPICALPRODUCTSSOAPSLOTIONSCOSMETICSANDBATH = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_TopicalProductsSoapsLotionsCosmeticsAndBath',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_UNMANNEDAIRCRAFTSYSTEMS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_UnmannedAircraftSystems',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_WEAPONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_Weapons',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenProducts_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_COMMERCIALFARMING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_CommercialFarming',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_OUTOFCOMPLIANCECOMPANIESOROPERATIONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_OutOfComplianceCompaniesOrOperations',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_GROCERYSTORESANDSUPERMARKETS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_GroceryStoresAndSupermarkets',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_HEALTHCOACHING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_HealthCoaching',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_IMPORTEROFPRODUCEORDAIRYMORETHAN20PERCENT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_ImporterOfProduceOrDairyMoreThan20Percent',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_IMPORTERSEAFOODMEATPOULTRY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_ImporterSeafoodMeatPoultry',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_NUTRITIONCOUNSELING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_NutritionCounseling',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_RESTAURANTCAFEBAKERYTAVERNFIXEDLOCATION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_RestaurantCafeBakeryTavernFixedLocation',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_WEAPONSSALES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_WeaponsSales',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MFSForbiddenServices_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MOBILEEQUIPEXCLUDEDSNOWBLOWING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_MobileEquipExcludedSnowBlowing',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFEMPLOYEESINCOMP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_NumOfEmployeesInComp',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFOWNERS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_NumOfOwners',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_EMPLOYEEWAGEROLL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_EmployeeWageroll',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_OPERATEDFROMHOME = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_OperatedFromHome',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_ADULTDAYCARE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_AdultDaycare',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_CHILDCAREORNANNY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_ChildcareOrNanny',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_CLEANINGORJANITORIAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_CleaningOrJanitorial',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_HEALTHCAREORRELATEDSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_HealthcareOrRelatedServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_MOVING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_Moving',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_PETSITTINGORPETCARE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_PetSittingOrPetCare',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_SOCIALWORK = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_SocialWork',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_TRANSPORTATIONOFPEOPLEORGOODS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_TransportationOfPeopleOrGoods',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_PCEligibilityGLBOP_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_AEROSPACECONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_AerospaceConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_APPRAISALS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_Appraisals',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_ARCHITECTUREORENGINEERINGADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_ArchitectureOrEngineeringAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_BACKGROUNDCHECKS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_BackgroundChecks',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_CONSTRUCTIONMANAGEMENTORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_ConstructionManagementOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_CLINICALORMEDICALTRIALS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_ClinicalOrMedicalTrials',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_ENVIRONMENTALCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_EnvironmentalConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_GENERALCONTRACTING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_GeneralContracting',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_LANDACQUISITION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_LandAcquisition',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_LAWENFORCEMENTTRAINING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_LawEnforcementTraining',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_LOBBYINGORPOLITICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_LobbyingOrPoliticalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_MEDICALADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_MedicalAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_MININGCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_MiningConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_OILGASPETROLEUMCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_OilGasPetroleumConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_SAFETYCONSULTINGORADVICE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_SafetyConsultingOrAdvice',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_SALESREPRESENTATIVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_SalesRepresentative',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibilityGLBOP_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SECONDARYCOBSMALLCONTRACTORS_CLASSOFBUSINESSCD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_AIRPORTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_Airports',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_AMUSEMENTRIDESPOOLSORPLAYGROUNDS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_AmusementRidesPoolsOrPlaygrounds',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_ASBESTOSLEADORMOLDEVALUATIONORABATEMENT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_AsbestosLeadOrMoldEvaluationOrAbatement',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_BridgesDamsHarborsMinesPiersOrTunnels',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_BLASTINGORDEMOLITION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_BlastingOrDemolition',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_CRANEOPERATION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_CraneOperation',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_EMERGENCYSYSTEMS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_EmergencySystems',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_FIRESUPPRESSION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_FireSuppression',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_FOUNDATIONSHEETINGORRETAININGWALLS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_FoundationSheetingOrRetainingWalls',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_HAZARDOUSMATERIALORWASTEREMOVAL = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_HazardousMaterialOrWasteRemoval',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_HYDRAULICFRACTURINGHYDROFRACTURINGORFRACKING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_HydraulicFracturingHydroFracturingOrFracking',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_LANDFILLS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_Landfills',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_OILGASORWELLS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_OilGasOrWells',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_PROESSPIPING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_ProessPiping',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_ROOFING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_Roofing',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_SCAFFOLDINGOPERATION = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_ScaffoldingOperation',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_UNDERGROUNDSTORAGETANKSORUTILITIES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_UndergroundStorageTanksOrUtilities',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_ANYOTHERSIMILARLYHAZARDOUSPROJECTSORMATERIALS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_AnyOtherHazardousProjectsOrMaterials',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SCForbiddenProjects_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SpaOwnership',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SpaOwnership2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP3 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SpaOwnership3',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProducts',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProducts2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsAandE',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsActivity1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsActivity2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSASSOCWEBSITE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsAssocWebsite',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDescribe',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDescribe1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBEAANDE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDescribeAandE',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDetailProcedures',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDetailProcedures1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDetailRecalls',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDetailRecalls1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDetailRecallsDescribe',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsDetailRecallsDescribe1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITED = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsLimited',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITEDPHOTO = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsLimitedPhoto',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANOTHERS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsManOthers',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTURED = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsManufactured',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTUREDDESCRIBE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsManufacturedDescribe',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsManWhere',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsManWhere1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSOWNERSHIP = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsOwnership',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsPercent',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsPercent1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsPercent2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT4 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsPercent4',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsProceduresDescribe',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsProceduresDescribe1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsUsed',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsUsed1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsUsed2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsWebsite',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsWebsite1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE5 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsWebsite5',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsWhoManu',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SupplyManufactDistbtGoodsOrProductsWhoManu1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TangibleGoodWork1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKDESCRIBE1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TangibleGoodWorkDescribe1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKIT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TangibleGoodWorkIT',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKTRADESMEN1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TangibleGoodWorkTradesmen1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TechSpclstActvty',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_HARDWAREDESIGNORMANUFACTURING = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TechSpclstActvty_HardwareDesignOrManufacturing',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_INSTALLATIONORSERVICETECHNICIANSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TechSpclstActvty_InstallationOrServiceTechnicianServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_REPAIRORMECHANICSERVICES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TechSpclstActvty_RepairOrMechanicServices',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_NONEOFTHEABOVE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_TechSpclstActvty_NoneOfTheAbove',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_CGLSTATEMENT1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_ProductAcknowledgements_CGLStatement1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_ProductAcknowledgements_ExcludedActivities',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_CoverQuoteRq_RatingInfo_AggLOI',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_CoverQuoteRq_RatingInfo_LOI',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_CoverQuoteRq_RatingInfo_Deductible',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_GLPLUSCOVERQUOTERQ_AGGLOI = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_GLPlusCoverQuoteRq_AggLOI',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_GLPLUSCOVERQUOTERQ_LOI = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_GLPlusCoverQuoteRq_LOI',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_GLPLUSCOVERQUOTERQ_DEDUCTIBLE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_GLPlusCoverQuoteRq_Deductible',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HireNonOwnedAutoCoverQuoteRq_',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HireNonOwnedAutoCoverQuoteRq_RatingInfo_LOI',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_HireNonOwnedAutoCoverQuoteRq_RatingInfo_Deductible',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_TRIACoverQuoteRq_',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_TRIACoverQuoteRq_CoverId',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PrimaryNonContribCoverQuoteRq_',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PrimaryNonContribCoverQuoteRq_CoverId',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_WaiverOfSubrogationCoverQuoteRq_',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_WaiverOfSubrogationCoverQuoteRq_CoverId',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PropertyAndEquipmentCoverQuoteRq_',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PropertyAndEquipmentCoverQuoteRq_CoverId',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_LOI = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PropertyAndEquipmentCoverQuoteRq_RatingInfo_LOI',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PropertyAndEquipmentCoverQuoteRq_RatingInfo_Deductible',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_BUSINESSPROPANDEQUIPPREMISELIMIT = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_PropertyAndEquipmentCoverQuoteRq_RatingInfo_BusinessPropandEquipPremiseLimit',
  ACKNOWLEDGEMENTS_AGREEDISAGREESTATEMENTS = 'Acknowledgements_AgreeDisagreeStatements',
  ACKNOWLEDGEMENTS_APPLICATIONAGREEMENTSTATEMENT = 'Acknowledgements_ApplicationAgreementStatement',
  ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED = 'Acknowledgements_ApplicantAuthorized',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP = 'Acknowledgements_BusinessOwnership_BusinessOwnership',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGCOMP = 'Acknowledgements_BusinessOwnership_BusOwnControllingComp',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGRELBUS = 'Acknowledgements_BusinessOwnership_BusOwnControllingRelBus',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGRELINS = 'Acknowledgements_BusinessOwnership_BusOwnControllingRelIns',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSCOV = 'Acknowledgements_BusinessOwnership_BusOwnInsCov',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSCOVPURPOSE = 'Acknowledgements_BusinessOwnership_BusOwnInsCovPurpose',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSREQ = 'Acknowledgements_BusinessOwnership_BusOwnInsReq',
  ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNPERCENTCONTROL = 'Acknowledgements_BusinessOwnership_BusOwnPercentControl',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYou',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUACT = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYouAct',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUACTDATE = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYouActDate',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUAMOUNT = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYouAmount',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUAVOID = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYouAvoid',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUCLAIMDATE = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYouClaimDate',
  ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUNAME = 'Acknowledgements_ClaimsAgainstYou_ClaimsAgainstYouName',
  ACKNOWLEDGEMENTS_DEDUCTIBLESTATEMENT = 'Acknowledgements_DeductibleStatement',
  ACKNOWLEDGEMENTS_EMAILCONSENT = 'Acknowledgements_EmailConsent',
  ACKNOWLEDGEMENTS_EMAILCONSENT2 = 'Acknowledgements_EmailConsent2',
  ACKNOWLEDGEMENTS_EMAILDELIVERYSTATEMENT = 'Acknowledgements_EmailDeliveryStatement',
  ACKNOWLEDGEMENTS_FRAUDWARNING = 'Acknowledgements_FraudWarning',
  ACKNOWLEDGEMENTS_HISCOXSTATEMENT = 'Acknowledgements_HiscoxStatement',
  ACKNOWLEDGEMENTS_INFORMATIONCONFIRMAGREEMENT = 'Acknowledgements_InformationConfirmAgreement',
  ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE = 'Acknowledgements_InsuranceDecline_InsuranceDecline',
  ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINEACTION = 'Acknowledgements_InsuranceDecline_InsuranceDeclineAction',
  ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECANCEL = 'Acknowledgements_InsuranceDecline_InsuranceDeclineCancel',
  ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECANCELDATE = 'Acknowledgements_InsuranceDecline_InsuranceDeclineCancelDate',
  ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECARRIER = 'Acknowledgements_InsuranceDecline_InsuranceDeclineCarrier',
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS = 'Acknowledgements_MergerAcquisitions',
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITION = 'Acknowledgements_MergerAcquisitions_MergerAcquisition',
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION = 'Acknowledgements_MergerAcquisitions_MergerAcquisitionsAction',
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE = 'Acknowledgements_MergerAcquisitions_MergerAcquisitionsActionPlace',
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY = 'Acknowledgements_MergerAcquisitions_MergerAcquisitionsActionWhy',
  ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY = 'Acknowledgements_MergerAcquisitions_MergerAcquisitionsEntity',
  ACKNOWLEDGEMENTS_STATESPCFCFRAUDWARNING = 'Acknowledgements_StateSpcfcFraudWarning',
  // INVALID XPaths in DQs
  // NOTE: these XPaths are needed because the DQs is either sending invalid XPaths that we need to type to override in cobOverridesV4,
  // or missing XPaths that are needed to conditionally show another question. Some of these will be removed when Hiscox fixes their API.
  ACKNOWLEDGEMENTSEMAILCONSENT = 'AcknowledgementsEmailConsent',
  ACKNOWLEDGEMENTSEMAILCONSENT2 = 'AcknowledgementsEmailConsent2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIESEXCLUDEDACTIVITIES = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_ProductAcknowledgements_ExcludedActivitiesExcludedActivities',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_RatingInfo_EstmtdAnnualCommissions ',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_RatingInfo_EstimatedAnnualRevenue',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_RatingInfo_EstmtdPayrollExpense',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_RatingInfo_SquareFeetOccupiedByYou',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_ADDR1 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_Addr1',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_ADDR2 = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_Addr2',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_CITY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_City',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_COUNTY = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_County',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_POSTALCODE = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_PostalCode',
  PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_STATEORPROVCD = 'ProductQuoteRqs_GeneralLiabilityQuoteRq_Locations_Primary - or - Secondary_AddrInfo_StateOrProvCd',
}

/*
  To find all hiscox fields it is possible to search the form tree after creation
  to find checkbox groups with a `none of the above` option. However adding
  validators at the beginning of a form was deemed more important for consistency.
 */
export const GL_V4_FIELDS_WITH_NONE_OF_THE_ABOVE_OPTION = _.reduce(
  Object.values(HiscoxGlFormDataFieldV4),
  (fieldsWithNoneOfTheAbove: HiscoxGlFormDataFieldV4[], dataField) => {
    // This find and remove of '_NoneOfTheAbove' works because the way hiscox fields are mapped
    // the parent of '_NoneOfTheAbove' will not have a suffix. The suffix more or less corresponds
    // to the checkbox value
    if (dataField.endsWith('_NoneOfTheAbove')) {
      fieldsWithNoneOfTheAbove.push(
        dataField.replace('_NoneOfTheAbove', '') as HiscoxGlFormDataFieldV4
      );
    }
    return fieldsWithNoneOfTheAbove;
  },
  []
);

export type HiscoxGlFormDataFieldNamesV4 = keyof typeof HiscoxGlFormDataFieldV4;

export const GL_ORDERED_FIELDS_V4 = [
  HiscoxGlFormDataFieldV4.BUSINESSINFO_COMMERCIALNAME,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_BUSINESSOWNERSHIPSTRUCTURE,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_CLASSOFBUSINESSCD,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_FIRSTNAME,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_LASTNAME,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONEEXTENSION,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE,
  HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_CARPENTRY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_CONSTRUCTIONDEMOLITIONORREMODELING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_DELIVERY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_ELECTRICAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_EQUIPMENTINSTALLATIONORREPAIR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_FLOORING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_HOMEBUILDING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_LAWNORGARDENMAINTENANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_PAINTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_PLUMBING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_ROOFING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_TILING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_TRANSPORTATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_ANYOTHERPHYSICALMAINTENANCEREPAIRSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_AEROSPACEENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_AMUSEMENTRIDESPOOLSORPLAYGROUNDS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_ASBESTOSLEADMOLDEVALUATIONORABETEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_BUILDINGENVELOPEINSPECTIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_CONDOMINIUMS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_DESIGNBUILDFIRMS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_EMERGENCYRESPONSEORHAZARDOUSWASTERCLEANUP,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_FOUNDATIONSHEETINGORRETAININGWALLDESIGN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_GEOTECHNICALSOILSENGINEER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_HOMEINSPECTIONSFORBUYERSORLENDERSORINREALESTATETRANSACTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_HYDRAULICFRACKINGHYDROFRACTURINGORFRACKING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_LABORATORYTESTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_LANDACQUISITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_LANDSURVEYINGBOUNDARYSURVEYINGORCONSTRUCTIONSTAKING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_MARINEENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_MECHANICALENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_MININGENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_NUCLEARENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_OILGASORWELLENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_PETROCHEMICALENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_STRCTURALENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_UNDERGROUNDSTORAGETANKSORUTILITIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CHIROPHYSICALTHERAPY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_ACTUARIALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_CREDITCOUNSELING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_FINANCINGORFINANCIALAUDITING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_HOMEORBUILDINGINSPECTIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_INVESTMENTORTAXADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_INSURANCEPLACEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LANDACQUISITIONADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LAWENFORCEMENTTRAINING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LEGALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_LOBBYINGORPOLITICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_MEDICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_MERGERSANDACQUISITIONSORBUSINESSVALUATIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_OILGASORPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_SALESREPRESENTATIVES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_SECURITYGUARDSERVICESORPERSONNEL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_EQUIPMENTRENTALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_ONLYHOSTINGORCONDUCTINGEVENTSFORYOUROWNBUSINESS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_STAFFINGORCATERINGSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_TRANSPORTATIONSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_AMUSEMENTRIDESPOOLSORPLAYGROUNDS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_ASBESTOSLEADORMOLDEVALUATIONORABATEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_DESIGNORBUILD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_EMERGENCYRESPONSEORCLEANUPOFHAZARDOUSWASTE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_FINANCINGORFINANCIALAUDITING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_FOUNDATIONSHEETINGORRETAININGWALLDESIGN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_HYDRAULICFRACTURINGHYDROFRACTURINGORFRACKING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_REALESTATEINSPECTIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_INSURANCEPLACEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_INVESTMENTORTAXADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LABORATORYTESTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LANDACQUISITIONADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LANDSURVEYINGBOUNDARYSURVEYINGORCONSTRUCTIONSTAKING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_LEGALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_OILGASPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_REALESTATEAPPRAISALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_UNDERGROUNDSTORAGETANKSORUTILITIESORSUBSURFACECONDITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_AMUSEMENTRIDESPOOLSORPLAYGROUNDS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_ASBESTOSLEADORMOLDEVALUATIONORABATEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_BRIDGESDAMSHARBORSMINESPIERSSUBDIVISIONSORTUNNELS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_CONSTRUCTIONCOSTESTIMATES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_DESIGNORBUILD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_EMERGENCYRESPONSEORCLEANUPOFHAZARDOUSWASTE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_FINANCINGORFINANCIALAUDITING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_FOUNDATIONSHEETINGORRETAININGWALLDESIGN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_GROUNDTESTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_HYDRAULICFRACTURINGHYDROFRACTURINGORFRACKING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_REALESTATEINSPECTIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_INSURANCEPLACEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_INVESTMENTORTAXADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_LABORATORYTESTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_LANDACQUISITIONADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_LEGALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_OILGASPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_QUANTITYSURVEYSORQUANTITYESTIMATES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_REALESTATEAPPRAISALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_PHYSICALWORKONUNDERGROUNDSTORAGETANKSORUTILITIESORSUBSURFACECONDITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_ALCOHOLTOBACCOELECTRONICCIGARETTEORVAPORPRODUCTSORSUPPLIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_AUTOBOATORUTILITYVEHICLESORPARTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_CHEMICALSORCHEMICALSUPPLIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_FARMINGORGARDENINGSUPPLIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_GUNFIREARMORAMMUNITIONPRODUCTSORSUPPLIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_PHARMACEUTICALORMEDICALDEVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_HAZARDOUSMATERIALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_HEALTHFOODHERBSVITAMINSORSUPPLEMENTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_HERBICIDESORPESTICIDES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_PETORPETSUPPLIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRBRANCHESOPS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINDPNTCTRCTR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINVNTRY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PMREALESTATESERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSLICENSESHEALTHSRVCS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRHOTELHEALTHCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRRENOVATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_CONSTRUCTIONORREMODELING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_HOMEINSPECTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_INSURANCEAGENTORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_JANITORIALORCLEANING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_LANDSCAPINGORLAWNCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_MORTGAGEBANKERORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_PROPERTYDEVELOPERORBUILDER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_PROPERTYPRESERVATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_REALESTATEAPPRAISER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_TITLEAGENTORABSTRACTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_CONSTRUCTIONORREMODELING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_HOMEINSPECTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_INSURANCEAGENTORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_JANITORIALORCLEANING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_LANDSCAPINGORLAWNCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_PROPERTYDEVELOPERORBUILDER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_PROPERTYPRESERVATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_BOXINGGYM,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_GYMNASTICSSTUDIO,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_MARTIALARTSSTUDIO,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_MUDRACESORCHALLENGECOURSES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_SPORTORRECREATIONALLEAGUES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_CONSTRUCTIONORREMODELING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_HOMEINSPECTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_INSURANCEAGENTORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_JANITORIALORCLEANING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_LANDSCAPINGORLAWNCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_MORTGAGEBANKERORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_PROPERTYDEVELOPERORBUILDER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_PROPERTYPRESERVATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_REALESTATEAPPRAISER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_TITLEAGENTORABSTRACTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_HOMEINSPECTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_INSURANCEAGENTORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_MORTGAGEBANKERORBROKER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_PROPERTYDEVELOPERORBUILDER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_REALESTATEAPPRAISER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_TITLEAGENTORABSTRACTOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REPROPMANSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_AEROSPACEORAVIATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_AMUSEMENTRIDESPOOLORPLAYGROUNDS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_CONSTRUCTIONSITES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_ENVIRONMENTAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_FOODORDRUGPROCESSING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_FOUNDATIONSHEETINGORRETAININGWALLS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_HAZARDOUSWASTE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_MINING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_OILANDGAS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_RAILROADS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_UNDERGROUNDSTORAGETANKSANDUTILITIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SIMILARINSURANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SPAOWNERSHIP,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTDESIGN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTINSURANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTOTHERSRVCS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTREPAIR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTSRVCSDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT3,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE4,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORK,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TANGIBLEGOODWORKTRADESMEN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_COLLEGEORUNIVERSITY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_DAYCAREORELDERLYFACILITY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_PRESCHOOL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_PUBLICPRIVATECHARTERSCHOOL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY2_TECHNICALORVOCATIONSCHOOL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_ADULTORCHILDCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_AFTERSCHOOLCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_ANIMAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_ARCHITECTUREORENGINEERING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_AVIATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_BEHINDTHEWHEELDRIVING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_CONSTRUCTIONMANAGEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_COOKINGORFOODPREPARATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_DAYCAREORCHILDCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_DROPINCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_FITNESS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_LAWENFORCEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_MEDICAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_MEDICALTRAININGORSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_SAFETY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY3_SECURITYGUARD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_ATLEASTANNUALTHIRDPARTYBACKGROUNDCHECKS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_NOSEXUALMISCONDUCTBYWORKERS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_THIRDPARTYBACKGROUNDCHECKS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY4_WRITTENPOLICYZEROTOLERANCESEXUALMISCONDUCT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_QUOTEID,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_QUOTERQDT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_STATEORPROVCD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_ADDR1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_ADDR2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_CITY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_STATEORPROVCD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_POSTALCODE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_COUNTY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_AGEOFBLDNG,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ANOTHERPARTYINTERESTBLDNG,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYLIMIT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYDED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDNLINTRSTEXPLAIN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTNAME,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTMAILINGADD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTAPPLIESTOBUILDING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTTYPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTDESCRIPTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BASEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BPPINVEHICLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BUILDINGCONSTRUCTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_BUILDINGOWNERSHIP,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESCALATORS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESCALATORSCOUNT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ENTITYTYPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIRECLASS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIREDISTRICT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIREHYDRANTDISTANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_FIRESTATIONDISTANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALCERT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALCOUNTY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALROOFAGE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALROOFTYPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANEALZONE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_HURRICANERESISTANT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_INDINSPECTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_MULTIPLEOCCUPANTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_NUMOFEMPLOYEES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_NUMOFSTORIESINBLDNG,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_OPERATEDFROMHOME,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_PROFMGMTTEAM,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_REPLACEMENTCOST,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ROOF,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLAN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLANDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SUBSCRIBEFIREPROTECTIONSRVC,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMROOFCOVER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSC,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCDOOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCOPENING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCPROOF,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFDECK,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFSHAPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCWALL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_WINDSTORMSCWATERRESISTANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_ADDR1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_ADDR2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_CITY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_STATEORPROVCD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_POSTALCODE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_COUNTY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_AGEOFBLDNG,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ANOTHERPARTYINTERESTBLDNG,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYLIMIT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BSNSPRSNLPROPERTYDED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDNLINTRSTEXPLAIN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTNAME,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTMAILINGADD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTAPPLIESTOBUILDING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTTYPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ADDITIONALINTERESTDESCRIPTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BASEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BPPINVEHICLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BUILDINGCONSTRUCTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_BUILDINGOWNERSHIP,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESCALATORS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ESCALATORSCOUNT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ENTITYTYPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIRECLASS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIREDISTRICT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIREHYDRANTDISTANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_FIRESTATIONDISTANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALCERT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALCOUNTY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALROOFAGE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALROOFTYPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANEALZONE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_HURRICANERESISTANT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_INDINSPECTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_MULTIPLEOCCUPANTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_NUMOFEMPLOYEES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_NUMOFSTORIESINBLDNG,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_OPERATEDFROMHOME,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_PROFMGMTTEAM,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_REPLACEMENTCOST,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_ROOF,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLAN,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_STRCTRLALTERATIONSPLANDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_SUBSCRIBEFIREPROTECTIONSRVC,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMROOFCOVER,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSC,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCDOOR,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCOPENING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCPROOF,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFDECK,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCROOFSHAPE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCWALL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_SECONDARY_ADDRINFO_RATINGINFO_WINDSTORMSCWATERRESISTANCE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GLOPERATESAUNASORSTEAMROOMS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GLOPERATETANNINGBEDSORBOOTHS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2GLNONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_LANDACQUISITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_LAWENFORCEMENTTRAINING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_MEDICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_OILGASORPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_SAFETYCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_SALESREPRESENTATIVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYCONGLBOP_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_LANDACQUISITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_LAWENFORCEMENTTRAINING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_MEDICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_OILGASORPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_SAFETYCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_SALESREPRESENTATIVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYEDUGLBOP_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_LANDACQUISITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_LAWENFORCEMENTTRAINING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_MEDICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_OILGASORPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_SAFETYCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_SALESREPRESENTATIVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYHRGLBOP_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_ESTMTDPAYROLLSC,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_AUTOBOATVEHICLECLEANINGORDETAILING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_COMMERCIALWINDOWCLEANING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_EXTERIORCLEANINGFORCOMMERCIALSURFACESBUILDINGSORSTRUCTURES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_HAZARDOUSWASTECLEANINGORREMOVAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_LAUNDRYORDRYCLEANINGSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_PETORANIMALGROOMING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_SANDBLASTINGSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_AUTOBOATVEHICLECLEANINGORDETAILING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_COMMERCIALWINDOWCLEANING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_CONSTRUCTIONMAINTENANCEREPAIRRENOVATIONORPROPERTYPRESERVATIONSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_EXTERIORCLEANINGFORCOMMERCIALSURFACESBUILDINGSORSTRUCTURES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_HAZARDOUSWASTECLEANINGORREMOVAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_LAUNDRYORDRYCLEANINGSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_PETORANIMALGROOMING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_SANDBLASTINGSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSJANITORIAL1_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_EXCAVATIONBELOWTWOFEET,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_PROPERTYPRESERVATIONSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_TREEREMOVAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSLANDSCAPERS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_ALLREVENUEFROMECOMMERCEORINTERNETSALES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_ALCOHOLTOBACCOELECTRONICCIGARETTEORVAPORSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_AUTOBOATORUTILITYVEHICLEPARTSORDEALERS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_AUTOREPAIRORGASSTATIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_CONVENIENCESTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_DEPARTMENTSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_FARMINGORGARDENINGSUPPLYSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_GROCERYSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_GUNFIREARMORAMMUNITIONSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_HEALTHFOODVITAMINORDRUGSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_PETORPETSUPPLYSTORES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_RESTAURANTSBARSORNIGHTCLUBS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTSRETAIL_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_CATHETERIZATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_DIAGNOSINGCONDITIONSDISORDERSORDISEASES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_HEALTHCARESTAFFINGORHOMEHEALTHSTAFFINGAGENCYSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_HOLISTICMEDICINE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_INFUSIONTHERAPY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_INJECTIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_LASERTREATMENTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_SERVICESRELATEDCANNABISNOTLIMITEDTOMEDICALMARIJUANADISPENSING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_ORGANSPERMBLOODSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PATIENTCLIENTCAREFACILITYANYTYPEYOUOWNORRENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PATIENTTRANSPORTATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PEDIATRICORDEVELOPMENTLYDISABLEDCLIENTCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_PHYSICALTHERAPY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_RESEARCHOREXPERIMENTALSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_SELFDIRECTEDCAREFAMILYCAREGIVERNURSINGWAIVERPROGRAM,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_STRESSTESTSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_WOUNDCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_LOBBYINGORPOLITICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_MEDICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_SALESREPRESENTATIVEORRETAIL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYGLBOP_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_ALCOHOLICBEVERAGESORPRODUCTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_ANIMALLIVESTOCKFEED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_PRODUCTSREQUIRINGPRESCRIPTION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_BABYFOOD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_BODYPIERCINGS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_CANNABISCBDOILORINFUSEDPRODUCTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_DIETPRODUCTSCLEANSESORPILLSASWEIGHTLOSS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_ENERGYDRINKS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_KAVA,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_MEDICALINVASIVEPRODUCTSWITHINBODY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_NUTRACEUTICALSVITAMINSSUPPLEMENTSSUPPOSITORIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_PERMANENTTATTOOS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_TOBACCORELATEDPRODUCTSECIGARETTESANDSMOKELESS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_TOPICALPRODUCTSSOAPSLOTIONSCOSMETICSANDBATH,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_UNMANNEDAIRCRAFTSYSTEMS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_WEAPONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENPRODUCTS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_COMMERCIALFARMING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_OUTOFCOMPLIANCECOMPANIESOROPERATIONS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_GROCERYSTORESANDSUPERMARKETS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_HEALTHCOACHING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_IMPORTEROFPRODUCEORDAIRYMORETHAN20PERCENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_IMPORTERSEAFOODMEATPOULTRY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_NUTRITIONCOUNSELING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_RESTAURANTCAFEBAKERYTAVERNFIXEDLOCATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_WEAPONSSALES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MFSFORBIDDENSERVICES_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_MOBILEEQUIPEXCLUDEDSNOWBLOWING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFEMPLOYEESINCOMP,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_NUMOFOWNERS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_EMPLOYEEWAGEROLL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_OPERATEDFROMHOME,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_ADULTDAYCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_CHILDCAREORNANNY,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_CLEANINGORJANITORIAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_HEALTHCAREORRELATEDSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_MOVING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_PETSITTINGORPETCARE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_SOCIALWORK,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_TRANSPORTATIONOFPEOPLEORGOODS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYGLBOP_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_AEROSPACECONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_APPRAISALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_ARCHITECTUREORENGINEERINGADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_BACKGROUNDCHECKS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_CONSTRUCTIONMANAGEMENTORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_CLINICALORMEDICALTRIALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_ENVIRONMENTALCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_GENERALCONTRACTING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_LANDACQUISITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_LAWENFORCEMENTTRAINING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_LOBBYINGORPOLITICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_MEDICALADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_MININGCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_OILGASPETROLEUMCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_SAFETYCONSULTINGORADVICE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_SALESREPRESENTATIVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITYGLBOP_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SECONDARYCOBSMALLCONTRACTORS_CLASSOFBUSINESSCD,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_AIRPORTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_AMUSEMENTRIDESPOOLSORPLAYGROUNDS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_ASBESTOSLEADORMOLDEVALUATIONORABATEMENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_BRIDGESDAMSHARBORSMINESPIERSORTUNNELS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_BLASTINGORDEMOLITION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_CRANEOPERATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_EMERGENCYSYSTEMS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_FIRESUPPRESSION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_FOUNDATIONSHEETINGORRETAININGWALLS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_HAZARDOUSMATERIALORWASTEREMOVAL,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_HYDRAULICFRACTURINGHYDROFRACTURINGORFRACKING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_LANDFILLS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_OILGASORWELLS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_PROESSPIPING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_ROOFING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_SCAFFOLDINGOPERATION,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_UNDERGROUNDSTORAGETANKSORUTILITIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_ANYOTHERSIMILARLYHAZARDOUSPROJECTSORMATERIALS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SCFORBIDDENPROJECTS_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SPAOWNERSHIP3,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTS2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSAANDE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSACTIVITY2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSASSOCWEBSITE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDESCRIBEAANDE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILPROCEDURES1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLS1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSDETAILRECALLSDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSLIMITEDPHOTO,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANOTHERS,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTURED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANUFACTUREDDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSMANWHERE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSOWNERSHIP,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPERCENT4,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSPROCEDURESDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSUSED2,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWEBSITE5,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SUPPLYMANUFACTDISTBTGOODSORPRODUCTSWHOMANU1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORK1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKDESCRIBE1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKIT,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TANGIBLEGOODWORKTRADESMEN1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_HARDWAREDESIGNORMANUFACTURING,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_INSTALLATIONORSERVICETECHNICIANSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_REPAIRORMECHANICSERVICES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_TECHSPCLSTACTVTY_NONEOFTHEABOVE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_CGLSTATEMENT1,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EXCLUDEDACTIVITIES,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_GLPLUSCOVERQUOTERQ_AGGLOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_GLPLUSCOVERQUOTERQ_LOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_GLPLUSCOVERQUOTERQ_DEDUCTIBLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_LOI,
  HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_RATINGINFO_DEDUCTIBLE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT2,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_AGREEDISAGREESTATEMENTS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_APPLICATIONAGREEMENTSTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_APPLICANTAUTHORIZED,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSINESSOWNERSHIP,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGCOMP,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGRELBUS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNCONTROLLINGRELINS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSCOV,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSCOVPURPOSE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNINSREQ,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_BUSINESSOWNERSHIP_BUSOWNPERCENTCONTROL,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOU,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUACT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUACTDATE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUAVOID,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUAMOUNT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUCLAIMDATE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_CLAIMSAGAINSTYOU_CLAIMSAGAINSTYOUNAME,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_DEDUCTIBLESTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILDELIVERYSTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_FRAUDWARNING,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_HISCOXSTATEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INFORMATIONCONFIRMAGREEMENT,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINEACTION,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECANCEL,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECANCELDATE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_INSURANCEDECLINE_INSURANCEDECLINECARRIER,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITION,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTION,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONPLACE,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSACTIONWHY,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_MERGERACQUISITIONS_MERGERACQUISITIONSENTITY,
  HiscoxGlFormDataFieldV4.ACKNOWLEDGEMENTS_STATESPCFCFRAUDWARNING,
];

// Values expected as answers for some Hiscox V4 questions
export const enum HiscoxExpectedAnswers {
  PRIMARY_NON_CONTRIB_COVERAGE = 'PNC',
  WAIVER_OF_SUBROGATION_COVERAGE = 'WaiverOfSubrogation',
  PROPERTY_AND_EQUIPMENT_COVERAGE = 'BusinessPropandEquipCoverage',
  TRIA_COVERAGE = 'TRIA',
}

export const AGG_LOI_COB_EXCEPTIONS = [
  HISCOX_BUSINESS_CLASS_CODES_V4.AIR_CONDITIONING_SYSTEMS_INSTALLATION_COOLING_PIPING_EQUIPMENT_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_HOUSE_HOLD_MAINTENANCE_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCE_AND_ACCESSORIES_INSTALLATION_AND_REPAIR_APPLIANCE_STORES_HOUSEHOLDTYPE,
  HISCOX_BUSINESS_CLASS_CODES_V4.CARPENTRY_INTERIOR_ONLY_BUILTIN_WOOD_CABINETS_CONSTRUCTED_ON_SITE,
  HISCOX_BUSINESS_CLASS_CODES_V4.CARPET_RUG_FURNITURE_OR_UPHOLSTERY_CLEANING_CUSTOMERS_PREMISES_ONLY_CARPET_CLEANING_ON_CUSTOMERS_PREMISES,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCKS_ASSEMBLING,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOCK_MAKING_AND_REPAIR_CLOCK_REPAIR_SHOPS_WITHOUT_RETAILING_NEW_CLOCKS,
  HISCOX_BUSINESS_CLASS_CODES_V4.DOOR_OR_WINDOW_INSTALLATION_AND_REPAIR_ALUMINUM_DOOR_AND_WINDOW_RESIDENTIALTYPE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.DRIVEWAY_OR_SIDEWALK_PAVINGREPAVING_ASPHALT_COATING_AND_SEALING_RESIDENTIAL_AND_COMMERCIAL_PARKING_LOT_AND_DRIVEWAY,
  HISCOX_BUSINESS_CLASS_CODES_V4.DRYWALL_OR_WALLBOARD_INSTALLATION_AND_REPAIR_DRYWALL_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.ELECTRICAL_WORK_INTERIOR_ONLY_ELECTRIC_CONTRACTING,
  HISCOX_BUSINESS_CLASS_CODES_V4.FENCE_INSTALLATION_AND_REPAIR_CHAIN_LINK_FENCE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.FLOOR_COVERING_INSTALLATION_NO_CERAMIC_TILESTONE_FLOOR_LAYING_TILE_HARDWOOD_VINYL,
  HISCOX_BUSINESS_CLASS_CODES_V4.GLASS_INSTALLATION_AND_REPAIR_NO_AUTO_WORK_DECORATIVE_GLASS_MIRROR_GLAZING_WINDOW_PANE_SHEET,
  HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_COMMERCIAL_AND_INSTITUTIONAL_BUILDING,
  HISCOX_BUSINESS_CLASS_CODES_V4.HANDYPERSON_NO_ROOF_WORK_HANDYMAN_CONSTRUCTION_SERVICE_RESIDENTIAL_BUILDING,
  HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIRCONDITIONING_EQUIPMENT_EXCEPT_ROOM_UNITS_MERCHANT_WHOLESALERS,
  HISCOX_BUSINESS_CLASS_CODES_V4.HEATING_AND_AIR_CONDITIONING_INSTALLATION_AND_REPAIR_NO_LIQUEFIED_PETROLEUM_GAS_LPG_AIR_SYSTEM_BALANCING_AND_TESTING,
  HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ASPHALT_WOOD_FLOORING,
  HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_ACOUSTICAL_CEILING_TILE_AND_PANEL_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_MOLDING_TRIM_WOOD_PLASTIC_PREFABRICATED_WINDOW_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CERAMIC_TILE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.INTERIOR_FINISHING_WORK_CONCRETE_FINISHING,
  HISCOX_BUSINESS_CLASS_CODES_V4.LOCKSMITHS_LOCK_REKEYING_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.MASONRY_WORK_BRICK_VENEER_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.PAINTING_INTERIOR_ONLY_PAINTING_EXCEPT_ROOF_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_STUCCO_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLASTERING_OR_STUCCO_WORK_FRESCO_IE_DECORATIVE_PLASTER_FINISHING_CONTRACTORS,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.PLUMBING_RESIDENTIAL_OR_DOMESTIC_BATHROOM_PLUMBING_FIXTURE_AND_SANITARY_WARE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_EXTERIOR_ONLY_SIGN_LETTERING_AND_PAINTING_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.SIGN_PAINTING_AND_LETTERING_INTERIOR_ONLY_DISPLAY_LETTERING_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.TILE_STONE_MARBLE_MOSAIC_OR_TERRAZZO_WORK_INTERIOR_ONLY_CERAMIC_TILE_INSTALLATION,
  HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_UPHOLSTERY_EXCEPT_MOTOR_VEHICLE_REPAIR_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.UPHOLSTERY_WORK_AUTOMOTIVE_UPHOLSTERY_SHOPS,
  HISCOX_BUSINESS_CLASS_CODES_V4.WINDOW_CLEANING_NOTHING_ABOVE_15_FEET_WINDOW_CLEANING_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_APPLIANCE_STORES_HOUSEHOLDTYPE_USED,
  HISCOX_BUSINESS_CLASS_CODES_V4.APPLIANCEELECTRONIC_STORES_AUDIO_EQUIPMENT_STORES_EXCEPT_AUTOMOTIVE,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_CHILDRENS_AND_INFANTS_CLOTHING,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_MENS_AND_BOYS_CLOTHING,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_APPAREL_STORES_WOMENS_AND_GIRLS_CLOTHING,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_BRIDAL_GOWN_SHOPS_EXCEPT_CUSTOM,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_CLOTHING_STORES_FAMILY,
  HISCOX_BUSINESS_CLASS_CODES_V4.CLOTHINGAPPAREL_STORES_NECKWEAR_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.FLORISTS_FLORISTS,
  HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_BATH_SHOPS,
  HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_CARPET_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.HOME_FURNISHING_STORES_CURTAIN_AND_DRAPERY_STORES_PACKAGED,
  HISCOX_BUSINESS_CLASS_CODES_V4.JEWELRY_STORES_CLOCK_SHOPS,
  HISCOX_BUSINESS_CLASS_CODES_V4.JEWELRY_STORES_COSTUME_JEWELRY_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_CONVENIENCE_FOOD_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_DELICATESSENS_PRIMARILY_RETAILING_A_RANGE_OF_GROCERY_ITEMS_AND_MEATS,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_LIQUOR_STORES_PACKAGE,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITH_FOODDRINKS_SUPERSTORES_IE_FOOD_AND_GENERAL_MERCHANDISE,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AIRCRAFT_DEALERS,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_NEW_ONLY_OR_NEW_AND_USED,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOBILE_DEALERS_USED_ONLY,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_AUTOMOTIVE_TIRE_DEALERS,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_BOAT_DEALERS_NEW_AND_USED,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CABINET_STORES_KITCHEN_EXCEPT_CUSTOM_TO_BE_INSTALLED,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CAMPER_DEALERS_RECREATIONAL,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CELLULAR_TELEPHONE_ACCESSORIES_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_CONVENIENCE_FOOD_WITH_GASOLINE_STATIONS,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_FURNITURE_AND_APPLIANCE_STORES_IE_PRIMARILY_RETAILING_FURNITURE,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_GASOLINE_STATIONS_WITHOUT_CONVENIENCE_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.OTHER_STORES_WITHOUT_FOODDRINKS_HANDBAG_STORES,
  HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_DRIVEWAYS_AND_PARKING_LOTS_IE_NOT_COMBINED_WITH_ANY_OTHER_SERVICE,
  HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_PLOWING_SERVICES_COMBINED_WITH_LANDSCAPING_SERVICES_IE_SEASONAL_PROPERTY_MAINTENANCE_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.SNOW_BLOWING_AND_REMOVAL_NO_AUTO_COVERAGE_SNOW_REMOVAL_HIGHWAY,
  HISCOX_BUSINESS_CLASS_CODES_V4.EXTERIOR_CLEANING_SERVICES_BUILDING_EXTERIOR_CLEANING_SERVICES_EXCEPT_SANDBLASTING_WINDOW_CLEANING,
  HISCOX_BUSINESS_CLASS_CODES_V4.EXTERIOR_CLEANING_SERVICES_SANDBLASTING_BUILDING_EXTERIOR,
  HISCOX_BUSINESS_CLASS_CODES_V4.JANITORIALCLEANING_SERVICES_BUILDING_CLEANING_SERVICES_JANITORIAL,
  HISCOX_BUSINESS_CLASS_CODES_V4.LANDSCAPINGGARDENING_SERVICES_GARDEN_MAINTENANCE_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.LAWN_CARE_SERVICES_CEMETERY_PLOT_CARE_SERVICES,
  HISCOX_BUSINESS_CLASS_CODES_V4.MOBILE_FOOD_SERVICES_BEVERAGE_STANDS_NONALCOHOLIC_MOBILE,
];
