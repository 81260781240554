<div class="book-mix-progress-bar-container">
  <div class="book-mix-progress-bar-label-container">
    <div
      class="book-mix-progress-bar-label book-mix-progress-bar-label__foundational js-progress-bar-left-label-{{
        productType
      }}"
    >
      <span class="book-mix-progress-bar-label-percent"> {{ getPreferredPercentage() }}% </span>
      <span>{{ getPolicyCountCopy('foundational') }}</span>
    </div>
    <div
      class="book-mix-progress-bar-target js-progress-bar-middle-label-{{ productType }}"
      [style.left]="targetPercentage + '%'"
    >
      <span><strong>Target:</strong> {{ targetPercentage }}% Foundational</span>
      <div class="book-mix-progress-bar-target-indicator"></div>
    </div>
    <div
      class="book-mix-progress-bar-label book-mix-progress-bar-label__frontier js-progress-bar-right-label-{{
        productType
      }}"
    >
      <span class="progress-label-preference-right">{{ getPolicyCountCopy('frontier') }}</span>
      <span class="book-mix-progress-bar-label-percent"> {{ getNonPreferredPercentage() }}% </span>
    </div>
  </div>
  <div class="book-mix-progress-bar">
    <div
      class="book-mix-progress-bar-segment book-mix-progress-bar-segment__foundational"
      [style.width]="getPreferredPercentage() + '%'"
    ></div>
    <div
      class="book-mix-progress-bar-segment book-mix-progress-bar-segment__frontier"
      [style.width]="getNonPreferredPercentage() + '%'"
    ></div>
  </div>
  <p *ngIf="preferredCount >= targetCount" class="mt-2 js-sustainable-message">
    <strong>Sustainable.</strong>
    You are on target, great job!
  </p>
  <app-dialog-box
    *ngIf="preferredCount < targetCount"
    type="warning"
    [fullWidth]="false"
    class="dialog-text__margin-top dialog-text__block book-mix-unsustainable-dialog"
  >
    <span class="js-unsustainable-message">
      <strong>Unsustainable.</strong>
      {{ getUnsustainableMessage() }}
    </span>
  </app-dialog-box>
  <p class="p p__small-text">
    <span *ngIf="!!calculationStartDate">
      This dashboard includes policies bound starting
      {{ calculationStartDate | date: 'MM/dd/yyyy' }}.
    </span>
    Contact your account manager for more information.
  </p>
</div>
