<div class="js-post-bind-servicing-info policy-detail-subsection mt-4">
  <p class="p__bold">
    Everything you need to know about servicing this {{ servicingInfo.displayName }} policy:
  </p>
  <ul class="js-servicing-item-list bulleted-list">
    <li *ngFor="let servicingItem of servicingInfo.servicingItems">
      <strong>{{ servicingItem.name }}</strong
      >: <span [innerHTML]="servicingItem.detail"></span>
    </li>
  </ul>
</div>
