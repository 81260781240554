import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PreferenceLevel } from '../services/preference.service';
import {
  NON_PREFERRED_PREFERENCE_LEVELS,
  PREFERRED_PREFERENCE_LEVELS,
  STRONGLY_PREFERRED_PREFERENCE_LEVELS,
} from '../../features/attune-bop/models/constants';
import {
  HIGH_HAZARD_GROUPS,
  PREFERRED_HAZARD_GROUPS,
  STRONGLY_PREFERRED_HAZARD_GROUPS,
} from '../../workers-comp/attune/constants';

export enum PreferenceCategory {
  STRONGLY_PREFERRED = 'foundationalPlus',
  PREFERRED = 'foundational',
  NON_PREFERRED = 'frontier',
}

export const PREFERENCE_CATEGORY_TO_DESCRIPTION: Readonly<Record<PreferenceCategory, string>> = {
  [PreferenceCategory.STRONGLY_PREFERRED]:
    'This business is accepted and eligible for a commission bonus.',
  [PreferenceCategory.PREFERRED]: 'This business is accepted.',
  [PreferenceCategory.NON_PREFERRED]: 'This business is accepted on a limited basis.',
};

export const getCategoryForPreferenceLevel = (
  preferenceLevel: PreferenceLevel
): PreferenceCategory | null => {
  if (STRONGLY_PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
    return PreferenceCategory.STRONGLY_PREFERRED;
  } else if (PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
    return PreferenceCategory.PREFERRED;
  } else if (NON_PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
    return PreferenceCategory.NON_PREFERRED;
  }
  return null;
};

export const getCategoryForHazardGroup = (
  hazardGroup: WcHazardGroup
): PreferenceCategory | null => {
  if (STRONGLY_PREFERRED_HAZARD_GROUPS.includes(hazardGroup)) {
    return PreferenceCategory.STRONGLY_PREFERRED;
  } else if (PREFERRED_HAZARD_GROUPS.includes(hazardGroup)) {
    return PreferenceCategory.PREFERRED;
  } else if (HIGH_HAZARD_GROUPS.includes(hazardGroup)) {
    return PreferenceCategory.NON_PREFERRED;
  }
  return null;
};

@Component({
  selector: 'app-preference-tag',
  templateUrl: './preference-tag.component.html',
})
export class PreferenceTagComponent implements OnChanges {
  @Input() preferenceCategory: PreferenceCategory;

  labelText: 'Frontier' | 'Foundational';
  isFoundationalPlus = false;
  pillTagClass: 'pill-tag__main' | 'pill-tag__warning';

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.preferenceCategory === PreferenceCategory.STRONGLY_PREFERRED) {
      this.labelText = `Foundational`;
      this.pillTagClass = 'pill-tag__main';
      this.isFoundationalPlus = true;
    } else if (this.preferenceCategory === PreferenceCategory.PREFERRED) {
      this.labelText = `Foundational`;
      this.pillTagClass = 'pill-tag__main';
      this.isFoundationalPlus = false;
    } else if (this.preferenceCategory === PreferenceCategory.NON_PREFERRED) {
      this.labelText = 'Frontier';
      this.pillTagClass = 'pill-tag__warning';
      this.isFoundationalPlus = false;
    }
  }
}
