<div id="building-{{ locationIndex }}-{{ buildingIndex }}">
  <h1>Location {{ locationIndex }} – Building {{ buildingIndex }}</h1>
  <app-form-field-radio
    *ngIf="isLROAllowed()"
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-lessorsRisk"
    cssClass="js-lessors-risk"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    questionText="Is this building Lessors' Risk only?"
    nameOfFormControl="lessorsRisk"
    tooltipText="(LRO) applies when the applicant is the building owner and rents the building to others while occupying less than 10% of the space."
  >
  </app-form-field-radio>

  <div *ngIf="isLRO()" class="helper-card-container">
    <article class="faq-container helper-card helper-card__lro-faq">
      <h5 class="h5">Frequently asked questions</h5>
      <app-faqs-list [faqs]="faqs"></app-faqs-list>
    </article>
  </div>

  <div class="helper-card-container" *ngIf="!isEligibilityOverride">
    <app-form-field-select
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-businessType"
      [form]="buildingExposureFormGroup"
      [submitted]="submitted"
      labelText="Business category"
      nameOfFormControl="businessType"
      [availableOptions]="getAvailableBusinessTypes()"
    >
    </app-form-field-select>
    <div *ngIf="!isLRO()" class="helper-card">
      <div class="pill-tag pill-tag__main">Note</div>
      <p>
        <strong>Don't see what you're looking for?</strong> Take a look at our
        <a [href]="getBopGuidelinesLink()" target="_blank">full guidelines</a> to learn more about
        what we cover.
      </p>
    </div>
  </div>

  <!-- Occupancy Classification -->
  <section *ngIf="isBusinessTypeExisting() && !isEligibilityOverride" class="form-subsection">
    <div class="form-field form-field__autocomplete" [formGroup]="buildingExposureFormGroup">
      <app-form-field-dropdown-search
        inputId="building-{{ locationIndex }}-{{ buildingIndex }}"
        labelText="{{
          isLRO()
            ? 'Please select the classification of the primary tenant'
            : 'Business classification'
        }}"
        tooltipText="{{
          isLRO()
            ? 'For more information on how to classify LROs, see the FAQs section to the right'
            : 'If this building has more than one classification, please complete the quote using the dominant classification. For the rest, email the quote number and the additional classification(s) to help@attuneinsurance.com'
        }}"
        typeaheadPlaceholderText="Search classifications"
        [submitted]="submitted"
        [queryMethod]="queryMethod"
        [formatter]="codeDescription"
        [targetFormControl]="buildingExposureFormGroup.get('classification.code')"
      >
      </app-form-field-dropdown-search>
    </div>
    <div *ngIf="isLRO() && lessorsRiskQuestionsEnabled">
      <app-form-field-text
        inputId="building-{{ locationIndex }}-{{ buildingIndex }}-largest-tenant-name"
        [form]="largestTenantFormGroup"
        [submitted]="submitted"
        labelText="Primary tenant name"
        nameOfFormControl="tenantName"
      >
      </app-form-field-text>
      <div class="form-split">
        <app-form-field-text
          inputId="building-{{ locationIndex }}-{{ buildingIndex }}-largest-tenant-square-footage"
          [form]="largestTenantFormGroup"
          [submitted]="submitted"
          labelText="Square footage of primary tenant"
          nameOfFormControl="squareFootage"
          appDelimitedNumberMask
        >
        </app-form-field-text>
        <app-form-field-text
          inputId="building-{{ locationIndex }}-{{ buildingIndex }}-largest-tenant-rent"
          [form]="largestTenantFormGroup"
          [submitted]="submitted"
          labelText="Tenant's monthly rent"
          specifierText="(optional)"
          nameOfFormControl="rent"
          placeholder="$"
          appMoneyMask="WITHOUT_DECIMAL"
        >
        </app-form-field-text>
      </div>
    </div>
    <app-dialog-box *ngIf="displayDeductibleChangedWarning" type="warning">
      This class code requires a minimum AOP (All Other Perils) of $2500. We automatically updated
      AOP to reflect the requirement.
    </app-dialog-box>
  </section>

  <div class="form-split" *ngIf="buildingLimitIsEnabled()">
    <app-form-field-text
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-buildingLimit"
      [form]="buildingExposureFormGroup"
      [submitted]="submitted"
      [readonly]="buildingLimitReadonly()"
      labelText="Building limit"
      nameOfFormControl="buildingLimit"
      errorText="{{ errorMessage('buildingLimit') }}"
      placeholder="$"
      appMoneyMask="WITHOUT_DECIMAL"
    >
    </app-form-field-text>
    <app-form-field-text
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-limitForBusinessPersonalProperty"
      [form]="buildingExposureFormGroup"
      [submitted]="submitted"
      labelText="Business Personal Property limit"
      nameOfFormControl="limitForBusinessPersonalProperty"
      placeholder="$"
      appMoneyMask="WITHOUT_DECIMAL"
      errorText="{{ errorMessage('limitForBusinessPersonalProperty') }}"
      tooltipText="(BPP) refers to personal property used in the business but not
      a part of the building or real property. Examples include tablets,
      cell phones, furniture, equipment not permanently installed, and inventory.
      If the insured is a tenant, it should include improvements and betterments"
    >
    </app-form-field-text>
  </div>
  <app-dialog-box *ngIf="buildingLimitReadonly()" type="warning">
    We are not a market for building coverage in California.
  </app-dialog-box>

  <app-form-field-text
    *ngIf="!buildingLimitIsEnabled()"
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-limitForBusinessPersonalProperty"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    labelText="Business Personal Property limit"
    nameOfFormControl="limitForBusinessPersonalProperty"
    placeholder="$"
    appMoneyMask="WITHOUT_DECIMAL"
    errorText="{{ errorMessage('limitForBusinessPersonalProperty') }}"
    tooltipText="(BPP) refers to personal property used in the business but not
    a part of the building or real property. Examples include tablets,
    cell phones, furniture, equipment not permanently installed, and inventory.
    If the insured is a tenant, it should include improvements and betterments"
  >
  </app-form-field-text>

  <app-form-field-text
    inputId="building-{{ locationIndex }}-totalSales"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    labelText="Total sales"
    nameOfFormControl="totalSales"
    appMoneyMask="WITHOUT_DECIMAL"
    placeholder="$"
  >
  </app-form-field-text>
  <app-form-field-text
    labelText="Alcohol Sales"
    [form]="classification()"
    nameOfFormControl="alcoholSales"
    [submitted]="submitted"
    inputId="building-{{ locationIndex }}-alcoholSales"
    appMoneyMask="WITHOUT_DECIMAL"
    placeholder="$"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="building-{{ locationIndex }}-payroll"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    labelText="Payroll"
    nameOfFormControl="payroll"
    appMoneyMask="WITHOUT_DECIMAL"
    placeholder="$"
  >
  </app-form-field-text>

  <!-- UW requested this question to be presented on the building page. -->
  <app-form-field-text
    inputId="quote-{{ locationIndex }}-percentageSmallPersonalDeviceSales"
    [form]="buildingExposureFormGroup"
    nameOfFormControl="percentSalesPersonalDevices"
    [submitted]="submitted"
    labelText="What percent of the insureds total sales are small personal devices? (examples: cell phones, laptops, tablets, etc.)"
  >
  </app-form-field-text>

  <h3 class="question-subsection-header">Building details</h3>
  <app-form-field-select
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-constructionType"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    labelText="Construction type of the building"
    nameOfFormControl="constructionType"
    [availableOptions]="availableConstructionTypes"
  >
  </app-form-field-select>
  <div class="form-split">
    <app-form-field-select
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-storyCount"
      [form]="buildingExposureFormGroup"
      [submitted]="submitted"
      labelText="Number of stories in the building"
      nameOfFormControl="storyCount"
      [availableOptions]="availableNumberOfStories"
    >
    </app-form-field-select>
    <app-form-field-text
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-squareFootage"
      [form]="buildingExposureFormGroup"
      [submitted]="submitted"
      [labelText]="
        isLRO() ? 'Square footage of the building' : 'Square footage occupied by insured'
      "
      nameOfFormControl="squareFootage"
      appDelimitedNumberMask
    >
    </app-form-field-text>
  </div>
  <div class="helper-card-container">
    <app-form-field-text
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-yearBuilt"
      [form]="buildingExposureFormGroup"
      [submitted]="submitted"
      labelText="Year built"
      nameOfFormControl="yearBuilt"
    >
    </app-form-field-text>
    <div
      *ngIf="displayFunctionalReplacementCoverageNote()"
      class="helper-card helper-card__no-margin"
    >
      <div class="pill-tag pill-tag__review">Note</div>
      <p class="js-functional-replacement-cost-message">
        {{ functionalReplacementCostMessage }}
      </p>
    </div>
  </div>
  <app-form-field-radio
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-roofUpdated"
    cssClass="js-updated-roof-radio"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    questionText="Has the roof been updated in the last 20 years?"
    nameOfFormControl="roofUpdated"
  >
  </app-form-field-radio>
  <app-form-field-radio
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-electricPlumbingHVACUpdated"
    cssClass="js-electric-update-radio"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    questionText="Has electrical, plumbing, roof and HVAC been updated?"
    nameOfFormControl="electricPlumbingHVACUpdated"
  >
  </app-form-field-radio>
  <app-form-field-radio
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-hasAutomaticSprinklerSystem"
    cssClass="js-sprinkler-radio"
    [form]="buildingExposureFormGroup"
    [submitted]="submitted"
    questionText="Does this building have an automatic sprinkler system?"
    nameOfFormControl="hasAutomaticSprinklerSystem"
  >
  </app-form-field-radio>
</div>
