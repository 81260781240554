import {
  throwError as observableThrowError,
  of as observableOf,
  Observable,
  BehaviorSubject,
  Subject,
  merge,
} from 'rxjs';
import * as _ from 'lodash';
import { map, catchError, filter, first, mapTo, shareReplay, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ARCHIVE_ACCOUNT_API_URI,
  CURRENT_CONTACT_URL,
  GET_ACCOUNT_API_URI,
  V3_ACCOUNT_API_URI,
  V3_CANCELLED_SUMMARY_API,
  V3_PENDING_CANCELLATION_SUMMARY_API,
  V3_POLICY_COUNT_API,
  V3_RENEWALS_SUMMARY_API,
  V4_USER_API_URL,
  GET_POLICY_PERIOD_URI,
  PRODUCER_DETAILS_URL,
  PRODUCER_CODE_DETAILS_URL,
  ACCOUNT_CONTACTS_URL,
  FOLLOW_ACCOUNT_URL,
  UNFOLLOW_ACCOUNT_URL,
  FOLLOW_NEW_ACCOUNT_URL,
  V3_ACCOUNTS_BY_POLICY_NO,
  GET_PRERENEWAL_DIRECTION_URL,
  API_V4_BASE,
} from 'app/constants';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { BackendCreateInsuredAccountRequestPayloadFactory } from 'app/shared/models/backend/backend-create-insured-account-payload-factory';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ProducerDetailsResponse,
  GwProducerCodeDetails,
  GwAccountContacts,
  GwCommContact,
  PolicyCancellation,
  PolicyCountResponse,
  PolicyRenewal,
  PolicyPeriod,
  AccountSearchByPolicyResponse,
  PreRenewalDirection,
} from 'app/bop/guidewire/typings';
import { SentryService } from 'app/core/services/sentry.service';
import * as moment from 'moment';
import { RESTRICTED_BOP_CAT_STATES } from 'app/features/attune-bop/models/constants';
import { environment } from 'environments/environment.aws-staging';

interface ProducerCodeCreationDate {
  producerCode: string;
  activationDate: string;
}

@Injectable()
export class InsuredAccountService {
  public token: string;
  public insuredSubject: BehaviorSubject<InsuredAccount> = new BehaviorSubject(
    new InsuredAccount()
  );
  public accountListSubject: BehaviorSubject<InsuredAccount[] | null> = new BehaviorSubject(null);
  public insuredError = new Subject();
  private currentlyFetchingId: string | null;
  private cachebustNextCall = false;
  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService,
    private sentryService: SentryService
  ) {}

  create(insuredAccount: InsuredAccount): Observable<string | null> {
    const payload = BackendCreateInsuredAccountRequestPayloadFactory.buildV3Payload(insuredAccount);

    if (!_.get(payload, 'naicsCodes[0].hash')) {
      this.sentryService.notify('Warning: creating account without naics hash', {
        severity: 'error',
        metaData: {
          insuredAccount,
          payload,
        },
      });
    }

    return this.http.post<BackendCreateAccountResponse>(V3_ACCOUNT_API_URI, payload).pipe(
      map((response) => {
        return response.id;
      }),
      catchError((error: any) => {
        this.sentryService.notify('Error creating account', {
          severity: 'error',
          metaData: {
            insuredAccount,
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        console.warn('*** Error creating account: ***', error);
        return observableOf(null);
      })
    );
  }

  get(id: string): Observable<InsuredAccount> {
    const currentId = this.insuredSubject.getValue().id;

    if (!this.cachebustNextCall && (id === currentId || id === this.currentlyFetchingId)) {
      return this.insuredSubject.asObservable().pipe(
        filter((ins) => String(ins.id) === id),
        first()
      );
    } else {
      // Note in-flight id
      this.currentlyFetchingId = id;

      // Clear store until fetch is complete
      if (currentId !== '') {
        this.insuredSubject.next(new InsuredAccount());
      }
      this.cachebustNextCall = false;
      return this.fetch(id);
    }
  }

  fetch(id: string): Observable<InsuredAccount> {
    this.http
      .get<any>(GET_ACCOUNT_API_URI + '/' + id)
      .pipe(
        map((response) => {
          return InsuredAccount.fromV3ApiPayload(response);
        }),
        catchError((error) => {
          this.sentryService.notify('Error fetching insured account.', {
            severity: 'error',
            metaData: {
              accountNumber: id,
              currentUserName: this.currentUserName(),
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          this.insuredError.next(error);
          // We need to bust the cache here otherwise we will return an insuredSubject
          // that will never emit on subsequent retries when an error occurs.
          this.cachebust();
          return observableThrowError(error);
        })
      )
      .subscribe((insAccount) => {
        this.currentlyFetchingId = null;
        this.insuredSubject.next(insAccount);
      });

    return this.insuredSubject.asObservable().pipe(
      filter((ins) => String(ins.id) === id),
      first()
    );
  }

  getProducerDetails(producerCode: string): Observable<ProducerDetailsResponse> {
    return this.http.get<ProducerDetailsResponse>(`${PRODUCER_DETAILS_URL}${producerCode}`).pipe(
      map((response: ProducerDetailsResponse) => {
        return response;
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to get producer details.', {
          severity: 'error',
          metaData: {
            producerCode,
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getProducerCodeDetails(producerCode: string): Observable<GwProducerCodeDetails> {
    return this.http.get(`${PRODUCER_CODE_DETAILS_URL}${producerCode}`).pipe(
      map((response: GwProducerCodeDetails) => {
        return response;
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to get producer details.', {
          severity: 'error',
          metaData: {
            producerCode,
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getProducerCodeCreationTime(producerCode: string): Observable<moment.Moment> {
    return this.http.get(`${API_V4_BASE}/user/${producerCode}/producer-create-time/`).pipe(
      map((response: string) => {
        return moment(response);
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to get producer code creation time.', {
          severity: 'error',
          metaData: {
            producerCode,
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  isProducerCatRestricted(producerCode: string, state: string): Observable<boolean> {
    if (RESTRICTED_BOP_CAT_STATES.includes(state)) {
      return this.http.get(`${V4_USER_API_URL}/${producerCode}/activation-date`).pipe(
        map((response: ProducerCodeCreationDate) => {
          const momentDate = moment(response.activationDate);
          return momentDate.isSameOrAfter(moment(environment.bopCatCutoffDate));
        }),
        catchError((error) => {
          this.sentryService.notify('Error getting producer code creation date.', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
    } else {
      return observableOf(false);
    }
  }

  getAccountContacts(accountNumber: string): Observable<GwAccountContacts> {
    return this.http.get(`${ACCOUNT_CONTACTS_URL}${accountNumber}`).pipe(
      map((response: GwAccountContacts) => {
        return response;
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to get account contacts.', {
          severity: 'error',
          metaData: {
            accountNumber,
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getCurrentContact(): Observable<GwCommContact> {
    return this.http.get(`${CURRENT_CONTACT_URL}`).pipe(
      map((response: GwCommContact) => {
        return response;
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to get current contacts.', {
          severity: 'error',
          metaData: {
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  followAccount(accountNumber: string, contactId: string, roles: string[]) {
    return this.http
      .post(`${FOLLOW_ACCOUNT_URL}`, {
        accountId: accountNumber,
        publicId: contactId,
        roles: roles,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          this.sentryService.notify('Unable to follow account.', {
            severity: 'error',
            metaData: {
              accountNumber,
              currentUserName: this.currentUserName(),
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  followNewAccount(
    accountNumber: string,
    email: string,
    firstName: string,
    lastName: string,
    roles: string[]
  ) {
    return this.http
      .post(`${FOLLOW_NEW_ACCOUNT_URL}`, {
        accountId: accountNumber,
        email,
        firstName,
        lastName,
        roles,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          this.sentryService.notify('Unable to follow account with new contact.', {
            severity: 'error',
            metaData: {
              accountNumber,
              currentUserName: this.currentUserName(),
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  unfollowAccount(accountNumber: string, contactId: string, roles: string[]) {
    return this.http
      .post(`${UNFOLLOW_ACCOUNT_URL}`, {
        accountId: accountNumber,
        publicId: contactId,
        roles: roles,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          this.sentryService.notify('Unable to unfollow account.', {
            severity: 'error',
            metaData: {
              accountNumber,
              currentUserName: this.currentUserName(),
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  list() {
    let params = new HttpParams();
    params = params.set('max_number_of_results', '100');
    params = params.set('start', '0');
    const options = { params: params };
    return this.http.get(V3_ACCOUNT_API_URI, options).pipe(
      timeout(60 * 1000),
      map((response: BackendListAccountsPayload) => {
        const processedAccountList = InsuredAccount.fromAccountsListBackendApiPayload(response);
        if (processedAccountList.length > 0) {
          try {
            localStorage.setItem('userHasAccounts', 'true');
          } catch (error) {
            this.sentryService.notify('Failed to set local storage for user with accounts', {
              severity: 'info',
              metaData: {
                currentUserName: this.currentUserName(),
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
        } else {
          const userHasAccounts = JSON.parse(localStorage.getItem('userHasAccounts') || 'false');
          if (userHasAccounts) {
            this.sentryService.notify(
              'Received empty account list response for user with accounts',
              {
                severity: 'error',
                metaData: {
                  accountListResponse: response,
                  currentUserName: this.currentUserName(),
                },
              }
            );
          }
        }
        this.accountListSubject.next(processedAccountList);
        return processedAccountList;
      }),
      shareReplay(),
      catchError((error) => {
        this.sentryService.notify('Error listing accounts.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  listCached() {
    return merge(
      this.list(),
      this.accountListSubject.pipe(
        // don't send out the null value that initializes the subject
        filter((value: InsuredAccount[]) => value !== null)
      )
    );
  }

  search(query: string) {
    let params = new HttpParams();
    params = params.set('max_number_of_results', '100');
    params = params.set('start', '0');
    // regex: match if the query is 3 or more digits
    if (query.match(/^\d{3,}$/)) {
      params = params.set('account_number', query);
    } else {
      params = params.set('company_name', query);
    }

    const options = { params: params };

    return this.http.get(V3_ACCOUNT_API_URI, options).pipe(
      map((response: BackendListAccountsPayload) => {
        return InsuredAccount.fromAccountsListBackendApiPayload(response);
      }),
      catchError((error) => {
        this.sentryService.notify('Error searching accounts.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  cachebust() {
    this.cachebustNextCall = true;
  }

  getPolicyCount(): Observable<PolicyCountResponse> {
    return this.http.get<PolicyCountResponse>(V3_POLICY_COUNT_API).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting policy count.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getAccountByPolicyNumber(policyNumber: string): Observable<AccountSearchByPolicyResponse> {
    return this.http
      .get<AccountSearchByPolicyResponse>(`${V3_ACCOUNTS_BY_POLICY_NO}${policyNumber}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Error getting policy count.', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  edit(insuredAccount: InsuredAccount) {
    const payload = BackendCreateInsuredAccountRequestPayloadFactory.buildV3Payload(insuredAccount);

    return this.http.put<BackendCreateAccountResponse>(V3_ACCOUNT_API_URI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Error editing account.', {
          severity: 'error',
          metaData: {
            insuredAccount,
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  archive(jobNumber: string) {
    return this.http.post(`${ARCHIVE_ACCOUNT_API_URI}/${jobNumber}/withdraw`, {}).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to archive quote.' + error, {
          severity: 'error',
          metaData: {
            jobNumber,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getPreRenewalDirection(jobNumber: string) {
    return this.http.get<PreRenewalDirection>(`${GET_PRERENEWAL_DIRECTION_URL}/${jobNumber}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve prerenewal direction.', {
          severity: 'error',
          metaData: {
            jobNumber,
            userName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
          },
        });
        throw error;
      })
    );
  }

  retrievePolicyTransactionTerms(policyNumber: string, termNumber: string) {
    return this.http
      .get<PolicyPeriod>(`${GET_POLICY_PERIOD_URI}${policyNumber}/${termNumber}/transactions`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to retrieve policy term.', {
            severity: 'error',
            metaData: {
              policyNumber,
              termNumber,
              userName: this.currentUserName(),
              underlyingErrorMessage: error && error.message,
            },
          });
          throw error;
        })
      );
  }

  // Update the org type and return the updated account info
  updateOrgTypeIfNecessary(newOrgType: string): Observable<InsuredAccount> {
    const currentValue = this.insuredSubject.value;
    if (!currentValue || currentValue.organizationType === newOrgType) {
      return observableOf(this.insuredSubject.value);
    } else {
      this.cachebust();
      const editedValue = { ...currentValue, organizationType: newOrgType } as InsuredAccount;
      return this.edit(editedValue).pipe(mapTo(editedValue));
    }
  }

  private currentUserName() {
    const user = this.currentUserService.getCurrentUser();
    if (user && user.username) {
      return user.username;
    }
    throw new Error('Login Expired');
  }

  // TODO: move getRenewalSummary, getPendingCancellationSummary, and getCancelledSummary to recent-activity-summary.service.ts since
  // they're more related to the summary widgets on the activity page than the account API
  getRenewalSummary(start: number, limit: number) {
    const payload = { start: start + 1, limit }; // This API is 1-indexed, not 0-indexed
    return this.http.post<PolicyRenewal[]>(V3_RENEWALS_SUMMARY_API, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting renewals.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getPendingCancellationSummary(start: number, limit: number) {
    const payload = { start: start + 1, limit }; // This API is 1-indexed, not 0-indexed
    return this.http.post<PolicyCancellation[]>(V3_PENDING_CANCELLATION_SUMMARY_API, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting pending cancellations summary.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getCancelledSummary(start: number, limit: number) {
    const payload = { start: start + 1, limit }; // This API is 1-indexed, not 0-indexed
    return this.http.post<PolicyCancellation[]>(V3_CANCELLED_SUMMARY_API, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting cancelled summary.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }
}
