<article>
  <h3 class="policy-pane-subheader">Additional documents</h3>
  <ul class="link-list">
    <li *ngIf="!isPayByPayPolicy">
      <a
        class="insured-billing-download"
        [attr.data-amplitude-input-name]="'downloaded_insured_billing_guide_' + paneType + '_pane'"
        target="_blank"
        href="{{ insuredBillingGuide }}"
      >
        Insured Billing Guide
      </a>
    </li>
    <li *ngIf="isAttuneWc">
      <a
        [attr.data-amplitude-input-name]="'attune-wc-guidelines-' + paneType + '-pane'"
        target="_blank"
        href="{{ attuneWcGuidelines }}"
      >
        Underwriting Guidelines
      </a>
    </li>
    <li *ngIf="isAttuneWc">
      <a
        [attr.data-amplitude-input-name]="'attune-wc-book-balance-' + paneType + '-pane'"
        target="_blank"
        href="{{ attuneWcBookBalanceGuide }}"
      >
        Book Balance at Attune
      </a>
    </li>
    <li *ngIf="!isAttuneWc">
      <a
        [attr.data-amplitude-input-name]="'endorsements-guide-' + paneType + '-pane'"
        target="_blank"
        href="{{ brokerEndorsementsGuide }}"
      >
        Broker Endorsements Guide
      </a>
    </li>
  </ul>
</article>
