/* eslint-disable max-len */

import { SpoilageClass, ClassRestrictedCoverage } from 'app/features/attune-bop/models/constants';

export type BuildingClassificationCategory =
  | 'Contractor'
  | 'ApartmentCondominiumAssociation'
  | 'OfficeCondominium'
  | 'Office'
  | 'ProcessingandService'
  | 'Wholesaler'
  | 'Mercantile'
  | 'RestaurantCasualDining'
  | 'RestaurantFineDining'
  | 'RestaurantLimitedCooking'
  | 'RestaurantFastFood'
  | 'Artisans';

export interface BuildingClassificationInfo {
  bopV2Only: boolean;
  bopV1Only?: boolean;
  code: string;
  description: string;
  descriptionCode: string;
  lroOptions: string[];
  category: BuildingClassificationCategory;
  classRestrictedCoverages: ClassRestrictedCoverage[];
  spoilageClass?: SpoilageClass;
  excludeInStates?: string[];
}

export const buildingClassifications: BuildingClassificationInfo[] = [
  {
    bopV2Only: false,
    category: 'ApartmentCondominiumAssociation',
    classRestrictedCoverages: [],
    code: '60989',
    description: 'Commercial Condominium (Association risk only)',
    descriptionCode: 'CommercialCondominiumAssociationriskonly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ApartmentCondominiumAssociation',
    classRestrictedCoverages: [],
    code: '60999',
    description: 'Office Condominium (Association risk only)',
    descriptionCode: 'OfficeCondominiumAssociationRiskOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74011',
    description: 'AC System or Equip-Dealer or Distbtrs & Install, Srvc or Repair-Office',
    descriptionCode: 'ACSystemEquipDealerDistbtrsInstallSrvcRepairOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74021',
    description: 'AC System or Equip-Dealer or Distbtrs & Install, Srvc or Repair-Shop',
    descriptionCode: 'ACSystemEquipDealerDistbtrsInstallSrvcRepairShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74071',
    description: 'Appliances & Accessories-Installation or Service or Repair-Comml-Office',
    descriptionCode: 'AppliancesAccessoriesInstallSrvcRepairCommlOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74081',
    description: 'Appliances & Accessories-Installation or Service or Repair-Comml-Shop',
    descriptionCode: 'AppliancesAccessoriesInstallSrvcRepairCommlShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74101',
    description:
      'Appliances and Accessories – Installation or Service or Repair – Household (Not including TV or Radio) - Office',
    descriptionCode: 'AppliancesAccessoriesInstallSrvcRepairHouseholdOff',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74111',
    description:
      'Appliances and Accessories – Installation or Service or Repair – Household (Not including TV or Radio) - Shop',
    descriptionCode: 'AppliancesAccessoriesInstallSrvcRepairHouseholdSho',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74161',
    description: 'Carpentry-Construct of Residential Prop-Not Exceeding 3 Stories in Height-Office',
    descriptionCode: 'CarpentryConstructResidentialPropNotOver3StoriesOf',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74171',
    description: 'Carpentry-Construct of Residential Prop-Not Exceeding 3 Stories in Height-Shop',
    descriptionCode: 'CarpentryConstructResidentialPropNotOver3StoriesSh',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74221',
    description: 'Carpentry-Interior-Office',
    descriptionCode: 'CarpentryInteriorOffice',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74231',
    description: 'Carpentry-Interior-Shop',
    descriptionCode: 'CarpentryInteriorShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74251',
    description: "Carpet, Rug, Furniture or Upholstery Cleaning-on Customer's Premises-Office",
    descriptionCode: 'CarpetRugFurnitUpholsteryCleaningCustomersPremOffi',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74261',
    description: "Carpet, Rug, Furniture or Upholstery Cleaning-on Customer's Premises-Shop",
    descriptionCode: 'CarpetRugFurnitUpholsteryCleaningCustomersPremShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74281',
    description: 'Ceiling/Wall Installation-Metal Only-Office',
    descriptionCode: 'CeilingWallInstallationMetalOnlyOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74291',
    description: 'Ceiling/Wall Installation-Metal Only-Shop',
    descriptionCode: 'CeilingWallInstallationMetalOnlyShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74341',
    description: 'Concrete Construction-Office',
    descriptionCode: 'ConcreteConstructionOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74351',
    description: 'Concrete Construction-Shop',
    descriptionCode: 'ConcreteConstructionShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74411',
    description: 'Door, Window or Assembled Millwork-Installation-Metal Only-Office',
    descriptionCode: 'DoorWindowAssembledMillworkInstallMetalOnlyOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74421',
    description: 'Door, Window or Assembled Millwork-Installation-Metal Only-Shop',
    descriptionCode: 'DoorWindowAssembledMillworkInstallMetalOnlyShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74471',
    description: 'Driveway, Parking Area or Sidewalk Repair-Paving or Re-Paving-Office',
    descriptionCode: 'DrivewayParkingAreaSidewalkRepairPavingRePavingOff',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74481',
    description: 'Driveway, Parking Area or Sidewalk Repair-Paving or Re-Paving-Shop',
    descriptionCode: 'DrivewayParkingAreaSidewalkRepairPavingRePavingSho',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74501',
    description: 'Dry Wall/Wallboard Installation-Office',
    descriptionCode: 'DryWallWallboardInstallationOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74511',
    description: 'Dry Wall/Wallboard Installation-Shop',
    descriptionCode: 'DryWallWallboardInstallationShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74541',
    description: 'Electrical Work-Within Buildings-Shop',
    descriptionCode: 'ElectricalWorkWithinBuildingsShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74561',
    description: 'Electrical Work-Within Buildings-Office',
    descriptionCode: 'ElectricalWorkWithinBuildingsOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74591',
    description: 'Fence Erection Contractors-Office',
    descriptionCode: 'FenceErectionContractorsOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74601',
    description: 'Fence Erection Contractors-Shop',
    descriptionCode: 'FenceErectionContractorsShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74651',
    description: 'Floor Covering Installation-Not Ceramic Tile or Stone-Office',
    descriptionCode: 'FloorCoveringInstallationNotCeramicTileStoneOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74661',
    description: 'Floor Covering Installation-Not Ceramic Tile or Stone-Shop',
    descriptionCode: 'FloorCoveringInstallationNotCeramicTileStoneShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74681',
    description: 'Furniture/Fixtures-Install in Offices or Stores-Portable-Metal or Wood-Office',
    descriptionCode: 'FurnitFixturesInstallOfficesStoresPortblMetlWoodOf',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74691',
    description: 'Furniture/Fixtures-Install in Offices or Stores-Portable-Metal or Wood-Shop',
    descriptionCode: 'FurnitFixturesInstallOfficesStoresPortblMetlWoodSh',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74741',
    description: 'Glass Dealers & Glaziers-Sales & Installation-Office',
    descriptionCode: 'GlassDealersGlaziersSalesInstallationOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74751',
    description: 'Glass Dealers & Glaziers-Sales & Installation-Shop',
    descriptionCode: 'GlassDealersGlaziersSalesInstallationShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74771',
    description: 'Heat/Comb Heat & AC Sys/Equipmt-Dlr, Distbtrs & Instl, Srvc/Repr-No LPG-Office',
    descriptionCode: 'HeatCombACSysEquipDlrDistbtrsInstlSrvcReprNoLPGOff',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74781',
    description: 'Heat/Comb Heat & AC Sys/Equipmt-Dlr, Distbtrs & Instl, Srvc/Repr-No LPG-Shop',
    descriptionCode: 'HeatCombACSysEquipDlrDistbtrsInstlSrvcReprNoLPGSho',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74831',
    description: 'House Furnishings Installation-Not Otherwise Classified-Office',
    descriptionCode: 'HouseFurnishingsInstallNotOtherwiseClassifiedOffic',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74841',
    description: 'House Furnishings Installation-Not Otherwise Classified-Shop',
    descriptionCode: 'HouseFurnishingsInstallNotOtherwiseClassifiedShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74861',
    description: 'Interior Decorators-Office',
    descriptionCode: 'InteriorDecoratorsOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74871',
    description: 'Interior Decorators-Shop',
    descriptionCode: 'InteriorDecoratorsShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*   {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74891',
    description: 'Landscape Gardening-No Tree Removal or Excavation-Office',
    descriptionCode: 'LandscapeGardeningNoTreeRemovalExcavationOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  /*   {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74901',
    description: 'Landscape Gardening-No Tree Removal or Excavation-Shop',
    descriptionCode: 'LandscapeGardeningNoTreeRemovalExcavationShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74951',
    description: 'Lawn Sprinkler Installation-Office',
    descriptionCode: 'LawnSprinklerInstallationOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '74961',
    description: 'Lawn Sprinkler Installation-Shop',
    descriptionCode: 'LawnSprinklerInstallationShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75511',
    description: 'Masonry-Office',
    descriptionCode: 'MasonryOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75521',
    description: 'Masonry-Shop',
    descriptionCode: 'MasonryShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75541',
    description: 'Metal Erection-Decorative/Artistic-Office',
    descriptionCode: 'MetalErectionDecorativeArtisticOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75551',
    description: 'Metal Erection-Decorative/Artistic-Shop',
    descriptionCode: 'MetalErectionDecorativeArtisticShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75601',
    description: 'Painting-Exterior-Buildings/Structures-3 Stories or Less in Height-Office',
    descriptionCode: 'PaintingExteriorBldgsStruct3StoriesLessHeightOffic',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75611',
    description: 'Painting-Exterior-Buildings/Structures-3 Stories or Less in Height-Shop',
    descriptionCode: 'PaintingExteriorBldgsStruct3StoriesLessHeightShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75631',
    description: 'Painting-Interior Buildings/Structures-Office',
    descriptionCode: 'PaintingInteriorBuildingsStructuresOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75641',
    description: 'Painting-Interior Buildings/Structures-Shop',
    descriptionCode: 'PaintingInteriorBuildingsStructuresShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75691',
    description: 'Paper Hanging-Office',
    descriptionCode: 'PaperHangingOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75701',
    description: 'Paper Hanging-Shop',
    descriptionCode: 'PaperHangingShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75751',
    description: 'Plastering/Stucco Work-Office',
    descriptionCode: 'PlasteringStuccoWorkOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75761',
    description: 'Plastering/Stucco Work-Shop',
    descriptionCode: 'PlasteringStuccoWorkShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*   {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75781',
    description: 'Plumbing-Comml-Office',
    descriptionCode: 'PlumbingCommlOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  /*   {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75791',
    description: 'Plumbing-Comml-Shop',
    descriptionCode: 'PlumbingCommlShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  /*   {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75811',
    description: 'Plumbing-Residential/Domestic-Office',
    descriptionCode: 'PlumbingResidentialDomesticOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  /*   {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75821',
    description: 'Plumbing-Residential/Domestic-Shop',
    descriptionCode: 'PlumbingResidentialDomesticShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75871',
    description: 'Refrig Systems/Equipmt-Dealers & Distbtrs & Install Srvc or Repair-Comml-Office',
    descriptionCode: 'RefrigSysEquipDealersDistbInstallSrvcRepairCommlOf',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75881',
    description: 'Refrig Systems/Equipmt-Dealers & Distbtrs & Install, Srvc or Repair-Comml-Shop',
    descriptionCode: 'RefrigSysEquipDealersDistbInstallSrvcRepairCommlSh',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75961',
    description: 'Septic Tank Systems-Cleaning-Office',
    descriptionCode: 'SepticTankSystemsCleaningOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '75971',
    description: 'Septic Tank Systems-Cleaning-Shop',
    descriptionCode: 'SepticTankSystemsCleaningShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '76051',
    description: 'Sign Painting/Lettering-Inside of Building-No Spray Painting-Office',
    descriptionCode: 'SignPaintingLetteringInsideBldgNoSprayPaintingOffi',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '76061',
    description: 'Sign Painting/Lettering-Inside of Building-No Spray Painting-Shop',
    descriptionCode: 'SignPaintingLetteringInsideBldgNoSprayPaintingShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '76111',
    description: 'Tile, Stone, Marble Mosaic or Terrazzo Work-Interior Construction-Office',
    descriptionCode: 'TileStoneMarbleMosaicTerrazzoWorkIntConstructOffic',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '76121',
    description: 'Tile, Stone, Marble Mosaic or Terrazzo Work-Interior Construction-Shop',
    descriptionCode: 'TileStoneMarbleMosaicTerrazzoWorkIntConstructShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '76221',
    description: 'Residential Cleaning Services - Office',
    descriptionCode: 'ResidentialCleaningServicesOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '76231',
    description: 'Residential Cleaning Services - Shop',
    descriptionCode: 'ResidentialCleaningServicesShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79986',
    description: 'Telecommunications - subcontracted work in connection - Shop',
    descriptionCode: 'TelecommsubcontractWorkInConnectShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79987',
    description: 'Telecommunications - subcontracted work in connection - Office',
    descriptionCode: 'TelecommsubcontractWorkInConnectOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79988',
    description: 'Telecommunications - installation service and construction - Shop',
    descriptionCode: 'TelecommInstallationServiceAndConstrShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79989',
    description: 'Telecommunications - installation service and construction - Office',
    descriptionCode: 'TelecommInstallationServiceAndConstrOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79990',
    description: 'Technology and Networking Hardware Service (commercial) - Shop',
    descriptionCode: 'TechnologyandNetworkingHardwareServiceShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79991',
    description: 'Technology and Networking Hardware Service (commercial) - Office',
    descriptionCode: 'TechnologyandNetworkingHardwareServiceOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79992',
    description: 'Technology and Networking Hardware Service (residential) - Shop',
    descriptionCode: 'TechnologyandNetworkingHardwareServiceResidShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79993',
    description: 'Technology and Networking Hardware Service (residential) - Office',
    descriptionCode: 'TechnologyandNetworkingHardwareServiceResidOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79994',
    description: 'Swimming Pool Cleaning and Service (no installation or repair) - Shop',
    descriptionCode: 'SwimmingPoolCleaningandServiceShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79995',
    description: 'Swimming Pool Cleaning and Service (no installation or repair) - Office',
    descriptionCode: 'SwimmingPoolCleaningandServiceOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79996',
    description: 'Solar Panel Installation - 3 Stories or Less in Height -Shop',
    descriptionCode: 'SolarPanelInstallation3StoriesorLessinHeightShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79997',
    description: 'Solar Panel Installation - 3 Stories or Less in Height - Office',
    descriptionCode: 'SolarPanelInstallation3StoriesorLessinHeightOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79998',
    description: 'Handypersons - Shop',
    descriptionCode: 'HandypersonsShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Contractor',
    classRestrictedCoverages: [],
    code: '79999',
    description: 'Handypersons - Office',
    descriptionCode: 'HandypersonsOffice',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50333',
    description: "Clothing/Wearing Apparel-Retail-Men's & Boy's Hats & Caps",
    descriptionCode: 'ClothingWearingApparelRetailMensBoysHatsCaps',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50811',
    description: 'Precision & Scientific Tools and Instruments-Retail',
    descriptionCode: 'PrecisionScientificToolsandInstrumentsRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50812',
    description: 'Bookbinding & Printing Supplies-Retail',
    descriptionCode: 'BookbindingPrintingSuppliesRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50819',
    description: 'Powered Equipment Dealers',
    descriptionCode: 'PoweredEquipmentDealers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50925',
    description: 'Office Machines or Appliances-Retail-No Repair',
    descriptionCode: 'OfficeMachinesorAppliancesRetailNoRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50928',
    description: 'Paper & Paper Products, Rag or Rubber Stock Dealers-Secondhand',
    descriptionCode: 'PaperPaperProductsRagRubberStockDealersSecondhand',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '50943',
    description: 'Wood Products-Not Otherwise Classified-Retail Only',
    descriptionCode: 'WoodProductsNotOtherwiseClassifiedRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51960',
    description: 'Yarn Stores',
    descriptionCode: 'YarnStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51961',
    description: 'Vacuum Cleaner Sales and Service',
    descriptionCode: 'VacuumCleanerSalesAndService',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51962',
    description: 'Uniform Store',
    descriptionCode: 'UniformStore',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51963',
    description: 'Tie Shop',
    descriptionCode: 'TieShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51964',
    description: 'Sunglass Shop',
    descriptionCode: 'SunglassShop',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51965',
    description: 'Sign Stores',
    descriptionCode: 'SignStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51966',
    description: 'Scrapbooking Stores',
    descriptionCode: 'ScrapbookingStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51967',
    description: 'Religious Articles Stores',
    descriptionCode: 'ReligiousArticlesStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /* 51968 is a Controlled class {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51968',
    description: 'Pottery Stores - with kiln',
    descriptionCode: 'PotteryStoreswithkiln',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51969',
    description: 'Pottery Stores - no kiln',
    descriptionCode: 'PotteryStoresNokiln',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51970',
    description: 'Popcorn Shops',
    descriptionCode: 'PopcornShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51971',
    description: 'Pool Table and Billiards Supply Stores',
    descriptionCode: 'PoolTableAndBilliardsSupplyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51972',
    description: 'Piece Goods, Sewing & Needlework Stores',
    descriptionCode: 'PieceGoodsSewingNeedleworkStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '51973',
    description: 'Pharmacies (Dispensing Only Prescription Drugs)',
    descriptionCode: 'PharmaciesDispensingOnlyPrescriptionDrugs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /* 51974 is a Controlled Class {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51974',
    description: 'Party Supply Stores (with rentals)',
    descriptionCode: 'PartySupplyStoreswithrentals',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51975',
    description: 'Party Supply Stores (no rentals)',
    descriptionCode: 'PartySupplyStoresNoRentals',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51976',
    description: 'Nut Shops',
    descriptionCode: 'NutShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51977',
    description: 'Monument and Tombstones',
    descriptionCode: 'MonumentAndTombstones',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51978',
    description: 'Knives and Scissors Stores',
    descriptionCode: 'KnivesAndScissorsStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51979',
    description: 'Kitchen Accessories',
    descriptionCode: 'KitchenAccessories',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51980',
    description: 'Kiosks - food',
    descriptionCode: 'KiosksFood',
    lroOptions: ['10orless', 'Over10'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51981',
    description: 'Kiosks - non food',
    descriptionCode: 'KiosksNonFood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51982',
    description: 'Instructional Arts and Crafts Stores - Bring Your Own Alcohol',
    descriptionCode: 'InstructionalArtsCraftsStoresBringOwnAlcohol',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51983',
    description: 'Holiday Boutiques',
    descriptionCode: 'HolidayBoutiques',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51985',
    description: 'Greeting Card Stores',
    descriptionCode: 'GreetingCardStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51986',
    description: 'Glass Novelty Store',
    descriptionCode: 'GlassNoveltyStore',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51987',
    description: 'Education and School Supply Stores',
    descriptionCode: 'EducationAndSchoolSupplyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51988',
    description: 'Edible Bouquet Stores',
    descriptionCode: 'EdibleBouquetStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51989',
    description: 'Cookie Stores',
    descriptionCode: 'CookieStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51990',
    description: 'Collectibles - New Merchandise Only',
    descriptionCode: 'CollectiblesNewMerchandiseOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51991',
    description: 'Coffee, Tea, and Spice Specialty Stores',
    descriptionCode: 'CoffeeTeaAndSpiceSpecialtyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51992',
    description: 'Clock Shop with Repair',
    descriptionCode: 'ClockShopWithRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51993',
    description: 'China and Crystal Stores',
    descriptionCode: 'ChinaAndCrystalStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51994',
    description: 'Cheese Shops',
    descriptionCode: 'CheeseShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51995',
    description: 'Candle Shops',
    descriptionCode: 'CandleShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51996',
    description: 'Boutique / Gourmet Food Shops',
    descriptionCode: 'BoutiqueGourmetFoodShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51997',
    description: 'Beer and Wine Making Supply Stores',
    descriptionCode: 'BeerAndWineMakingSupplyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51998',
    description: 'Bed and Bath Shops',
    descriptionCode: 'BedandBathShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '51999',
    description: 'Battery Stores',
    descriptionCode: 'BatteryStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '52322',
    description: 'Paint, Wallpaper or Wallcovering Stores',
    descriptionCode: 'PaintWallpaperorWallcoveringStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '52512',
    description: 'Locksmiths',
    descriptionCode: 'Locksmiths',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '52512',
    description: 'Hardware & Tools-Retail',
    descriptionCode: 'HardwareToolsRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '53127',
    description: 'Department Stores',
    descriptionCode: 'DepartmentStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*   {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '53315',
    description: 'Variety Stores-Discount Houses',
    descriptionCode: 'VarietyStoresDiscountHouses',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  /*   {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '53317',
    description: 'Variety Stores-Five and Ten Cent Stores',
    descriptionCode: 'VarietyStoresFiveandTenCentStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '53983',
    description: 'Army and Navy Stores',
    descriptionCode: 'ArmyandNavyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '53985',
    description: 'Dry Goods Dealers-Retail-Including Fabrics,Yarn & Piece Goods',
    descriptionCode: 'DryGoodsDealersRetailIncludingFabricsYarnPieceGood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '53989',
    description: 'Home Improvement Stores',
    descriptionCode: 'HomeImprovementStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: ['Spoilage'],
    code: '54136',
    description: 'Health/Natural Food Stores-With an Area Less Than 4000 Square Feet',
    descriptionCode: 'HealthNaturalFoodStoresWithanAreaLessThan4000Squar',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '54136',
    description: 'Convenience Food Stores-Without Gasoline Sales-No Restaurant',
    descriptionCode: 'ConvenienceFoodStoresWithoutGasolineSalesNoRestaur',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'Seafood',
    classRestrictedCoverages: ['Spoilage'],
    code: '54216',
    description: 'Meat, Fish, Poultry or Seafood-Fish or Seafood',
    descriptionCode: 'MeatFishPoultryorSeafoodFishorSeafood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'MeatandPoultryMarkets',
    classRestrictedCoverages: ['Spoilage'],
    code: '54216',
    description: 'Meat, Fish, Poultry or Seafood-Meat or Poultry',
    descriptionCode: 'MeatFishPoultryorSeafoodMeatorPoultry',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'FruitsandVegetables',
    classRestrictedCoverages: ['Spoilage'],
    code: '54315',
    description: 'Fruit or Vegetable-Dealers',
    descriptionCode: 'FruitorVegetableDealers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'Other',
    classRestrictedCoverages: ['Spoilage'],
    code: '54457',
    description: 'Candy/Confectionery Stores-No Manufacturing on Premises',
    descriptionCode: 'CandyConfectioneryStoresNoManufacturingPremises',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'Other',
    classRestrictedCoverages: ['Spoilage'],
    code: '54477',
    description: 'Candy/Confectionery Stores-Nonchocolate Confectionary Mfg on Premises',
    descriptionCode: 'CandyConfectioneryStoresNonchocolateConfectionaryM',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'Other',
    classRestrictedCoverages: ['Spoilage'],
    code: '54606',
    description: 'Bakeries-Retail-No Baking on Premises',
    descriptionCode: 'BakeriesRetailNoBakingonPremises',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '55313',
    description: 'Automobile Parts and Supplies-Retail Stores',
    descriptionCode: 'AutomobilePartsandSuppliesRetailStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56113',
    description: "Clothing/Wearing Apparel-Retail-Haberdashery & Men's Furnishings",
    descriptionCode: 'ClothingWearingApparelRetailHaberdasheryMensFurnis',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56114',
    description: "Clothing/Wearing Apparel-Retail-Clothing-Mens' & Boys' (Coats & Suits)",
    descriptionCode: 'ClothingWearingApparelRetailClothingMensBoysCoatsS',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56214',
    description: 'Clothing/Wearing Apparel-Retail-Clothing-Ladies & Girls (Coats, Suits & Dresses)',
    descriptionCode: 'ClothingWearingApparelRetailClothingLadiesGirlsCoa',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56311',
    description: 'Clothing/Wearing Apparel-Retail-Fabric Stores',
    descriptionCode: 'ClothingWearingApparelRetailFabricStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56311',
    description: 'Fabric Stores',
    descriptionCode: 'FabricStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56312',
    description: "Clothing/Wearing Apparel-Retail-Ladies' Undergarments & Lingerie",
    descriptionCode: 'ClothingWearingApparelRetailLadiesUndergarmentsLin',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56313',
    description: 'Clothing/Wearing Apparel-Retail-Hosiery',
    descriptionCode: 'ClothingWearingApparelRetailHosiery',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56319',
    description: "Clothing/Wearing Apparel-Retail-Ladies' Specialty Stores",
    descriptionCode: 'ClothingWearingApparelRetailLadiesSpecialtyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56325',
    description: 'Clothing/Wearing Apparel-Retail-Family Clothing Stores',
    descriptionCode: 'ClothingWearingApparelRetailFamilyClothingStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56413',
    description: "Clothing/Wearing Apparel-Retail-Children's & Infants Wear",
    descriptionCode: 'ClothingWearingApparelRetailChildrensInfantsWear',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56613',
    description: "Clothing/Wearing Apparel-Retail-Shoe Stores-Children's, Ladies & Men's",
    descriptionCode: 'ClothingWearingApparelRetailShoeStoresChildrensLad',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56613',
    description: 'Shoe Stores-Retail',
    descriptionCode: 'ShoeStoresRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56814',
    description: 'Fur-Garments & Pelts-Retail Only',
    descriptionCode: 'FurGarmentsPeltsRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56992',
    description: 'Leather Products/Hide Stores-Retail Only',
    descriptionCode: 'LeatherProductsHideStoresRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '56992',
    description: 'Clothing/Wearing Apparel-Retail-Leather Products or Hide Stores',
    descriptionCode: 'ClothingWearingApparelRetailLeatherProductsHideSto',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57121',
    description: 'Furniture-Upholstered-Retail Only',
    descriptionCode: 'FurnitureUpholsteredRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57128',
    description: 'Furniture-Wood or Metal-Retail Only',
    descriptionCode: 'FurnitureWoodorMetalRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57134',
    description: 'Floor Covering-Stores-Except Wood or Ceramic Tile Only',
    descriptionCode: 'FloorCoveringStoresExceptWoodCeramicTileOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57134',
    description: 'Floor Covering-Stores-Wood or Ceramic Tile Only',
    descriptionCode: 'FloorCoveringStoresWoodorCeramicTileOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57155',
    description: 'Glass Dealers & Glaziers-Retail Only',
    descriptionCode: 'GlassDealersGlaziersRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57223',
    description: 'Sewing Machine Stores',
    descriptionCode: 'SewingMachineStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*   {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57224',
    description: 'Appliance Stores-Household Appliances and Home Furnishings',
    descriptionCode: 'ApplianceStoresHouseholdAppliancesandHomeFurnishin',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57224',
    description: 'Home Furnishings Stores',
    descriptionCode: 'HomeFurnishingsStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57326',
    description: 'Computer Stores',
    descriptionCode: 'ComputerStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57326',
    description: 'Electronics Stores',
    descriptionCode: 'ElectronicsStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57326',
    description: 'Appliance Stores-Radio, Television and Phonographic Stores',
    descriptionCode: 'ApplianceStoresRadioTelevisionandPhonographicStore',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57334',
    description: 'Musical Instrument Stores',
    descriptionCode: 'MusicalInstrumentStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57338',
    description: 'Video Stores-Rental',
    descriptionCode: 'VideoStoresRental',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57338',
    description: 'Music Stores-Pre-Recorded',
    descriptionCode: 'MusicStoresPreRecorded',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '57338',
    description: 'Video Stores-Sales',
    descriptionCode: 'VideoStoresSales',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '59116',
    description: 'Drugstores',
    descriptionCode: 'Drugstores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59215',
    description: 'Beverage Stores-Liquor & Wine',
    descriptionCode: 'BeverageStoresLiquorWine',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59325',
    description: 'Antique Stores',
    descriptionCode: 'AntiqueStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59425',
    description: 'Books & Magazines Stores-Used',
    descriptionCode: 'BooksMagazinesStoresUsed',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59425',
    description: 'Books & Magazines Stores-New',
    descriptionCode: 'BooksMagazinesStoresNew',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59435',
    description: 'Stationery/Paper Products-Retail',
    descriptionCode: 'StationeryPaperProductsRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59505',
    description: 'Bicycle Shops-Retail',
    descriptionCode: 'BicycleShopsRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59505',
    description: 'Bicycle Shops-Repair & Maintenance Shops Without Retail',
    descriptionCode: 'BicycleShopsRepairMaintenanceShopsWithoutRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59526',
    description: 'Clothing/Wearing Apparel-Retail-Sporting Goods & Athletic Apparel',
    descriptionCode: 'ClothingWearingApparelRetailSportingGoodsAthleticA',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59526',
    description: 'Sporting Goods/Athletic Equipment Stores',
    descriptionCode: 'SportingGoodsAthleticEquipmentStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59625',
    description: 'Feed, Grain or Hay Dealers',
    descriptionCode: 'FeedGrainorHayDealers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    spoilageClass: 'Florists',
    classRestrictedCoverages: ['Spoilage'],
    code: '59685',
    description: 'Florists-Retail',
    descriptionCode: 'FloristsRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59695',
    description: 'Machinery/Equipment Dealers-Farm Type Only',
    descriptionCode: 'MachineryEquipmentDealersFarmTypeOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59698',
    description: 'Gardening & Light Farming Supply-Retail',
    descriptionCode: 'GardeningLightFarmingSupplyRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59715',
    description: 'Jewelry-Retail-Precious',
    descriptionCode: 'JewelryRetailPrecious',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59715',
    description: 'Jewelry-Retail-Costume',
    descriptionCode: 'JewelryRetailCostume',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59935',
    description: 'Newsstands',
    descriptionCode: 'Newsstands',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59954',
    description: 'Optical Goods-Retail',
    descriptionCode: 'OpticalGoodsRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59955',
    description: 'Camera & Photographic Equipment-Retail Only',
    descriptionCode: 'CameraPhotographicEquipmentRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59955',
    description: 'Photographic Equipment-Retail Only',
    descriptionCode: 'PhotographicEquipmentRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59974',
    description: 'Hearing Aid-Retail',
    descriptionCode: 'HearingAidRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59983',
    description: 'Refrigeration Equipment-Comml-Retail',
    descriptionCode: 'RefrigerationEquipmentCommlRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59991',
    description: 'Cosmetic, Hair or Skin Preparation-Retail Only',
    descriptionCode: 'CosmeticHairorSkinPreparationRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59992',
    description: 'Collectibles & Memorabilia-Retail',
    descriptionCode: 'CollectiblesMemorabiliaRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59993',
    description: 'Wigs-Retail Only',
    descriptionCode: 'WigsRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59993',
    description: 'Clothing/Wearing Apparel-Retail-Wigs',
    descriptionCode: 'ClothingWearingApparelRetailWigs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59994',
    description: 'Gift Shops',
    descriptionCode: 'GiftShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59995',
    description: "Hobby, Craft or Artists' Supply-Retail",
    descriptionCode: 'HobbyCraftorArtistsSupplyRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59996',
    description: 'Trophy Stores',
    descriptionCode: 'TrophyStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59998',
    description: 'Toys-Retail',
    descriptionCode: 'ToysRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Janitorial Supplies-Retail',
    descriptionCode: 'JanitorialSuppliesRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Electrical Lighting Stores',
    descriptionCode: 'ElectricalLightingStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Ceramics-Retail Only',
    descriptionCode: 'CeramicsRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Marble Products-Retail Only',
    descriptionCode: 'MarbleProductsRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Fence Dealers',
    descriptionCode: 'FenceDealers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Plumbing Supplies and Fixtures-Retail',
    descriptionCode: 'PlumbingSuppliesandFixturesRetail',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Straw & Straw Products-Retail Only',
    descriptionCode: 'StrawStrawProductsRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Mail Order Houses-Retail Only',
    descriptionCode: 'MailOrderHousesRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Luggage Goods-Retail Only',
    descriptionCode: 'LuggageGoodsRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Painting, Picture or Frame Stores',
    descriptionCode: 'PaintingPictureorFrameStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Metal Dealers',
    descriptionCode: 'MetalDealers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'AC Equipment-Retail Only',
    descriptionCode: 'ACEquipmentRetailOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '73905',
    description: 'Catalog/Premium Coupon Redemption Stores',
    descriptionCode: 'CatalogPremiumCouponRedemptionStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '84112',
    description: 'Art Galleries-Not-for-Profit',
    descriptionCode: 'ArtGalleriesNotforProfit',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  },
  /*   {
    bopV2Only: true,
    category: 'Mercantile',
    classRestrictedCoverages: [],
    code: '84113',
    description: 'Art Galleries-Other Than Not-for-Profit',
    descriptionCode: 'ArtGalleriesOtherThanNotforProfit',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  }, */
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63611',
    description: 'Accounting Services-Except CPAs',
    descriptionCode: 'AccountingServicesExceptCPAs',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63611',
    description: 'Accounting Services-Except CPAs-Lessors Risk Only',
    descriptionCode: 'AccountingServicesExceptCPAsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63631',
    description: 'Accounting Services-CPAs',
    descriptionCode: 'AccountingServicesCPAs',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63631',
    description: 'Accounting Services-CPAs-Lessors Risk Only',
    descriptionCode: 'AccountingServicesCPAsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63651',
    description: 'Advertising and Related Services',
    descriptionCode: 'AdvertisingandRelatedServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63651',
    description: 'Advertising and Related Services-Lessors Risk Only',
    descriptionCode: 'AdvertisingandRelatedServicesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63671',
    description: 'Bookkeeping Services',
    descriptionCode: 'BookkeepingServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63671',
    description: 'Bookkeeping Services-Lessors Risk Only',
    descriptionCode: 'BookkeepingServicesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63691',
    description: 'Collection Agencies-Lessors Risk Only',
    descriptionCode: 'CollectionAgenciesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63691',
    description: 'Collection Agencies',
    descriptionCode: 'CollectionAgencies',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63721',
    description: 'Credit Reporting Agencies-Lessors Risk Only',
    descriptionCode: 'CreditReportingAgenciesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63721',
    description: 'Credit Reporting Agencies',
    descriptionCode: 'CreditReportingAgencies',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63761',
    description: 'Employment Agencies-Lessors Risk Only',
    descriptionCode: 'EmploymentAgenciesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63761',
    description: 'Employment Agencies',
    descriptionCode: 'EmploymentAgencies',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63781',
    description: 'Engineers/Architects-Consulting-Not Engaged in Construction-Lessors Risk Only',
    descriptionCode: 'EngineersArchitectsConsultNotEngagedConstructionLe',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63781',
    description: 'Engineers/Architects-Consulting-Not Engaged in Construction',
    descriptionCode: 'EngineersArchitectsConsultNotEngagedConstruction',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63811',
    description: 'Health Maintenance Organizations-Lessors Risk Only',
    descriptionCode: 'HealthMaintenanceOrganizationsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63811',
    description: 'Health Maintenance Organizations',
    descriptionCode: 'HealthMaintenanceOrganizations',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63831',
    description: 'Inspection/Appraisal Co-Inspecting for Ins/Valuation Purposes',
    descriptionCode: 'InspectAppraisalCoInspectingInsValuationPurposes',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63831',
    description: 'Inspection/Appraisal Co-Inspecting for Ins/Valuation Purposes-Lessors Risk Only',
    descriptionCode: 'InspectAppraisalCoInspectingInsValuationPurposesLe',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63851',
    description: 'Insurance Agents-Lessors Risk Only',
    descriptionCode: 'InsuranceAgentsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63851',
    description: 'Insurance Agents',
    descriptionCode: 'InsuranceAgents',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63891',
    description: 'Labor Union',
    descriptionCode: 'LaborUnion',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63891',
    description: 'Labor Union-Lessors Risk Only',
    descriptionCode: 'LaborUnionLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63921',
    description: 'Lawyers',
    descriptionCode: 'Lawyers',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63921',
    description: 'Lawyers-Lessors Risk Only',
    descriptionCode: 'LawyersLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63941',
    description: "Manufacturers' Representatives-Lessors Risk Only",
    descriptionCode: 'ManufacturersRepresentativesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63941',
    description: "Manufacturers' Representatives",
    descriptionCode: 'ManufacturersRepresentatives',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63961',
    description: 'Marine Appraisers or Surveyors',
    descriptionCode: 'MarineAppraisersorSurveyors',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63961',
    description: 'Marine Appraisers or Surveyors-Lessors Risk Only',
    descriptionCode: 'MarineAppraisersorSurveyorsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '63981',
    description: 'Dental Offices',
    descriptionCode: 'DentalOffices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '63981',
    description: 'Dental Offices-Lessors Risk Only',
    descriptionCode: 'DentalOfficesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '63981',
    description: 'Medical Offices',
    descriptionCode: 'MedicalOffices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '63981',
    description: 'Medical Offices-Lessors Risk Only',
    descriptionCode: 'MedicalOfficesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64011',
    description: 'Payroll Accounting Services-Lessors Risk Only',
    descriptionCode: 'PayrollAccountingServicesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64011',
    description: 'Payroll Accounting Services',
    descriptionCode: 'PayrollAccountingServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64031',
    description: 'Political Campaign Headquarters or Offices-Lessors Risk Only',
    descriptionCode: 'PoliticalCampaignHeadquartersOfficesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64031',
    description: 'Political Campaign Headquarters or Offices',
    descriptionCode: 'PoliticalCampaignHeadquartersorOffices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64061',
    description: 'Real Estate Agents-Lessors Risk Only',
    descriptionCode: 'RealEstateAgentsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64061',
    description: 'Real Estate Agents',
    descriptionCode: 'RealEstateAgents',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64121',
    description: 'Ticket Agencies-Theatrical-Lessors Risk Only',
    descriptionCode: 'TicketAgenciesTheatricalLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64121',
    description: 'Ticket Agencies-Theatrical',
    descriptionCode: 'TicketAgenciesTheatrical',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64141',
    description: 'Ticket Agencies-Other Than Theatrical-Lessors Risk Only',
    descriptionCode: 'TicketAgenciesOtherThanTheatricalLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64141',
    description: 'Ticket Agencies-Other Than Theatrical',
    descriptionCode: 'TicketAgenciesOtherThanTheatrical',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64161',
    description: 'Title Agents-Lessors Risk Only',
    descriptionCode: 'TitleAgentsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '64161',
    description: 'Title Agents',
    descriptionCode: 'TitleAgents',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '64181',
    description: 'Veterinarians Office',
    descriptionCode: 'VeterinariansOffice',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Office',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '64181',
    description: 'Veterinarians Office-Lessors Risk Only',
    descriptionCode: 'VeterinariansOfficeLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69975',
    description: 'Writer - Grants or resume Lessors Risk Only',
    descriptionCode: 'WriterGrantsOrResumeLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69975',
    description: 'Writer - Grants or resume',
    descriptionCode: 'WriterGrantsOrResume',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69977',
    description: 'Website design and online marketing (for others) Lessors Risk Only',
    descriptionCode: 'WebsiteDesignAndOnlineMarketingLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69977',
    description: 'Website design and online marketing (for others)',
    descriptionCode: 'WebsiteDesignAndOnlineMarketing',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  /* 69978 is a Controlled class {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69978',
    description: 'Video Game Designer-Lessors Risk Only',
    descriptionCode: 'VideoGameLessorRisk',
    lroOptions: ['10orless'],
  }, */
  /* 69978 is a Controlled class {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69978',
    description: 'Video Game Designer',
    descriptionCode: 'VideoGameDesigner',
    lroOptions: ['Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69980',
    description: 'Software Development-Lessors Risk Only',
    descriptionCode: 'SoftwareDevLessorRisk',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69980',
    description: 'Software Development (no medical, industrial, or manufacturing work)',
    descriptionCode: 'SoftwareDevnomedindmanf',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  /*
   Social services not ready yet
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69983',
    description:
      'Social Service -not for profit only,  no onsite or housing services Lessors Risk Only',
    descriptionCode: 'SocialServiceLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  */
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69985',
    description: 'Life / Career Coach Lessors Risk Only',
    descriptionCode: 'LifeCareerCoachLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69985',
    description: 'Life / Career Coach',
    descriptionCode: 'LifeCareerCoach',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69987',
    description: 'Classroom Instructors (no educational facilities/institutions or tutoring)',
    descriptionCode: 'ClassroomInstructors',
    lroOptions: ['Over10', 'NotApplicable'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69987',
    description:
      'Classroom Instructors (no educational facilities/institutions or tutoring)  Lessors Risk Only',
    descriptionCode: 'ClassroomInstructorsLesserRiskOnly',
    lroOptions: ['10orless'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69988',
    description: 'Executive Search Agent Lessors Risk Only',
    descriptionCode: 'ExecutiveSearchAgentLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69988',
    description: 'Executive Search Agent',
    descriptionCode: 'ExecutiveSearchAgent',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69990',
    description: 'Data Processing Services Lessors Risk Only',
    descriptionCode: 'DataProcessingServicesLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69990',
    description: 'Data Processing Services',
    descriptionCode: 'DataProcessingServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69993',
    description: 'Consultants - not engaged in turn-around or implementation',
    descriptionCode: 'ConsultantsNotEngagedInTurnAroundOrImplementation',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69993',
    description: 'Consultants - not engaged in turn-around or implementation Lessors Risk Only',
    descriptionCode: 'ConsultantsNotEngagedLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  /*
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69994',
    description: 'Computer Software and Services Lessors Risk Only',
    descriptionCode: 'ComputerSoftwareAndServicesLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69994',
    description: 'Computer Software and Services',
    descriptionCode: 'ComputerSoftwareAndServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  */
  /*
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69995',
    description: 'Computer Software and Services Lessors Risk Only',
    descriptionCode: 'ComputerSoftwareAndServicesLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69995',
    description: 'Computer Software and Services',
    descriptionCode: 'ComputerSoftwareAndServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69996',
    description: 'Claims Adjuster',
    descriptionCode: 'ClaimsAdjuster',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69996',
    description: 'Claims Adjuster Lessors Risk Only',
    descriptionCode: 'ClaimsAdjusterLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69998',
    description: 'Arbitration and Mediation Services',
    descriptionCode: 'ArbitrationMediationServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69998',
    description: 'Arbitration and Mediation Services Office Lessors Risk Only',
    descriptionCode: 'ArbitrationMediationServicesLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63621',
    description: 'Accounting Services-Except CPAs-Lessors Risk Only',
    descriptionCode: 'AccountingServicesExceptCPAsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63621',
    description: 'Accounting Services-Except CPAs',
    descriptionCode: 'AccountingServicesExceptCPAs',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63641',
    description: 'Accounting Services-CPAs',
    descriptionCode: 'AccountingServicesCPAs',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63641',
    description: 'Accounting Services-CPAs-Lessors Risk Only',
    descriptionCode: 'AccountingServicesCPAsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63661',
    description: 'Advertising and Related Services',
    descriptionCode: 'AdvertisingandRelatedServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63661',
    description: 'Advertising and Related Services-Lessors Risk Only',
    descriptionCode: 'AdvertisingandRelatedServicesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63681',
    description: 'Bookkeeping Services',
    descriptionCode: 'BookkeepingServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63681',
    description: 'Bookkeeping Services-Lessors Risk Only',
    descriptionCode: 'BookkeepingServicesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63711',
    description: 'Collection Agencies-Lessors Risk Only',
    descriptionCode: 'CollectionAgenciesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63711',
    description: 'Collection Agencies',
    descriptionCode: 'CollectionAgencies',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63731',
    description: 'Credit Reporting Agencies-Lessors Risk Only',
    descriptionCode: 'CreditReportingAgenciesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63731',
    description: 'Credit Reporting Agencies',
    descriptionCode: 'CreditReportingAgencies',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63771',
    description: 'Employment Agencies-Lessors Risk Only',
    descriptionCode: 'EmploymentAgenciesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63771',
    description: 'Employment Agencies',
    descriptionCode: 'EmploymentAgencies',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63791',
    description: 'Engineers/Architects-Consulting-Not Engaged in Construction',
    descriptionCode: 'EngineersArchitectsConsultNotEngagedConstruction',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63791',
    description: 'Engineers/Architects-Consulting-Not Engaged in Construction-Lessors Risk Only',
    descriptionCode: 'EngineersArchitectsConsultNotEngagedConstructionLe',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63821',
    description: 'Health Maintenance Organizations-Lessors Risk Only',
    descriptionCode: 'HealthMaintenanceOrganizationsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63821',
    description: 'Health Maintenance Organizations',
    descriptionCode: 'HealthMaintenanceOrganizations',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63841',
    description: 'Inspection/Appraisal Co-Inspecting for Ins/Valuation Purposes',
    descriptionCode: 'InspectAppraisalCoInspectingInsValuationPurposes',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63841',
    description: 'Inspection/Appraisal Co-Inspecting for Ins/Valuation Purposes-Lessors Risk Only',
    descriptionCode: 'InspectAppraisalCoInspectingInsValuationPurposesLe',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63861',
    description: 'Insurance Agents-Lessors Risk Only',
    descriptionCode: 'InsuranceAgentsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63861',
    description: 'Insurance Agents',
    descriptionCode: 'InsuranceAgents',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63911',
    description: 'Labor Union',
    descriptionCode: 'LaborUnion',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63911',
    description: 'Labor Union-Lessors Risk Only',
    descriptionCode: 'LaborUnionLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63931',
    description: 'Lawyers-Lessors Risk Only',
    descriptionCode: 'LawyersLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63931',
    description: 'Lawyers',
    descriptionCode: 'Lawyers',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63951',
    description: "Manufacturers' Representatives-Lessors Risk Only",
    descriptionCode: 'ManufacturersRepresentativesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63951',
    description: "Manufacturers' Representatives",
    descriptionCode: 'ManufacturersRepresentatives',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63971',
    description: 'Marine Appraisers or Surveyors',
    descriptionCode: 'MarineAppraisersorSurveyors',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63971',
    description: 'Marine Appraisers or Surveyors-Lessors Risk Only',
    descriptionCode: 'MarineAppraisersorSurveyorsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '63991',
    description: 'Dental Offices-Lessors Risk Only',
    descriptionCode: 'DentalOfficesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '63991',
    description: 'Medical Offices',
    descriptionCode: 'MedicalOffices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '63991',
    description: 'Dental Offices',
    descriptionCode: 'DentalOffices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '63991',
    description: 'Medical Offices-Lessors Risk Only',
    descriptionCode: 'MedicalOfficesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64021',
    description: 'Payroll Accounting Services',
    descriptionCode: 'PayrollAccountingServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64021',
    description: 'Payroll Accounting Services-Lessors Risk Only',
    descriptionCode: 'PayrollAccountingServicesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64051',
    description: 'Political Campaign Headquarters or Offices',
    descriptionCode: 'PoliticalCampaignHeadquartersorOffices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64051',
    description: 'Political Campaign Headquarters or Offices-Lessors Risk Only',
    descriptionCode: 'PoliticalCampaignHeadquartersOfficesLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64071',
    description: 'Real Estate Agents',
    descriptionCode: 'RealEstateAgents',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64071',
    description: 'Real Estate Agents-Lessors Risk Only',
    descriptionCode: 'RealEstateAgentsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64131',
    description: 'Ticket Agencies-Theatrical-Lessors Risk Only',
    descriptionCode: 'TicketAgenciesTheatricalLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64131',
    description: 'Ticket Agencies-Theatrical',
    descriptionCode: 'TicketAgenciesTheatrical',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64151',
    description: 'Ticket Agencies-Other Than Theatrical-Lessors Risk Only',
    descriptionCode: 'TicketAgenciesOtherThanTheatricalLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64151',
    description: 'Ticket Agencies-Other Than Theatrical',
    descriptionCode: 'TicketAgenciesOtherThanTheatrical',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64171',
    description: 'Title Agents-Lessors Risk Only',
    descriptionCode: 'TitleAgentsLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '64171',
    description: 'Title Agents',
    descriptionCode: 'TitleAgents',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '64191',
    description: 'Veterinarians Office',
    descriptionCode: 'VeterinariansOffice',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'OfficeCondominium',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '64191',
    description: 'Veterinarians Office-Lessors Risk Only',
    descriptionCode: 'VeterinariansOfficeLessorRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69974',
    description: 'Writer - Grants or resume Lessors Risk Only',
    descriptionCode: 'WriterGrantsOrResumeLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69974',
    description: 'Writer - Grants or resume',
    descriptionCode: 'WriterGrantsOrResume',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69976',
    description: 'Website design and online marketing (for others) Lessors Risk Only',
    descriptionCode: 'WebsiteDesignAndOnlineMarketingLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69976',
    description: 'Website design and online marketing (for others)',
    descriptionCode: 'WebsiteDesignAndOnlineMarketing',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  /*   {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69979',
    description: 'Video Game Designer',
    descriptionCode: 'VideoGameDesigner',
    lroOptions: ['Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69979',
    description: 'Video Game Designer-Lessors Risk Only',
    descriptionCode: 'VideoGameLessorRisk',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69981',
    description: 'Software Development-Lessors Risk Only',
    descriptionCode: 'SoftwareDevLessorRisk',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69981',
    description: 'Software Development (no medical, industrial, or manufacturing work)',
    descriptionCode: 'SoftwareDevnomedindmanf',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69984',
    description: 'Life / Career Coach',
    descriptionCode: 'LifeCareerCoach',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69984',
    description: 'Life / Career Coach Lessors Risk Only',
    descriptionCode: 'LifeCareerCoachLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69986',
    description:
      'Classroom Instructors (no educational facilities/institutions or tutoring)  Lessors Risk Only',
    descriptionCode: 'ClassroomInstructorsLesserRiskOnly',
    lroOptions: ['10orless'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69986',
    description: 'Classroom Instructors (no educational facilities/institutions or tutoring)',
    descriptionCode: 'ClassroomInstructors',
    lroOptions: ['Over10', 'NotApplicable'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69989',
    description: 'Executive Search Agent Lessors Risk Only',
    descriptionCode: 'ExecutiveSearchAgentLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69989',
    description: 'Executive Search Agent',
    descriptionCode: 'ExecutiveSearchAgent',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69991',
    description: 'Data Processing Services',
    descriptionCode: 'DataProcessingServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69991',
    description: 'Data Processing Services Lessors Risk Only',
    descriptionCode: 'DataProcessingServicesLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69992',
    description: 'Consultants - not engaged in turn-around or implementation Lessors Risk Only',
    descriptionCode: 'ConsultantsNotEngagedLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69992',
    description: 'Consultants - not engaged in turn-around or implementation',
    descriptionCode: 'ConsultantsNotEngagedInTurnAroundOrImplementation',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69997',
    description: 'Claims Adjuster Lessors Risk Only',
    descriptionCode: 'ClaimsAdjusterLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69997',
    description: 'Claims Adjuster',
    descriptionCode: 'ClaimsAdjuster',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69999',
    description: 'Arbitration and Mediation Services Office Lessors Risk Only',
    descriptionCode: 'ArbitrationMediationServicesLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69999',
    description: 'Arbitration and Mediation Services',
    descriptionCode: 'ArbitrationMediationServices',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '59999',
    description: "Auctioneers-Sales Conducted Away From the Insured's Premises",
    descriptionCode: 'AuctioneersSalesConductedAwayFromtheInsuredsPremis',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '59999',
    description: 'Auctions-on Premises Owned or Rented by the Insured',
    descriptionCode: 'AuctionsonPremisesOwnedRentedbytheInsured',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    spoilageClass: 'BakeryGoods',
    classRestrictedCoverages: ['Spoilage'],
    code: '71311',
    description: 'Bakeries-Retail-With Baking on Premises',
    descriptionCode: 'BakeriesRetailWithBakingonPremises',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71332',
    description: 'Barber Shops',
    descriptionCode: 'BarberShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    spoilageClass: 'Pharmaceuticals',
    classRestrictedCoverages: ['Spoilage'],
    code: '71444',
    description: 'Dental Laboratories',
    descriptionCode: 'DentalLaboratories',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71811',
    description: 'Laundry & Dry Cleaning-Laundry & Dry Cleaning/Dyeing Receiving Stations',
    descriptionCode: 'LaundryDryCleaningLaundryDryCleaningDyeingReceivin',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71837',
    description: 'Mail Box/Packaging Stores-Packaging Services',
    descriptionCode: 'MailBoxPackagingStoresPackagingServices',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71837',
    description: 'Mailing/Addressing Co-Mailing List Compiling Services/Mailing List Publishers',
    descriptionCode: 'MailingAddressingCoMailingListCompilingSrvcsMailin',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71837',
    description: 'Mail Box/Packaging Stores-Packing & Preparing Goods for Shipping',
    descriptionCode: 'MailBoxPackagingStoresPackingPreparingGoodsShippin',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71837',
    description: 'Mailing/Addressing Co-Direct Mailing Co',
    descriptionCode: 'MailingAddressingCoDirectMailingCo',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71842',
    description: 'Engraving',
    descriptionCode: 'Engraving',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71855',
    description: 'Lithographing',
    descriptionCode: 'Lithographing',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71865',
    description: 'Funeral Homes/Chapels',
    descriptionCode: 'FuneralHomesChapels',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71877',
    description: 'Copying & Duplicating Stores',
    descriptionCode: 'CopyingDuplicatingStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71888',
    description: 'Photoengraving',
    descriptionCode: 'Photoengraving',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71899',
    description: 'Photographers',
    descriptionCode: 'Photographers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71912',
    description: 'Printing',
    descriptionCode: 'Printing',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71921',
    description: 'Television/Radio Receiving Set Installation or Repair',
    descriptionCode: 'TelevisionRadioReceivingSetInstallationRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71926',
    description: 'Shoe Stores-Repair',
    descriptionCode: 'ShoeStoresRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71941',
    description: 'Jewelry-Repair',
    descriptionCode: 'JewelryRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71952',
    description: 'Nail Salons',
    descriptionCode: 'NailSalons',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71952',
    description: 'Beauty Parlors & Hair Styling Salons',
    descriptionCode: 'BeautyParlorsHairStylingSalons',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71961',
    description: 'Tailoring/Dressmaking Establishments-Custom',
    descriptionCode: 'TailoringDressmakingEstablishmentsCustom',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '71976',
    description: 'Taxidermists',
    descriptionCode: 'Taxidermists',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /* 89990 Tour Guide is a Controlled class {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89990',
    description: 'Tour Guide',
    descriptionCode: 'TourGuide',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89991',
    description: 'Pet Grooming',
    descriptionCode: 'PetGrooming',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89993',
    description: 'Document Scanning and/or Preservation',
    descriptionCode: 'DocumentScanningPreservation',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89994',
    description: 'Disc Jockey',
    descriptionCode: 'DiscJockey',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /* 89997 Automobile Oil Change Store is a Controlled class {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89997',
    description: 'Automobile Oil Change Store',
    descriptionCode: 'AutomobileOilChangeStore',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  }, */
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89998',
    description: 'Automobile Glass Repair',
    descriptionCode: 'AutomobileGlassRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'ProcessingandService',
    classRestrictedCoverages: [],
    code: '89999',
    description: 'Automobile Detailing',
    descriptionCode: 'AutomobileDetailing',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09611',
    description: 'Bistros, Brasseries, and Cafes-With no sales of alcoholic beverages',
    descriptionCode: 'BistrosBrasseriesandCafesWithnosalesalcoholicbever',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09621',
    description:
      'Bistros, Brasseries, and Cafes-With sales of alcoholic beverages up to 25% of total sales',
    descriptionCode: 'BistrosBrasseriesandCafesWithsalesalcoholicbev25',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    bopV1Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09621',
    description:
      'Bistros, Brasseries, and Cafes-With sales of alcoholic beverages up to 50% of total sales',
    descriptionCode: 'BistrosBrasseriesandCafesWithsalesalcoholicbeverag',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09631',
    description: 'Diners-With no sales of alcoholic beverages',
    descriptionCode: 'DinersWithnosalesofalcoholicbeverages',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09641',
    description: 'Diners-With sales of alcoholic beverages up to 25% of total sales',
    descriptionCode: 'DinersWithsalesalcoholicbeveragesupto25totalsales',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    bopV1Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09641',
    description: 'Diners-With sales of alcoholic beverages up to 50% of total sales',
    descriptionCode: 'DinersWithsalesalcoholicbeveragesupto50totalsales',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09651',
    description: 'Family-style Restaurants - With no sales of alcoholic beverages',
    descriptionCode: 'FamilystyleRestaurantsWithnosalesalcoholicbeverage',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09661',
    description:
      'Family-style Restaurants-With sales of alcoholic beverages up to 25% of total sales',
    descriptionCode: 'FamilystyleRestaurantsWithsalesalcoholicbev25',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    bopV1Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09661',
    description:
      'Family-style Restaurants - With sales of alcoholic beverages up to 50% of total sales',
    descriptionCode: 'FamilystyleRestaurantsWithsalesalcoholicbeveragesu',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09671',
    description:
      'Bistros, Brasseries and Cafes-Bring Your Own Alcohol Estabts-With no sales of alcoholic beverages',
    descriptionCode: 'BistrosBrasseriesandCafesBringYourOwnAlcoholEstabt',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09681',
    description:
      'Diners-Bring Your Own Alcohol Establishments-With no sales of alcoholic beverages',
    descriptionCode: 'DinersBringYourOwnAlcoholEstablishmentsWithnosales',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09691',
    description:
      'Family-style Restaurants-Bring Your Own Alcohol Establishments-With no sales of alcoholic beverages',
    descriptionCode: 'FamilystyleRestaurantsBringYourOwnAlcoholEstablish',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09997',
    description: 'Wine bars - with sales of alcohol beverages up to 30% of total sales', // Note: this descriptionCode has not been updated in GW but the % has changed in the description.
    descriptionCode: 'WinebarsSalesOfAlcoholUpto50',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09998',
    description:
      'Taverns with Full Kitchen - with sales of alcohol beverages up to 30% of total sales', // Note: this descriptionCode has not been updated in GW but the % has changed in the description.
    descriptionCode: 'TavernsFullKitchenSalesOfAlcohol50',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*
  {
    bopV2Only: true,
    category: 'RestaurantCasualDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09999',
    description:
      'Pubs with Full Kitchen - with sales of alcohol beverages up to 50% of total sales',
    descriptionCode: 'PubsWithFullKitchenSalesofAlcohol50',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09001',
    description: 'Cafes',
    descriptionCode: 'Cafes',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09021',
    description: 'Cafeteria Style - Buffet',
    descriptionCode: 'CafeteriaStyleBuffet',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09031',
    description: 'Chicken',
    descriptionCode: 'Chicken',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09051',
    description: 'Concession Stands/Snack Bars',
    descriptionCode: 'ConcessionStandsSnackBars',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09071',
    description: 'Delicatessens & Sandwich Shops',
    descriptionCode: 'DelicatessensSandwichShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09091',
    description: 'Donut Shops',
    descriptionCode: 'DonutShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09111',
    description: 'Drive-Ins/Service in Car',
    descriptionCode: 'DriveInsServiceinCar',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09151',
    description: 'Hamburger/Malt Shops',
    descriptionCode: 'HamburgerMaltShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09161',
    description: 'Hotdog Shops',
    descriptionCode: 'HotdogShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09181',
    description: 'Asian Style',
    descriptionCode: 'AsianStyle',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09191',
    description: 'Other Ethnic Style',
    descriptionCode: 'OtherEthnicStyle',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09201',
    description: 'Pizza Shops',
    descriptionCode: 'PizzaShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09221',
    description: 'Roast Beef',
    descriptionCode: 'RoastBeef',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09241',
    description: 'Seafood',
    descriptionCode: 'Seafood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09251',
    description: 'Take Out Only Restaurants-No on-Premises Consumption of Food',
    descriptionCode: 'TakeOutOnlyRestaurantsNoonPremisesConsumptionFood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  /*
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09341',
    description: 'Convenience Food Stores-With Fast Food Restaurant-No Gasoline Sales',
    descriptionCode: 'ConvenienceFoodStoresWithFastFoodRestaurantNoGasol',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  */
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09995',
    description: 'Pretzel Shops',
    descriptionCode: 'PretzelShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantFastFood',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09996',
    description: 'Caterers',
    descriptionCode: 'Caterers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFineDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09421',
    description: 'Fine Dining-With no sales of alcoholic beverages',
    descriptionCode: 'FineDiningWithnosalesofalcoholicbeverages',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFineDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09431',
    description: 'Fine Dining-With sales of alcoholic beverages up to 30% of total sales',
    descriptionCode: 'FineDiningWithsalesalcoholicbeveragesupto30totalsa',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFineDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09441',
    description:
      'Fine Dining-With sales of alcoholic beverages more than 30% up to 50% of total sales',
    descriptionCode: 'FineDiningWithsalesalcoholicbeveragesmorethan30upt',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantFineDining',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09451',
    description: 'Bring Your Own Alcohol Establishments-With no sales of alcoholic beverages',
    descriptionCode: 'BringYourOwnAlcoholEstablishmentsWithnosalesalcoho',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09011',
    description: 'Cafes',
    descriptionCode: 'Cafes',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09041',
    description: 'Coffee Bars/Shops',
    descriptionCode: 'CoffeeBarsShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09061',
    description: 'Concession Stands/Snack Bars',
    descriptionCode: 'ConcessionStandsSnackBars',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09081',
    description: 'Delicatessens & Sandwich Shops',
    descriptionCode: 'DelicatessensSandwichShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09101',
    description: 'Donut Shops',
    descriptionCode: 'DonutShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09121',
    description: 'Drive-Ins/Service in Car',
    descriptionCode: 'DriveInsServiceinCar',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09171',
    description: 'Ice Cream & Yogurt Stores',
    descriptionCode: 'IceCreamYogurtStores',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09211',
    description: 'Pizza Shops',
    descriptionCode: 'PizzaShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09231',
    description: 'Salad Bars',
    descriptionCode: 'SaladBars',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09261',
    description: 'Take Out Only Restaurants-No on-Premises Consumption of Food',
    descriptionCode: 'TakeOutOnlyRestaurantsNoonPremisesConsumptionFood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09331',
    description: 'Convenience Food Stores-With Limited Cooking Restaurant-No Gasoline Sales',
    descriptionCode: 'ConvenienceFoodStoresWithLimitedCookingRestaurantN',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
    excludeInStates: ['CA', 'FL', 'LA', 'NJ', 'NY', 'TX'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09991',
    description: 'Pretzel Shops',
    descriptionCode: 'PretzelShops',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09992',
    description: 'Juice, Health Drink, Smoothie Bars',
    descriptionCode: 'JuiceHealthDrinkSmoothieBars',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'RestaurantLimitedCooking',
    spoilageClass: 'Restaurants',
    classRestrictedCoverages: ['Spoilage'],
    code: '09993',
    description: 'Caterers',
    descriptionCode: 'Caterers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50061',
    description: 'Appliance Distbtrs-Household Types-Radio, Television or Compact Disc Players',
    descriptionCode: 'ApplianceDistbtrsHouseholdTypesRadioTelevisionComp',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50081',
    description: 'Appliance Distbtrs-Household & Home Furnishings',
    descriptionCode: 'ApplianceDistbtrsHouseholdHomeFurnishings',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50111',
    description: 'Automobile Parts & Supplies-Distbtrs',
    descriptionCode: 'AutomobilePartsSuppliesDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    spoilageClass: 'BakeryGoods',
    classRestrictedCoverages: ['Spoilage'],
    code: '50141',
    description: 'Bakeries-Distbtrs-No Baking on Premises',
    descriptionCode: 'BakeriesDistbtrsNoBakingonPremises',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50171',
    description: 'Barber/Beauty Shop Supplies Distbtrs',
    descriptionCode: 'BarberBeautyShopSuppliesDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50201',
    description: 'Bookbinding & Printing Supplies-Distbtrs',
    descriptionCode: 'BookbindingPrintingSuppliesDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50231',
    description: "Clothing/Wearing Apparel-Distbtrs-Men's and Boy's Clothing and Furnishing",
    descriptionCode: 'ClothingWearingApparelDistbtrsMensandBoysClothinga',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50231',
    description: "Clothing/Wearing Apparel-Distbtrs-Woman's, Children's & Infant's Clothing/Access",
    descriptionCode: 'ClothingWearingApparelDistbtrsWomansChildrensInfan',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50261',
    description: 'Collectibles & Memorabilia-Distbtrs',
    descriptionCode: 'CollectiblesMemorabiliaDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50321',
    description: 'Fabric-Distbtrs',
    descriptionCode: 'FabricDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50351',
    description: 'Floor Covering-Distbtrs',
    descriptionCode: 'FloorCoveringDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    spoilageClass: 'Florists',
    classRestrictedCoverages: ['Spoilage'],
    code: '50381',
    description: 'Florists-Distbtrs',
    descriptionCode: 'FloristsDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    spoilageClass: 'FruitsandVegetables',
    classRestrictedCoverages: ['Spoilage'],
    code: '50391',
    description: 'Fruit or Vegetable-Distbtrs',
    descriptionCode: 'FruitorVegetableDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50471',
    description: 'Gardening & Light Farming Supply-Distbtrs',
    descriptionCode: 'GardeningLightFarmingSupplyDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50481',
    description: 'Grocery-Distbtrs',
    descriptionCode: 'GroceryDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50571',
    description: 'Hearing Aid-Distbtrs',
    descriptionCode: 'HearingAidDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50581',
    description: 'AC /Combined AC & Heating Equipment-Distbtrs Only',
    descriptionCode: 'ACCombinedACHeatingEquipmentDistbtrsOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50581',
    description: 'Heating/Combined Heating & AC Equipment-Distbtrs Only',
    descriptionCode: 'HeatingCombinedHeatingACEquipmentDistbtrsOnly',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50641',
    description: "Hobby, Craft/Artists' Supply-Distbtrs",
    descriptionCode: 'HobbyCraftArtistsSupplyDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50651',
    description: 'Janitorial Supplies-Distbtrs',
    descriptionCode: 'JanitorialSuppliesDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50661',
    description: 'Jewelry-Distbtrs',
    descriptionCode: 'JewelryDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    spoilageClass: 'MeatandPoultryMarkets',
    classRestrictedCoverages: ['Spoilage'],
    code: '50671',
    description: 'Meat, Fish, Poultry or Seafood Distbtrs-Poultry',
    descriptionCode: 'MeatFishPoultryorSeafoodDistbtrsPoultry',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    spoilageClass: 'MeatandPoultryMarkets',
    classRestrictedCoverages: ['Spoilage'],
    code: '50671',
    description: 'Meat, Fish, Poultry or Seafood Distbtrs-Meat',
    descriptionCode: 'MeatFishPoultryorSeafoodDistbtrsMeat',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    spoilageClass: 'Seafood',
    classRestrictedCoverages: ['Spoilage'],
    code: '50671',
    description: 'Meat, Fish, Poultry or Seafood Distbtrs-Fish or Seafood',
    descriptionCode: 'MeatFishPoultrySeafoodDistbtrsFishSeafood',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50691',
    description: 'Office Machines or Appliances-Distbtrs-No Repair',
    descriptionCode: 'OfficeMachinesorAppliancesDistbtrsNoRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50721',
    description: 'Optical Goods-Distbtrs',
    descriptionCode: 'OpticalGoodsDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50741',
    description: 'Plumbing Supplies and Fixtures-Distbtrs',
    descriptionCode: 'PlumbingSuppliesandFixturesDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50771',
    description: 'Refrigeration Equipment-Comml-Distbtrs',
    descriptionCode: 'RefrigerationEquipmentCommlDistbtrs',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50801',
    description: 'Station/Paper Prod Distbtrs-Paper (Fine, Printing, Writing) Bulk, Wholesale',
    descriptionCode: 'StationPaperProdDistbtrsPaperFinePrintingWritingBu',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50801',
    description:
      'Station/Paper Prod Distbtrs-Paper No Offc Supply, Print/Write Paper, Station Wholesale',
    descriptionCode: 'StationPaperProdDistbtrsPaperNoOffcSupplyPrintWrit',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50813',
    description: 'Equipment, Fixtures or Supplies Distbtrs-Restaurant, Bars and Hotel Equipment',
    descriptionCode: 'EquipmentFixturesSuppliesDistbtrsRestaurantBarsand',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: false,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50813',
    description: 'Equipment, Fixtures or Supplies Distbtrs-Office & Store Equipment',
    descriptionCode: 'EquipmentFixturesSuppliesDistbtrsOfficeStoreEquipm',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50996',
    description: 'Hardware & Tool (not power) - Distributors',
    descriptionCode: 'HardwareToolDistributors',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50997',
    description: 'Food - Beverage - Alcohol',
    descriptionCode: 'FoodBeverageAlcohol',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50998',
    description: 'Food - Beverage - Non Alcohol',
    descriptionCode: 'FoodBeverageNonAlcohol',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Wholesaler',
    classRestrictedCoverages: [],
    code: '50999',
    description: 'Food - Bakery Items',
    descriptionCode: 'FoodBakeryItems',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'OfficeCondominium',
    classRestrictedCoverages: [],
    code: '69982',
    description: 'Social Service -not for profit only,  no onsite or housing services',
    descriptionCode: 'SocialService',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69982',
    description: 'Social Service -not for profit only,  no onsite or housing services',
    descriptionCode: 'SocialServiceLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69983',
    description: 'Social Service -not for profit only,  no onsite or housing services',
    descriptionCode: 'SocialService',
    lroOptions: ['Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Office',
    classRestrictedCoverages: [],
    code: '69983',
    description: 'Social Service -not for profit only,  no onsite or housing services',
    descriptionCode: 'SocialServiceLesserRiskOnly',
    lroOptions: ['10orless'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99978',
    description: 'Window Treatment Makers',
    descriptionCode: 'WindowTreatmentsMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99979',
    description: 'Wallcovering Makers',
    descriptionCode: 'WallcoveringMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99980',
    description: 'Pottery Makers - household',
    descriptionCode: 'PotteryMakershousehold',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99981',
    description: 'Picklers',
    descriptionCode: 'Picklers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99982',
    description: 'Pasta Makers',
    descriptionCode: 'PastaMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99983',
    description: 'Monument Makers and Retailers',
    descriptionCode: 'MonumentMakersandRetailers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99984',
    description: 'Maple Syrup Makers',
    descriptionCode: 'MapleSyrupMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99985',
    description: 'Leather Goods Makers',
    descriptionCode: 'LeatherGoodsMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99986',
    description: 'Lamp Makers - Electric',
    descriptionCode: 'LampMakersElectric',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99987',
    description: 'Jewelry Makers',
    descriptionCode: 'JewelryMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99988',
    description: 'Jam and Jelly Makers',
    descriptionCode: 'JamandJellyMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99989',
    description: 'Ice Makers and Distributors',
    descriptionCode: 'IceMakersandDistributors',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99990',
    description: 'Ice Cream and Frozen Dessert Makers',
    descriptionCode: 'IceCreamandFrozenDessertMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99991',
    description: 'Fruit Juice Makers',
    descriptionCode: 'FruitJuiceMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99992',
    description: 'Cutlery Makers',
    descriptionCode: 'CutleryMakersratedasJewelryRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99993',
    description: 'Cosmetic Makers',
    descriptionCode: 'CosmeticMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99994',
    description: 'Confectionery Makers',
    descriptionCode: 'ConfectioneryMakersCandyOrConfectioneryStore',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99995',
    description: 'Clock and Watch Makers',
    descriptionCode: 'ClockAndWatchMakersRratedAsJewelryRepair',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99996',
    description: 'Chocolate and Cocoa Makers',
    descriptionCode: 'ChocolateAndCocoaMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99997',
    description: 'Cheese Makers',
    descriptionCode: 'CheeseMakers',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99998',
    description: 'Artists - performing, audio or visual',
    descriptionCode: 'ArtistsPerformingAudioOrVisual',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
  {
    bopV2Only: true,
    category: 'Artisans',
    classRestrictedCoverages: [],
    code: '99999',
    description: 'Apparel Makers',
    descriptionCode: 'ApparelMakerstailoringordressmaker',
    lroOptions: ['10orless', 'Over10', 'NotApplicable'],
  },
];

export const classCodesWithSpoilage = buildingClassifications
  .filter((classification) => {
    return classification.classRestrictedCoverages.includes('Spoilage');
  })
  .map((classification) => classification.code);
